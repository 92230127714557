import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Profile.css';
import Loading from './../Layout/Loading';
import BottomNav from './../Layout/BottomNav'; 


export default function Alamat(props) {
    const { isLoading, profile } = props;

    return (

        <Container>
            {/* Loading State */}
            {isLoading && <Loading />}

            <Row>
                {!isLoading &&

                    <Col md={12} xs={12}>
                        <div className="TopHeader">

                            {profile != null &&
                                <div className="TopHeaderName">{profile.FullName}</div>}
                            {profile != null &&
                                <div className="TopHeaderNumber">{profile.MemberNumber}</div>}



                        </div>

                    </Col>
                }
            </Row>

            <Row>
                {!isLoading &&

                    <Col>
                        <div className="profileContainer">

                            <Row>


                                <Col md={3} xs={12}>
                                    <div className="profileMenu">
                                        <div className="profileJudul">Profil</div>
                                        <ul className="sidenav-list-menu">
                                            <li><a href={"/profile"} >Data Pribadi</a></li>
                                            <li><a className="active" href={'/profile/alamat'}>Daftar Alamat</a></li>

                                        </ul>

                                    </div>

                                </Col>

                                <Col md={9} xs={12}>   
                                    {!isLoading && props.children}
                                </Col>

                            </Row>




                        </div>

                    </Col>
                }

            </Row>


            <BottomNav activeKey={5} />

        </Container>


    );
}