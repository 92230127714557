import axios from "axios";
import { removeStorage } from "./common";

export const login = async (data) => {
  const LOGIN_ENDPOINT = "/user/login";
  try {
    // tambahkan parameter OID
    data = { ...data, OID: parseInt(process.env.REACT_APP_OID), Aud: "web" };

    // jadikan userid lowercase
    if (typeof data["userid"] !== "undefined") {
      data["userid"] = data["userid"].toLowerCase();
    }

    let response = await axios.post(LOGIN_ENDPOINT, data);
    let login = response.data.data;
    let result = null;
    //Berhasil login

    if (response.status === 200 && typeof login.Token !== "undefined") {
      let UserID = login.UserID;
      let OID = login.OID;
      let Token = login.Token;

      localStorage.setItem("AGREGO_USER_ID", UserID);
      localStorage.setItem("AGREGO_USER_TOKEN", Token);
      localStorage.setItem("AGREGO_USER_OID", OID);
      localStorage.removeItem(process.env.REACT_APP_PREFIX + "FCM_TOKEN");

      result = {
        success: true,
        message: "Berhasil login",
        data: { UserID, OID, Token },
      };
    } else {
      result = {
        success: false,
        message:
          "Email / Password salah, periksa kembali email dan password anda",
      };
    }

    return result;
  } catch (error) {
    let result = { success: false, message: "" };
    let respon = error.response;
    let responData = respon != null && respon.data != null ? respon.data : null;
    let responMsg =
      responData != null && responData.message != null
        ? responData.message
        : null;

    if (
      respon &&
      parseInt(respon.status) === 401 &&
      responMsg.toLowerCase() === "unverified account"
    ) {
      result["message"] = "UNVERIFIED_ACCOUNT";
      return result;
    } else if (
      respon &&
      parseInt(respon.status) === 401 &&
      responMsg.toLowerCase() === "verified account"
    ) {
      result["message"] = "UNVERIFIED_ACCOUNT_EMAIL";
      return result;
    } else if (respon) {
      switch (parseInt(respon.status)) {
        case 401:
          result["message"] =
            "Email / Password salah, periksa kembali email dan password anda";
          break;

        case 500:
          result["message"] =
            "Gagal terhubung dengan server, periksa jaringan internet anda";
          break;

        default:
          result["message"] =
            "Maaf saat ini kami tidak bisa memenuhi permintaan anda, coba kembali nanti";
          break;
      }
    }

    return result;
  }
};

export const register = async (data) => {
  const LOGIN_ENDPOINT = "/user";
  try {
    // tambahkan parameter OID
    data = { ...data, OrgName: process.env.REACT_APP_ORGNAME };

    // jadikan email lowercase
    if (typeof data["Email"] !== "undefined") {
      data["Email"] = data["Email"].toLowerCase();
    }

    let response = await axios.post(LOGIN_ENDPOINT, data);
    let register = response.data.data;
    let message = response.data.message;
    let result = null;

    if (response.status === 200 && register != null && response.data.status) {
      let UserID = register.UserID;
      let FullName = register.FullName;
      let OID = register.OID;

      result = {
        success: true,
        message: "Berhasil register",
        data: { UserID, OID, FullName },
      };
    } else if (typeof message !== "undefined") {
      // catch message
      if (message === "record not found") {
        message = "Organisasi yang anda masukkan belum terdaftar";
      } else if (message === "Email already registered") {
        message = "Email sudah terdaftar";
      }

      result = { success: false, message: message };
    }

    return result;
  } catch (error) {
    let result = { success: false, message: "" };
    let respon = error.response;

    if (typeof respon !== "undefined") {
      switch (parseInt(respon.status)) {
        case 401:
          result["message"] = "Email / Password sudah terdaftar";
          break;

        case 500:
          result["message"] =
            "Gagal terhubung dengan server, periksa jaringan internet anda";
          break;

        default:
          result["message"] =
            "Maaf saat ini kami tidak bisa memenuhi permintaan anda, coba kembali nanti";
          break;
      }
    } else if (typeof error.message !== "undefined") {
      result["message"] = error.message;
    }

    return result;
  }
};

export const forgot = async (data) => {
  const LOGIN_ENDPOINT = "/forgotpassword?lang=id";

  try {
    // tambahkan parameter OID
    // data = {...data, "OID": parseInt(process.env.REACT_APP_OID), "Aud":"web"};
    data = {
      ...data,
      OrganizationName: process.env.REACT_APP_ORGNAME,
      Aud: "web",
    };

    // jadikan email lowercase
    if (typeof data["Email"] !== "undefined") {
      data["Email"] = data["Email"].toLowerCase();
    }

    let response = await axios.put(LOGIN_ENDPOINT, data);
    // let forgot = response.data.data;
    let result = null;
    //Berhasil login

    if (
      parseInt(response.status) === 200 &&
      response.data.message === "Email not exists" &&
      !response.data.status
    ) {
      // let Email = forgot.Email;
      // let OID = response.OID;
      // // let Token = forgot.Token;

      // // localStorage.setItem('AGREGO_USER_ID', Email);
      // // localStorage.setItem('AGREGO_USER_TOKEN', Token);
      //  localStorage.setItem('AGREGO_USER_OID', OID);

      // let CSPHONE = localStorage.AGREGO_CSPHONE;
      // let ORNAME = process.env.REACT_APP_ORGNAME;
      result = { success: false, message: response.data.usermsg };
      // const CS_ENDPOINT = '/customercare/';
      // let responsecs = await axios.put(CS_ENDPOINT,data.OID);
    } else if (parseInt(response.status) === 200 && response.data.status) {
      result = {
        success: true,
        message:
          "Cek email anda. Petunjuk untuk memperbarui password akan dikirim ke email anda.",
      };
    }

    return result;
  } catch (error) {
    let result = { success: false, message: "" };
    let respon = error.response;

    if (typeof respon !== "undefined") {
      switch (parseInt(respon.status)) {
        case 401:
          result["message"] = "Email / Password sudah terdaftar";
          break;

        case 500:
          result["message"] =
            "Gagal terhubung dengan server, periksa jaringan internet anda";
          break;

        default:
          result["message"] =
            "Maaf saat ini kami tidak bisa memenuhi permintaan anda, coba kembali nanti";
          break;
      }
    } else if (typeof error.message !== "undefined") {
      result["message"] = error.message;
    }

    return result;
  }
};

export const logout = () => {
  removeStorage();
};

// cek user apakah sudah login (memiliki token) atau belum
export const isLogin = () => {
  let authToken = localStorage.getItem(process.env.REACT_APP_PREFIX + "TOKEN");

  if (authToken) {
    return true;
  }

  return false;
};
