import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAppState } from "redux/actions/app";
import imgDefaultError from "./../../Images/default-error.png";

const ShowButton = ({ status, ...props }) => {
  let btn = null;
  switch (status) {
    case "ERROR_400":
      btn = (
        <Button variant="link" href="/login">
          Login
        </Button>
      );
      break;

    case "ERROR_401":
      btn = (
        <Button variant="link" href="/login">
          Login
        </Button>
      );
      break;

    case "ERROR_404":
      btn = (
        <Button variant="link" href="/beranda">
          Kembali ke beranda
        </Button>
      );
      break;

    default:
      btn = (
        <Button variant="link" onClick={props.onHistoryBack}>
          Coba Lagi
        </Button>
      );
      break;
  }

  return btn;
};

// Loading state
export default function ErrorPage({ status, ...props }) {
  const [msg, setMessage] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const history = useHistory();
  const stateApp = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    const { errorType, message, messageExtra } = stateApp;
    setStatusCode(errorType);

    if (status === "ERROR_404") {
      setStatusCode("ERROR_404");
      setMessage([
        "Halaman tidak ditemukan",
        "Periksa kembali halaman yang anda tuju",
      ]);
    } else if (message) {
      setMessage([message, messageExtra]);
    } else {
      setMessage(["Terjadi kesalahan"]);
    }

    dispatch(resetAppState());
  }, []);

  useEffect(() => {
    if (stateApp && stateApp["isError"]) {
      dispatch(resetAppState());
    }
  }, [stateApp]);

  return (
    <Container>
      <div className="text-center" style={{ marginTop: "80px" }}>
        <img src={imgDefaultError} width={300} alt="error-img" />
        {msg && msg[0] && (
          <div style={{ paddingTop: "64px", fontSize: "20px" }}>{msg[0]}</div>
        )}
        {msg && msg[1] && <div>{msg[1]}</div>}
        <ShowButton
          status={statusCode}
          onHistoryBack={(e) => history.goBack()}
        />
      </div>
    </Container>
  );
}
