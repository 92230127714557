import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { fetchMartProductChoice } from "../../../redux/actions/mart_product";
import EmptyState from "./../../Layout/EmptyState";
import Loading from "./../../Layout/Loading";
import ProductItem from "./../Product/ProductItem.component";
import "./ProductChoice.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.5,
  },
};

function ProductChoice(props) {
  const { isLoading } = props;
  let filterData = { Status: 1 };

  const productState = useSelector((state) => state.martproduct);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState(props.title);
  const [desc, setDesc] = useState(props.description);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchMartProductChoice({ page: 1, size: 200, search: filterData }, true)
    );
    setLoadingProduct(true);
  }, [isLoading]);

  useEffect(() => {
    if (!productState.isFetchingMartProductChoiceList && loadingProduct) {
      const { Title, Subtitle, ProductChoices } = {
        ...productState.mart_product_choice_list,
      };

      let products =
        ProductChoices && ProductChoices.length
          ? ProductChoices.filter((obj) => parseInt(obj.Stock) > 0)
          : null;

      if (!products || (products && !products.length)) {
        props.onEmpty(true);
      }

      setData(products);
      setTitle(Title);
      setDesc(Subtitle);
      setLoadingProduct(false);
    }
  }, [productState]);

  return (
    <div
      className={"product-choice-card " + props.className}
      style={props.style}
    >
      {(productState.isFetchingMartProductChoiceList || isLoading) && (
        <Loading />
      )}

      {!isLoading && !productState.isFetchingMartProductChoiceList && !data && (
        <div className="text-center">
          <EmptyState style={{ margin: "auto" }} />
        </div>
      )}

      <div className="choice-box">
        <div className="choice-info">
          <div className="choice-info-title"> {title}</div>
          <div className="choice-info-desc">{desc} </div>
          <div className="d-lg-none float-right">
            <a href="/shop/product?type=choice">Lihat Semua</a>
          </div>
        </div>
        <div className="choice-product">
          <div className="d-none d-lg-block text-right mb-2">
            <a href="/shop/product?type=choice">Lihat Semua</a>
          </div>
          {!productState.isFetchingMartProductChoiceList &&
            data &&
            data.length && (
              <Carousel
                responsive={responsive}
                autoPlay={false}
                deviceType={props.deviceType}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="react-multi-carousel-dot-list"
                keyBoardControl={true}
                swipeable={true}
                draggable={true}
              >
                {data.map((item, index) => {
                  return (
                    <div key={index}>
                      <ProductItem
                        product={item}
                        style={props.styleItem}
                        className={props.classNameItem}
                      />
                    </div>
                  );
                })}
              </Carousel>
            )}
        </div>
      </div>
    </div>
  );
}

ProductChoice.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmpty: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  classNameItem: PropTypes.string,
  styleItem: PropTypes.object,
};

ProductChoice.defaultProps = {
  title: "Produk Pilihan",
  description: "Produk unggulan pilihan kami ",
  isLoading: false,
  onEmpty: () => {},
  className: "",
  style: {},
  classNameItem: "",
  styleItem: {},
};

export default ProductChoice;
