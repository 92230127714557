import React, {useEffect} from 'react';  
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMartProduct } from '../../../redux/actions/mart_product'; 
import './ProductLain.css';
import Carousel from "react-multi-carousel"; 
import ProductItem from './../Product/ProductItem.component'; 
import { filterLimit } from './../../../utils/common';

const responsive = { 
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3
      
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 3
      
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2.4
    }
};

function ProductLain(props) { 

    const {title, excludeProductID, CategoryID, isLoading, storeID} = props; 

    const init = true; 
 
    const productState = useSelector(state => {
        // tampilkan product id selain yang terpilih di halaman detail 
        if(state.martproduct.mart_products.length > 0) {
            let productList = filterLimit(state.martproduct.mart_products, (o) => {
                return !isNaN(parseInt(o.Stock)) && parseInt(o.Stock) > 0 && parseInt(excludeProductID) !== parseInt(o.ID)
            },10); 
            
            state.martproduct = {...state.martproduct, mart_products: productList};
        } 

        return state.martproduct;
    });

    
    const dispatch = useDispatch(); 

    useEffect(() => {

        let filterData = {};
        filterData['Status'] = 1;
        if(CategoryID != null) {
            filterData['CategoryID'] = CategoryID;
        } else if (typeof(filterData['CategoryID']) !== "undefined") {
            delete filterData['CategoryID'];
        } 

        if(storeID) {
            filterData['StoreID'] = storeID;
        } else {
            delete filterData['StoreID'];
        }  
        
        dispatch(fetchMartProduct({page: 1, size: 50, search:filterData}));  

    },[init])
 
    return (<>

        {!productState.isFetchingMartProductList && productState.mart_products.length > 0 && 
        <div style={props.style} className={"product-lain-card "+props.className}>
            <div className="product-lain-title">{title}</div>

            {/* {(productState.isFetchingMartProductList || isLoading) && <Loading />}

            {!productState.isFetchingMartProductList && productState.mart_products.length <= 0 &&  <div className="text-center"><EmptyState style={{margin:'auto'}} /></div>} */}

            <Carousel responsive={responsive} autoPlay={false}
            deviceType={props.deviceType}
            
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="react-multi-carousel-dot-list"
            keyBoardControl={true}
            swipeable={true}
            draggable={true}
        > 
            {!isLoading && !productState.isFetchingMartProductList && productState.mart_products.length > 0 && productState.mart_products.map((item, index) => {
                return (<div key={index}>  
                    <ProductItem product={item} className={props.classNameItem} style={props.styleItem} />
                </div>)})} 
            </Carousel>
        </div>}
    </>)
}


ProductLain.propTypes = {
    title: PropTypes.string,
    excludeProductID: PropTypes.number,
    CategoryID: PropTypes.number,
    isLoading: PropTypes.bool,
    minimalItem: PropTypes.number,
    className: PropTypes.string,
    classNameItem: PropTypes.string,
    style: PropTypes.object,
    styleItem: PropTypes.object,
    onEmpty: PropTypes.func, 
}


ProductLain.defaultProps = {
    title: "Produk Lainnya",
    excludeProductID: null,
    CategoryID: null,
    isLoading: false,
    minimalItem: 1,
    className: "",
    classNameItem: "",
    style: {},
    styleItem: {},
    onEmpty: () => {},
}


export default ProductLain;