import Axios from "axios";
import { addToCart, setDataSubmitOrder } from "./../../utils/cart";
import { displayMessage } from "./app";

export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const UPDATE_DELIVERY_OPTION = "UPDATE_DELIVERY_OPTION";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_DELIVERY_COURIER = "UPDATE_DELIVERY_COURIER";
export const UPDATE_STORE_DELIVERY_ADDRESS = "UPDATE_STORE_DELIVERY_ADDRESS";
export const GET_ALL_CART = "GET_ALL_CART";
export const GET_ALL_CART_DIGITAL = "GET_ALL_CART_DIGITAL";
export const GET_CART_BY_PRODUCT = "GET_CART_BY_PRODUCT";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const REQUEST_SUBMIT_ORDER = "REQUEST_SUBMIT_ORDER";
export const RESULT_ADD_CART = "RESULT_ADD_CART";
export const RESULT_SUBMIT_ORDER = "RESULT_SUBMIT_ORDER";

// Request mart product from API
export const getCart = () => ({
  type: GET_ALL_CART,
});

// Request mart product from API
export const getCartDigital = () => ({
  type: GET_ALL_CART_DIGITAL,
});

// Request mart product from API
export const getCartByProduct = (productID) => ({
  type: GET_CART_BY_PRODUCT,
  payload: {
    productID,
  },
});

// Request mart product from API
const addingCart = (product) => ({
  type: ADD_TO_CART,
  payload: {
    product,
  },
});

// Request mart product from API
const resultAddCart = () => ({
  type: RESULT_ADD_CART,
});

// Request mart product from API
const requestSubmitOrder = () => ({
  type: REQUEST_SUBMIT_ORDER,
});

// Request mart product from API
const resultSubmitOrder = (data, isError, errorNote) => ({
  type: RESULT_SUBMIT_ORDER,
  payload: {
    data,
    isError: typeof isError !== "undefined" && isError ? isError : false,
    message: typeof errorNote !== "undefined" ? errorNote : null,
  },
});

export const updateDeliveryAddress = (data) => {
  return {
    type: UPDATE_DELIVERY_ADDRESS,
    payload: {
      data,
    },
  };
};

export const updateDeliveryOption = (DeliveryOption) => ({
  type: UPDATE_DELIVERY_OPTION,
  payload: {
    DeliveryOption,
  },
});

export const updatePaymentMethod = (data) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: {
    data,
  },
});

export const updateDeliveryCourier = (data) => ({
  type: UPDATE_DELIVERY_COURIER,
  payload: {
    data,
  },
});

export const updateStoreDeliveryAddress = (data) => ({
  type: UPDATE_STORE_DELIVERY_ADDRESS,
  payload: {
    data,
  },
});

// Request mart product from API
export const removeCart = (productID) => ({
  type: REMOVE_CART_ITEM,
  payload: {
    productID,
  },
});

// Request mart product from API
export const addCart = (product, type) => {
  return (dispatch) => {
    dispatch(addingCart(product));
    // let msg = product.Name+" telah ditambahkan ke keranjang";

    let result = addToCart(product);
    if (typeof result["status"] !== "undefined") {
      // if(!result['status']) {
      //     msg = (result['message'] != null ? result['message'] : "Gagal menambahkan produk ke keranjang");
      // }

      if (type && ["INCREMENT", "CHANGE"].includes(type)) {
        dispatch(displayMessage("Produk berhasil dimasukkan ke keranjang"));
      }
      return dispatch(resultAddCart());
    }
  };
};

// Request mart category from API
export const submitOrder = () => {
  return (dispatch) => {
    dispatch(requestSubmitOrder());

    let data = setDataSubmitOrder();

    return Axios.post("/me/order?lang=id", data)
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;

        // respon atau data dari server ada di variable data
        // let message = responseData.message;
        let message = data.message;

        // Order gagal saat wallet tidak cukup
        if (data.status === false) {
          message =
            message === "Insufficient stock"
              ? "Order Gagal. Stok tidak mencukupi, periksa kembali barang belanja anda"
              : message;
          dispatch(displayMessage(message, true));
          return dispatch(resultSubmitOrder({}, true));
        } else if (parseInt(responseData.Status) === 7) {
          message = responseData.Note;
          return dispatch(resultSubmitOrder(responseData, true, message));
        }

        // remove item from cart
        dispatch(removeCart());
        return dispatch(resultSubmitOrder(responseData));
      })
      .catch((error) => {
        console.log(error);

        // message = responseData.Note;
        return dispatch(resultSubmitOrder({}, true, "Terjadi kesalahan"));
      });
  };
};
