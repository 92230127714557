import _ from "lodash";
import moment from "moment";
import "moment/locale/id";

export const parseDate = (date) => {
  if (
    typeof date === "undefined" ||
    date == null ||
    date === "" ||
    date === "-" ||
    date === "0000-00-00"
  ) {
    return date;
  }

  if (moment(date).isValid()) {
    return moment(date).format(process.env.REACT_APP_FORMAT_DATE);
  }

  return date;
};

// full month
export const parseFullDate = (date) => {
  if (
    typeof date === "undefined" ||
    date == null ||
    date === "" ||
    date === "-" ||
    date === "0000-00-00"
  ) {
    return date;
  }

  if (moment(date).isValid()) {
    return moment(date).format("DD MMMM YYYY");
  }

  return date;
};

export const parseDateTime = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format(process.env.REACT_APP_FORMAT_DATE_TIME);
  }

  return date;
};

export const parseDateSystem = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format(process.env.REACT_APP_FORMAT_DATE_INPUT);
  }

  return date;
};

export const parseDateTimeSystem = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format(process.env.REACT_APP_FORMAT_DATE_TIME_INPUT);
  }

  return date;
};

// strip character in string
export const stripCharacter = (string, character) => {
  const re = new RegExp(`[${character}]+`, "g");
  return string.replace(re, "");
};

// karena data dari server yang kadang inconsistent ada rp kadang tidak ada mungkin juga kadang ada titik?
export const parseCurrency = (amount) => {
  if (amount == null || amount === "" || isNaN(amount)) {
    return 0;
  }

  return amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.00$/, "");
};

export const normalizePrice = (string) => {
  string = string.toString().toLowerCase();

  if (string.indexOf("rp") >= 0) {
    string = string.replace("rp", "");
  }

  if (string.indexOf(".") >= 0) {
    string = string.replace(".", "").replace(".", "");
  }

  if (string.indexOf(",") >= 0) {
    string = string.replace(",", "").replace(",", "");
  }

  return parseInt(string);
};

// mencari path dari url
export const findUrlPath = (urlString, index) => {
  let url = urlString.split("/");

  // jika tidak terisi mendapatkan path terakhir
  index =
    typeof index === "undefined" || index == null || index === ""
      ? url.length - 1
      : index;

  return typeof url[index] ? url[index] : null;
};

// Query Parameter for searching

// generic search
export const setSearchQuery = (keyword, fields, fieldType, operand) => {
  // spesific search by field {Name:'sprite',Category:'drink'}

  if (typeof keyword !== "undefined" && typeof keyword === "object") {
    // specific using operand AND
    operand = "AND";

    return setFieldSearchQuery(keyword, fields, fieldType, operand);
  }

  var search = [];
  var searchString = "";

  if (typeof keyword !== "undefined" && keyword.length >= 1) {
    if (typeof fields !== "undefined" && fields.length >= 1) {
      fields.forEach((field, i) => {
        search.push(field + "=" + keyword);

        // term di backend selain string harus menambah field type
        if (
          typeof fieldType !== "undefined" &&
          typeof fieldType[field] !== "undefined"
        ) {
          search.push(field + "=" + fieldType[field]);
        }
      });
    }
  }

  if (search.length >= 1) {
    searchString =
      (typeof operand !== "undefined" ? "Operand=" + operand + "&" : "") +
      search.join("&");
  }

  return searchString;
};

// search to specific fields
export const setFieldSearchQuery = (keywords, fields, fieldType, operand) => {
  var search = [];
  var searchString = "";

  if (typeof keywords !== "undefined") {
    for (let k in keywords) {
      if (keywords.hasOwnProperty(k)) {
        let val = keywords[k];

        if (!Array.isArray(val)) {
          val += "";
          val = val.split(",");
        }

        if (val.length > 1) {
          operand = "OR";
        }

        for (let i = 0; i < val.length; i++) {
          search.push(k + "=" + val[i]);
        }
      }

      if (
        typeof fieldType !== "undefined" &&
        typeof fieldType[k] !== "undefined"
      ) {
        search.push(k + "=" + fieldType[k]);
      }
    }
  }

  if (search.length >= 1) {
    searchString =
      (typeof operand !== "undefined"
        ? "Operand=" + operand + "&"
        : "Operand=AND&") + search.join("&");
  }

  return searchString;
};

export const setSortingQuery = (sorting) => {
  var sortingString = "";

  if (typeof sorting !== "undefined" && sorting.length >= 1) {
    sortingString = "SortBy=" + sorting;
  }

  return sortingString;
};

export const setPagingQuery = (page, size) => {
  if (typeof page === "undefined" && typeof size === "undefined") {
    return "";
  }

  var pagingString = "";
  var pagingArray = [];

  pagingArray.push(
    "Page=" + fillValue(page, process.env.REACT_APP_PAGE_DEFAULT)
  );
  pagingArray.push(
    "Size=" + fillValue(size, process.env.REACT_APP_PAGE_SIZE_DEFAULT)
  );

  pagingString = pagingArray.join("&");

  return pagingString;
};

export const setQueryString = (props) => {
  let query = [];

  let pagingQuery = setPagingQuery(props.page, props.size);
  let sortingQuery = setSortingQuery(props.sorting);
  let searchingQuery = setSearchQuery(
    props.search,
    props.searchField,
    props.searchFieldType,
    props.operand
  );

  if (pagingQuery !== "") {
    query.push(pagingQuery);
  }

  if (sortingQuery !== "") {
    query.push(sortingQuery);
  }

  // setup params query
  if (searchingQuery !== "") {
    query.push(searchingQuery);
  }

  return trimArray(query).join("&");
};

export const trimArray = (arr) => {
  if (!Array.isArray(arr)) {
    return arr;
  }

  return arr.filter((arr) => {
    return arr != null;
  });
};

// sample use case fillValue([],[0],["Best"],[]) => ["BEST"]
// sample use case fillValue(undefined,"Good","") => "Good"
export const fillValue = (...values) => {
  let result = "";

  if (values.length > 0) {
    for (let i = 0; i < values.length; i++) {
      if (
        !Array.isArray(values[i]) &&
        typeof values[i] !== "undefined" &&
        (result === "" || result == null)
      ) {
        result = values[i];
      } else if (
        Array.isArray(values[i]) &&
        values[i][0] !== 0 &&
        typeof values[i][0] != "undefined" &&
        (result === "" || result == null || result === [])
      ) {
        result = values[i];
      }
    }
  }

  return result;
};

export const getQueryString = () => {};

// membuat tampilan html di view
export const createMarkup = (html) => {
  let transformN = "";

  html.split("\n").forEach((item, i) => {
    transformN += item + "<br />";
  });

  return { __html: transformN };
};

// USING LOCAL DATA
export const getLocalData = (LocalKey, ID) => {
  let getProducts = JSON.parse(localStorage.getItem(LocalKey));
  let products =
    typeof getProducts !== "undefined" && getProducts != null
      ? getProducts
      : [];

  if (typeof ID !== "undefined" && products.length > 0) {
    return _.find(products, (o) => parseInt(o.ID) === parseInt(ID));
  }

  return products;
};

// setup view dengan current path yang sedang dibuka, implementasinya menggunakan method isChangeView
// jika terjadi perubahan path maka isChangeView akan mengembalikan nilai true jika tidak false
// @params : pathUrl (object) dari reactrouter this.porps.match.params
// @params : allowPath (object | optional) path  yang diizinkan {"extra": ["detail","edit"]}, path extra diluar nilai yang ditentukan akan not found
export const setupView = (pathUrl, allowPath) => {
  let currentView = null;

  if (
    typeof pathUrl !== "undefined" &&
    pathUrl != null &&
    pathUrl !== "" &&
    typeof pathUrl === "object"
  ) {
    // cek semua parameter, sebagai contoh id, dan extra, ada juga yang menggunakan task
    for (let key in pathUrl) {
      if (
        typeof allowPath !== "undefined" &&
        allowPath != null &&
        typeof allowPath[key] !== "undefined"
      ) {
        currentView = currentView != null ? currentView : {};

        if (allowPath[key].includes(pathUrl[key])) {
          currentView[key] = pathUrl[key];
        } else {
          currentView[key] = "NOT_FOUND";
        }
      } else {
        currentView = currentView != null ? currentView : {};
        currentView[key] = pathUrl[key];
      }
    }
  }

  return currentView;
};

// baca juga bagian setupView
// @params : pathUrl (object) dari reactrouter this.porps.match.params
// @params : currentView (object) didapat dari method setupView
// @params : allowPath (object | optional) path  yang diizinkan {"extra": ["detail","edit"]}, path extra diluar nilai yang ditentukan akan not found
export const isChangeView = (pathUrl, currentView, allowPath) => {
  let isReload = false;
  if (
    typeof pathUrl !== "undefined" &&
    typeof pathUrl === "object" &&
    Object.keys(pathUrl).length > 0
  ) {
    // cek semua parameter, sebagai contoh id, dan extra, ada juga yang menggunakan task
    for (let key in pathUrl) {
      // jika currentview berbeda dengan pathnya
      if (currentView == null || typeof currentView[key] === "undefined") {
        isReload = true;
        break;
      }

      // jika antara currentview dan path berbeda jumlah path
      else if (currentView.length !== pathUrl.length) {
        isReload = true;
        break;

        // jika ada perbedaan di path semisal idnya berbeda
      } else if (currentView[key] !== pathUrl[key]) {
        // cek jika halaman not found
        if (
          typeof allowPath !== "undefined" &&
          allowPath != null &&
          typeof allowPath[key] !== "undefined" &&
          !allowPath[key].includes(currentView[key])
        ) {
          isReload = false;
          break;
        }

        isReload = true;
        break;
      }
      //
    }
  }
  // halaman sebelumnya detail ke halaman list yangn pathUrl {}
  else if (
    currentView != null &&
    Object.keys(pathUrl).length !== Object.keys(currentView).length
  ) {
    isReload = true;
  }

  return isReload;
};

export const filterLimit = (data, fnCondition, maxItem) => {
  var results = [];

  _.forEach(data, function (item) {
    if (results.length >= maxItem) {
      return false;
    }

    if (fnCondition(item)) {
      results.push(item);
    }
  });
  return results;
};

export const parsePhoneNumber = (number) => {
  let phoneInt = parseInt(number.replace(" ", ""));
  let phoneStr = phoneInt.toString();

  // diawali 0
  if (phoneStr.charAt(0) === "0") {
    phoneStr = phoneStr.subString(1);
  }

  if (phoneStr.charAt(0) !== "62") {
    phoneStr = "62" + phoneStr;
  }

  return phoneStr;
};

export const parseSlugCase = (string) => {
  return string.toLowerCase().replaceAll(" ", "-");
};

export const arraySum = (values) => {
  let result = 0;

  if (Array.isArray(values)) {
    result = values.reduce((total, val) => {
      return parseInt(total) + parseInt(!isNaN(parseInt(val)) ? val : 0);
    });
  }

  return result;
};

export const toSlug = (string) => {
  return string.toLowerCase().replace(" ", "-");
};

export const humanDate = (defaultDate) => {
  // jumat, 11 desember 2020
  if (!defaultDate) {
    return defaultDate;
  }

  return moment(defaultDate).format("dddd, DD MMMM YYYY");
};

export const calculateDimensi = (items) => {
  let products = [];

  for (let i = 0; i < items.length; i++) {
    for (let j = 0; j < parseInt(items[i]["Qty"]); j++) {
      products.push(items[i]);
    }
  }

  let newPackage = null;
  for (let i = 0; i < products.length; i++) {
    if (i === 0) {
      newPackage = createPackage(products[0]);
    } else {
      newPackage = createPackage(newPackage, products[i]);
    }
  }

  return newPackage;
};

// create from calculateDimensi
export const createPackage = (ProductA, ProductB) => {
  let product = {
    Width: ProductA["Width"],
    Height: ProductA["Height"],
    Length: ProductA["Length"],
    Total:
      parseInt(ProductA["Width"]) *
      parseInt(ProductA["Height"]) *
      parseInt(ProductA["Length"]),
  };

  if (
    !ProductB ||
    !ProductB["Width"] ||
    !ProductB["Height"] ||
    !ProductB["Length"]
  ) {
    return product;
  }

  let maxWidth = Math.max(
    parseInt(ProductA["Width"]),
    parseInt(ProductB["Width"])
  );
  let sumWidth = parseInt(ProductA["Width"]) + parseInt(ProductB["Width"]);
  let maxHeight = Math.max(
    parseInt(ProductA["Height"]),
    parseInt(ProductB["Height"])
  );
  let sumHeight = parseInt(ProductA["Height"]) + parseInt(ProductB["Height"]);
  let maxLength = Math.max(
    parseInt(ProductA["Length"]),
    parseInt(ProductB["Length"])
  );
  let sumLength = parseInt(ProductA["Length"]) + parseInt(ProductB["Length"]);

  let scenarioLength = maxWidth * maxHeight * sumLength;
  let scenarioWidth = sumWidth * maxHeight * maxLength;
  let scenarioHeight = maxWidth * sumHeight * maxLength;

  let res = Math.min(scenarioLength, scenarioWidth, scenarioHeight);

  switch (res) {
    case scenarioLength:
      product = {
        Width: maxWidth,
        Height: maxHeight,
        Length: sumLength,
        Total: scenarioLength,
      };
      break;

    case scenarioWidth:
      product = {
        Width: sumWidth,
        Height: maxHeight,
        Length: maxLength,
        Total: scenarioWidth,
      };
      break;

    case scenarioHeight:
      product = {
        Width: maxWidth,
        Height: sumHeight,
        Length: maxLength,
        Total: scenarioHeight,
      };
      break;

    default:
      product = product;
      break;
  }

  return product;
};

export const validateDimensi = (dimensiCourier, dimensiProduct) => {
  if (!dimensiCourier || !dimensiProduct) {
    return true;
  }

  if (
    dimensiCourier["Width"] >= dimensiProduct["Width"] &&
    dimensiCourier["Height"] >= dimensiProduct["Height"] &&
    dimensiCourier["Length"] >= dimensiProduct["Length"]
  ) {
    return true;
  } else {
    return false;
  }
};

export const maxValidateDimensi = (dimensions) => {
  let info = null;
  if (Array.isArray(dimensions)) {
    if (dimensions.length >= 2) {
      if (
        parseInt(dimensions[0]["dimensi"]["Width"]) >=
          parseInt(dimensions[1]["dimensi"]["Width"]) &&
        parseInt(dimensions[0]["dimensi"]["Height"]) >=
          parseInt(dimensions[1]["dimensi"]["Height"]) &&
        parseInt(dimensions[0]["dimensi"]["Length"]) >=
          parseInt(dimensions[1]["dimensi"]["Length"])
      ) {
        info = {
          dimensi: dimensions[0]["dimensi"],
          service: dimensions[0]["courier"]["courier"] + " Instant dan Sameday",
        };
      } else {
        info = {
          dimensi: dimensions[1]["dimensi"],
          service: dimensions[0]["courier"]["courier"] + " Instant dan Sameday",
        };
      }
    } else {
      info = {
        dimensi: dimensions[0]["dimensi"],
        service:
          dimensions[0]["courier"]["courier"] +
          " " +
          dimensions[0]["courier"]["service"],
      };
    }
  }

  return info;
};

// digunakan di fungsi logout, agar storage hilang. tapi jangan digunakan di fungsi error 401 (login ulang), karena
// user kemungkinan sedang belanja tapi harus login, jadi setelah login item belanjanya harus tetap ada
export const removeStorage = () => {
  localStorage.removeItem(process.env.REACT_APP_PREFIX + "ID");
  localStorage.removeItem(process.env.REACT_APP_PREFIX + "OID");
  localStorage.removeItem(process.env.REACT_APP_PREFIX + "TOKEN");
  localStorage.removeItem(process.env.REACT_APP_PREFIX + "FCM_TOKEN");
  localStorage.removeItem("AGREGO_PRODUCTS");
  localStorage.removeItem("AGREGO_CSPHONE");
  localStorage.removeItem("AGREGO_PRODUCTS_COUNT");
  localStorage.removeItem("AGREGO_ORDER_STATUS");
  localStorage.removeItem("AGREGO_CART");
  localStorage.removeItem("AGREGO_CART_TOPUP");
  localStorage.removeItem("AGREGO_CATEGORY");
  localStorage.removeItem("AGREGO_DELIVERY_OPTION");
  localStorage.removeItem("AGREGO_WALLET");
  localStorage.removeItem("AGREGO_ORDER");
  localStorage.removeItem("AGREGO_ORDER_COUNT");
};
