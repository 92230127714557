import { 
    REQUEST_MARTORDER_LIST,  
    REQUEST_MARTORDER, 
    REQUEST_MARTORDER_WALLET, 
    REQUEST_MARTORDER_STATUS, 
    CANCELING_MARTORDER,
    CONFIRMING_MARTORDER,
    UPLOADING_MARTORDER_BUKTIBAYAR,
    RESULT_MARTORDER_LIST, 
    RESULT_MART_TOTAL_ORDER, 
    RESULT_MARTORDER_WALLET,
    RESULT_MARTORDER_STATUS,
    RESULT_MARTORDER,
    CANCELED_MARTORDER,
    CONFIRMED_MARTORDER,
    UPLOADED_MARTORDER_BUKTIBAYAR } from "./../actions/mart_order";

const initialState = { 
    isFetchingMartOrderList: false, 
    isFetchingMartOrder: false, 
    isFetchingMartOrderWallet: false, 
    isFetchingMartOrderStatus: false, 
    isCancelingMartOrder: false,
    isConfirmingMartOrder: false,
    isUploadingMartOrderBuktiBayar: false,
    mart_orders: [],
    mart_total_order: null,
    mart_order: null,
    mart_order_wallet: null,
    mart_order_status: null,
    mart_order_bukti_bayar: null
};

export default function(state = initialState, action) {
    switch (action.type) {  
        case REQUEST_MARTORDER_LIST: { 
            return {
                ...state,
                isFetchingMartOrderList: true,
                mart_orders: []
            };
        }
 
        case REQUEST_MARTORDER: { 
            return {
                ...state,
                mart_order: null,
                isFetchingMartOrder: true
            };
        }
 
        case REQUEST_MARTORDER_WALLET: { 
            return {
                ...state,
                mart_order_wallet: null,
                isFetchingMartOrderWallet: true
            };
        }
 
        case REQUEST_MARTORDER_STATUS: { 
            return {
                ...state,
                mart_order_status: null,
                isFetchingMartOrderStatus: true
            };
        }
 
        case CANCELING_MARTORDER: { 
            return {
                ...state, 
                isCancelingMartOrder: true
            };
        }
 
        case CONFIRMING_MARTORDER: { 
            return {
                ...state, 
                isConfirmingMartOrder: true
            };
        }

        case UPLOADING_MARTORDER_BUKTIBAYAR: { 
            return {
                ...state, 
                isUploadingMartOrderBuktiBayar: true
            };
        }
       
        case RESULT_MARTORDER_LIST: { 

            // local data
            if(process.env.REACT_APP_LOCAL_DATA === "true") {
                localStorage.setItem('AGREGO_USER_ORDER', JSON.stringify(action.payload.data)); 
            }

            return {
                ...state,
                isFetchingMartOrderList: false,
                mart_orders : action.payload.data
            };
        }
       
        case RESULT_MART_TOTAL_ORDER: { 
            
            // local data
            if(process.env.REACT_APP_LOCAL_DATA === "true") {
                localStorage.setItem('AGREGO_USER_ORDER', JSON.stringify(action.payload.data));
                localStorage.setItem('AGREGO_USER_ORDER_COUNT', JSON.stringify(action.payload.count));
            }

            return {
                ...state,
                isFetchingMartOrderList: false,
                mart_orders : action.payload.data,
                mart_total_order: action.payload.count
            };
        }
       
        case RESULT_MARTORDER: { 
            return {
                ...state,
                isFetchingMartOrder: false,
                mart_order : action.payload.data
            };
        }
       
        case RESULT_MARTORDER_WALLET: { 
            
            // // local data
            // if(process.env.REACT_APP_LOCAL_DATA === "true") {
            //     localStorage.setItem('AGREGO_USER_WALLET', JSON.stringify(action.payload.data));
            // }  

            return {
                ...state,
                isFetchingMartOrderWallet: false,
                mart_order_wallet : action.payload.data
            };
        }
       
        case RESULT_MARTORDER_STATUS: { 
            localStorage.setItem('AGREGO_ORDER_STATUS', JSON.stringify(action.payload.data)); 

            return {
                ...state,
                isFetchingMartOrderStatus: false,
                mart_order_status : action.payload.data
            };
        }
       
        case CANCELED_MARTORDER: { 
            
            return {
                ...state,
                isCancelingMartOrder: false
            };
        }
       
        case CONFIRMED_MARTORDER: { 
            
            return {
                ...state,
                isConfirmingMartOrder: false
            };
        }
       
        case UPLOADED_MARTORDER_BUKTIBAYAR: {  
            return {
                ...state,
                isUploadingMartOrderBuktiBayar: false,
                mart_order_bukti_bayar: action.payload.data
            };
        }

        default:
            return state;
  }
}