import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { parsePhoneNumber } from "../../utils/common";
import imgLoginUnverified from "./../../Images/success-register.png";
import { resetAppState } from "./../../redux/actions/app";
import { login } from "./../../utils/JWTAuth";
import "./Auth.css";

// logo
var Logo = require(`./../../${process.env.REACT_APP_THEME_IMAGE}logo-white.png`);
var LogoColor = require(`./../../${process.env.REACT_APP_THEME_IMAGE}logo-color.png`);

// Validasi form disini
const LoginSchema = Yup.object().shape({
  UserID: Yup.string()
    .email("Format email salah")
    .min(4, "Email terlalu pendek, minimal 4 karakter")
    .required("Email harus diisi"),
  Password: Yup.string()
    .min(4, "Password terlalu pendek, minimal 4 karakter")
    .required("Password harus diisi"),
});

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      input: { email: null, password: null },

      // ini untuk menandakan login sedang proses atau sudah selesai
      processLogin: false,
      responLogin: null,
      data: null,
      showPassword: false,
      isUnverified: false,
      isUnverifiedType: null,

      message: null,
      isError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
  }

  componentDidMount() {
    let { message, isError } = this.props.app;

    ReactGA.set({ title: "login" });
    ReactGA.pageview("/login");

    if (this.state.message !== message && message !== "" && message != null) {
      this.setState({
        message: message,
        isError: isError,
      });

      this.props.resetAppState();
    }
  }

  componentDidUpdate() {
    let { data, responLogin, isUnverified } = this.state;
    const { state } = this.props.location;

    let from =
      state != null &&
      state["from"] != null &&
      state["from"]["pathname"] != null
        ? state["from"]["pathname"]
        : "/beranda";

    // berhasil login
    if (data != null && typeof data.UserID !== "undefined") {
      this.props.history.push(from);

      ReactGA.set({
        userId: localStorage.getItem(process.env.REACT_APP_PREFIX + "ID"),
      });

      axios.defaults.headers.common["Authorization"] =
        "Bearer " +
        localStorage.getItem(process.env.REACT_APP_PREFIX + "TOKEN");
    }

    if (
      !isUnverified &&
      responLogin != null &&
      !responLogin.success &&
      responLogin.message === "UNVERIFIED_ACCOUNT"
    ) {
      this.setState({
        isUnverified: true,
        isUnverifiedType: "UNVERIFIED_ACCOUNT",
      });
    } else if (
      !isUnverified &&
      responLogin != null &&
      !responLogin.success &&
      responLogin.message === "UNVERIFIED_ACCOUNT_EMAIL"
    ) {
      this.setState({
        isUnverified: true,
        isUnverifiedType: "UNVERIFIED_ACCOUNT_EMAIL",
      });
    }
  }

  handleChange(e) {
    let input = {};
    let name = e.target.name;
    let value = e.target.value;

    input[name] = value;

    this.setState({ input });
  }

  async handleLogin({ UserID, Password }) {
    this.setState({ processLogin: true });

    let info = {
      userid: UserID,
      password: Password,
    };

    // mengambil result dari fungsi login (yang sudah direturn)
    let respon = await login(info);

    //kita akan menyimpan hasil login jika sudah dapat ke state
    this.setState({
      data: respon.data,
      responLogin: respon,
      processLogin: false,
    });
  }

  handleShowPassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  render() {
    const {
      processLogin,
      responLogin,
      message,
      isError,
      isUnverified,
      isUnverifiedType,
    } = this.state;
    let csphone = localStorage.getItem("AGREGO_CSPHONE");
    if (csphone != null && typeof csphone !== "undefined") {
      csphone = parsePhoneNumber(csphone);
    }

    return (
      <div>
        <Row noGutters>
          <Col md={7} className="d-none d-lg-block">
            <div className="login-left-box">
              <div className="login-bg-pattern"></div>
              <div className="login-logo">
                <img src={Logo} alt="logo" width="100%"></img>
                {/* <p>Solusi untuk komunitas anda</p> */}
              </div>
            </div>
          </Col>

          <Col>
            <div className="login-form-wrapper">
              {!isUnverified && (
                <div className="login-green-logo d-md-none">
                  <img src={LogoColor} alt="logo agrego hijau" />
                </div>
              )}

              {isUnverified && (
                <div className="text-center" style={{ marginTop: "12px" }}>
                  <img
                    src={imgLoginUnverified}
                    width="100%"
                    alt="unverified login"
                  />
                  <div style={{ paddingTop: "32px", fontWeight: "bold" }}>
                    {isUnverifiedType === "UNVERIFIED_ACCOUNT" ? (
                      <>Akun belum dikonfirmasi, silakan kontak Administrator</>
                    ) : (
                      <>
                        Email Anda belum diverifikasi. Cek email Anda untuk
                        melakukan verifikasi.
                      </>
                    )}
                  </div>

                  <div className="login-text-help">
                    Butuh bantuan?{" "}
                    <a
                      href={
                        "https://wa.me/" +
                        csphone +
                        "?text=Hallo Admin, ada yang ingin saya tanyakan"
                      }
                    >
                      Hubungi Kami
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    block
                    disabled={processLogin}
                    onClick={(e) => this.setState({ isUnverified: false })}
                    href="/login"
                  >
                    OK
                  </Button>
                </div>
              )}

              {!isUnverified && (
                <div className="login-text-welcome">Selamat Datang</div>
              )}

              {!isUnverified && responLogin != null && !responLogin.success && (
                <div className="alert alert-danger login-message-error">
                  {responLogin.message}
                </div>
              )}

              {!isUnverified &&
                message != null &&
                isError &&
                responLogin == null && (
                  <div className="alert alert-danger login-message-error">
                    {message}
                  </div>
                )}

              {!isUnverified && (
                <Formik
                  enableReinitialize={true}
                  initialValues={{ email: null, password: null }}
                  validationSchema={LoginSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    ReactGA.event({
                      category: "membership",
                      action: "login",
                    });
                    this.handleLogin(values);
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit} id="form-login">
                        <Form.Group
                          className="login-form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="UserID"
                            value={values["email"]}
                            placeholder="Masukkan Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.UserID && touched.UserID && (
                            <Form.Text className="text-danger">
                              {" "}
                              {errors.UserID}{" "}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="login-form-group"
                          controlId="formBasicPassword"
                          style={{ position: "relative" }}
                        >
                          <Form.Label>Kata Sandi</Form.Label>
                          <Form.Control
                            type={this.state.showPassword ? "text" : "password"}
                            name="Password"
                            placeholder="Masukkan Kata Sandi"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <div
                            className="eye-icon"
                            onClick={this.handleShowPassword}
                          >
                            {this.state.showPassword ? (
                              <FontAwesomeIcon icon={faEye} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                          </div>

                          {errors.Password && touched.Password && (
                            <Form.Text className="text-danger">
                              {" "}
                              {errors.Password}{" "}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </form>
                    );
                  }}
                </Formik>
              )}

              {!isUnverified && (
                <React.Fragment>
                  <div className="login-text-forgot">
                    <a href="/forgot-password">Lupa kata sandi?</a>
                  </div>

                  <Button
                    variant="primary"
                    block
                    className="login-btn"
                    disabled={processLogin}
                    type="submit"
                    form="form-login"
                  >
                    {processLogin ? "Memproses..." : "Masuk"}
                  </Button>

                  <div className="login-text-notregister">
                    Belum punya akun? <a href="/register">Daftar di sini</a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

export default withRouter(
  connect(mapStateToProps, {
    resetAppState,
  })(Login)
);
