import qs from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchMartBankAccount } from "./../../../redux/actions/mart_bank";
import {
  getCart,
  getCartDigital,
  updatePaymentMethod,
} from "./../../../redux/actions/mart_cart";
import {
  fetchMartPaymentScheme,
  fetchMartQrisPayment,
} from "./../../../redux/actions/mart_payment";
import { fetchWalletBalance } from "./../../../redux/actions/wallet";
import Payment from "./Payment.component";

class PaymentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      filterData: {},
      bankAccount: [],
      breadcrumb: null,
      showModal: false,
      bankAccountID: null,
      bankName: null,
      bankImage: null,
      paymentSchema: [],
      paymentQris: null,
      option: null,
      type: null,
    };

    // this.loadData = this.loadData.bind(this);
    this.handleChangePayment = this.handleChangePayment.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const {
      isFetchingMartBankAccount,
      mart_bank_account,
    } = this.props.martbank;
    const {
      isFetchingMartPaymentScheme,
      isFetchingMartPaymentQris,
      mart_payment_scheme,
      mart_payment_qris,
    } = this.props.martpayment;
    const { isFetchingWalletBalance, wallet_balance } = this.props.wallet;
    const { isLoading, option, type } = this.state;
    let { breadcrumb, paymentSchema, paymentQris } = this.state;
    const { cartDigital, carts } = this.props.martcart;
    const { summary } = carts;

    if (
      !isFetchingMartBankAccount &&
      !isFetchingMartPaymentScheme &&
      !isFetchingWalletBalance &&
      !isFetchingMartPaymentQris &&
      isLoading
    ) {
      breadcrumb = [
        // {link:'/beranda', text:'Beranda'},
        // {link:'/shop/product', text: 'Mart'},
        // { link: "/shop/cart", text: "Keranjang" },
        { link: "/shop/delivery", text: "Atur Pesanan" },
        { link: "/shop/payment", text: "Pilih metode pembayaran" },
      ];

      paymentSchema = [];
      if (typeof mart_payment_scheme["Payment"] !== "undefined") {
        mart_payment_scheme["Payment"].forEach((schema) => {
          if (option != null) {
            if (option.includes(schema["Type"].toLowerCase())) {
              // [140820] khusus digital wallet , payment dan paymenttypenya sama yakni wallet
              // schema = schema['Type'] === "Wallet" ? {...schema, Value: "Wallet"} : schema

              paymentSchema.push(schema);
            }
          } else {
            paymentSchema.push(schema);
          }
        });
      }

      if (type != null) {
        breadcrumb = [{ link: "/beranda", text: "Beranda" }];

        if (type === "digital") {
          // cek category
          if (
            cartDigital != null &&
            cartDigital["CategoryName"] != null &&
            cartDigital["CategoryURL"] != null
          ) {
            breadcrumb.push({
              link: cartDigital["CategoryURL"],
              text: cartDigital["CategoryName"],
            });
          }

          breadcrumb = breadcrumb.concat([
            { link: "/digital/confirmation", text: "Atur Pesanan" },
            {
              link: "/shop/payment?type=digital",
              text: "Pilih metode pembayaran",
            },
          ]);
        } else if (type === "topup") {
          breadcrumb = breadcrumb.concat([
            { link: "/wallet", text: "Deposit" },
            { link: "/wallet/topup", text: "Isi Saldo" },
            {
              link: "/shop/payment?type=topup",
              text: "Pilih metode pembayaran",
            },
          ]);
        }
      }

      if (
        Array.isArray(mart_payment_qris) &&
        mart_payment_qris != null &&
        mart_payment_qris[0] != null
      ) {
        paymentQris = mart_payment_qris[0];
      }

      this.setState({
        isLoading: false,
        bankAccount: mart_bank_account,
        bankAccountID:
          summary != null && typeof summary["BankAccountID"] !== "undefined"
            ? summary["BankAccountID"]
            : null,
        bankName:
          summary != null && typeof summary["BankName"] !== "undefined"
            ? summary["BankName"]
            : null,
        bankImage:
          summary != null && typeof summary["BankImage"] !== "undefined"
            ? summary["BankImage"]
            : null,
        walletBalance: wallet_balance,
        paymentQris,
        paymentSchema,
        breadcrumb,
      });
    }
  }

  loadData() {
    let { type } = qs.parse(this.props.location.search);
    let option = null;

    if (typeof type !== "undefined" && type.toLowerCase() === "digital") {
      option = ["wallet", "bank"];
      type = type.toLowerCase();
    } else if (typeof type !== "undefined" && type.toLowerCase() === "topup") {
      option = ["bank"];
      type = type.toLowerCase();
    } else {
      type = null;
    }

    this.props.fetchMartBankAccount({ page: 1, size: 100 });
    this.props.fetchMartPaymentScheme();
    this.props.fetchMartQrisPayment();
    this.props.fetchWalletBalance();
    this.props.getCart();
    this.props.getCartDigital();

    this.setState({
      isLoading: true,
      type,
      option,
    });
  }

  handleChangePayment({
    Payment,
    PaymentType,
    QRISPaymentID,
    BankAccountID,
    BankName,
    BankImage,
    BankAccountNumber,
    BankAccountName,
  }) {
    const { type, walletBalance } = this.state;

    let redirect = "/shop/delivery";
    if (type === "digital") {
      redirect = "/digital/confirmation";
    } else if (type === "topup") {
      redirect = "/wallet/topup";
    }

    this.props.updatePaymentMethod({
      Payment,
      PaymentType,
      QRISPaymentID,
      BankAccountID,
      BankName,
      BankImage,
      BankAccountNumber,
      BankAccountName,
      WalletBalance: walletBalance,
      type,
    });
    this.props.history.push(redirect);
  }

  render() {
    const {
      isLoading,
      breadcrumb,
      bankAccount,
      paymentSchema,
      paymentQris,
      bankAccountID,
      bankName,
      bankImage,
      option,
      type,
    } = this.state;

    return (
      <Payment
        isLoading={isLoading}
        breadcrumb={breadcrumb}
        paymentQris={paymentQris}
        bankAccount={bankAccount}
        bankAccountID={bankAccountID}
        bankName={bankName}
        bankImage={bankImage}
        paymentSchema={paymentSchema}
        option={option}
        type={type}
        onChangePayment={this.handleChangePayment}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { martbank, martcart, martpayment, wallet } = state;
  return { martbank, martcart, martpayment, wallet };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchMartPaymentScheme,
    fetchMartQrisPayment,
    fetchMartBankAccount,
    fetchWalletBalance,
    updatePaymentMethod,
    getCart,
    getCartDigital,
  })(PaymentContainer)
);
