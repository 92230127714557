import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import ReactGA from "react-ga";
import Pagination from "react-js-pagination";
import Breadcrumb from "./../../Layout/Breadcrumb";
import EmptyState from "./../../Layout/EmptyState";
import Loading from "./../../Layout/Loading";
import "./Store.css";
import StoreItem from "./StoreItem.component";

function Store(props) {
  const {
    stores,
    isLoading,
    breadcrumb,
    activePage,
    itemPerPage,
    totalStore,
    pageType,
  } = props;

  useEffect(() => {
    ReactGA.set({ title: "store_detail" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container>
      {isLoading && <Loading />}
      {/* {!isLoading && <div>{pageTitle}</div>}  */}

      {!isLoading && breadcrumb != null && (
        <div style={{ marginTop: "33px", marginBottom: "15px" }}>
          {" "}
          <Breadcrumb url={breadcrumb} />{" "}
        </div>
      )}

      {!isLoading && (stores == null || stores.length <= 0) && (
        <div className="text-center">
          <EmptyState style={{ margin: "auto" }} />
        </div>
      )}

      <div className="store-container">
        {!isLoading &&
          stores != null &&
          stores.length > 0 &&
          stores.map((item, index) => {
            return (
              <div className="store-item" key={index}>
                <StoreItem store={item} pageType={pageType} />
              </div>
            );
          })}
      </div>

      <div className="clearfix d-flex justify-content-center">
        {!isLoading &&
          stores != null &&
          stores.length > 0 &&
          parseInt(totalStore) > parseInt(itemPerPage) && (
            <div style={{ margin: "20px auto", display: "inline-block" }}>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={itemPerPage}
                totalItemsCount={totalStore}
                pageRangeDisplayed={3}
                onChange={props.onChangePage}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}{" "}
      </div>
    </Container>
  );
}

Store.propTypes = {
  isLoading: PropTypes.bool,
  stores: PropTypes.array,
};

Store.defaultProps = {
  isLoading: false,
  stores: [],
};

export default Store;
