import qs from "query-string";
import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorPage from "../../Layout/ErrorPage";
import { displayMessage } from "./../../../redux/actions/app";
import {
  cancelMartOrder,
  confirmMartOrder,
  fetchMartOrder,
  fetchMartOrderById,
  fetchMartOrderStatus,
  uploadBuktiBayar,
} from "./../../../redux/actions/mart_order";
import { isChangeView, setupView } from "./../../../utils/common";
import Order from "./Order.component";
import OrderCompleted from "./OrderCompleted.component";
import OrderDetail from "./OrderDetail.component";
import OrderPayment from "./OrderPayment.component";

const previewImgInputFile = (file) => {
  return URL.createObjectURL(file);
};

class OrderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      filterData: {},
      currentView: null,
      allowPath: { extra: ["detail", "payment", "completed"] },
      breadcrumb: null,

      orders: [],
      order: null,
      orderStatus: null,
      action: null,
      activePage: 1,
      itemPerPage: 10,
      totalOrder: null,

      showModal: false,
      modalTitle: null,
      modalBody: null,
      modalAction: null,
      modalBtnContinueText: "Lanjutkan",
      modalBtnContinueVariant: "primary",
      loadingConfirm: false,
      loadingUpload: false,

      fileBuktiBayar: null,
      previewFileBuktiBayar: null,

      multipleOrderStatus: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  componentDidMount() {
    ReactGA.set({ title: "payment_detail" });
    this.loadData(1, true);
  }

  componentDidUpdate() {
    const {
      activePage,
      itemPerPage,
      loadingConfirm,
      loadingUpload,
      fileBuktiBayar,
      currentView,
      allowPath,
      filterData,
      orders,
      modalAction,
    } = this.state;
    const {
      isFetchingMartOrderList,
      isFetchingMartOrder,
      isFetchingMartOrderStatus,
      isCancelingMartOrder,
      isConfirmingMartOrder,
      isUploadingMartOrderBuktiBayar,
      mart_orders,
      mart_order,
      mart_total_order,
      mart_order_status,
    } = this.props.martorder;
    let {
      breadcrumb,
      action,
      orderStatus,
      orderStatusSchema,
      isLoading,
      multipleOrderStatus,
      totalOrder,
    } = this.state;
    let { extra } = this.props.match.params;
    let { status } = qs.parse(this.props.location.search);

    if (fileBuktiBayar != null) {
      // console.log(fileBuktiBayar);
    }

    if (loadingConfirm && !isCancelingMartOrder && !isConfirmingMartOrder) {
      this.setState({ loadingConfirm: false, showModal: false });
      this.loadData(1, true);

      if (modalAction != null && modalAction.type === "CANCEL_ORDER") {
        this.props.history.push("/shop/order");
      }
    }

    if (loadingUpload && !isUploadingMartOrderBuktiBayar) {
      this.setState({ loadingUpload: false, showModal: false });
      this.loadData(1, true);
    }

    if (
      !isFetchingMartOrderList &&
      !isFetchingMartOrder &&
      !isFetchingMartOrderStatus &&
      isLoading
    ) {
      breadcrumb = null;
      if (action === "list") {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          { link: "/shop/order", text: "Pesanan" },
        ];
      } else if (action === "payment") {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          { link: "/shop/order", text: "Pesanan" },
          {
            link: "/shop/order/" + mart_order.ID + "/payment",
            text: "Detail pembayaran",
          },
        ];
      }

      if (
        ["completed"].includes(extra) &&
        parseInt(mart_order.Status) === 1 &&
        ["bank", "qris"].includes(mart_order.PaymentType.toLowerCase())
      ) {
        action = "payment";
      }

      if (
        filterData["Status"] != null &&
        multipleOrderStatus != null &&
        multipleOrderStatus[0] != null
      ) {
        isLoading = !isLoading;
        let nextStatus = multipleOrderStatus[0];

        multipleOrderStatus = multipleOrderStatus.filter((stat) => {
          return parseInt(stat) !== parseInt(nextStatus);
        });

        this.props.fetchMartOrder(
          {
            page: activePage,
            size: itemPerPage,
            search: { Status: nextStatus },
          },
          true
        );
      }

      orderStatusSchema =
        mart_order_status != null &&
        mart_order_status.length > 0 &&
        mart_order_status.map((o) => o["TextTranslate"]);

      if (orderStatus == null) {
        orderStatus = [];
        // if(orderStatus != null && orderStatus.length > 0) {
        orderStatus.push({
          Group: "TODO",
          Text: "ORDER ENTRY",
          TextTranslate: "MENUNGGU PEMBAYARAN",
          Value: "1",
        });
        orderStatus.push({
          Group: "TODO",
          Text: "PROCESSING",
          TextTranslate: "PESANAN DIPROSES",
          Value: "2",
        });
        orderStatus.push({
          Group: "TODO",
          Text: "ON DELIVERY",
          TextTranslate: "PESANAN DIKIRIM",
          Value: "5",
        });
        orderStatus.push({
          Group: "TODO",
          Text: "IN CHART",
          TextTranslate: "SEMUA",
          Value: "all",
        });
        // }
      }

      totalOrder =
        parseInt(totalOrder) > parseInt(mart_total_order)
          ? totalOrder
          : mart_total_order;

      this.setState({
        orders: mart_orders.concat(orders),
        order: mart_order,
        isLoading: !isLoading,
        orderStatus,
        breadcrumb,
        action,
        orderStatusSchema,
        totalOrder,
        multipleOrderStatus,
      });
    }

    // reload component
    let isReloadComponent = isChangeView(
      this.props.match.params,
      currentView,
      allowPath
    );
    if (
      isReloadComponent ||
      (typeof filterData["Status"] === "undefined" &&
        typeof status !== "undefined") ||
      (typeof filterData["Status"] !== "undefined" &&
        parseInt(filterData["Status"]) !== parseInt(status))
    ) {
      this.loadData(1, true);
    }
  }

  loadData(newPage, loadMore) {
    let {
      action,
      activePage,
      itemPerPage,
      allowPath,
      filterData,
      multipleOrderStatus,
    } = this.state;
    let currentView = setupView(this.props.match.params, allowPath);
    let { status } = qs.parse(this.props.location.search);

    activePage = typeof newPage !== "undefined" ? newPage : activePage;
    // let withTotalCount = totalOrder == null ? true : false;

    if (typeof status !== "undefined" && !isNaN(parseInt(status))) {
      filterData["Status"] = status;

      // pesanan di proses
      if ([2, 3, 4].includes(parseInt(status)) && loadMore) {
        multipleOrderStatus = [2, 3, 4];
      }

      // pesanan dikirim
      if ([5, 6].includes(parseInt(status)) && loadMore) {
        multipleOrderStatus = [5, 6];
      }

      if (multipleOrderStatus != null) {
        multipleOrderStatus = multipleOrderStatus.filter((stat) => {
          return parseInt(stat) !== parseInt(status);
        });
      }

      // let indexStatus = multipleOrderStatus.indexOf(parseInt(status))
      // delete multipleOrderStatus[indexStatus]

      // console.log(multipleOrderStatus)
    } else {
      delete filterData["Status"];
    }

    if (currentView != null && typeof currentView["extra"] !== "undefined") {
      if (currentView["extra"] === "NOT_FOUND") {
        action = "NOT_FOUND";
      } else {
        this.props.fetchMartOrderById(currentView["id"]);
        action = currentView["extra"];
      }
    } else {
      this.props.fetchMartOrder(
        { page: activePage, size: itemPerPage, search: filterData },
        true
      );
      action = "list";
    }

    this.props.fetchMartOrderStatus();

    this.setState({
      isLoading: true,
      action: action,
      currentView,
      activePage,
      filterData,
      multipleOrderStatus,

      // reset order & total order
      orders: [],
      totalOrder: null,
    });
  }

  handleChangePage(page) {
    this.loadData(page, true);
  }

  toggleModal(openModal, action, confirm) {
    let {
      modalAction,
      modalTitle,
      modalBody,
      modalBtnContinueVariant,
      modalBtnContinueText,
      order,
      isLoading,
      loadingConfirm,
      loadingUpload,
      fileBuktiBayar,
      previewFileBuktiBayar,
    } = this.state;

    if (
      typeof action !== "undefined" &&
      action != null &&
      typeof action.type !== "undefined"
    ) {
      modalAction = action;
    }

    if (modalAction != null) {
      switch (modalAction.type) {
        case "CANCEL_ORDER":
          modalTitle = "Batalkan Pesanan";
          modalBody = "Anda yakin ingin membatalkan pesanan ini?";
          modalBtnContinueVariant = "danger";
          modalBtnContinueText = "Lanjutkan membatalkan pesanan";

          if (
            typeof confirm !== "undefined" &&
            confirm &&
            typeof modalAction.id !== "undefined"
          ) {
            this.props.cancelMartOrder(modalAction.id);
            loadingConfirm = true;
          }
          break;

        case "CONFIRM_ORDER":
          modalTitle = "Konfirmasi Pesanan";
          modalBody = "Anda yakin ingin mengkonfirmasi pesanan ini?";
          modalBtnContinueVariant = "primary";
          modalBtnContinueText = "Lanjutkan konfirmasi pesanan";

          if (
            typeof confirm !== "undefined" &&
            confirm &&
            typeof modalAction.id !== "undefined"
          ) {
            this.props.confirmMartOrder(modalAction.id);
            loadingConfirm = true;
          }
          break;

        case "UPLOAD_BUKTI_BAYAR":
          modalTitle = "Upload Bukti Bayar";
          modalBtnContinueVariant = "primary";
          modalBtnContinueText = "Lanjutkan";
          modalBody = (
            <Form>
              <input
                type="file"
                name="bukti-bayar"
                className="form-control"
                onChange={(e) => {
                  // Create an object of formData
                  let selectedFile = e.target.files[0];
                  let previewBukti = previewImgInputFile(selectedFile);

                  this.setState({
                    fileBuktiBayar: selectedFile,
                    previewFileBuktiBayar: previewBukti,
                  });
                }}
              />
            </Form>
          );

          if (
            typeof confirm !== "undefined" &&
            confirm &&
            fileBuktiBayar != null
          ) {
            this.props.uploadBuktiBayar(fileBuktiBayar, "", {
              ID: order.ID,
              Type: 1,
            });
            loadingUpload = true;
          }
          break;

        default:
          modalBtnContinueVariant = "primary";
          modalBtnContinueText = "Lanjutkan";
          break;
      }
    }

    if (!openModal) {
      fileBuktiBayar = null;
      previewFileBuktiBayar = null;
    }

    this.setState({
      showModal: openModal,
      modalAction,
      modalTitle,
      modalBody,
      modalBtnContinueVariant,
      modalBtnContinueText,
      isLoading,
      loadingConfirm,
      loadingUpload,
      fileBuktiBayar,
      previewFileBuktiBayar,
    });
  }

  render() {
    const {
      isLoading,
      loadingConfirm,
      loadingUpload,
      order,
      orders,
      orderStatus,
      orderStatusSchema,
      activePage,
      itemPerPage,
      totalOrder,
      breadcrumb,
      action,
      showModal,
      modalAction,
      modalTitle,
      modalBody,
      modalBtnContinueText,
      modalBtnContinueVariant,
      filterData,
      fileBuktiBayar,
      previewFileBuktiBayar,
    } = this.state;

    let renderView = null;

    switch (action) {
      case "detail":
        renderView = (
          <OrderDetail
            isLoading={isLoading}
            order={order}
            orderStatus={orderStatus}
            breadcrumb={breadcrumb}
            onOpenModal={this.toggleModal}
          />
        );
        break;

      case "payment":
        renderView = (
          <OrderPayment
            isLoading={isLoading}
            order={order}
            breadcrumb={breadcrumb}
            onOpenModal={this.toggleModal}
          />
        );
        break;

      case "completed":
        renderView = (
          <OrderCompleted
            isLoading={isLoading}
            order={order}
            breadcrumb={breadcrumb}
          />
        );
        break;

      case "list":
        renderView = (
          <Order
            isLoading={isLoading}
            orders={orders}
            orderStatus={orderStatus}
            breadcrumb={breadcrumb}
            itemPerPage={itemPerPage}
            totalOrder={totalOrder}
            activePage={activePage}
            filterData={filterData}
            orderStatusSchema={orderStatusSchema}
            onChangePage={this.handleChangePage}
            onOpenModal={this.toggleModal}
          />
        );
        break;

      default:
        renderView = <ErrorPage />;
        break;
    }

    return (
      <React.Fragment>
        {renderView}

        <Modal show={showModal} onHide={(e) => this.toggleModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalAction &&
              modalAction.type === "UPLOAD_BUKTI_BAYAR" &&
              previewFileBuktiBayar && (
                <div style={{ margin: "16px", textAlign: "center" }}>
                  <img
                    src={previewFileBuktiBayar}
                    style={{ width: "100%" }}
                    alt="img-bukti-bayar"
                  />
                </div>
              )}

            {modalBody}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => this.toggleModal(false)}
            >
              Kembali
            </Button>
            <Button
              variant={modalBtnContinueVariant}
              disabled={
                loadingConfirm ||
                loadingUpload ||
                (modalAction != null &&
                  modalAction.type === "UPLOAD_BUKTI_BAYAR" &&
                  fileBuktiBayar == null)
              }
              onClick={(e) => {
                ReactGA.event({
                  category: "shopping",
                  action: "upload_receipt",
                });
                return this.toggleModal(true, null, true);
              }}
            >
              {loadingConfirm || loadingUpload
                ? "Memproses..."
                : modalBtnContinueText}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { martorder } = state;
  return { martorder };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchMartOrder,
    fetchMartOrderById,
    fetchMartOrderStatus,
    displayMessage,
    cancelMartOrder,
    confirmMartOrder,
    uploadBuktiBayar,
    //list of dispatch action
  })(OrderContainer)
);
