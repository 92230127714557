import {
  REQUEST_MARTPRODUCT,
  REQUEST_MARTPRODUCT_CHOICE_LIST,
  REQUEST_MARTPRODUCT_LATEST_LIST,
  REQUEST_MARTPRODUCT_LIST,
  REQUEST_MARTPRODUCT_POPULAR_LIST,
  RESULT_MARTPRODUCT,
  RESULT_MARTPRODUCT_CHOICE_LIST,
  RESULT_MARTPRODUCT_LATEST_LIST,
  RESULT_MARTPRODUCT_LIST,
  RESULT_MARTPRODUCT_POPULAR_LIST,
  RESULT_MART_TOTAL_PRODUCT,
  RESULT_MART_TOTAL_PRODUCT_POPULAR
} from "./../actions/mart_product";

const initialState = {
  isFetchingMartProductList: false,
  isFetchingMartProduct: false,
  isFetchingMartProductPopularList: false,
  isFetchingMartProductLatestList: false,
  isFetchingMartProductChoiceList: false,
  mart_products: [],
  mart_product_choice_list: [],
  mart_product_choice_total: null,
  mart_product_popular_list: [],
  mart_product_latest_list: [],
  mart_total_products: null,
  mart_total_product_popular: null,
  mart_product: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_MARTPRODUCT_LIST: {
      return {
        ...state,
        isFetchingMartProductList: true,
        mart_products: [],
      };
    }

    case REQUEST_MARTPRODUCT_POPULAR_LIST: {
      return {
        ...state,
        isFetchingMartProductPopularList: true,
        mart_product_popular_list: [],
      };
    }

    case REQUEST_MARTPRODUCT_LATEST_LIST: {
      return {
        ...state,
        isFetchingMartProductLatestList: true,
        mart_product_latest_list: [],
      };
    }

    case REQUEST_MARTPRODUCT_CHOICE_LIST: {
      return {
        ...state,
        isFetchingMartProductChoiceList: true,
        mart_product_choice_list: [],
      };
    }

    case REQUEST_MARTPRODUCT: {
      return {
        ...state,
        mart_product: null,
        isFetchingMartProduct: true,
      };
    }

    case RESULT_MARTPRODUCT_LIST: {
      return {
        ...state,
        isFetchingMartProductList: false,
        mart_products: action.payload.data,
      };
    }

    case RESULT_MARTPRODUCT_POPULAR_LIST: {
      return {
        ...state,
        isFetchingMartProductPopularList: false,
        mart_product_popular_list: action.payload.data,
      };
    }

    case RESULT_MARTPRODUCT_LATEST_LIST: {
      return {
        ...state,
        isFetchingMartProductLatestList: false,
        mart_product_latest_list: action.payload.data,
      };
    }

    case RESULT_MARTPRODUCT_CHOICE_LIST: {
      return {
        ...state,
        isFetchingMartProductChoiceList: false,
        mart_product_choice_list: action.payload.data,
        mart_product_choice_total: action.payload.count,
      };
    }

    case RESULT_MART_TOTAL_PRODUCT: {
      // local data
      if (process.env.REACT_APP_LOCAL_DATA === "true") {
        localStorage.setItem(
          "AGREGO_PRODUCTS_COUNT",
          JSON.stringify(action.payload.count)
        );
        localStorage.setItem(
          "AGREGO_PRODUCTS",
          JSON.stringify(action.payload.data)
        );
      }

      return {
        ...state,
        isFetchingMartProductList: false,
        mart_products: action.payload.data,
        mart_total_products: action.payload.count,
      };
    }

    case RESULT_MART_TOTAL_PRODUCT_POPULAR: {
      return {
        ...state,
        isFetchingMartProductPopularList: false,
        mart_product_popular_list: action.payload.data,
        mart_total_product_popular: action.payload.count,
      };
    }

    case RESULT_MARTPRODUCT: {
      return {
        ...state,
        isFetchingMartProduct: false,
        mart_product: action.payload.data,
      };
    }

    default:
      return state;
  }
}
