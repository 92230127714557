import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../Beranda.css";
import { fetchBanner } from "../../redux/actions/banner";
import Loading from "./../Layout/Loading";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    };
  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    //get news dari api

    this.props.fetchBanner();

    this.setState({
      isLoading: true,
    });
  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    //cek state dari news
    const { fetchingBanner, banner } = this.props.banner;
    const { isLoading } = this.state;

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (!fetchingBanner && isLoading) {
      this.setState({
        isLoading: false,
        data: banner,
      });
    }
  }

  render() {
    const { isLoading, data } = this.state;

    return (
      <div>
        {isLoading && <Loading />}

        {!isLoading && (
          <div className="banner-carousel">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={5000}
              responsive={responsive}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={this.props.deviceType}
              dotListClass="react-multi-carousel-dot-list"
              showDots={true}
              renderDotsOutside={true}
            >
              {data != null &&
                data.length > 0 &&
                !isLoading &&
                data.map((item, index) => (
                  <div key={index} className="banner-item">
                    {parseInt(item.Category) === 0 && (
                      <a
                        href={item.URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <img
                          src={item.Img}
                          width="100%"
                          alt={"banner " + index}
                        />
                      </a>
                    )}
                    {parseInt(item.Category) === 1 && (
                      <a href={"/berita/" + item.CatID + "/detail"}>
                        {" "}
                        <img
                          src={item.Img}
                          width="100%"
                          alt={"banner " + index}
                        />
                      </a>
                    )}
                    {parseInt(item.Category) === 2 && (
                      <a href={"/promo/" + item.CatID + "/detail"}>
                        {" "}
                        <img
                          src={item.Img}
                          width="100%"
                          alt={"banner " + index}
                        />
                      </a>
                    )}
                  </div>
                ))}
            </Carousel>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { banner } = state;
  return { banner };
};
export default withRouter(
  connect(mapStateToProps, {
    fetchBanner,
    //list of dispatch action
  })(Banner)
);
