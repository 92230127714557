import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { parseCurrency } from '../../../utils/common';
import { parseInquiryPlnPostpaid } from '../../../utils/digital_parser';

const styles = {
    title: {
        fontSize: '20px',
        marginBottom:'18px'
    },
    label: {
        color: '#A0A0A0'
    },
    value: {
        color: '#5A5A5A',
        fontWeight: 'bold'
    },
    labelTotal: {
        color: '#5A5A5A'
    },
    price: {
        color: '#A0A0A0',
        textAlign: 'right',
        padding:'0 5px'
    },
    valueTotal: {
        color: '#5A5A5A', 
        fontWeight: 'bold',
        textAlign: 'right',
        padding:'0 5px'
    },
    line : {
        height:'1px', 
        margin:'18px 0',
        borderBottom:'2px solid #E7E7E7'
    }
}

function PlnPostpaid(props) {

    const {order} = props;
    const note = order != null && order['Note'] != null && order['Note'] !== "" && order['Note'] !== "Under Process" && parseInquiryPlnPostpaid(order['Note']);
    let parseData = note != null && note['data'] != null ? note['data'] : null;

    return <Container>
        <Row>
            <Col>

                <Row>
                    <Col style={styles.label}>ID Pelanggan</Col>
                </Row>
                <Row>
                    <Col style={styles.value}>{parseData != null && parseData['IDPelanggan']}</Col>
                </Row>
                
                <Row style={{marginTop:'16px'}}>
                    <Col style={styles.label}>Nama Lengkap</Col>
                </Row>
                <Row>
                    <Col style={styles.value}>{parseData != null && parseData['NamaPelanggan']}</Col>
                </Row>

            </Col>    

            <Col>
                <Row>
                    <Col style={styles.label}>Tarif / Daya</Col>
                </Row>
                <Row>
                    <Col style={styles.value}>{parseData != null && parseData['Daya']}</Col>
                </Row>
                
                <Row style={{marginTop:'16px'}}>
                    <Col style={styles.label}>Hingga</Col>
                </Row>
                <Row>
                    <Col style={styles.value}>{parseData != null && parseData['Periode']}</Col>
                </Row>
            </Col>
        </Row>
        <div style={styles.line}>&nbsp;</div>
        <Row>
            <Col style={styles.title}>Detail Pembayaran</Col> 
        </Row>
        <Row noGutters>
            <Col md={6} xs={6} style={styles.label}>Tagihan Listrik</Col> 
            <Col md={{span:1, offset:1}} xs={{span: 1, offset:1}} style={styles.price}>Rp</Col> 
            <Col md={{span:2}} xs={4} style={styles.price}>{parseCurrency(parseInt(order.Price) - parseInt(order.AdminFee))}</Col> 
        </Row>
        <Row noGutters>
            <Col md={6} xs={6} style={styles.label}>Biaya Admin</Col> 
            <Col md={{span:1, offset:1}} xs={{span: 1, offset:1}} style={styles.price}>Rp</Col> 
            <Col md={{span:2}} xs={4} style={styles.price}>{parseCurrency(order.AdminFee)}</Col> 
        </Row>
        <Row noGutters>
            <Col md={6} xs={6} style={styles.labelTotal}>Total Tagihan</Col> 
            <Col md={{span:1, offset:1}} xs={{span: 1, offset:1}}  style={styles.price}>Rp </Col> 
            <Col md={{span:2}} xs={4} style={styles.valueTotal}>{parseCurrency(order.Price)}</Col> 
        </Row> 
        <div style={styles.line}>&nbsp;</div>
        <Row>
            <Col style={styles.label}>Pilihan Pembayaran</Col> 
        </Row>
        <Row>
            <Col style={styles.value}>{order != null && order['Payment']}</Col>  
        </Row>
    </Container> 
}

export default PlnPostpaid;