import { createGlobalStyle } from "styled-components";

// theming dari environment

const GlobalStyle = createGlobalStyle` 
  .bg-primary, .btn-outline-primary:hover {
    background: ${process.env.REACT_APP_THEME_PRIMARY} !important;
    color: #fff !important; 
  } 
  .bg-secondary, .btn-outline-secondary:hover {
    background: ${process.env.REACT_APP_THEME_SECONDARY} !important;
    color: #fff !important; 
  }

  .btn-outline-primary {  
    border-color: ${process.env.REACT_APP_THEME_PRIMARY} !important;
    color: ${process.env.REACT_APP_THEME_PRIMARY} !important;
  }
  .btn-outline-secondary {  
    border-color: ${process.env.REACT_APP_THEME_SECONDARY} !important;
    color: ${process.env.REACT_APP_THEME_SECONDARY} !important;
  }

  .btn-primary {
      background: ${process.env.REACT_APP_THEME_PRIMARY} !important;
      color: #fff !important;
  }
  .btn-secondary {
      background: ${process.env.REACT_APP_THEME_SECONDARY} !important;
      color: #fff !important;
  }

  .btn-link {
      color: ${process.env.REACT_APP_THEME_PRIMARY} !important;
  } 

  :root {
    --primary: ${process.env.REACT_APP_THEME_PRIMARY} !important; 
  }
`;

export default GlobalStyle;
