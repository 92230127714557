// import action type newsnya
import { REQUEST_PRODUKVIRTUAL, RESULT_PRODUKVIRTUAL } from "./../actions/produkvirtual";

// state untuk menyimpan data 
const initialState = {
    // state ini sebagai tanda apakah sedang loading mengambil data atau tidak
    fetchingProdukVirtual: false,
    
    // nantinya data news dari API disimpan di state news
    produkVirtual: [],

    stateSatu: 1,
    stateDua: 2,
    StateTiga: 3
};

export default function(state = initialState, action) {
  switch (action.type) {
    
    // Ketika action yang berjalan request news, artinya sedang loading data news
    case REQUEST_PRODUKVIRTUAL: { 
      return {
        ...state,
        fetchingProdukVirtual: true
      };
    }

    // data selesai di load, maka fetchingNews = false (selesai loading), dan datanya di simpan di state news
    case RESULT_PRODUKVIRTUAL: {
      const { data } = action.payload; 
      return {
        ...state,
        fetchingProdukVirtual: false,
        produkVirtual : data
      };
    }

    default:
      return state;
  }
}