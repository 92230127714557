import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Pulsa.css'; 
import Breadcrumb from '../../Layout/Breadcrumb'; 
  

export default function PulsaDetail(props) {
    const{ breadcrumb} = props;
    
    
  return (

    
    
    <Container>
        <Row>
            <Col>
            <div style={{ marginTop: '20px', paddingBottom: '0px' }}>
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                </div>

                <div className="RincianContainer">
                    <Row>
                        <Col md={8}>
                        <div className="RincianPesananJudul">Rincian Pesanan</div>
                        <div style={{marginTop:'27px', marginLeft:'45px'}}>
                        <div className="pulsaListJudul">Telkomsel internet 1 GB, 7 hari</div>
                        <div className="pulsaListDesk">Kuota Utama 1 GB. Masa aktif 7 hari</div>
                        </div>
                            
                        </Col>
                            <div className="rincianPrice">Rp.15000</div>
                        <Col md={4}>
                        
                        </Col>
                    </Row>
                </div>

            </Col>
        </Row>
    </Container>
  
  );
}