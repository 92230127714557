import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../Beranda.css";
import {
  deleteMartDeliveryAddress,
  fetchMartDeliveryAddress,
  fetchMartDeliveryAddressById,
  saveMartDeliveryAddress,
} from "../../redux/actions/mart_delivery";
import {
  fetchRegArea,
  fetchRegCity,
  fetchRegProvince,
  fetchRegSubDistrict,
} from "../../redux/actions/regional";
import AddressForm from "./../Mart/Address/AddressForm.component";
import AddressList from "./../Mart/Address/AddressList.component";
import AddressMap from "./../Mart/Address/AddressMap.component";
import Alamat from "./Alamat.component";
import AlamatAdd from "./AlamatAdd.component";
import "./Profile.css";

class ProfileAddressContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingProvince: false,
      loadingCity: false,
      loadingSubDistrict: false,
      loadingAddress: false,
      loadingArea: false,

      provinces: [],
      cities: [],
      subdistricts: [],
      area: [],

      formAddress: null,
      formAddressID: null,

      showModal: false,
      modalTitle: null,
      modalBody: null,
      modalAction: null,

      action: null,
      breadcrumb: null,
      addressList: [],
      addressDetail: [],
      addressID: null,
      SubDistrictID: null,
      submittingProfile: false,
      successUpdate: false,
      submittingAddress: false,
      successAdd: false,
      pageTitle: null,
    };

    this.loadAddress = this.loadAddress.bind(this);
    this.handleChangeProvince = this.handleChangeProvince.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeSubDistrict = this.handleChangeSubDistrict.bind(this);
    this.handleSubmitAddress = this.handleSubmitAddress.bind(this);
    this.handleDeleteAddress = this.handleDeleteAddress.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const {
      isFetchingMartDeliveryAddressList,
      mart_delivery_addresslist,
      mart_delivery_address,
      isSavingMartDeliveryAddress,
      isFetchingMartDeliveryAddress,
      isDeletingMartDeliveryAddress,
    } = this.props.martdelivery;
    const {
      isLoading,
      action,
      loadingAddress,
      submittingAddress,
      SubDistrictID,
    } = this.state;
    const { id, extra } = this.props.match.params;
    const {
      isFetchingRegProvince,
      isFetchingRegCity,
      isFetchingRegSubDistrict,
      isFetchingRegArea,
      reg_provinces,
      reg_cities,
      reg_subdistricts,
      reg_area,
    } = this.props.regional;
    let {
      successAdd,
      successUpdate,
      breadcrumb,
      loadingProvince,
      loadingCity,
      loadingSubDistrict,
      loadingArea,
      pageTitle,
    } = this.state;

    if (
      !isFetchingMartDeliveryAddressList &&
      !isSavingMartDeliveryAddress &&
      !isFetchingMartDeliveryAddress &&
      isLoading &&
      !isFetchingRegProvince
    ) {
      // successAdd = submittingAddress && successAddAdress;

      // kembali ke view data diri setelah berhasil update data diri
      if (submittingAddress) {
        this.props.history.push("/profile/alamat");
        // this.loadData()s;
      }

      if (!isDeletingMartDeliveryAddress && loadingAddress) {
        this.loadData();
      }

      if (loadingProvince && !isFetchingRegProvince) {
        this.setState({ loadingProvince: false, provinces: reg_provinces });
      }

      if (
        loadingArea &&
        !isFetchingRegArea &&
        reg_subdistricts !== "undefined"
      ) {
        this.setState({ loadingArea: false, area: reg_area });
      }

      pageTitle = "Daftar Alamat";

      // update city setelah selesai load
      if (action === "edit") {
        this.props.fetchRegCity({
          page: 1,
          size: 1000,
          search: { ProvinceID: mart_delivery_address.ProvinceID },
        });
        this.props.fetchRegSubDistrict({
          page: 1,
          size: 1000,
          search: { CityID: mart_delivery_address.CityID },
        });

        loadingCity = true;
        loadingSubDistrict = true;
      }

      breadcrumb = [
        { link: "/beranda", text: "Beranda" },
        { link: "/profile/alamat", text: "Profil" },
      ];

      if (id) {
        pageTitle = !isNaN(parseInt(id)) ? "Tambah Alamat" : "Ubah Alamat";
        breadcrumb.push({
          link: "/profile/alamat/" + id,
          text: id && !isNaN(parseInt(id)) ? "Ubah Alamat" : "Tambah Alamat",
        });
      }

      if (action === "map") {
        pageTitle = "Pinpoint Alamat";
        breadcrumb.push({
          link: "/profile/alamat/" + id + "/map",
          text: "Pinpoint Alamat",
        });
      }

      this.setState({
        isLoading: false,
        loadingAddress: false,
        addressList: mart_delivery_addresslist,
        addressDetail: mart_delivery_address,
        submittingProfile: false,
        successUpdate: successUpdate,
        breadcrumb,
        submittingAddress: false,
        successAdd: successAdd,
        loadingProvince: false,
        loadingCity: loadingCity,
        loadingSubDistrict: loadingSubDistrict,
        loadingArea: loadingArea,
        provinces: reg_provinces,
        cities: reg_cities,
        subdistricts: reg_subdistricts,
        area: reg_area,
        SubDistrictID: SubDistrictID,
        pageTitle,
      });
    }

    if (!isLoading && loadingCity && !isFetchingRegCity) {
      this.setState({ loadingCity: false, cities: reg_cities });
    }

    //sudah ada hasil atau result dari api
    if (!isLoading && loadingSubDistrict && !isFetchingRegSubDistrict) {
      this.setState({
        loadingSubDistrict: false,
        subdistricts: reg_subdistricts,
      });

      if (
        action === "edit" &&
        reg_subdistricts != null &&
        mart_delivery_address
      ) {
        let selectedSubdistrict = _.find(
          reg_subdistricts,
          (o) =>
            parseInt(o.ID) === parseInt(mart_delivery_address.SubDistrictID)
        );
        if (selectedSubdistrict != null) {
          this.props.fetchRegArea({
            page: 1,
            size: 1000,
            search: { SubDistrictIDShipper: selectedSubdistrict.IDShipper },
          });
          this.setState({ loadingArea: true, area: reg_area });
        }
      }
    }

    if (!isLoading && loadingArea && !isFetchingRegArea) {
      this.setState({ loadingArea: false, area: reg_area });
    }

    // // load data jika dari list menjadi edit
    // // if (typeof (action) !== "undefined") {
    // //   this.loadData();
    // //   // load data jika dari edit menjadi list

    //  if (typeof (extra) !== "undefined" && extra !== action) {
    //   this.loadData();
    // }

    if (extra && extra === "map" && action !== "map") {
      this.loadData();
    }
    if (!extra && id && !isNaN(parseInt(id)) && action !== "edit") {
      this.loadData();
    }
    if (!extra && id && isNaN(parseInt(id)) && action !== "add") {
      this.loadData();
    }
    if (!extra && !id && action !== "list") {
      this.loadData();
    }
  }

  loadData(param) {
    let { action } = this.state;
    const { id, extra } = this.props.match.params;
    const { breadcrumb } = this.props.match;
    this.props.fetchRegProvince({ page: 1, size: 100 });

    if (id && !isNaN(parseInt(id))) {
      this.props.fetchMartDeliveryAddressById(id);
      // this.props.fetchRegProvince({page:1,size:100});
      // this.props.fetchRegCity({page:1,size:100});
      // this.props.fetchRegSubDistrict({page:1,size:100});

      action = "edit";
    } else if (id && isNaN(parseInt(id))) {
      action = "add";
    } else {
      this.props.fetchMartDeliveryAddress({ page: 1, size: 10 });
      action = "list";
    }

    if (extra && extra === "map") {
      action = "map";
    }

    this.setState({
      isLoading: true,
      loadingAddress: true,
      action: action,
      addressID: id,
      breadcrumb: breadcrumb,
    });
  }

  handleSubmitAddress(values) {
    let { id } = this.props.match.params;

    // hapus localstorage sebelumnya
    localStorage.removeItem(process.env.REACT_APP_PREFIX + "ADDRESS_FORM");

    if (!isNaN(parseInt(id))) {
      //update
      this.props.saveMartDeliveryAddress(values, id);
    } else {
      //add
      this.props.saveMartDeliveryAddress(values);
    }

    this.setState({ isLoading: true, submittingAddress: true });
  }

  handleChangeProvince(e) {
    let ProvinceID = e;
    this.props.fetchRegCity({
      page: 1,
      size: 1000,
      search: { ProvinceID: ProvinceID },
    });
    this.setState({ loadingCity: true, subdistricts: [] });

    // this.handleChangeFormAddress(e);
  }

  handleChangeCity(e) {
    let CityID = e;
    this.props.fetchRegSubDistrict({
      page: 1,
      size: 1000,
      search: { CityID: CityID },
    });
    this.setState({ loadingSubDistrict: true });

    // this.handleChangeFormAddress(e);
  }

  handleChangeSubDistrict(e) {
    const { subdistricts } = this.state;
    let SubDistrictID = e;

    let selectedSubdistrict = _.find(
      subdistricts,
      (o) => parseInt(o.ID) === parseInt(SubDistrictID)
    );
    this.props.fetchRegArea({
      page: 1,
      size: 1000,
      search: { SubDistrictIDShipper: selectedSubdistrict.IDShipper },
    });
    this.setState({ loadingArea: true, area: [] });
  }

  handleDeleteAddress(id) {
    this.props.deleteMartDeliveryAddress(id);
    this.setState({ loadingAddress: true, submittingAddress: true });
    this.loadData();
  }

  toggleModal(isOpen, data, confirm, id) {
    let { modalTitle, modalBody, modalAction } = this.state;

    if (typeof data !== "undefined" && typeof data["type"] !== "undefined") {
      modalAction = data;
    }

    if (modalAction != null && typeof modalAction["type"] !== "undefined") {
      switch (modalAction["type"]) {
        case "DELETE_ADDRESS":
          modalTitle = "Konfirmasi Hapus";
          modalBody = "Apakah anda ingin menghapus alamat ini?";

          if (typeof confirm !== "undefined" && confirm) {
            this.props.deleteMartDeliveryAddress(modalAction["id"]);
            this.setState({ loadingAddress: true });
            this.loadData();
          }

          break;

        default:
          console.log("");
          break;
      }
    }

    this.setState({
      showModal: isOpen,
      modalTitle,
      modalBody,
      modalAction,
    });
  }

  loadAddress() {
    this.props.fetchMartDeliveryAddress({ page: 1, size: 10 });
    this.setState({ loadingAddress: true });
  }
  render() {
    const {
      isLoading,
      action,
      breadcrumb,
      addressList,
      loadingAddress,
      successAdd,
      addressDetail,
      loadingCity,
      loadingProvince,
      loadingArea,
      provinces,
      cities,
      subdistricts,
      area,
      loadingSubDistrict,
      showModal,
      modalTitle,
      modalBody,
      addressID,
      pageTitle,
    } = this.state;

    let renderComponent = null;

    if (action === "add" || action === "edit") {
      renderComponent = (
        <AlamatAdd
          breadcrumb={breadcrumb}
          pageTitle={pageTitle}
          isLoading={isLoading}
          loadingAddress={loadingAddress}
        >
          <AddressForm
            addressDetail={action === "add" ? null : addressDetail}
            addressID={addressID}
            isLoading={isLoading}
            breadcrumb={breadcrumb}
            loadingAddress={loadingAddress}
            onSubmitAddress={this.handleSubmitAddress}
            successAdd={successAdd}
            loadingProvince={loadingProvince}
            loadingCity={loadingCity}
            loadingSubDistrict={loadingSubDistrict}
            loadingArea={loadingArea}
            provinces={provinces}
            cities={cities}
            subdistricts={subdistricts}
            area={area}
            addressUrl={"/profile/alamat/"}
            onChangeProvince={this.handleChangeProvince}
            onChangeCity={this.handleChangeCity}
            onChangeSubdistrict={this.handleChangeSubDistrict}
          />
        </AlamatAdd>
      );
    } else if (action === "map") {
      renderComponent = (
        <AlamatAdd
          breadcrumb={breadcrumb}
          pageTitle={pageTitle}
          isLoading={isLoading}
          loadingAddress={loadingAddress}
        >
          <AddressMap
            addressDetail={action === "add" ? null : addressDetail}
            addressID={addressID}
            isLoading={isLoading}
            breadcrumb={breadcrumb}
            loadingAddress={loadingAddress}
            addressUrl={"/profile/alamat/"}
          />
        </AlamatAdd>
      );
    } else {
      renderComponent = (
        <Alamat
          pageTitle={pageTitle}
          isLoading={isLoading}
          loadingAddress={loadingAddress}
        >
          <AddressList
            addressList={addressList}
            isLoading={isLoading}
            loadingAddress={loadingAddress}
            handleDeleteAddress={this.handleDeleteAddress}
            onOpenModal={this.toggleModal}
            addressUrl={"/profile/alamat/"}
          />
          {/* Confirmation Modal */}
          <Modal show={showModal} onHide={(e) => this.toggleModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={(e) => this.toggleModal(false)}
              >
                Batalkan
              </Button>
              <Button
                variant="danger"
                onClick={(e) => this.toggleModal(false, "", true, "")}
              >
                Lanjutkan
              </Button>
            </Modal.Footer>
          </Modal>
        </Alamat>
      );
    }

    return <div>{renderComponent}</div>;
  }
}

const mapStateToProps = (state) => {
  const { martdelivery, regional, addressDetail } = state;
  return { martdelivery, regional, addressDetail };
};
export default withRouter(
  connect(mapStateToProps, {
    fetchMartDeliveryAddress,
    fetchRegProvince,
    fetchRegCity,
    fetchRegSubDistrict,
    saveMartDeliveryAddress,
    fetchMartDeliveryAddressById,
    deleteMartDeliveryAddress,
    fetchRegArea,

    //list of dispatch action
  })(ProfileAddressContainer)
);
