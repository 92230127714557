import React from 'react';
import { Button, Row, Col } from 'react-bootstrap'; 
import './Auth.css';  

var Logo = require(`./../../${process.env.REACT_APP_THEME_IMAGE}logo-white.png`); 

function WelcomeMessage() {  
  return ( 
      <div>

      <Row noGutters>
        <Col md={7} className="d-none d-lg-block"> 
          <div className="login-left-box">
            <div className="login-bg-pattern">
            </div>
            <div className="login-logo">
              <img src={Logo} alt="logo" width="100%"></img>
              {/* <p>Solusi untuk komunitas anda</p> */}
            </div>
          </div> 
        </Col>
      
        <Col>  
          <div className="login-form-wrapper">  
            <div className="welcome-mesage-Container">
            <div className="welcome-message-text-bold">Selamat</div>
                  
              <div className="welcome-message-text">Email Anda sudah terverifikasi, silakan login</div>

              <Button href="/login" variant="primary" block className="login-btn">
                  Login
                </Button>

              </div>
              
          </div>
        </Col>
      </Row> 
      </div> 
  ); 
} 

export default WelcomeMessage;