import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import Breadcrumb from "./../../Layout/Breadcrumb";
import Loading from "./../../Layout/Loading";
import "./../Product/Product.css";
import "./Payment.css";

export default class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        PaymentType: null,
      },
      isLoading: true,
    };

    this.handleChangePayment = this.handleChangePayment.bind(this);
  }

  componentDidMount() {
    ReactGA.set({ title: "payment_methods" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate() {
    const { values } = this.state;
    const {
      payment,
      paymentType,
      bankAccountID,
      paymentQris,
      bankName,
      bankImage,
      isLoading,
    } = this.props;

    if (!isLoading && this.state.isLoading) {
      values["Payment"] = payment;
      values["PaymentType"] = paymentType;

      if (typeof bankAccountID !== "undefined" && bankAccountID != null) {
        values["BankAccountID"] = bankAccountID;
        values["BankName"] = bankName;
        values["BankImage"] = bankImage;
      } else if (
        values["PaymentType"] != null &&
        values["PaymentType"].toLowerCase() === "qris" &&
        paymentQris != null
      ) {
        values["QRISPaymentID"] = paymentQris["ID"];
      }

      this.setState({ values, isLoading: false });
    }
  }

  handleChangePayment({ type, payment, accountID, bankName, bankImage, accountName, accountNumber }) {
    let { values } = this.state;

    values["PaymentType"] = type;
    values["Payment"] = payment;

    if (type.toLowerCase() === "bank") {
      values["BankAccountID"] =
        typeof accountID !== "undefined" ? accountID : null;
      values["BankName"] = bankName;
      values["BankImage"] = bankImage;
      values["BankAccountName"] = accountName;
      values["BankAccountNumber"] = accountNumber;
    } else if (type.toLowerCase() === "qris") {
      values["QRISPaymentID"] =
        typeof accountID !== "undefined" ? accountID : null;
    } else {
      delete values["QRISPaymentID"];
      delete values["BankAccountID"];
      delete values["BankName"];
      delete values["BankImage"];
      delete values["BankAccountName"];
      delete values["BankAccountNumber"];
    }

    this.setState({ values });
  }

  render() {
    const { isLoading, bankAccount, breadcrumb, paymentSchema, paymentQris } =
      this.props;
    const { values } = this.state;

    return (
      <Container>
        {/* loading state */}
        {isLoading && <Loading />}
        {!isLoading && (
          <div
            style={{ marginTop: "29px", marginBottom: "28px" }}
            className="payment-page-title"
          >
            Metode Pembayaran
          </div>
        )}

        <div style={{ marginTop: "40px" }}>
          {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
        </div>

        {!isLoading &&
          paymentSchema.length > 0 &&
          paymentSchema.map((schema, index) => {
            return (
              <div key={index}>
                {schema.Type.toLowerCase() === "bank" && (
                  <>
                  {process.env.REACT_APP_SHOW_BANK_ACCOUNT_NUMBER ?
                      <CustomBankAccount
                          schema={schema}
                          values={values}
                          onChange={(account) =>
                            this.handleChangePayment({
                              type: schema.Type,
                              payment: schema.Value,
                              accountID: account.ID,
                              accountNumber: account.AccountNumber,
                              accountName: account.AccountName,
                              bankName: account.BankName,
                              bankImage: account.BankImage,
                            })
                          }
                      /> :
                      <BankAccount
                          schema={schema}
                          values={values}
                          bankAccount={bankAccount}
                          onChange={(account) =>
                              this.handleChangePayment({
                                type: schema.Type,
                                payment: schema.Value,
                                accountID: account.ID,
                                accountNumber: account?.AccountNumber || null,
                                accountName: account?.AccountName || null,
                                bankName: account.BankName,
                                bankImage: account.BankImage,
                              })
                          }
                      />}
                  </>
                )}

                {schema.Type.toLowerCase() === "qris" && (
                  <div className="payment-card">
                    <div className="title">{schema.Type}</div>
                    <Row style={{ paddingTop: "20px" }}>
                      <Col valign="top">
                        <Form.Check
                          onChange={(e) =>
                            this.handleChangePayment({
                              type: schema.Type,
                              payment: schema.Value,
                              accountID: paymentQris["ID"],
                            })
                          }
                          checked={
                            values != null && values.PaymentType === schema.Type
                          }
                          inline
                          name="PaymentType"
                          label={schema.Text}
                          type="radio"
                          value="Wallet"
                          id={`inline-check-${index}`}
                        />
                        <div style={{ fontSize: "14px", paddingLeft: "18px" }}>
                          Untuk layanan dompet digital (Gopay, OVO, LinkAja,
                          Dana, dsb)
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                {!["qris", "bank"].includes(schema.Type.toLowerCase()) && (
                  <div className="payment-card">
                    <div className="title">{schema.Type}</div>
                    <Row style={{ paddingTop: "20px" }}>
                      <Col>
                        <Form.Check
                          onChange={(e) =>
                            this.handleChangePayment({
                              type: schema.Type,
                              payment: schema.Value,
                            })
                          }
                          checked={
                            values != null && values.PaymentType === schema.Type
                          }
                          inline
                          name="PaymentType"
                          label={schema.Text}
                          type="radio"
                          value="Wallet"
                          id={`inline-check-${index}`}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            );
          })}
        {!isLoading && (
          <Button
            variant="primary"
            disabled={values.PaymentType == null}
            onClick={(e) => this.props.onChangePayment(values)}
            className="btn-simpan d-none d-md-block"
          >
            Simpan
          </Button>
        )}

        <div style={{ height: "100px" }}>&nbsp;</div>

        {/* Mobile view fixed in bottom */}
        {!isLoading && (
          <div className="product-summary-bottom d-sm-block d-md-none">
            <Button
              variant="primary"
              disabled={values.PaymentType == null}
              onClick={(e) => this.props.onChangePayment(values)}
              block
            >
              Simpan
            </Button>
          </div>
        )}
      </Container>
    );
  }
}

const BankAccount = ({bankAccount, schema, values, onChange}) => {
  return (
      <>
      {typeof bankAccount !== "undefined" &&
            bankAccount.length > 0 && (
                <div className="payment-card">
                  <div className="title">{schema.Value}</div>

                  <Form>
                    {bankAccount.map((account, index) => {
                      return (
                        <Row className="bank-list-item" key={index}>
                          <Col
                              xs={8}
                              md={10}
                              style={{ paddingTop: "15px" }}
                          >
                            <Form.Check
                                onChange={e => onChange(account)}
                                inline
                                checked={
                                    values != null &&
                                    values.PaymentType === schema.Type &&
                                    values.BankAccountID === account.ID
                                }
                                name="BankAccountID"
                                label={<span>{account.BankName}</span>}
                                type="radio"
                                id={`inline-check-${index}-${account.ID}`}
                            />
                          </Col>
                          <Col xs={4} md={2}>
                            <img
                                src={account.BankImage}
                                width={104}
                                alt={"Logo " + account.BankName}
                            />
                          </Col>
                        </Row>);
                    })}
                  </Form>
                </div>
            )}
      </>)
}

const CustomBankAccount = ({schema, values, onChange}) => {

  let account = {
    ID: process.env.REACT_APP_SUBMIT_BANK_ACCOUNT_ID,
    AccountNumber: process.env.REACT_APP_SHOW_BANK_ACCOUNT_NUMBER,
    AccountName: process.env.REACT_APP_SHOW_BANK_ACCOUNT_NAME,
    BankName: process.env.REACT_APP_SHOW_BANK_NAME,
    BankImage: process.env.REACT_APP_SHOW_BANK_IMAGE,
    ShowAccountName: process.env.REACT_APP_SHOW_BANK_ACCOUNT_NAME,
  }

  return (
      <div className="payment-card">
        <div className="title">{schema.Value}</div>
        <Form>
          <Row className="bank-list-item">
            <Col
                xs={8}
                md={10}
                style={{ paddingTop: "15px" }}
            >
              <Form.Check
                  onChange={(e) => onChange(account)}
                  inline
                  checked={
                      values != null &&
                      values.PaymentType === schema.Type &&
                      values.BankAccountID === account.ID
                  }
                  name="BankAccountID"
                  label={<span>{account.ShowAccountName}</span>}
                  type="radio"
                  id={`inline-check-0-${account.ID}`}
              />
            </Col>
            <Col xs={4} md={2}>
              <img
                  src={account.BankImage}
                  width={104}
                  alt={"Logo " + account.BankName}
              />
            </Col>
          </Row>
        </Form>
      </div>)
}
