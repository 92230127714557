import Axios from "axios";
import { setQueryString } from "./../../utils/common";
import { handleError } from "./app";

export const REQUEST_MARTCATEGORY = "REQUEST_MARTCATEGORY";
export const RESULT_MARTCATEGORY = "RESULT_MARTCATEGORY";

const searchFieldType = { ID: "int" };

export const requestMartCategory = () => ({
  type: REQUEST_MARTCATEGORY,
});

export const resultMartCategory = (data) => ({
  type: RESULT_MARTCATEGORY,
  payload: {
    data,
  },
});

// Request mart category from API
export const fetchMartCategory = (props) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartCategory());

    // LOCAL DATA
    // let localCategory = getLocalData("AGREGO_CATEGORY");
    // if(localCategory.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartCategory(localCategory));
    // }

    return Axios.get(
      "/productcategory/" + process.env.REACT_APP_OID + "?" + queryString,
      {
        // Ini digunakan hanya jika API membutuhkan token / login user untuk mengaksesnya
        // headers: {
        //     'Authorization' : 'bearer '+localStorage.getItem('AGREGO_USER_TOKEN')
        // }
      }
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartCategory(data.data));
      })
      .catch((error) => {
        dispatch(resultMartCategory(null));
        return dispatch(handleError(error));
      });
  };
};
