import Axios from "axios";
import { setFieldSearchQuery, setQueryString } from "./../../utils/common";
import { displayMessage } from "./app";

export const REQUEST_MARTORDER_LIST = "REQUEST_MARTORDER_LIST";
export const REQUEST_MARTORDER = "REQUEST_MARTORDER";
export const REQUEST_MARTORDER_WALLET = "REQUEST_MARTORDER_WALLET";
export const REQUEST_MARTORDER_STATUS = "REQUEST_MARTORDER_STATUS";
export const CANCELING_MARTORDER = "CANCELING_MARTORDER";
export const CONFIRMING_MARTORDER = "CONFIRMING_MARTORDER";
export const UPLOADING_MARTORDER_BUKTIBAYAR = "UPLOADING_MARTORDER_BUKTIBAYAR";

export const RESULT_MARTORDER_LIST = "RESULT_MARTORDER_LIST";
export const RESULT_MART_TOTAL_ORDER = "RESULT_MART_TOTAL_ORDER";
export const RESULT_MARTORDER = "RESULT_MARTORDER";
export const RESULT_MARTORDER_WALLET = "RESULT_MARTORDER_WALLET";
export const RESULT_MARTORDER_STATUS = "RESULT_MARTORDER_STATUS";
export const CANCELED_MARTORDER = "CANCELED_MARTORDER";
export const CONFIRMED_MARTORDER = "CONFIRMED_MARTORDER";
export const UPLOADED_MARTORDER_BUKTIBAYAR = "UPLOADED_MARTORDER_BUKTIBAYAR";

const searchFieldType = { CategoryID: "int", Status: "int" };

const requestMartOrderList = () => ({
  type: REQUEST_MARTORDER_LIST,
});

const requestMartOrder = () => ({
  type: REQUEST_MARTORDER,
});

const requestMartOrderWallet = () => ({
  type: REQUEST_MARTORDER_WALLET,
});

const requestMartOrderStatus = () => ({
  type: REQUEST_MARTORDER_STATUS,
});

const cancelingMartOrder = () => ({
  type: CANCELING_MARTORDER,
});

const confirmingMartOrder = () => ({
  type: CONFIRMING_MARTORDER,
});

const uploadingMartOrderBuktiBayar = () => ({
  type: UPLOADING_MARTORDER_BUKTIBAYAR,
});

const resultMartOrderList = (data) => ({
  type: RESULT_MARTORDER_LIST,
  payload: {
    data,
  },
});

const resultMartTotalOrder = (data) => ({
  type: RESULT_MART_TOTAL_ORDER,
  payload: {
    data: data.data,
    count: data.count,
  },
});

const resultMartOrder = (data) => ({
  type: RESULT_MARTORDER,
  payload: {
    data,
  },
});

const resultMartOrderWallet = (data) => ({
  type: RESULT_MARTORDER_WALLET,
  payload: {
    data: data.EndBalance,
  },
});

const resultMartOrderStatus = (data) => ({
  type: RESULT_MARTORDER_STATUS,
  payload: {
    data: data,
  },
});

const canceledMartOrder = (data) => ({
  type: CANCELED_MARTORDER,
  payload: {
    data: data,
  },
});

const confirmedMartOrder = (data) => ({
  type: CONFIRMED_MARTORDER,
  payload: {
    data: data,
  },
});

const uploadedMartOrderBuktiBayar = (data) => ({
  type: UPLOADED_MARTORDER_BUKTIBAYAR,
  payload: {
    data: data,
  },
});

// Request mart order
export const fetchMartOrder = (props, withTotalCount) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  withTotalCount = typeof withTotalCount !== "undefined" && withTotalCount;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartOrderList());

    // // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_ORDER");
    // if(localData.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartTotalOrder({data: localData, count: 16}));
    // }

    return Axios.get("/me/order?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        // kalau pake total count, maka jalankan query count data
        if (withTotalCount) {
          return dispatch(
            fetchMartTotalOrder({
              ...props,
              items: data.data,
              message: data.message,
            })
          );
        }

        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartOrder
        return dispatch(resultMartOrderList(data.data));
      })
      .catch((error) => {
        return dispatch(resultMartOrderList(null));
      });
  };
};

// Request mart order
export const fetchMartOrderById = (id) => {
  return (dispatch) => {
    dispatch(requestMartOrder());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_ORDER", id);
    // if(localData != null && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartOrder(localData));
    // }

    return Axios.get("/me/order/" + id)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartOrder(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart order
export const fetchMartTotalOrder = ({
  search,
  operand,
  items,
  message,
  queryString,
}) => {
  return (dispatch) => {
    search = typeof search !== "undefined" ? search : "";
    queryString = setFieldSearchQuery(search, "", searchFieldType);

    return Axios.get("/me/ordercount?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartOrder
        return dispatch(
          resultMartTotalOrder({ data: items, count: data.data.count })
        );
      })
      .catch((error) => console.log("error"));
  };
};

// get my wallet balance
export const fetchMartOrderWallet = () => {
  return (dispatch) => {
    dispatch(requestMartOrderWallet());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_WALLET");
    // if(localData != null && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return setTimeout(() => dispatch(resultMartOrderWallet({EndBalance: localData})), 1000);
    // }

    return Axios.get("/me/wallet")
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartOrderWallet(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

// get order status scheme
export const fetchMartOrderStatus = () => {
  return (dispatch) => {
    dispatch(requestMartOrderStatus());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_ORDER_STATUS");
    // if(localData.length > 0) {
    //     return dispatch(resultMartOrderStatus(localData));
    // }

    return Axios.get("/customdata/1/orderstatusscheme")
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartOrderStatus(data.data.OrderStatus));
      })
      .catch((error) => console.log("error"));
  };
};

// cancel order
export const confirmMartOrder = (id) => {
  return (dispatch) => {
    if (typeof id === "undefined" || isNaN(parseInt(id))) {
      return dispatch(displayMessage("ERROR"));
    }

    dispatch(confirmingMartOrder());

    return Axios.put("/me/arrivedorder/" + id, {})
      .then((response) => response.data)
      .then((data) => {
        let status =
          data.data != null && data.data.OrderStatus != null
            ? data.data.OrderStatus
            : null;
        return dispatch(confirmedMartOrder(status));
      })
      .catch((error) => console.log("error"));
  };
};

export const cancelMartOrder = (id) => {
  return (dispatch) => {
    if (typeof id === "undefined" || isNaN(parseInt(id))) {
      return dispatch(displayMessage("ERROR"));
    }

    dispatch(cancelingMartOrder());

    return Axios.put("/me/cancelorder/" + id, {})
      .then((response) => response.data)
      .then((data) => {
        return dispatch(canceledMartOrder(data.data.OrderStatus));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
export const uploadBuktiBayar = (File, FileName, Order) => {
  return (dispatch) => {
    dispatch(uploadingMartOrderBuktiBayar());

    return Axios.get("/tools/s3/upload", {
      headers: {
        Path:
          "agrego/asset/" + localStorage.getItem("AGREGO_USER_OID") + "/image",
        ContentType: "image",
        FileName:
          typeof FileName !== "undefined" && FileName !== "" && FileName != null
            ? FileName
            : "",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;

        let form = new FormData();
        for (var key in responseData) {
          form.set(key, responseData[key]);
        }

        let imageUrl = responseData.endpoint + responseData.key;

        form.append("file", File, responseData["x-amz-meta-uuid"]);

        // // respon atau data dari server ada di variable data
        // let message = responseData.message;

        // // Order gagal saat wallet tidak cukup
        // if(parseInt(responseData.Status) === 7) {
        //     message = responseData.Note;
        //     return dispatch(resultSubmitOrder(responseData, true, message));
        //     // return dispatch(displayMessage(message, true));
        // }

        // // remove item from cart
        // dispatch(removeCart());
        // return dispatch(resultSubmitOrder(responseData));
        return dispatch(uploadToBucket(form, imageUrl, Order));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
const uploadToBucket = (form, imageUrl, Order) => {
  return (dispatch) => {
    // dispatch(requestUploadBuktiBayar());

    return Axios.post("https://agrego.s3.eu-central-1.wasabisys.com", form, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Request-Headers": "origin",
        "Access-Control-Request-Method": "POST",
      },
    })
      .then((resp) => {
        if (resp.status === 200 || resp.status === 204) {
          // return dispatch(uploadedMartOrderBuktiBayar(imageUrl));
          return dispatch(
            submitPaymentConfirmation({
              Image: imageUrl,
              OrderID: Order.ID,
              Type: Order.Type,
            })
          );
        }
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
export const submitPaymentConfirmation = ({ Image, OrderID, Type }) => {
  return (dispatch) => {
    return Axios.post("/me/paymentconfirmation?lang=id", {
      OID: localStorage.getItem("AGREGO_USER_OID"),
      TypeID: OrderID,
      Img: Image,
      Type: typeof Type !== "undefined" && !isNaN(parseInt(Type)) ? Type : 1,
    })
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;
        dispatch(displayMessage("Berhasil mengunggah bukti pembayaran"));
        return dispatch(uploadedMartOrderBuktiBayar(responseData));
      })
      .catch((error) => console.log("error"));
  };
};
