import Axios from "axios";

export const DISPLAY_MESSAGE = "DISPLAY_MESSAGE";
export const HANDLE_ERROR = "HANDLE_ERROR";
export const RESET_APP_STATE = "RESET_APP_STATE";
export const FETCHING_CS_PHONE = "FETCHING_CS_PHONE";
export const FETCHED_CS_PHONE = "FETCHED_CS_PHONE";
export const OPEN_SEARCH_PRODUCT = "OPEN_SEARCH_PRODUCT";
export const CLOSE_SEARCH_PRODUCT = "CLOSE_SEARCH_PRODUCT";

export const FETCHING_HOME_DESCRIPTION = "FETCHING_HOME_DESCRIPTION";
export const FETCHED_HOME_DESCRIPTION = "FETCHED_HOME_DESCRIPTION";
export const FETCHING_SETTING = "FETCHING_SETTING";
export const FETCHED_SETTING = "FETCHED_SETTING";

export const displayMessage = (message, isError, redirect, type) => ({
  type: DISPLAY_MESSAGE,
  payload: {
    type: "toast",
    message,
    isError,
    redirect,
  },
});

export const handleError = (error, message) => {
  return {
    type: HANDLE_ERROR,
    payload: {
      error,
      message,
    },
  };
};

export const resetAppState = () => ({
  type: RESET_APP_STATE,
});

export const fetchingCsPhone = () => ({
  type: FETCHING_CS_PHONE,
});

export const fetchedCsPhone = (data) => ({
  type: FETCHED_CS_PHONE,
  payload: {
    data,
  },
});

export const fetchingHomeDescription = () => ({
  type: FETCHING_HOME_DESCRIPTION,
});

export const fetchedHomeDescription = (data) => ({
  type: FETCHED_HOME_DESCRIPTION,
  payload: {
    data,
  },
});

export const fetchingSetting = () => ({
  type: FETCHING_SETTING,
});

export const fetchedSetting = (data) => ({
  type: FETCHED_SETTING,
  payload: {
    data,
  },
});

export const openSearch = () => ({
  type: OPEN_SEARCH_PRODUCT,
});

export const closeSearch = () => ({
  type: CLOSE_SEARCH_PRODUCT,
});

export const fetchCsPhone = () => {
  // {{base_url}}/
  return (dispatch) => {
    return Axios.get("/customercare/" + process.env.REACT_APP_OID)
      .then((response) => response.data)
      .then((data) => {
        let phone = null;
        if (
          data["data"] != null &&
          typeof data["data"][0] !== "undefined" &&
          typeof data["data"][0]["PhoneNumber"] !== "undefined"
        ) {
          phone = data["data"][0]["PhoneNumber"];
        }

        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(fetchedCsPhone(phone));
      })
      .catch((error) => {
        dispatch(fetchedCsPhone(null));
        return dispatch(handleError(error));
      });
  };
};

export const fetchHomeDescription = () => {
  // {{base_url}}/
  return (dispatch) => {
    dispatch(fetchingHomeDescription());

    return Axios.get(
      "/homedescription/" +
        process.env.REACT_APP_OID +
        "?Page=1&Size=20&SortBy=ID-asc"
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(fetchedHomeDescription(data.data));
      })
      .catch((error) => {
        dispatch(fetchedHomeDescription(null));
        return dispatch(handleError(error));
      });
  };
};

export const fetchSetting = (SettingID) => {
  // {{base_url}}/
  return (dispatch) => {
    dispatch(fetchingSetting());

    return Axios.get(
      "/public/setting/" + process.env.REACT_APP_OID + "/" + SettingID
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(fetchedSetting(data.data));
      })
      .catch((error) => {
        console.log(error);
        return dispatch(fetchedSetting(null));
        // return dispatch(handleError(error));
      });
  };
};
