import React from 'react';
import { 
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import Pulsa from './Pulsa.component';
import Data from './ListData.component';
import PulsaDetail from './PulsaDetail.component';
import { fetchProvider, fetchDigitalBrand } from '../../../redux/actions/digital_brand';
import { getCartDigital, addCartDigital } from '../../../redux/actions/digital_cart';
import { fetchProviderProduct } from '../../../redux/actions/digital_product';  


class PulsaContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      action: null,
      provider: [],
      providerProduct: [],
      productdatalist: [],
      digitalBrand: [],
      breadcrumb: null,
      cekProductID: true,


    }
    this.handleChangeProvider = this.handleChangeProvider.bind(this);
    this.handleListData = this.handleListData.bind(this);
    this.handleListPulsa = this.handleListPulsa.bind(this);
    this.handleChangeProviderProduct = this.handleChangeProviderProduct.bind(this);
    this.handleChangeProductDigital = this.handleChangeProductDigital.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);


  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    this.loadData();
  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    const { fetchingProvider, provider, fetchingDigitalBrand, digitalBrand } = this.props.digitalbrand;
    const { fetchingProviderProduct, providerProduct } = this.props.digitalproduct;
    const { cartDigital } = this.props.digitalcart;
    const { isLoading, providerResults, action } = this.state;
    let { breadcrumb } = this.state
    // console.log(localStorage.AGREGO_CART_DIGITAL);


    if (!fetchingProvider && isLoading && !fetchingProviderProduct && !fetchingDigitalBrand) {

      digitalBrand.forEach(function (item, index) {

        let providerResultName = item.Name;
        let providerResultImg = item.Img;

        localStorage.setItem('AGREGO_PROVIDER_PRODUCT', providerResultName);
        localStorage.setItem('AGREGO_PROVIDER_PRODUCTIMG', providerResultImg);


      });






      if (action === "detail") {
        breadcrumb = [
          { link: '/beranda', text: 'Beranda' },
          { link: '/digital/pulsa/', text: 'Pulsa & Data' },
          { link: '#', text: 'Atur Pesanan' },

        ]
      }
      else {
        breadcrumb = [
          { link: '/beranda', text: 'Beranda' },
          { link: '#', text: 'Pulsa & Data' },
        ]
      }






      this.setState({
        isLoading: false,
        provider: provider,
        providerProduct: providerProduct,
        providerResults: providerResults,
        digitalBrand: digitalBrand,
        breadcrumb,
        cekProductID: true,
        CartDigital: cartDigital,

      })

      if (localStorage.AGREGO_PROVIDER_PRODUCT === '') {
        this.setState({ isLoading: false, providerProduct: '' })
      }

    }



  }

  loadData(param) {
    const { breadcrumb, cekProductID } = this.props.match;
    const { extra } = this.props.match.params;

    let { action, categoryName, categoryURL } = this.state;
    this.props.fetchProvider();
    this.props.getCartDigital();



    if (extra === "detail") {
      action = "detail";
    }

    if (extra === "listdata") {
      this.props.fetchProvider();
      this.props.fetchProviderProduct({ page: 1, size: 100, sorting: "Price-asc", search: { BrandName: localStorage.AGREGO_PROVIDER_PRODUCT, CategoryName: "Data" }, SortBy: "Price-Asc" });
      action = "listdata";
      categoryName = "Pulsa & Data";
      categoryURL = "/digital/pulsa/listdata";
    }

    else {
      this.props.fetchProviderProduct({ page: 1, size: 100, sorting: "Price-asc", search: { BrandName: localStorage.AGREGO_PROVIDER_PRODUCT, CategoryName: "Pulsa" }, SortBy: "Price-Asc" });
      categoryName = "Pulsa & Data";
      categoryURL = "/digital/pulsa";


    }

    this.setState({
      isLoading: true,
      action: action,
      loadingProduct: true,
      breadcrumb: breadcrumb,
      cekProductID: cekProductID,
      categoryName,
      categoryURL

    });
  }

  handleChangeProvider(values) {

    let str = values;
    let notelp = str.slice(0, 4);

    const { provider } = this.state;
    const { extra } = this.props.match.params;


    if (str.length >= "10") {
      var providerResult;

      provider["Provider"].forEach(function (item, index) {
        if (item["PrefixCode"].includes(notelp)) {
          providerResult = item["Name"];

        }

      });

      this.props.fetchDigitalBrand({ page: 1, size: 100, search: { Name: providerResult } });


      if (extra === "listdata") {
        this.props.fetchProviderProduct({ page: 1, size: 100, sorting: "Price-asc", search: { BrandName: providerResult, CategoryName: "Data" }, SortBy: "Price-Asc" });
        this.setState({ isLoading: true, providerResults: providerResult, digitalBrand: [] });

      } else {

        this.props.fetchProviderProduct({ page: 1, size: 100, sorting: "Price-asc", search: { BrandName: providerResult, CategoryName: "Pulsa" }, SortBy: "Price-Asc" });
        this.setState({ isLoading: true, providerResults: providerResult, digitalBrand: [] });


      }


      localStorage.setItem('AGREGO_PROVIDER_RESULT', providerResult);


    }

    localStorage.setItem('AGREGO_USER_PHONE', str);


    let newvalues = { Reference: str, ProductID: '' };

    this.handleChangeProductDigital(newvalues);
    this.setState({ cekProductID: true });





  }

  handleListData() {

    // const { providerResults } = this.state
    this.props.fetchProviderProduct({ page: 1, size: 100, search: { BrandName: localStorage.AGREGO_PROVIDER_PRODUCT, CategoryName: "Data" } });
    this.setState({ isLoading: true });
  }

  handleListPulsa() {
    const { providerResults } = this.state

    this.props.fetchProviderProduct({ page: 1, size: 100, search: { BrandName: providerResults, CategoryName: "Pulsa" } });
    this.setState({ isLoading: true });

  }

  handleChangeProviderProduct(values) {
    // let idProduct = values;
    // const { providerResult } = this.state;
  }

  handleChangeProductDigital(values) {
    const { categoryName, categoryURL } = this.state;

    let cartDigital = values;
    this.props.addCartDigital({ ...values, CategoryName: categoryName, CategoryURL: categoryURL });
    // this.setState({isLoading:true});
    if (cartDigital.ProductID == null) {
      this.setState({ cekProductID: true });
    }

    else {
      this.setState({ cekProductID: false });
    }


  }

  handleClearForm() {
    let newReference = { Reference: '' }
    let newdata = '';

    this.handleChangeProductDigital(newReference);

    // this.setState({ProductID: true, isLoading: true});
    // document.getElementById("formpulsa").reset();
    localStorage.setItem('AGREGO_USER_PHONE', newdata);
    localStorage.setItem('AGREGO_PROVIDER_RESULT', newdata);
    localStorage.setItem('AGREGO_PROVIDER_PRODUCT', newdata);
    localStorage.setItem('AGREGO_PROVIDER_PRODUCTIMG', newdata);
    this.setState({ isLoading: false, providerProduct: '' })
  }

  render() {
    const { isLoading,
      provider,
      providerProduct,
      providerResults,
      digitalBrand,
      breadcrumb,
      action,
      cekProductID,
      CartDigital,
    } = this.state;


    let renderComponent = null;

    if (action === "detail") {
      renderComponent =
        <PulsaDetail
          isLoading={isLoading}
          provider={provider}
          providerProduct={providerProduct}
          providerResults={providerResults}
          digitalBrand={digitalBrand}
          breadcrumb={breadcrumb}
          onChangeProvider={this.handleChangeProvider}
        // handleListData={this.handleListData}
        // handleListPulsa={this.handleListPulsa}

        />;

    }

    else {

      if (action === "listdata") {
        renderComponent =
          <Data
            isLoading={isLoading}
            provider={provider}
            providerProduct={providerProduct}
            providerResults={providerResults}
            digitalBrand={digitalBrand}
            breadcrumb={breadcrumb}
            cekProductID={cekProductID}
            CartDigital={CartDigital}
            onClearForm={this.handleClearForm}
            onChangeProvider={this.handleChangeProvider}
            onChangeProductDigital={this.handleChangeProductDigital}
            // handleListData={this.handleListData}
            // handleListPulsa={this.handleListPulsa}
            onChangeProviderProduct={this.handleChangeProviderProduct}

          />;

      }

      else {
        renderComponent =
          <Pulsa
            isLoading={isLoading}
            provider={provider}
            providerProduct={providerProduct}
            providerResults={providerResults}
            digitalBrand={digitalBrand}
            breadcrumb={breadcrumb}
            cekProductID={cekProductID}
            CartDigital={CartDigital}
            onClearForm={this.handleClearForm}

            onChangeProvider={this.handleChangeProvider}
            onChangeProductDigital={this.handleChangeProductDigital}
            // handleListData={this.handleListData}
            // handleListPulsa={this.handleListPulsa}
            onChangeProviderProduct={this.handleChangeProviderProduct}

          />


      }

    }
    return (<div>{renderComponent}</div>);
  }


}


const mapStateToProps = (state) => {
  const { digitalbrand, digitalproduct, digitalcart } = state;
  return { digitalbrand, digitalproduct, digitalcart }
};
export default withRouter(connect(mapStateToProps, {
  fetchProvider,
  fetchProviderProduct,
  fetchDigitalBrand,
  getCartDigital,
  addCartDigital,

  //list of dispatch action
})(PulsaContainer));
