import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Profile.css';
import { parseDate } from './../../utils/common';  
import Loading from './../Layout/Loading';
import BottomNav from './../Layout/BottomNav';

export default function Datadiri(props) {

    const { isLoading, profile } = props;

    return (

        <Container>
            {/* Loading State */}
            {isLoading && <Loading />}



            <Row>
                {!isLoading &&


                    <Col md={12} xs={12}>


                        <div className="TopHeader">



                            {profile != null &&
                                <div className="TopHeaderName">{profile.FullName}</div>}
                            {profile != null &&
                                <div className="TopHeaderNumber">{profile.MemberNumber}</div>}



                        </div>

                    </Col>
                }

            </Row>

            <Row>
                {!isLoading &&

                    <Col>

                        <div className="profileContainer">

                            <Row>


                                <Col md={3} xs={12}>


                                    <div className="profileMenu">
                                        <div className="profileJudul">Profil</div>
                                        <ul className="sidenav-list-menu">
                                            <li><a className="active" href={"/profile"} >Data Pribadi</a></li>
                                            <li><a href={'/profile/alamat'}>Daftar Alamat</a></li>

                                        </ul>

                                    </div>

                                </Col>

                                <Col md={9} xs={12}>
                                    <Row>
                                        <Col md={8} xs={8}>
                                            <div className="judulContainer">
                                                <div className="dataDiriJudul2">Data Pribadi</div>
                                            </div>
                                        </Col>

                                        <Col md={2} xs={4}>
                                            <Button className="Edit-btn" href={"/profile/datadiri/edit"}>Ubah</Button>
                                        </Col>
                                    </Row>
                                    <div className="hr-line"></div>


                                    <Row>
                                        {profile != null &&

                                            <div className="dataGroup">
                                                <Col md={12} xs={12}><div className="content">Nomor Anggota</div></Col>
                                                <Col md={12} xs={12}><div className="contentIsi">{profile.MemberNumber}</div></Col>
                                            </div>
                                        }
                                    </Row>


                                    <Row>
                                        {profile != null &&

                                            <div className="dataGroup">
                                                <Col md={12} xs={12}><div className="content">Nama</div></Col>
                                                <Col md={12} xs={12}><div className="contentIsi">{profile.FullName}</div></Col>
                                            </div>
                                        }
                                    </Row>

                                    <Row>
                                        {profile != null &&

                                            <div className="dataGroup">
                                                <Col md={12} xs={12}><div className="content">Jenis Kelamin</div></Col>
                                                <Col md={12} xs={12}>
                                                    {parseInt(profile.Gender) === 0 && <div className="contentIsi">Perempuan </div>}
                                                    {parseInt(profile.Gender) === 1 && <div className="contentIsi">Laki-laki </div>}
                                                </Col>
                                            </div>
                                        }
                                    </Row>
                                    <Row>
                                        {profile != null &&

                                            <div className="dataGroup">
                                                <Col md={12} xs={12}><div className="content">Email</div></Col>
                                                <Col md={12} xs={12}><div className="contentIsi">{profile.Email}</div></Col>
                                            </div>
                                        }
                                    </Row>



                                    <Row>
                                        {profile != null &&

                                            <div className="dataGroup">
                                                <Col md={12} xs={12}><div className="content">Nomor Handphone</div></Col>
                                                <Col md={12} xs={12}><div className="contentIsi">{profile.Phone}</div></Col>
                                            </div>
                                        }
                                    </Row>

                                    <Row>
                                        {profile != null &&

                                            <div className="dataGroup">
                                                <Col md={12} xs={12}><div className="content">Tempat Lahir</div></Col>
                                                <Col md={12} xs={12}><div className="contentIsi">{profile.BirthPlace}</div></Col>
                                            </div>
                                        }
                                    </Row>

                                    <Row>
                                        {profile != null &&

                                            <div className="dataGroup">
                                                <Col md={12} xs={12}><div className="content">Tanggal Lahir</div></Col>
                                                <Col md={12} xs={12}><div className="contentIsi">{parseDate(profile.BirthDate)}</div></Col>

                                            </div>
                                        }
                                    </Row>




                                </Col>

                            </Row>

                        </div>

                    </Col>
                }
            </Row>

            <BottomNav activeKey={5} />

        </Container>


    );
}