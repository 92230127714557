import _ from "lodash";
import moment from "moment";
import qs from "query-string";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { displayMessage } from "redux/actions/app";
import {
  addCart,
  getCart,
  getCartByProduct,
  removeCart,
} from "redux/actions/mart_cart";
import { fetchMartCategory } from "redux/actions/mart_category";
import {
  fetchMartProduct,
  fetchMartProductById,
  fetchMartProductChoice,
} from "redux/actions/mart_product";
import { calculateProduct, mergeProductCart } from "utils/cart";
import { parseSlugCase } from "utils/common";
import Product from "./Product.component";
import "./Product.css";
import ProductDetail from "./ProductDetail.component";

const getProductItems = ({ mart_products, mart_product_choice_list }, type) => {
  let products = null;
  if (type === "choice") {
    products =
      mart_product_choice_list && mart_product_choice_list["ProductChoices"]
        ? mart_product_choice_list["ProductChoices"]
        : null;
  } else {
    products = mart_products;
  }

  return products;
};

const getTotalItems = (
  { mart_total_products, mart_product_choice_total },
  type
) => {
  let products = null;
  if (type === "choice") {
    products = mart_product_choice_total ? mart_product_choice_total : null;
  } else {
    products = mart_total_products;
  }

  return products;
};

class ProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      action: null,
      filterData: {},
      searchType: null,
      productID: null,
      breadcrumb: null,
      pageTitle: null,

      products: [],
      product: null,
      categories: [],
      category: null,
      catID: null,
      cartCount: null,
      cartSummary: null,
      cartItems: [],
      modalBody: [],

      // initial page
      activePage: 1,
      itemPerPage: 12,
      totalProduct: null,

      updatingCart: false,
      searchKeyword: null,

      // modal
      showModalConfirm: false,
      modalConfirmAction: null,
    };

    this.handleUpdateCart = this.handleUpdateCart.bind(this);
    this.handleChangeQty = this.handleChangeQty.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handlePushPage = this.handlePushPage.bind(this);
    this.toggleModalConfirm = this.toggleModalConfirm.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const {
      isFetchingMartProductChoiceList,
      isFetchingMartProductList,
      isFetchingMartProduct,
      mart_product,
      mart_products,
    } = this.props.martproduct;
    const { isFetchingMartCategory, mart_category } = this.props.martcategory;
    const { id, extra } = this.props.match.params;
    const { carts } = this.props.martcart;
    const {
      isLoading,
      productID,
      action,
      itemPerPage,
      searchType,
      filterData,
    } = this.state;
    let {
      breadcrumb,
      catID,
      category,
      cartCount,
      updatingCart,
      pageTitle,
      searchKeyword,
    } = this.state;
    let { cat_id, keyword, type } = qs.parse(this.props.location.search);

    // small update change
    if (updatingCart) {
      this.setState({
        updatingCart: false,
        cartSummary: typeof carts.summary ? carts.summary : null,
        cartItems: typeof carts.items ? carts.items : null,
        products: mergeProductCart(mart_products),
      });
    }

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (
      !isFetchingMartProductChoiceList &&
      !isFetchingMartProductList &&
      !isFetchingMartProduct &&
      !isFetchingMartCategory &&
      isLoading
    ) {
      let productItems = mart_products;
      let totalItems = this.props.martproduct;

      if (catID != null && mart_category.length > 0) {
        category = _.find(
          mart_category,
          (o) => parseInt(o.ID) === parseInt(catID)
        );
      }

      if (typeof extra !== "undefined" && extra === "detail" && mart_product) {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          {
            link: "/shop/product?type=cat&cat_id=" + mart_product.CategoryID,
            text: mart_product.CategoryName,
          },
          {
            link:
              "/shop/product?type=cat&cat_id=" +
              mart_product.CategoryID +
              "/detail",
            text: mart_product.Name,
          },
        ];
      } else if (category) {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          {
            link: "/shop/product?type=cat&cat_id=" + category["ID"],
            text: category["Name"],
          },
        ];

        if (catID != null) {
          pageTitle = category["Name"];

          ReactGA.pageview(
            window.location.pathname + window.location.search,
            [],
            `product_category_${parseSlugCase(category["Name"])}`
          );
        }
      } else {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          { link: "/shop/product", text: "Produk" },
        ];
      }

      if (typeof carts !== "undefined" && typeof carts.items !== "undefined") {
        cartCount = _.sumBy(carts.items, (o) => parseInt(o.Qty));
      }

      this.setState({
        isLoading: false,
        products: mergeProductCart(productItems),

        // merge product dengan qty yang ada di cart
        // product: mergeProductCart(mart_product),
        product: mergeProductCart(mart_product),
        cartSummary: typeof carts.summary ? carts.summary : null,
        cartItems: typeof carts.items ? carts.items : null,
        categories: mart_category,
        catID: catID,
        totalProduct: totalItems != null ? totalItems : itemPerPage,
        category,
        cartCount,
        breadcrumb,
        pageTitle,
      });
    }

    // load baru jika datanya berubah
    if (
      typeof extra !== "undefined" &&
      extra === "detail" &&
      parseInt(id) !== parseInt(productID)
    ) {
      this.loadData({ activePage: 1 });
    }

    // load ulang jika categorynya berubah
    if (typeof searchType !== "undefined" && type !== searchType) {
      this.loadData({ activePage: 1 });
    } else if (
      typeof searchType !== "undefined" &&
      type === searchType &&
      filterData["CategoryID"] !== cat_id
    ) {
      this.loadData({ activePage: 1 });
    } else if (
      typeof cat_id !== "undefined" &&
      parseInt(cat_id) !== parseInt(catID)
    ) {
      this.loadData({ activePage: 1 });
    } else if (typeof keyword !== "undefined" && keyword !== searchKeyword) {
      this.loadData({ activePage: 1 });
    }

    // load ulang jika dari detail ke list
    if (action === "detail" && typeof extra === "undefined") {
      this.loadData({ activePage: 1 });
    }
  }

  loadData(param) {
    let {
      action,
      catID,
      filterData,
      activePage,
      itemPerPage,
      categories,
      pageTitle,
      searchKeyword,
    } = this.state;
    const { breadcrumb, modalBody } = this.props.match;
    const { id, extra } = this.props.match.params;
    let { type, cat_id, keyword } = qs.parse(this.props.location.search);

    if (
      typeof param !== "undefined" &&
      typeof param.activePage !== "undefined" &&
      !isNaN(parseInt(param.activePage))
    ) {
      activePage = param.activePage;
    }

    if (typeof id !== "undefined" && typeof extra !== "undefined") {
      this.props.fetchMartProductById(id);
      this.props.getCartByProduct(id);
      action = "detail";
    } else {
      // Category
      if (typeof type !== "undefined") {
        filterData["TYPE"] = type;

        if (type === "cat" && typeof keyword !== "undefined") {
          filterData["CategoryName"] = keyword;
          pageTitle = "Pencarian Kategori '" + keyword + "'";
          catID = null;
          delete filterData["Name"];
          delete filterData["CategoryID"];

          ReactGA.pageview(
            window.location.pathname + window.location.search,
            [],
            `product_category_${keyword}`
          );
        } else if (type === "cat" && typeof cat_id !== "undefined") {
          filterData["CategoryID"] = cat_id;
          catID = cat_id;
          delete filterData["Name"];
          delete filterData["CategoryName"];
        } else if (type === "search" && typeof keyword !== "undefined") {
          pageTitle = "Pencarian Produk '" + keyword + "'";
          filterData["Name"] = keyword;
          catID = null;
          delete filterData["CategoryName"];
          delete filterData["CategoryID"];

          ReactGA.pageview(
            window.location.pathname + window.location.search,
            [],
            `product_search`
          );
        } else if (type === "latest") {
          pageTitle = "Produk Terbaru";

          ReactGA.pageview(
            window.location.pathname + window.location.search,
            [],
            `latest_products`
          );
        } else if (type === "popular") {
          pageTitle = "Produk Populer";

          ReactGA.pageview(
            window.location.pathname + window.location.search,
            [],
            `popular_products`
          );
        } else if (type === "choice") {
          pageTitle = "Produk Pilihan";

          ReactGA.pageview(
            window.location.pathname + window.location.search,
            [],
            `products_choice`
          );
        }
      } else {
        delete filterData["TYPE"];
        delete filterData["Name"];
        delete filterData["CategoryID"];
        delete filterData["CategoryName"];
      }

      // let withTotalCount = totalProduct != null ? false : true;
      // if (type === "choice") {
      //   ReactGA.pageview(
      //     window.location.pathname + window.location.search,
      //     [],
      //     `products_choice`
      //   );

      //   this.props.fetchMartProductChoice({
      //     page: activePage,
      //     size: itemPerPage,
      //   });
      // } else {
      this.props.fetchMartProduct(
        { page: activePage, size: itemPerPage, search: filterData },
        true
      );
      // }

      if (!categories.length) {
        this.props.fetchMartCategory({ page: 1, size: 100 });
      }
      this.props.getCart();
      action = "view";
    }

    this.setState({
      isLoading: true,
      action: action,
      productID: id,
      breadcrumb: breadcrumb,
      modalBody: modalBody,
      searchKeyword: typeof keyword !== "undefined" ? keyword : searchKeyword,
      searchType: type,
      catID,
      filterData,
      activePage,
      pageTitle,
    });
  }

  toggleModalConfirm(isOpen, action, confirm) {
    let { modalConfirmAction } = this.state;

    if (
      typeof action != "undefined" &&
      action != null &&
      typeof action.type !== "undefined"
    ) {
      modalConfirmAction = action;
    }

    if (
      modalConfirmAction != null &&
      modalConfirmAction.type === "CONFIRM_DIFF_STORE" &&
      typeof confirm !== "undefined" &&
      confirm
    ) {
      this.handleChangeQty(modalConfirmAction.data, true);
    }

    this.setState({
      showModalConfirm: isOpen,
      modalConfirmAction,
    });
  }

  handleUpdateCart(product) {
    this.props.addCart(product);
  }

  handleChangeQty(param, isConfirm) {
    let { cartSummary, cartItems } = this.state;
    let { type, product } = param;

    let confirmStore = isConfirm != null && isConfirm;
    let isExistCart =
      cartItems != null &&
      cartItems[0] != null &&
      cartItems[0]["ProductID"] != null;

    let ExistStoreID =
      cartSummary != null && !isNaN(cartSummary["StoreID"])
        ? parseInt(cartSummary["StoreID"])
        : null;
    let ProductStoreID =
      product != null && !isNaN(product["StoreID"])
        ? parseInt(product["StoreID"])
        : null;

    let ExistPurchaseTypeID =
      cartSummary != null && !isNaN(cartSummary["PurchaseType"])
        ? parseInt(cartSummary["PurchaseType"])
        : null;
    let ProductPurchaseTypeID =
      product != null && !isNaN(product["PurchaseType"])
        ? parseInt(product["PurchaseType"])
        : null;

    let ExistProduct =
      cartItems != null && cartItems[0] != null ? cartItems[0] : null;
    let ExistProductID =
      cartItems != null &&
      cartItems[0] != null &&
      !isNaN(cartItems[0]["ProductID"])
        ? parseInt(cartItems[0]["ProductID"])
        : null;
    let ProductID =
      product != null && !isNaN(product["ID"]) ? parseInt(product["ID"]) : null;

    // kondisi untuk validasi Berbeda Store
    const diffStore = ExistStoreID !== ProductStoreID && isExistCart;
    const diffPurchaseType =
      ExistPurchaseTypeID !== ProductPurchaseTypeID && isExistCart;
    const limitPreorder =
      ExistPurchaseTypeID === 2 &&
      ExistProduct &&
      moment(ExistProduct.DeliveryDate).diff(product.DeliveryDate, "days") !==
        0 &&
      ProductID !== ExistProductID &&
      isExistCart;

    // cek validasi jika berbeda store dan purchaseType
    if (diffStore && !confirmStore) {
      this.toggleModalConfirm(true, {
        type: "CONFIRM_DIFF_STORE",
        data: param,
      });
      this.setState({
        modalBody:
          "Item yang anda tambahkan berbeda toko dengan sebelumnya, jika dilanjutkan maka item di keranjang sebelumnya akan terhapus",
      });
    }
    // cek validasi berbeda purchasetype
    else if (diffPurchaseType && !confirmStore) {
      this.toggleModalConfirm(true, {
        type: "CONFIRM_DIFF_STORE",
        data: param,
      });
      this.setState({
        modalBody:
          "Keranjang hanya dapat memuat produk sejenis (ready stock / pre order). Jika dilanjutkan, maka item sebelumnya akan terhapus",
      });
    }
    // cek validasi untuk purchaseType hanya 1 jenis produk
    else if (limitPreorder && !confirmStore) {
      this.toggleModalConfirm(true, {
        type: "CONFIRM_DIFF_STORE",
        data: param,
      });
      this.setState({
        modalBody:
          "Keranjang tidak dapat memuat lebih dari satu barang Pre Order dengan tanggal pengiriman yang berbeda. Jika dilanjutkan, barang sebelumnya akan terhapus",
      });
    }
    // update cart
    else {
      product = calculateProduct(param);

      if (confirmStore) {
        this.props.removeCart();
      }

      if (
        typeof product["error"] !== "undefined" &&
        product["error"] &&
        typeof product["message"]
      ) {
        this.props.displayMessage(product["message"], product["error"]);
      } else {
        ReactGA.event({
          category: "shopping",
          action: type === "DECREMENT" ? "remove_from_cart" : "add_to_cart",
        });
        this.props.addCart(product, type);
      }
    }

    this.setState({ updatingCart: true });
  }

  handleChangePage(pageNumber) {
    this.loadData({ activePage: pageNumber });
  }

  handlePushPage(page) {
    this.props.history.push(page);
  }

  render() {
    const {
      products,
      product,
      productID,
      categories,
      category,
      isLoading,
      action,
      breadcrumb,
      cartCount,
      activePage,
      itemPerPage,
      totalProduct,
      cartSummary,
      pageTitle,
      showModalConfirm,
      modalBody,
    } = this.state;

    let renderComponent = null;

    if (action != null && action === "detail") {
      renderComponent = (
        <ProductDetail
          product={product}
          productID={productID}
          isLoading={isLoading}
          breadcrumb={breadcrumb}
          cartSummary={cartSummary}
          onUpdateCart={this.handleUpdateCart}
          onChangeQty={this.handleChangeQty}
        />
      );
    } else {
      renderComponent = (
        <Product
          products={products}
          categories={categories}
          category={category}
          cartCount={cartCount}
          isLoading={isLoading}
          breadcrumb={breadcrumb}
          pageTitle={pageTitle}
          // Paging
          activePage={activePage}
          itemPerPage={itemPerPage}
          totalProduct={totalProduct}
          onChangePage={this.handleChangePage}
          onPushPage={this.handlePushPage}
          onChangeQty={this.handleChangeQty}
        />
      );
    }

    return (
      <React.Fragment>
        {renderComponent}

        <Modal
          show={showModalConfirm}
          onHide={(e) => this.toggleModalConfirm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Konfirmasi Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => this.toggleModalConfirm(false)}
            >
              Batal
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.toggleModalConfirm(false, "", true)}
            >
              Lanjutkan
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { martproduct, martcategory, martcart } = state;
  return { martproduct, martcategory, martcart };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchMartProductChoice,
    fetchMartProduct,
    fetchMartProductById,
    fetchMartCategory,
    addCart,
    getCart,
    getCartByProduct,
    removeCart,
    displayMessage,
  })(ProductContainer)
);
