import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import GoogleMap from "./../../GMap";

export default function AddressMap({
  addressID,
  addressDetail,
  pageReference,
  ...props
}) {
  const [detail, setDetail] = useState(null);
  const history = useHistory();

  const handleChange = (lat, lng) => {
    let form = detail;
    form["Latitude"] = lat;
    form["Longitude"] = lng;
    setDetail(form);
  };

  const handleSubmitLocation = () => {
    // jika user diarahkan dari courier karena belom menentukan pinpoin maka simpan alamat,
    // dan kembalikan user ke halaman courier
    if (pageReference && pageReference === "courier") {
      props.onSubmitAddress(detail);
    } else {
      localStorage.setItem(
        process.env.REACT_APP_PREFIX + "ADDRESS_FORM",
        JSON.stringify(detail)
      );
      history.push(props.addressUrl + (addressID ? addressID : "create"));
    }
  };

  useEffect(() => {
    ReactGA.set({ title: "set_pinpoint" });
    ReactGA.pageview(window.location.pathname + window.location.search);

    let tempForm = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_PREFIX + "ADDRESS_FORM")
    );
    setDetail({ ...addressDetail, ...(tempForm ? tempForm : {}) });
  }, []);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <GoogleMap data={detail} onChange={handleChange} />
      </div>

      <div className="text-center" style={{ margin: "100px auto 50px auto" }}>
        <Button className="d-none d-md-block" onClick={handleSubmitLocation}>
          Simpan Lokasi
        </Button>
      </div>

      <div style={{ height: "50px" }}>&nbsp;</div>

      {/* Mobile view fixed in bottom */}
      <div className="product-summary-bottom d-sm-block d-md-none">
        <Button variant="primary" onClick={handleSubmitLocation} block>
          Simpan Lokasi
        </Button>
      </div>
    </div>
  );
}
