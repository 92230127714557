import React from 'react';
import PropTypes from 'prop-types';
import {parseCurrency} from './../../../utils/common'; 
import {Button, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import './ProductItem.css';

// const limit_productname = function (str, length, ending) {
//   if(str != null){
  
//     if (length == null) {
//       length = 35;
//     }
//     if (ending == null) {
//       ending = '';
//     }
//     if (str.length > length) {
//       return str.substring(0, length - ending.length) + ending;
//     } else {
//       return str;
//     }
//   } 
// };
  
function ProductItem(props) {

    const {product, buyButton} = props;   
    var currentDate = moment();
      
    const iconPlus = <FontAwesomeIcon className="btn-qty" icon={faPlus} />
    const iconMinus = <FontAwesomeIcon className="btn-qty" icon={faMinus} />

    const history = useHistory(); 

    return (<div className={"product-item-card "+props.className} style={props.style} >  
        <div onClick={e => history.push("/shop/product/"+product.ID+'/detail')} className="product-image-wrapper">
            {product != null && <div style={{backgroundImage:"url("+product.Img+")",width:"100%",height:"100%",backgroundRepeat:'no-repeat', backgroundPosition:'center',backgroundSize:'cover',opacity: (parseInt(product.Stock) <= 0 || isNaN(parseInt(product.Stock))) ? '0.2' : '1'}}>&nbsp;</div>}
        
        </div>
        
        <div className="product-content">
            
            <div className="product-price clearfix" style={{position:'relative'}}>
                <span style={{display:'inline-block'}} className={parseInt(product.Discount) > 0 ? "product-price-discount pr-2" :"pr-2"}>Rp {parseCurrency(product.PromoPrice)}</span>  
                {parseInt(product.Discount) > 0 && <span className="product-strip-price">Rp {parseCurrency(product.Price)}</span>} 
            </div>

            {/* <div className={"product-title clearfix"+"limit-2-line"}>
              
                <div className="product-line-clamp"><a href={"/shop/product/"+product.ID+"/detail"}>{(product.Name)}</a></div>
             
               
              </div>  */}

            <div className="product-title"><a href={"/shop/product/"+product.ID+"/detail"}>{product.Name}</a></div>

            {parseInt(product.PurchaseType) === 1 && <div>{buyButton ? <div className="btnPosition">&nbsp;</div>:<div style={{marginTop:'5px'}}>&nbsp;</div>}</div>}         
            {parseInt(product.PurchaseType) === 2 && <div style={{marginTop:'10px'}}><div className="btnPreorder">Pre Order</div></div>}
            {parseInt(product.PurchaseType) === 3 && <div style={{marginTop:'10px'}}><div className="madeOrderBox">Made to Order {product.MinDays ? ': '+product.MinDays+' hari' : ''}</div></div>}
  
            {parseInt(product.Discount) > 0 && <div className="discount-image">{product.Discount}%</div>}
            {/* {product.Unit !== "" && parseInt(product.Weight) > 0 ? <span className="product-unit"> {product.Weight} {product.Unit}</span> : <span>&nbsp;</span>} */}
        </div>

        {buyButton && parseInt(product.Stock) > 0 && parseInt(product.Qty) > 0 && <div className="button-group-qty"> 
            <div> <Button className="btn-minus" variant="primary" onClick={(e) => props.onChangeQty({type:"DECREMENT", product: product})} >
                {iconMinus}</Button> </div>  
            <div className="product-input-wrapper"> <Form.Control className="text-center input-product-qty" value={product.Qty} onChange={(e) => props.onChangeQty({type:"CHANGE", product: product, value: e.target.value})} /> </div>
            <div> <Button className=" btn-plus" disabled={parseInt(product.Qty) >= parseInt(product.Stock)} variant="primary" onClick={(e) => props.onChangeQty({type:"INCREMENT", product: product})} >{iconPlus}</Button> </div>
        </div>}

        {buyButton && parseInt(product.PurchaseType) === 1 && parseInt(product.Stock) > 0 && parseInt(product.Qty) <= 0 && <Button className="btn-product btn-beli" style={{borderBottom:'0px !important'}} variant="primary" onClick={(e) => props.onChangeQty({type:"CHANGE", product: product, value:1})} block >Beli Sekarang</Button>}

        {buyButton && parseInt(product.PurchaseType) === 2 && moment(product.ClosedDate).diff(currentDate,'Days') < 0 ? <Button variant="outline" style={{ background: '#fff' }} className=" btn-product btn-beli btn-stock-kosong" block>Pre Order Berakhir</Button>:
        buyButton && parseInt(product.PurchaseType) === 2 && parseInt(product.Stock) > 0 && parseInt(product.Qty) <= 0 && <Button className="btn-product btn-beli" style={{borderBottom:'0px !important'}} variant="primary" onClick={(e) => props.onChangeQty({type:"CHANGE", product: product, value:1})} block >Pre Order</Button>}

        {buyButton && parseInt(product.PurchaseType) === 3 && parseInt(product.Stock) > 0 && parseInt(product.Qty) <= 0 && <Button className="btn-product btn-beli" style={{borderBottom:'0px !important'}} variant="primary" onClick={(e) => props.onChangeQty({type:"CHANGE", product: product, value:1})} block >Pesan</Button>}
        
        {/* {buyButton && parseInt(product.Stock) > 0 && parseInt(product.Qty) <= 0 && <Button className="btn-product btn-beli" style={{borderBottom:'0px !important'}} variant="primary" onClick={(e) => props.onChangeQty({type:"CHANGE", product: product, value:1})} block >Beli Sekarang</Button>}
         */}
        {/* Stok kosong */}
       
        {
        product.PurchaseType === 2 && moment(product.ClosedDate).diff(currentDate,'Days') < 0 && buyButton && (parseInt(product.Stock) <= 0 || isNaN(parseInt(product.Stock))) ? '':
        buyButton && (parseInt(product.Stock) <= 0 || isNaN(parseInt(product.Stock))) && <Button className="btn-product btn-beli btn-stock-kosong" variant="outline">Stok Kosong</Button>}
    </div>);
}

ProductItem.propTypes = {
    product: PropTypes.object, 
    buyButton: PropTypes.bool, 
    onChangeQty: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
} 

ProductItem.defaultProps = {
    product: null, 
    buyButton: false, 
    onChangeQty: () => {},
    className: "",
    style: {}
} 

export default ProductItem;