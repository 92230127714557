import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactGA from 'react-ga';
import Pagination from "react-js-pagination";
import Breadcrumb from "../Layout/Breadcrumb";
import { parseCurrency, parseDateTime } from "./../../utils/common";
import Loading from "./../Layout/Loading";
import "./Wallet.css";

// import { useHistory } from 'react-router-dom';

export function Wallet({
  isLoading,
  balance,
  activePage,
  itemPerPage,
  transactions,
  breadcrumb,
  pendingTopupRequest,
  ...props
}) {
  useEffect(() => {
    ReactGA.set({ title: "deposit" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container fluid>
      {isLoading && <Loading />}

      {!isLoading && (
        <div className="breadcrumbStyleWallet">
          {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
        </div>
      )}

      {!isLoading && (
        <div className="wallet-container">
          {/* Header */}
          <div className="wallet-header">
            <Row>
              <Col md={8}>
                {/* info saldo */}
                <div className="info-saldo">
                  <div className="label-saldo">Saldo Deposit</div>
                  {balance != null && (
                    <div className="wallet-saldo">
                      Rp {parseCurrency(balance)}{" "}
                    </div>
                  )}
                </div>
              </Col>

              {/* tambah saldo di hide sementara */}
              <Col md={4}>
                {pendingTopupRequest == null && (
                  <Button
                    className="btn-tambah-saldo"
                    variant="link"
                    href="/wallet/topup"
                  >
                    ISI SALDO
                  </Button>
                )}
                {pendingTopupRequest != null &&
                  !isNaN(parseInt(pendingTopupRequest["ID"])) && (
                    <Button
                      className="btn-tambah-saldo"
                      variant="link"
                      href={"/wallet/payment"}
                    >
                      Lihat Pembayaran
                    </Button>
                  )}
              </Col>
            </Row>
          </div>

          {/* Content */}
          <div className="wallet-content">
            <div className="section-title">Riwayat Transaksi</div>

            {!isLoading &&
              transactions.length > 0 &&
              transactions.map((item, index) => (
                <div key={index} className="transaction-item">
                  <div className="item-title">{item.Information}</div>
                  <Row>
                    <Col xs={8} lg={9} className="item-desc">
                      {item.InformationDetail}
                    </Col>

                    {parseInt(item.Debit) > 0 ? (
                      <Col xs={4} lg={3} className="item-price">
                        {" "}
                        - Rp{parseCurrency(item.Debit)}
                      </Col>
                    ) : (
                      <Col xs={4} lg={3} className="item-price-credit">
                        {" "}
                        + Rp{parseCurrency(item.Credit)}
                      </Col>
                    )}

                    {/* <Col xs={4} lg={3} className="item-price">{parseInt(item.Debit) > 0 ? "-Rp "+parseCurrency(item.Debit) : "+Rp "+parseCurrency(item.Credit)}</Col> */}
                  </Row>
                  <div className="item-date">
                    {parseDateTime(item.TransactionDate)} WIB
                  </div>
                </div>
              ))}

            {!isLoading && transactions.length > 0 && (
              <Row
                className="justify-content-center"
                style={{ margin: "10px 0px" }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemPerPage}
                  totalItemsCount={
                    transactions.length >= parseInt(itemPerPage)
                      ? (parseInt(activePage) + 1) * parseInt(itemPerPage)
                      : parseInt(activePage) * parseInt(itemPerPage)
                  }
                  // totalItemsCount={totalTransaction}
                  pageRangeDisplayed={3}
                  onChange={props.onChangePage}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </Row>
            )}
          </div>
        </div>
      )}
    </Container>
  );
}

export default Wallet;
