// import action type ßnya
import { REQUEST_PROMO, RESULT_PROMO, REQUEST_PROMO_DETAIL, RESULT_PROMO_DETAIL, RESULT_TOTAL_PROMO } from "./../actions/promo";

// state untuk menyimpan data 
const initialState = {
    // state ini sebagai tanda apakah sedang loading mengambil data atau tidak
    fetchingPromo: false,
    fetchingPromoDetailById: false,
    
    // nantinya data news dari API disimpan di state news
    promo: [],
    promoDetail:[],
};

export default function(state = initialState, action) {
  switch (action.type) {
    
    // Ketika action yang berjalan request news, artinya sedang loading data news
    case REQUEST_PROMO: { 
      return {
        ...state,
        fetchingPromo: true
      };
    }

    case REQUEST_PROMO_DETAIL: { 
      return {
        ...state,
        fetchingPromoDetailById: true
      };
    }
    

    // data selesai di load, maka fetchingNews = false (selesai loading), dan datanya di simpan di state news
    
    case RESULT_PROMO: {
      return {
        ...state,
        fetchingPromo: false,
        promo: action.payload.data
      };
    }

    case RESULT_PROMO_DETAIL: { 
      return {
        ...state,
        fetchingPromoDetailById: false,
        promoDetail : action.payload.data
      };
    }

    case RESULT_TOTAL_PROMO: {

      return {
          ...state,
          fetchingPromo: false,
          promo: action.payload.data,
          promo_total: action.payload.count
      };
  }

    default:
      return state;
  }
}