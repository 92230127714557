// import action type newsnya
import { REQUEST_BANNER, RESULT_BANNER } from "./../actions/banner";

// state untuk menyimpan data
const initialState = {
  // state ini sebagai tanda apakah sedang loading mengambil data atau tidak
  fetchingBanner: false,

  // nantinya data news dari API disimpan di state news
  banner: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Ketika action yang berjalan request news, artinya sedang loading data news
    case REQUEST_BANNER: {
      return {
        ...state,
        fetchingBanner: true,
      };
    }

    // data selesai di load, maka fetchingNews = false (selesai loading), dan datanya di simpan di state news
    case RESULT_BANNER: {
      const { data } = action.payload;
      return {
        ...state,
        fetchingBanner: false,
        banner: data,
      };
    }

    default:
      return state;
  }
}
