import { REQUEST_MARTBANK_ACCOUNT, RESULT_MARTBANK_ACCOUNT } from "./../actions/mart_bank";

const initialState = { 
    isFetchingMartBankAccount: false, 
    mart_bank_account: [],
};

export default function(state = initialState, action) {
    switch (action.type) { 
        case REQUEST_MARTBANK_ACCOUNT: { 
            return {
                ...state,
                isFetchingMartBankAccount: true
            };
        }
       
        case RESULT_MARTBANK_ACCOUNT: {
            const { data } = action.payload;  
            
            // local data
            // if(process.env.REACT_APP_LOCAL_DATA === "true") {
            //     localStorage.setItem('AGREGO_BANK_ACCOUNT', JSON.stringify(data));
            // } 
            
            return {
                ...state,
                isFetchingMartBankAccount: false,
                mart_bank_account : data
            };
        }

        default:
            return state;
  }
}