import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { displayMessage } from "./../../../redux/actions/app";
import {
  addCart,
  getCart,
  removeCart,
  updateStoreDeliveryAddress,
} from "./../../../redux/actions/mart_cart";
import { fetchMartStoreByID } from "./../../../redux/actions/mart_store";
import { calculateProduct } from "./../../../utils/cart";
import Cart from "./Cart.component";

class CartContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingStore: false,
      updateCartStore: false,
      filterData: {},

      products: [],
      product: null,
      cartItems: [],
      cartSummary: null,
      breadcrumb: null,
      updateQty: false,
      store: null,

      showModal: false,
      modalTitle: null,
      modalBody: null,
      modalAction: null,
      modalButtonVariant: "success",
      showCancelModalBtn: true,
      modalButtonText: null,
      noteProduct: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleChangeQty = this.handleChangeQty.bind(this);
    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
  }

  componentDidMount() {
    // if(cartItems.length <= 0) {
    this.props.getCart();

    this.setState({
      isLoading: true,
    });
    // }
  }

  componentDidUpdate() {
    const { updateQty } = this.state;
    const { carts } = this.props.martcart;
    const { isFetchingMartStore, mart_store } = this.props.martstore;
    const { items, summary } = carts;
    let { breadcrumb, isLoading, loadingStore, updateCartStore } = this.state;

    if (updateQty) {
      this.setState({
        updateQty: false,
        cartItems: items,
        cartSummary: summary,
      });
    }

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (isLoading && !isFetchingMartStore) {
      breadcrumb = [
        { link: "/beranda", text: "Beranda" },
        { link: "/shop/cart", text: "Keranjang" },
      ];

      // isLoading true setelah cart terupdate
      if (updateCartStore) {
        isLoading = false;
      }

      // update cart
      if (loadingStore && !updateCartStore) {
        this.props.updateStoreDeliveryAddress(mart_store);
        updateCartStore = true;
      }

      // load store
      if (
        summary != null &&
        typeof summary["StoreID"] !== "undefined" &&
        !isNaN(parseInt(summary["StoreID"])) &&
        // (typeof(summary['StoreSubDistrictID']) !== "undefined" || !isNaN(parseInt(summary['StoreSubDistrictID']))))
        !loadingStore
      ) {
        this.props.fetchMartStoreByID(summary["StoreID"]);
        loadingStore = true;
      } else if (!loadingStore) {
        // store null kemungkinan
        loadingStore = false;
        isLoading = false;
      }

      this.setState({
        cartItems: items,
        cartSummary: summary,
        store: mart_store,
        updateCartStore,
        loadingStore,
        isLoading,
        breadcrumb,
      });
    }
  }

  handleChangeQty(param) {
    let product = calculateProduct(param);
    let { type } = param;

    if (
      typeof product["error"] !== "undefined" &&
      product["error"] &&
      typeof product["message"]
    ) {
      this.props.displayMessage(product["message"], product["error"]);
    } else {
      ReactGA.event({
        category: "shopping",
        action: type === "DECREMENT" ? "remove_from_cart" : "add_to_cart",
      });
      this.props.addCart(product);
    }

    // update state
    this.setState({ updateQty: true });
  }

  handleRemoveProduct(ProductID) {
    ReactGA.event({
      category: "shopping",
      action: "remove_from_cart",
    });

    this.props.removeCart(ProductID);
    this.setState({ isLoading: true });
  }

  toggleModal(isOpen, data, confirm) {
    let {
      modalTitle,
      modalBody,
      modalAction,
      modalButtonVariant,
      showCancelModalBtn,
      modalButtonText,
      noteProduct,
    } = this.state;

    if (typeof data !== "undefined" && typeof data["type"] !== "undefined") {
      modalAction = { ...data, value: data && data["Qty"] ? data["Qty"] : 0 };
      noteProduct = data && data["Note"] ? data["Note"] : "";
    }

    if (modalAction != null && typeof modalAction["type"] !== "undefined") {
      switch (modalAction["type"]) {
        case "EMPTY_CART":
          modalTitle = "Konfirmasi Hapus";
          modalBody = "Apakah anda ingin menghapus semua isi Keranjang?";

          if (typeof confirm !== "undefined" && confirm) {
            ReactGA.event({
              category: "shopping",
              action: "remove_from_cart",
            });

            this.props.removeCart();
            this.setState({ isLoading: true });
          }

          break;

        case "NOTE_PRODUCT":
          modalTitle = "Catatan";
          modalTitle = "Catatan";
          modalButtonVariant = "link";
          showCancelModalBtn = false;
          modalButtonText = "OK";
          modalBody = (
            <div>
              <p>Tambahkan catatan untuk pesanan Anda</p>
              <textarea
                name="catatan"
                className="form-control"
                onChange={(e) => this.setState({ noteProduct: e.target.value })}
              >
                {noteProduct}
              </textarea>
            </div>
          );

          if (typeof confirm !== "undefined" && confirm) {
            modalAction = { ...modalAction, Note: noteProduct };
            this.props.addCart(modalAction);

            this.setState({ updateQty: true });
          }

          break;

        default:
          console.log("");
          break;
      }
    }

    this.setState({
      showModal: isOpen,
      modalTitle,
      modalBody,
      modalAction,
      modalButtonVariant,
      showCancelModalBtn,
      modalButtonText,
      noteProduct,
    });
  }

  render() {
    const {
      cartItems,
      cartSummary,
      isLoading,
      breadcrumb,
      showModal,
      modalTitle,
      modalBody,
      modalButtonVariant,
      showCancelModalBtn,
      modalButtonText,
    } = this.state;

    return (
      <React.Fragment>
        <Cart
          cartItems={cartItems}
          cartSummary={cartSummary}
          isLoading={isLoading}
          breadcrumb={breadcrumb}
          onChangeQty={this.handleChangeQty}
          onDeleteProduct={this.handleRemoveProduct}
          onOpenModal={this.toggleModal}
        />

        {/* Confirmation Modal */}
        <Modal show={showModal} onHide={(e) => this.toggleModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          <Modal.Footer>
            {showCancelModalBtn && (
              <Button
                variant="secondary"
                onClick={(e) => this.toggleModal(false)}
              >
                Batalkan
              </Button>
            )}
            <Button
              variant={modalButtonVariant ? modalButtonVariant : "primary"}
              onClick={(e) => this.toggleModal(false, "", true)}
            >
              {modalButtonText ? modalButtonText : "Lanjutkan"}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { martcart, martstore } = state;
  return { martcart, martstore };
};

export default withRouter(
  connect(mapStateToProps, {
    getCart,
    addCart,
    removeCart,
    displayMessage,
    updateStoreDeliveryAddress,
    fetchMartStoreByID,
    //list of dispatch action
  })(CartContainer)
);
