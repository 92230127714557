import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../Beranda.css";
import { fetchPromo, fetchPromoDetailById } from "./../../redux/actions/promo";
import Promo from "./promo.component";
import "./Promo.css";
import PromoDetail from "./PromoDetail.component";

class PromoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      action: null,
      promoID: null,
      promo: [],
      promoDetail: null,
      products: [],
      promoLainnya: [],
      breadcrumb: null,

      activePage: 1,
      itemPerPage: 9,
      totalPromo: null,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    this.loadData();
    console.log("mount");
  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    //cek state dari news
    const {
      fetchingPromo,
      fetchingPromoDetailById,
      promo,
      promoDetail,
      promo_total,
    } = this.props.promo;
    const { isLoading, action, promoID, itemPerPage } = this.state;
    const { id, extra } = this.props.match.params;
    let { breadcrumb, promoLainnya } = this.state;

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (!fetchingPromo && !fetchingPromoDetailById && isLoading) {
      if (action === "detail") {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          { link: "/promo", text: "Promo" },
          { link: "/promo/" + promoID + "/detail", text: "Detail Promo" },
        ];
      } else {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          { link: "/promo", text: "Promo" },
        ];
      }

      if (promoDetail != null && promo != null && promo.length > 0) {
        promoLainnya = _.filter(
          promo,
          (o) => parseInt(o.ID) !== parseInt(promoDetail.ID)
        );
      }

      this.setState({
        isLoading: false,
        totalPromo: promo_total != null ? promo_total : itemPerPage,
        promo,
        promoDetail,
        promoLainnya,
        breadcrumb,
      });
    }

    if (
      typeof extra !== "undefined" &&
      extra === "detail" &&
      parseInt(id) !== parseInt(promoID)
    ) {
      this.loadData();
    }

    if (action === "detail" && typeof extra === "undefined") {
      this.loadData();
    }
  }

  loadData(param) {
    let { action, activePage, itemPerPage } = this.state;
    const { id, extra } = this.props.match.params;
    const { breadcrumb } = this.props.match;

    if (
      typeof param !== "undefined" &&
      typeof param.activePage !== "undefined" &&
      !isNaN(parseInt(param.activePage))
    ) {
      activePage = param.activePage;
    }

    if (typeof id !== "undefined" && typeof extra !== "undefined") {
      this.props.fetchPromoDetailById(id);
      this.props.fetchPromo();
      action = "detail";
    } else {
      this.props.fetchPromo({ page: activePage, size: itemPerPage }, true);
      action = "view";
    }

    this.setState({
      isLoading: true,
      action: action,
      promoID: id,
      activePage,
      breadcrumb: breadcrumb,
    });
  }

  handleChangePage(pageNumber) {
    this.loadData({ activePage: pageNumber });
  }

  render() {
    const {
      promo,
      promoDetail,
      isLoading,
      action,
      activePage,
      itemPerPage,
      totalPromo,
      breadcrumb,
      promoLainnya,
    } = this.state;
    return (
      <div>
        {" "}
        {action != null && action === "detail" ? (
          <PromoDetail
            promoDetail={promoDetail}
            promoLainnya={promoLainnya}
            breadcrumb={breadcrumb}
            products={null}
            isLoading={isLoading}
          />
        ) : (
          <Promo
            promo={promo}
            isLoading={isLoading}
            breadcrumb={breadcrumb}
            //paging
            activePage={activePage}
            itemPerPage={itemPerPage}
            totalPromo={totalPromo}
            onChangePage={this.handleChangePage}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { promo, promoDetail } = state;
  return { promo, promoDetail };
};
export default withRouter(
  connect(mapStateToProps, {
    fetchPromo,
    fetchPromoDetailById,
    //list of dispatch action
  })(PromoContainer)
);
