import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { parseCurrency, parseDate } from './../../../utils/common';  
import Loading from './../../Layout/Loading'; 
import orderPending from './../../../Images/order-digital-pending.png';
import orderSuccess from './../../../Images/order-digital-success.png';
import orderFailed from './../../../Images/order-digital-failed.png';
import './Order.css';
import { useHistory } from 'react-router-dom';

const OrderStatus = (props) => { 
        
    const { isLoading, order } = props; 
    const history = useHistory();  
    
    return (
        <Container>

            {isLoading && <Loading />}

            {/* <div style={{ marginTop: '46px' }}>
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
            </div>  */}

            {!isLoading && order != null && <center>
                <div className="digital-order-status-card">

                    {parseInt(order.OrderStatus) <= 3 && <div>
                        <img src={orderPending}  width="161" alt="order pending" />
                        <div className="digital-order-status-title order-pending">Dalam Proses</div>
                    </div>}

                    {parseInt(order.OrderStatus) === 7 && <div>
                        <img src={orderSuccess}  width="161" alt="order success" />
                        <div className="digital-order-status-title order-success">Pembayaran Selesai</div>
                    </div>}

                    {parseInt(order.OrderStatus) === 9 && <div>
                        <img src={orderFailed}  width="161" alt="order failed" />
                        <div className="digital-order-status-title order-failed">Tidak Berhasil</div>
                    </div>}

                    <div className="digital-order-status-label-total">Total Tagihan</div>
                    <div className="digital-order-status-price-total">Rp {parseCurrency(order.Price)}</div>

                    <div className="digital-order-status-label-jenis">Untuk Pembayaran</div>
                    <div className="digital-order-status-text-jenis">{order.ProductCategory} {order.ProductName}</div>

                    <div className="digital-order-status-text-date">{parseDate(order.UpdatedAt)}</div>

                    <div className="digital-order-status-btn-selesai"><Button variant="primary" href={"/digital/order"}>Selesai</Button></div>
                    {parseInt(order.OrderStatus) <= 3 && <div><Button variant="link" onClick={e => history.go(0)}>Perbarui Status</Button></div>}
                </div>
            </center>}

        </Container>
    )
} 

export default OrderStatus;