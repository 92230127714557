import Axios from "axios";
// import {getLocalData} from './../../utils/common';

export const REQUEST_MARTPAYMENT_SCHEME = "REQUEST_MARTPAYMENT_SCHEME";
export const REQUEST_MARTPAYMENT_QRIS = "REQUEST_MARTPAYMENT_QRIS";
export const RESULT_MARTPAYMENT_SCHEME = "RESULT_MARTPAYMENT_SCHEME";
export const RESULT_MARTPAYMENT_QRIS = "RESULT_MARTPAYMENT_QRIS";

const requestMartPaymentScheme = () => ({
  type: REQUEST_MARTPAYMENT_SCHEME,
});

const requestMartPaymentQris = () => ({
  type: REQUEST_MARTPAYMENT_QRIS,
});

const resultMartPaymentScheme = (data) => ({
  type: RESULT_MARTPAYMENT_SCHEME,
  payload: {
    data,
  },
});

const resultMartPaymentQris = (data) => ({
  type: RESULT_MARTPAYMENT_QRIS,
  payload: {
    data,
  },
});

// Request mart category from API
export const fetchMartQrisPayment = () => {
  return (dispatch) => {
    dispatch(requestMartPaymentQris());

    return Axios.get("/qrispayment")
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartPaymentQris(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
export const fetchMartPaymentScheme = () => {
  return (dispatch) => {
    dispatch(requestMartPaymentScheme());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_PAYMENT_SCHEME");
    // if(typeof(localData['Payment']) !== "undefined" && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartPaymentScheme(localData));
    // }

    return Axios.get(
      "/customdata/" +
        localStorage.getItem("AGREGO_USER_OID") +
        "/paymentscheme"
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartPaymentScheme(data.data));
      })
      .catch((error) => console.log("error"));
  };
};
