import { 
    REQUEST_MARTPAYMENT_SCHEME, 
    REQUEST_MARTPAYMENT_QRIS, 
    RESULT_MARTPAYMENT_SCHEME,
    RESULT_MARTPAYMENT_QRIS 
} from "./../actions/mart_payment";

const initialState = { 
    isFetchingMartPaymentScheme: false, 
    isFetchingMartPaymentQris: false, 
    mart_payment_scheme: null,
    mart_payment_qris: null,
};

export default function(state = initialState, action) {
    switch (action.type) { 
        case REQUEST_MARTPAYMENT_SCHEME: { 
            return {
                ...state,
                isFetchingMartPaymentScheme: true
            };
        }

        case REQUEST_MARTPAYMENT_QRIS: { 
            return {
                ...state,
                isFetchingMartPaymentQris: true
            };
        }
       
        case RESULT_MARTPAYMENT_SCHEME: {
            const { data } = action.payload; 
                        
            // local data
            // if(process.env.REACT_APP_LOCAL_DATA === "true") {
            //     localStorage.setItem('AGREGO_PAYMENT_SCHEME', JSON.stringify(data));
            // }

            return {
                ...state,
                isFetchingMartPaymentScheme: false,
                mart_payment_scheme : data
            };
        }

        case RESULT_MARTPAYMENT_QRIS: {
            const { data } = action.payload;  

            return {
                ...state,
                isFetchingMartPaymentQris: false,
                mart_payment_qris : data
            };
        }

        default:
            return state;
  }
}