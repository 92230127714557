// import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import ReactGA from "react-ga";
import { parseCurrency } from "./../../../utils/common";
import { labelMetodePembayaran } from "./../../../utils/order";
import Breadcrumb from "./../../Layout/Breadcrumb";
import Loading from "./../../Layout/Loading";
import "./Confirmation.css";

function CustomToggle({ children, eventKey, className, variant }) {
  const [isToggle, setIsToggle] = useState(false);
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    setIsToggle(!isToggle)
  );

  return (
    <Button
      variant={variant}
      className={className}
      style={{ marginLeft: isToggle ? "-35px" : "-85px" }}
      onClick={decoratedOnClick}
    >
      {isToggle ? (
        <span>
          Tutup <FontAwesomeIcon icon={faChevronUp} />
        </span>
      ) : (
        children
      )}
    </Button>
  );
}

function Confirmation({
  isLoading,
  submittingOrder,
  cartItems,
  cartSummary,
  breadcrumb,
  ...props
}) {
  const noCollapseProduct = 2;
  useEffect(() => {
    ReactGA.set({ title: "order_confirmation" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container fluid>
      {isLoading && <Loading />}

      <div className="delivery-top-space" style={{ marginTop: "58px" }}>
        {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
      </div>

      {typeof cartItems !== "undefined" &&
        cartItems != null &&
        cartItems.length > 0 && (
          <Row className="deliveryRowStyle">
            <Col md={8} xs={12}>
              {!isLoading &&
                typeof cartItems !== "undefined" &&
                cartItems != null &&
                cartItems.length >= 0 && (
                  <div>
                    <div className="delivery-product-card">
                      {/* <div className="section-title">Produk dibeli</div>  */}
                      <Col>
                        <Accordion>
                          {cartItems !== "undefined" &&
                            cartItems != null &&
                            cartItems.length >= 0 &&
                            cartItems.map((item, index) => {
                              return (
                                <div>
                                  {index < noCollapseProduct && (
                                    <div
                                      key={index}
                                      className="delivery-product-item"
                                    >
                                      <Row noGutters>
                                        <Col
                                          xs={4}
                                          md={2}
                                          style={{ paddingTop: "2px" }}
                                        >
                                          <img
                                            src={item.Img}
                                            width="100%"
                                            alt={item.Name + " Image"}
                                          />
                                          {parseInt(item.Discount) > 0 && (
                                            <div className="discount-img">
                                              {item.Discount}%
                                            </div>
                                          )}
                                        </Col>
                                        <Col
                                          xs={8}
                                          md={10}
                                          className="delivery-product-content"
                                        >
                                          <Row>
                                            <Col xs={12} md={6}>
                                              <div className="list-product-name">
                                                {item["Name"]}
                                              </div>
                                              <div>
                                                <span
                                                  className={
                                                    "list-product-price " +
                                                    (parseInt(item.Discount) >
                                                      0 &&
                                                      "product-price-discount")
                                                  }
                                                >
                                                  Rp{" "}
                                                  {parseCurrency(
                                                    item.PromoPrice
                                                  )}
                                                </span>
                                                {parseInt(item.Discount) >
                                                  0 && (
                                                  <span className="list-product-price-strip">
                                                    {" "}
                                                    Rp{" "}
                                                    {parseCurrency(item.Price)}
                                                  </span>
                                                )}
                                              </div>

                                              {item["Unit"] !== "" &&
                                                item["Weight"] > 0 && (
                                                  <div className="list-product-unit">
                                                    {item["Unit"] === "ml"
                                                      ? "Volume"
                                                      : "Berat"}
                                                    : {item["Weight"]}{" "}
                                                    {item["Unit"]}
                                                  </div>
                                                )}
                                              <div className="list-product-qty-confirm">
                                                Jumlah : {item["Qty"]}
                                              </div>
                                              {item && item["Note"] && (
                                                <div className="list-product-qty-confirm">
                                                  Catatan : {item["Note"]}
                                                </div>
                                              )}

                                              {parseInt(
                                                item["PurchaseType"]
                                              ) === 2 && (
                                                <div
                                                  className="btnPreordercart"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  Pre Order
                                                </div>
                                              )}
                                              {parseInt(
                                                item["PurchaseType"]
                                              ) === 3 && (
                                                <div
                                                  className="btn-madetoorder-cart"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  Made To Order{" "}
                                                  {item["MinDays"] &&
                                                    ": " +
                                                      item["MinDays"] +
                                                      " hari"}
                                                </div>
                                              )}
                                            </Col>

                                            <Col xs={12} md={4}>
                                              <div className="list-product-total-price text-right">
                                                Rp{" "}
                                                {parseCurrency(item["Total"])}
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}

                                  {index >= noCollapseProduct && (
                                    <Accordion.Collapse
                                      eventKey={"index-" + cartItems.ID}
                                    >
                                      <div
                                        key={index}
                                        className="delivery-product-item"
                                      >
                                        <Row noGutters>
                                          <Col
                                            xs={4}
                                            md={2}
                                            style={{ paddingTop: "15px" }}
                                          >
                                            <img
                                              src={item.Img}
                                              width="100%"
                                              alt={item.Name + " Image"}
                                            />
                                            {parseInt(item.Discount) > 0 && (
                                              <div className="discount-img">
                                                {item.Discount}%
                                              </div>
                                            )}
                                          </Col>
                                          <Col
                                            xs={8}
                                            md={10}
                                            className="delivery-product-content"
                                          >
                                            <Row>
                                              <Col xs={12} md={6}>
                                                <div className="list-product-name">
                                                  {item["Name"]}
                                                </div>
                                                <div>
                                                  <span
                                                    className={
                                                      "list-product-price " +
                                                      (parseInt(item.Discount) >
                                                        0 &&
                                                        "product-price-discount")
                                                    }
                                                  >
                                                    Rp{" "}
                                                    {parseCurrency(
                                                      item.PromoPrice
                                                    )}
                                                  </span>
                                                  {parseInt(item.Discount) >
                                                    0 && (
                                                    <span className="list-product-price-strip">
                                                      {" "}
                                                      Rp{" "}
                                                      {parseCurrency(
                                                        item.Price
                                                      )}
                                                    </span>
                                                  )}
                                                </div>
                                                {item["Unit"] !== "" &&
                                                  item["Weight"] > 0 && (
                                                    <div className="list-product-unit">
                                                      {item["Unit"] === "ml"
                                                        ? "Volume"
                                                        : "Berat"}
                                                      : {item["Weight"]}{" "}
                                                      {item["Unit"]}
                                                    </div>
                                                  )}
                                                <div className="list-product-qty-confirm">
                                                  Jumlah : {item["Qty"]}
                                                </div>
                                                {item && item["Note"] && (
                                                  <div className="list-product-qty-confirm">
                                                    Catatan : {item["Note"]}
                                                  </div>
                                                )}

                                                {parseInt(
                                                  item["PurchaseType"]
                                                ) === 2 && (
                                                  <div
                                                    className="btnPreordercart"
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    Pre Order
                                                  </div>
                                                )}
                                                {parseInt(
                                                  item["PurchaseType"]
                                                ) === 3 && (
                                                  <div
                                                    className="btn-madetoorder-cart"
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    Made To Order{" "}
                                                    {item["MinDays"] &&
                                                      ": " +
                                                        item["MinDays"] +
                                                        " hari"}
                                                  </div>
                                                )}
                                              </Col>

                                              <Col xs={12} md={4}>
                                                <div className="list-product-total-price text-right">
                                                  Rp{" "}
                                                  {parseCurrency(item["Total"])}
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>{" "}
                                      </div>
                                    </Accordion.Collapse>
                                  )}
                                </div>
                              );
                            })}

                          {cartItems.length > noCollapseProduct && (
                            <div className="order-collapse">
                              {/* <div className="order-collase-text"> */}
                              <CustomToggle
                                as={Button}
                                className="order-collase-text"
                                variant="link"
                                eventKey={"index-" + cartItems.ID}
                              >
                                Lihat {cartItems.length - noCollapseProduct}{" "}
                                item lainnya{" "}
                                <FontAwesomeIcon icon={faChevronDown} />
                              </CustomToggle>
                              {/* </div> */}
                            </div>
                          )}
                        </Accordion>
                      </Col>
                    </div>

                    {cartSummary != null &&
                      cartSummary["DeliveryOption"] === "Self Pickup" && (
                        <div className="Confr-Card">
                          <div className="delivery-section-title">
                            Pilihan Pengantaran
                          </div>

                          {/* Selected Address */}
                          <div
                            className="address-list-card"
                            style={{ marginBottom: "16px" }}
                          >
                            <div>Ambil pesanan sendiri (Self Pickup)</div>
                          </div>
                        </div>
                      )}

                    {cartSummary != null &&
                      ["Delivery", "Store Courier"].includes(
                        cartSummary["DeliveryOption"]
                      ) && (
                        <div className="Confr-Card">
                          <div className="delivery-section-title">
                            <b>Alamat Pengiriman</b>
                          </div>

                          {/* Selected Address */}
                          <div
                            className="address-list-card"
                            style={{ marginBottom: "16px", paddingTop: "9px" }}
                          >
                            {/* <div>Pengiriman (Delivery)</div>  */}
                            <div className="address-name">
                              {cartSummary["DeliveryRecipient"]}
                            </div>
                            <div className="address-phone-number">
                              {cartSummary["DeliveryPhone"]}
                            </div>
                            <div className="address-phone-number">
                              {cartSummary["DeliveryAddress"]}
                              <div>
                                {cartSummary["DeliverySubDistrict"] &&
                                  cartSummary["DeliverySubDistrict"]}
                                {cartSummary["DeliveryCity"] &&
                                  ", " + cartSummary["DeliveryCity"]}
                                {cartSummary["DeliveryProvince"] &&
                                  "," + cartSummary["DeliveryProvince"]}
                                {cartSummary["DeliveryPostalCode"] &&
                                  "," + cartSummary["DeliveryPostalCode"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {cartSummary != null &&
                      ["Delivery", "Store Courier"].includes(
                        cartSummary["DeliveryOption"]
                      ) &&
                      typeof cartSummary["CourierName"] !== "undefined" && (
                        <div className="Confr-Card">
                          <div className="delivery-section-title">
                            <b>Opsi Pengiriman</b>
                          </div>
                          <div className="address-list-card">
                            <div className="deliveryFont">
                              {cartSummary["CourierCode"] === "StoreCourier" ? (
                                "Pengiriman Toko"
                              ) : (
                                <span>
                                  {cartSummary["CourierName"]}{" "}
                                  {cartSummary["CourierServiceName"]}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                    {cartSummary != null &&
                      typeof cartSummary["PaymentType"] !== "undefined" &&
                      cartSummary["PaymentType"] != null && (
                        <div className="Confr-Card">
                          <div className="delivery-section-title">
                            <b>Metode Pembayaran</b>
                          </div>

                          <div className="metode-pembayaran-list">
                            <div style={{ paddingTop: "11px" }}>
                              {cartSummary["PaymentType"] === "Bank" ? (
                                <Row>
                                  <Col md={2} xs={4}>
                                    <img
                                      src={cartSummary["BankImage"]}
                                      alt={"Logo " + cartSummary["BankName"]}
                                      width="100%"
                                    />
                                  </Col>
                                  <Col>
                                    <div style={{ paddingTop: "6px" }}>
                                      {" "}
                                      {cartSummary["Payment"]} (
                                      {cartSummary["BankName"]}){" "}
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  {/* <Col md={1} xs={2}><FontAwesomeIcon icon={faWallet} size="2x"/></Col>
                                   */}
                                  <Col>
                                    <div style={{ paddingTop: "6px" }}>
                                      {labelMetodePembayaran({
                                        Payment: cartSummary["Payment"],
                                        PaymentType: cartSummary["PaymentType"],
                                      })}{" "}
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )}
            </Col>

            {/* Desktop view */}
            <Col className="d-none d-md-block">
              <div className="delivery-card-2">
                <div className="delivery-section-title delivery-summary-title">
                  Ringkasan Belanja
                </div>

                <div>
                  {cartSummary != null && (
                    <div className="total-price float-right">
                      Rp {parseCurrency(cartSummary.TotalPrice)}
                    </div>
                  )}
                  <div>Total Belanja</div>
                </div>
                {cartSummary != null &&
                  ["delivery", "store courier"].includes(
                    cartSummary["DeliveryOption"].toLowerCase()
                  ) && (
                    <div>
                      {cartSummary != null && (
                        <div className="total-price float-right">
                          Rp {parseCurrency(cartSummary.CourierServiceFee)}
                        </div>
                      )}
                      <div>Biaya Pengiriman</div>
                    </div>
                  )}

                <div className="delivery-summary-line">&nbsp;</div>

                <div style={{ marginTop: "14px", marginBottom: "33px" }}>
                  {cartSummary != null && (
                    <div className="total-price float-right">
                      Rp {parseCurrency(cartSummary.Total)}
                    </div>
                  )}
                  <div>Total</div>
                </div>
              </div>

              <Button
                variant="primary"
                disabled={submittingOrder}
                onClick={(e) => {
                  ReactGA.event({
                    category: "shopping",
                    action: "create_order",
                  });

                  return props.onSubmitOrder();
                }}
                className="delivery-btn-next"
              >
                {submittingOrder ? "Memproses..." : "Selesaikan Pesanan"}
              </Button>
            </Col>

            {/* Mobile view fixed in bottom */}
            <div className="product-summary-bottom d-sm-block d-md-none">
              <Row>
                <Col xs={6}>
                  <div className="label-total">Total Harga</div>
                  <div className="label-price">
                    {cartSummary != null && (
                      <div className="total-price">
                        Rp {parseCurrency(cartSummary.Total)}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={6}>
                  <Button
                    variant="primary"
                    disabled={submittingOrder}
                    onClick={(e) => {
                      ReactGA.event({
                        category: "shopping",
                        action: "create_order",
                      });

                      return props.onSubmitOrder();
                    }}
                    block
                  >
                    {submittingOrder ? "Memproses..." : "Selesaikan Pesanan"}
                  </Button>
                </Col>
              </Row>
            </div>

            <div className="d-sm-block d-md-none" style={{ height: "80px" }}>
              &nbsp;
            </div>
          </Row>
        )}
    </Container>
  );
}

export default Confirmation;
