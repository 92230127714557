import { combineReducers } from "redux"; 
import app from "./app";
import news from "./news";
import banner from "./banner";
import promo from "./promo";
import martproduct from "./mart_product";
import martcategory from "./mart_category";
import martcart from "./mart_cart";
import martdelivery from "./mart_delivery";
import martbank from "./mart_bank";
import martpayment from "./mart_payment";
import martcourier from "./mart_courier";
import martorder from "./mart_order";
import martstore from "./mart_store";
import produkvirtual from "./produkvirtual";
import profile from "./profile";
import wallet from "./wallet";
import regional from "./regional";
import digitalbrand from "./digital_brand";
import digitalproduct from "./digital_product";
import digitalcart from "./digital_cart";
import digitalorder from "./digital_order";


export default combineReducers({ app, news, banner, promo, martproduct, martcategory, martcart, martdelivery, 
    martbank, martpayment, martcourier, martorder, martstore, produkvirtual, profile, wallet, regional, digitalbrand, 
    digitalproduct, digitalcart, digitalorder }); 

