import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { fetchMartProductPopular } from "../../../redux/actions/mart_product";
import { filterLimit } from "./../../../utils/common";
import EmptyState from "./../../Layout/EmptyState";
import Loading from "./../../Layout/Loading";
import ProductItem from "./../Product/ProductItem.component";
import "./ProductPopular.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.5,
  },
};

function ProductPopular(props) {
  const { title, isLoading } = props;
  let filterData = { Status: 1 };

  // const productState = useSelector(state =>  state.martproduct);
  const productState = useSelector((state) => {
    // tampilkan product id selain yang terpilih di halaman detail
    if (state.martproduct.mart_product_popular_list.length > 0) {
      let productList = filterLimit(
        state.martproduct.mart_product_popular_list,
        (o) => {
          return !isNaN(parseInt(o.Stock)) && parseInt(o.Stock) > 0;
        },
        10
      );

      state.martproduct = {
        ...state.martproduct,
        mart_product_popular_list: productList,
      };
    }

    // onEmpty Product
    if (
      !isLoading &&
      !state.martproduct.isFetchingMartProductPopularList &&
      state.martproduct.mart_product_popular_list != null
    ) {
      if (state.martproduct.mart_product_popular_list.length <= 0) {
        props.onEmpty(true);
      } else {
        props.onEmpty(false);
      }
    }

    return state.martproduct;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchMartProductPopular({ page: 1, size: 100, search: filterData })
    );
  }, [isLoading]);

  return (
    <div
      className={"product-popular-card " + props.className}
      style={props.style}
    >
      <a
        href="/shop/product?type=popular"
        className="float-right popular-link-more"
      >
        Lihat Semua
      </a>
      <div className="product-popular-title">{title}</div>

      {(productState.isFetchingMartProductPopularList || isLoading) && (
        <Loading />
      )}

      {!isLoading &&
        !productState.isFetchingMartProductPopularList &&
        productState.mart_product_popular_list.length <= 0 && (
          <div className="text-center">
            <EmptyState style={{ margin: "auto" }} />
          </div>
        )}

      <Carousel
        responsive={responsive}
        autoPlay={false}
        deviceType={props.deviceType}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="react-multi-carousel-dot-list"
        keyBoardControl={true}
        swipeable={true}
        draggable={true}
        infinite={true}
      >
        {!productState.isFetchingMartProductPopularList &&
          productState.mart_product_popular_list.length > 0 &&
          productState.mart_product_popular_list.map((item, index) => {
            return (
              <div key={index}>
                <ProductItem
                  product={item}
                  style={props.styleItem}
                  className={props.classNameItem}
                />
              </div>
            );
          })}
      </Carousel>
    </div>
  );
}

ProductPopular.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmpty: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  classNameItem: PropTypes.string,
  styleItem: PropTypes.object,
};

ProductPopular.defaultProps = {
  title: "Produk Populer",
  isLoading: false,
  onEmpty: () => {},
  className: "",
  style: {},
  classNameItem: "",
  styleItem: {},
};

export default ProductPopular;
