import {
  REQUEST_REG_AREA,
  REQUEST_REG_BY_LAT_LNG,
  REQUEST_REG_CITY,
  REQUEST_REG_PROVINCE,
  REQUEST_REG_SUBDISTRICT,
  RESULT_REG_AREA,
  RESULT_REG_BY_LAT_LNG,
  RESULT_REG_CITY,
  RESULT_REG_PROVINCE,
  RESULT_REG_SUBDISTRICT,
} from "./../actions/regional";

const initialState = {
  isFetchingRegProvince: false,
  isFetchingRegCity: false,
  isFetchingRegSubDistrict: false,
  isFetchingRegArea: false,
  isFetchingRegByLatLng: false,
  reg_provinces: [],
  reg_cities: [],
  reg_subdistricts: [],
  reg_area: [],
  reg_by_latlng: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_REG_PROVINCE: {
      return {
        ...state,
        isFetchingRegProvince: true,
      };
    }

    case RESULT_REG_PROVINCE: {
      return {
        ...state,
        isFetchingRegProvince: false,
        reg_provinces: action.payload.data,
      };
    }

    case REQUEST_REG_CITY: {
      return {
        ...state,
        isFetchingRegCity: true,
      };
    }

    case RESULT_REG_CITY: {
      return {
        ...state,
        isFetchingRegCity: false,
        reg_cities: action.payload.data,
      };
    }

    case REQUEST_REG_SUBDISTRICT: {
      return {
        ...state,
        isFetchingRegSubDistrict: true,
      };
    }

    case RESULT_REG_SUBDISTRICT: {
      return {
        ...state,
        isFetchingRegSubDistrict: false,
        reg_subdistricts: action.payload.data,
      };
    }

    case REQUEST_REG_AREA: {
      return {
        ...state,
        isFetchingRegArea: true,
      };
    }

    case RESULT_REG_AREA: {
      return {
        ...state,
        isFetchingRegArea: false,
        reg_area: action.payload.data,
      };
    }

    case REQUEST_REG_BY_LAT_LNG: {
      return {
        ...state,
        isFetchingRegByLatLng: true,
      };
    }

    case RESULT_REG_BY_LAT_LNG: {
      return {
        ...state,
        isFetchingRegByLatLng: false,
        reg_by_latlng: action.payload.data,
      };
    }

    default:
      return state;
  }
}
