import React,{ Component } from 'react'; 
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';  
import { fetchDigitalOrder, fetchDigitalOrderById, fetchDigitalOrderStatus, cancelDigitalOrder, confirmDigitalOrder, uploadBuktiBayar } from './../../../redux/actions/digital_order';
import { displayMessage } from './../../../redux/actions/app';
import { isChangeView, setupView } from './../../../utils/common';
import Order from './Order.component';   
import OrderDetail from './OrderDetail.component';    
import OrderStatus from './OrderStatus.component';   
import OrderPaymentDigital from './OrderPayment.component';
import ErrorPage from '../../Layout/ErrorPage';
import {Modal, Button, Form} from 'react-bootstrap';
import qs from 'query-string'; 

const STATUS_PENDING = 1;

class OrderContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,  
            filterData: {},
            currentView: null,
            allowPath: {extra : ['detail','status','payment']},
            breadcrumb: null,  

            orders: [],
            order: null,
            orderStatus: null,
            action: null,
            activePage: 1, 
            itemPerPage: 10,
            totalOrder: null,

            showModal: false,
            modalTitle: null,
            modalBody: null,
            modalAction: null,
            modalBtnContinueText: "Lanjutkan",
            modalBtnContinueVariant: "primary",
            loadingConfirm: false,
            loadingUpload: false,

            fileBuktiBayar: null,
            tmpOrder: [],
            tmpTotalOrder: 0,
            loadMoreOrder: false,

            intervalId: null,
            reloadCountStatus: 0,
            maxReloadStatus: 60, //reload selama 5 menit
            timeIntervalInquiry: 5000, //5detik sekali,
            successOrder: false,
            loadStatus: false 
        }   
 
        this.toggleModal = this.toggleModal.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.reloadStatus = this.reloadStatus.bind(this);
        
    } 

    componentDidMount()  {    
        this.loadData(); 
    }
 
    componentDidUpdate() {  
        const {loadingConfirm, loadingUpload, fileBuktiBayar, currentView, allowPath, filterData, timeIntervalInquiry, modalAction} = this.state;   
        const {isFetchingDigitalOrderList, isFetchingDigitalOrder, isFetchingDigitalOrderStatus, isCancelingDigitalOrder, isConfirmingDigitalOrder, 
            isUploadingDigitalOrderBuktiBayar, digital_orders, digital_order, digital_total_order, digital_order_status} = this.props.digitalorder;  
        let {breadcrumb, action, orderStatus, orderStatusSchema, orders, isLoading, intervalId} = this.state;
        let {status} = qs.parse(this.props.location.search);  

        if(fileBuktiBayar != null) {
            // console.log(fileBuktiBayar);
        }
  
        if(loadingConfirm && !isCancelingDigitalOrder && !isConfirmingDigitalOrder ) { 
            this.setState({loadingConfirm: false, showModal: false});
            this.loadData();

            if(modalAction != null && modalAction.type === "CANCEL_ORDER") {
                this.props.history.push('/digital/order');
            }
        }

        if(loadingUpload && !isUploadingDigitalOrderBuktiBayar) { 
            this.setState({loadingUpload: false, showModal: false}); 
            this.loadData();
        }  

        if(!isFetchingDigitalOrderList && !isFetchingDigitalOrder && !isFetchingDigitalOrderStatus && isLoading) {  
 
            breadcrumb = [
                {link:'/beranda', text:'Beranda'},
                {link:'/digital/order', text: 'Pesanan Digital'}
            ]
                
            if(action === "status") {
                breadcrumb.push({link:'/digital/order/'+digital_order.ID+'/status', text: 'Status Pesanan'})
            } 
 
            orderStatusSchema = digital_order_status != null && digital_order_status.length > 0 && digital_order_status.map(o => o['TextTranslate'])
 
            if(orderStatus == null) {       
                orderStatus = [];
                // if(orderStatus != null && orderStatus.length > 0) {  
                    orderStatus.push({ Group: "TODO", TextTranslate: "MENUNGGU PEMBAYARAN", Value: "1" }) 
                    orderStatus.push({ Group: "TODO", TextTranslate: "PESANAN DIPROSES", Value: "3" })  
                    orderStatus.push({ Group: "TODO", TextTranslate: "SEMUA", Value: "all" }) 
                // } 
            }  

            // filter digital order tampilkan selain inquiry status > 0
            // orders = digital_orders.filter(order => {
            //     return order != null && parseInt(order.Action) > 0;
            // }) 
            orders = digital_orders;

            // reload status order otomatis
            if(action === "status" && digital_order != null &&  
                parseInt(digital_order['OrderStatus']) <= STATUS_PENDING && intervalId == null) { 

                intervalId = setInterval(this.reloadStatus, timeIntervalInquiry); 
            }  
 
            this.setState({
                // orders: digital_orders,  
                order: digital_order, 
                totalOrder: digital_total_order,
                isLoading: !isLoading,
                orders,
                orderStatus,
                breadcrumb,
                action,
                orderStatusSchema, 

                intervalId
            }) 
        }  


        // reload component
        let isReloadComponent = isChangeView(this.props.match.params, currentView, allowPath);  
        if(isReloadComponent || 
            (typeof(filterData['OrderStatus']) === "undefined" && typeof(status) !== "undefined") || 
            (typeof(filterData['OrderStatus']) !== "undefined" && parseInt(filterData['OrderStatus']) !== parseInt(status))) {
             
            this.loadData(1, true);
        }
    }      

    loadData(newPage, loadMore) { 
        
        let {action, activePage, itemPerPage, allowPath, filterData, loadMoreOrder} = this.state; 
        let currentView = setupView(this.props.match.params, allowPath); 
        let {status} = qs.parse(this.props.location.search);  


        if(loadMore != null) {
            // load ulang beberapa order jika ganti halaman
            // console.log('loadMore')
            loadMoreOrder = false
        }

        activePage = typeof(newPage) !== "undefined" ? newPage : activePage;  
        // let withTotalCount = totalOrder == null ? true : false; 

        if(typeof(status) !== "undefined" && !isNaN(parseInt(status))) {
            filterData['OrderStatus'] = status;
        } else {
            delete filterData['OrderStatus'];
        }

        if(currentView != null && typeof currentView['extra'] !== "undefined") {  
            if(currentView['extra'] === "NOT_FOUND") {
                action = "NOT_FOUND";
            } else {
                this.props.fetchDigitalOrderById(currentView['id']); 
                action = currentView['extra'];
            }
        } else { 
            this.props.fetchDigitalOrder({page:activePage,size:itemPerPage, search:filterData}, true); 
            action = "list";
        }   

        this.props.fetchDigitalOrderStatus();
        
        this.setState({
            isLoading: true,
            action: action, 
            currentView,
            activePage,
            filterData,
            loadMoreOrder
        }); 
    } 

    reloadStatus() {
        const { order, reloadCountStatus, maxReloadStatus} = this.state; 
        const { isFetchingDigitalOrder, digital_order } = this.props.digitalorder; 
        let {successOrder} = this.state; 

        // Cek jika digital order sudah berubah status
        if(digital_order != null && !isFetchingDigitalOrder && (
            (parseInt(digital_order['OrderStatus']) > STATUS_PENDING) || 
            (reloadCountStatus >= maxReloadStatus)
            )) { 

            if(parseInt(digital_order['OrderStatus']) > STATUS_PENDING) {
                successOrder = true;
            } 

            this.setState({isLoading: true, loadStatus: true, successOrder})
            return clearInterval(this.state.intervalId); 
        }  

        this.props.fetchDigitalOrderById(order['TransactionID'])
        this.setState(prev => ({reloadCountStatus: prev.reloadCountStatus + 1}))
    }

    handleChangePage(page) {
        this.loadData(page, true); 
    }

    toggleModal(openModal, action, confirm) {

        let {modalAction, 
            modalTitle, 
            modalBody, 
            modalBtnContinueVariant, 
            modalBtnContinueText, 
            fileBuktiBayar, 
            isLoading, 
            loadingConfirm, 
            loadingUpload} = this.state;

        if(typeof(action) !== "undefined" && action != null && typeof(action.type) !== "undefined") {
            modalAction = action;
        }

        if(modalAction != null) {
            switch(modalAction.type) {
                case "CANCEL_ORDER": 
                    modalTitle = "Batalkan Pesanan";
                    modalBody = "Anda yakin ingin membatalkan pesanan ini?";
                    modalBtnContinueVariant = "danger";
                    modalBtnContinueText = "Lanjutkan membatalkan pesanan";

                    if(typeof(confirm) !== "undefined" && confirm && typeof(modalAction.id) !== "undefined") {
                        this.props.cancelDigitalOrder(modalAction.id);
                        loadingConfirm = true;
                    }
                    break; 
                
                case "UPLOAD_BUKTI_BAYAR": 
                    modalTitle = "Upload Bukti Bayar";
                    modalBody = <Form>  
                        <input type="file" name="bukti-bayar" className="form-control" onChange={(e) => {
                            // Create an object of formData 
                            let selectedFile = e.target.files[0]; 

                            this.setState({fileBuktiBayar: selectedFile}) 
                        }} />
                    </Form>; 

                    if(typeof(confirm) !== "undefined" && confirm && fileBuktiBayar != null) {
                        this.props.uploadBuktiBayar(fileBuktiBayar,'', {ID: modalAction.id, Type: 3}); 
                        loadingUpload = true;
                    }
                    break;

                default:
                    modalBtnContinueVariant = "primary";
                    modalBtnContinueText = "Lanjutkan";
                    break;
            }
        }

        if(!openModal) {
            fileBuktiBayar = null;
        }

        this.setState({
            showModal: openModal,
            modalAction,
            modalTitle,
            modalBody,
            modalBtnContinueVariant,
            modalBtnContinueText,
            isLoading,
            loadingConfirm,
            loadingUpload,
            fileBuktiBayar
        })
    }

    render() {
        const {isLoading, loadingConfirm, loadingUpload, order, orders, orderStatus, orderStatusSchema, activePage, itemPerPage, totalOrder, fileBuktiBayar,
            breadcrumb, action, showModal, modalAction, modalTitle, modalBody, modalBtnContinueText, modalBtnContinueVariant, filterData, loadInquiry, successInquiry} = this.state;  

            let renderView = null;

            switch(action) {
                case "detail":
                    renderView = <OrderDetail
                        isLoading={isLoading}
                        order={order}
                        orderStatus={orderStatus}
                        breadcrumb={breadcrumb}   

                        onOpenModal={this.toggleModal}   
                    />
                    break; 

                case "status":
                    renderView = <OrderStatus 
                        isLoading={isLoading}
                        order={order}
                        breadcrumb={breadcrumb}   
                        loadInquiry={loadInquiry}
                        successInquiry={successInquiry}
                    />
                    break; 
                
                case "payment":
                        renderView = <OrderPaymentDigital 
                        order={order}
                        isLoading={isLoading}

                        onOpenModal={this.toggleModal}
                        />
                        break;
    

                case "list":
                    renderView = <Order 
                        isLoading={isLoading}
                        orders={orders}
                        orderStatus={orderStatus}
                        breadcrumb={breadcrumb}  
                        itemPerPage={itemPerPage}
                        totalOrder={totalOrder}
                        activePage={activePage}
                        filterData={filterData}
                        orderStatusSchema={orderStatusSchema}

                        onChangePage={this.handleChangePage}    
                        onOpenModal={this.toggleModal}

                    />
                    break;

                    
                default:
                    renderView = <ErrorPage />;
                    break;
            } 

        return (<React.Fragment>
            
            {renderView}

            <Modal show={showModal} onHide={(e) => this.toggleModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => this.toggleModal(false)}>
                        Kembali
                    </Button>
                    <Button variant={modalBtnContinueVariant} disabled={
                            loadingConfirm || loadingUpload || (modalAction != null && modalAction.type === "UPLOAD_BUKTI_BAYAR" && fileBuktiBayar == null)
                        } onClick={(e) => this.toggleModal(true,null,true)}>
                        {loadingConfirm || loadingUpload ? 'Memproses...' : modalBtnContinueText}
                    </Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>);
    }
}

const mapStateToProps = (state) => { 
    const { digitalorder } = state; 
    return { digitalorder }
};

export default withRouter(connect(mapStateToProps, { 
    fetchDigitalOrder,
    fetchDigitalOrderById,
    fetchDigitalOrderStatus,
    displayMessage,
    cancelDigitalOrder,
    confirmDigitalOrder,
    uploadBuktiBayar
    //list of dispatch action
})(OrderContainer));

