import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import './Profile.css';
import { parseDateSystem } from './../../utils/common'; 
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from './../Layout/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Breadcrumb from '../Layout/Breadcrumb';

// Validasi form disini
const DatadiriSchema = Yup.object().shape({
    FullName: Yup.string()
        .min(5, 'Nama lengkap terlalu pendek, minimal 5 karakter')
        .max(50, 'Nama lengkap terlalu panjang, maksimal 50 karakter')
        .required('Nama lengkap harus diisi'),
    
    
    Email: Yup.string()
        .email('Invalid email')
        .required('Required'),

   Gender:Yup.string()
        .required('Jenis Kelamin harus dipilih'),

});

export default function DataDiriEdit(props) {

    const { profile, isLoading, successUpdate, breadcrumb } = props;
    const [showAlert, setShowAlert] = useState(true);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ FullName: '', ...(profile != null ? profile : {}) }}
            validationSchema={DatadiriSchema}
            onSubmit={(values, { setSubmitting }) => {
                // ketika di submit data form di variable values dikirim ke parent component lewat props 

                // kirim form ke parent lewat onSubmitProfile
                setSubmitting(true);
                props.onSubmitProfile(values);
            }}
        >
            {({ errors, touched, handleChange, handleBlur, handleSubmit, values, isSubmitting }) => { 
return ( 
                <Container>
                    
                    <Row>
                        <Col>

                            <div className="profileContainer">
                                <Row>

                                    <Col md={12} xs={12}>

                                        <Row>
                                            <Col>
                                                <div style={{ paddingLeft: '40px' , paddingTop:'66px'}}>
                                                    {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                                                </div>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8} xs={12}>
                                                <div style={{ paddingLeft: '40px', paddingBottom:'25px' }}>
                                                {!isLoading &&<div className="dataDiriJudul">Data Pribadi</div>}
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md={12} xs={12}>
                                                <div style={{ paddingLeft: '40px', paddingRight: '40px', paddingBottom: '70px' }}>

                                                    {/* Loading State */}
                                                    {isLoading && <Loading />}

                                                    {/* State berhasil update */}
                                                    {!isLoading && successUpdate &&
                                                        <Alert show={showAlert} onClose={() => setShowAlert(false)} dismissible variant="success">
                                                            <FontAwesomeIcon icon={faCheckCircle} /> Data diri berhasil di update
                                        </Alert>}

                                                    {/* Form Data diri */}
                                                    {!isLoading && <Form onSubmit={handleSubmit} id="dataDiriForm">
                                                        <Form.Group controlId="formMemberNumber">
                                                            <Form.Label><div style={{fontSize:'14px'}}>Nomor Anggota</div></Form.Label>
                                                            <Form.Control type="text"
                                                                placeholder="Nomor Anggota"
                                                                name="MemberNumber"
                                                                value={profile != null ? values.MemberNumber : ''}
                                                                onChange={handleChange}
                                                                disabled={true}
                                                                onBlur={handleBlur} />

                                                            {errors.MemberNumber && touched.MemberNumber &&
                                                                <Form.Text className="text-danger">
                                                                    {errors.MemberNumber}
                                                                </Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="formFullName">
                                                            <Form.Label><div style={{fontSize:'14px'}}>Nama Lengkap</div></Form.Label>
                                                            <Form.Control type="text"
                                                                placeholder="Nama Lengkap"
                                                                name="FullName"
                                                                value={profile != null ? values.FullName : ''}
                                                                onChange={handleChange}
                                                                disabled={profile == null}
                                                                onBlur={handleBlur} />

                                                            {errors.FullName && touched.FullName &&
                                                                <Form.Text className="text-danger">
                                                                    {errors.FullName}
                                                                </Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="formGender">
                                                            <Form.Label><div style={{fontSize:'14px'}}>Jenis Kelamin</div></Form.Label>
                                                            <Form.Control as="select"
                                                            name="Gender"
                                                            value={values.Gender}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}     
                                                            >
                                                                <option value={1}>Laki - laki</option>
                                                                <option value={0}>Perempuan</option> 
                                                            </Form.Control>
                                                                                            
                                                            {errors.Gender && touched.Gender &&
                                                                <Form.Text className="text-danger">
                                                                    {errors.Gender}
                                                                </Form.Text>}
                                                                </Form.Group>


                                                        <Form.Group controlId="formEmail">
                                                            <Form.Label><div style={{fontSize:'14px'}}>Email</div></Form.Label>
                                                            <Form.Control type="text"
                                                                placeholder="Email"
                                                                name="Email"
                                                                value={profile != null ? values.Email : ''}
                                                                onChange={handleChange}
                                                                disabled={true}
                                                                onBlur={handleBlur} />

                                                            {errors.Email && touched.Email &&
                                                                <Form.Text className="text-danger">
                                                                    {errors.Email}
                                                                </Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="formNoHandphone">
                                                            <Form.Label><div style={{fontSize:'14px'}}>Nomor Handphone</div></Form.Label>
                                                            <Form.Control type="text"
                                                                placeholder="Nomor Handphone"
                                                                name="Phone"
                                                                value={profile != null ? values.Phone : ''}
                                                                onChange={handleChange}
                                                                disabled={profile == null}
                                                                onBlur={handleBlur} />

                                                            {errors.Phone && touched.Phone &&
                                                                <Form.Text className="text-danger">
                                                                    {errors.NoHandphone}
                                                                </Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="formTempatLahir">
                                                            <Form.Label><div style={{fontSize:'14px'}}>Tempat Lahir</div></Form.Label>
                                                            <Form.Control type="text"
                                                                placeholder="Tempat Lahir"
                                                                name="BirthPlace"
                                                                value={profile != null ? values.BirthPlace : ''}
                                                                onChange={handleChange}
                                                                disabled={profile == null}
                                                                onBlur={handleBlur} />

                                                            {errors.BirthPlace && touched.BirthDate &&
                                                                <Form.Text className="text-danger">
                                                                    {errors.TempatLahir}
                                                                </Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="formTanggalLahir">
                                                            <Form.Label><div style={{fontSize:'14px'}}>Tanggal Lahir</div></Form.Label>
                                                            <Form.Control type="date"
                                                                placeholder="Tanggal Lahir"
                                                                name="BirthDate"
                                                                value={profile != null ? parseDateSystem(values.BirthDate) : ''}
                                                                onChange={handleChange}
                                                                disabled={profile == null}
                                                                onBlur={handleBlur} />

                                                            {errors.BirthDate && touched.BirthDate &&
                                                                <Form.Text className="text-danger">
                                                                    {errors.BirthDate}
                                                                </Form.Text>}
                                                        </Form.Group>
                                                        <Button className="Save-btn" type="submit" form="dataDiriForm" disabled={isSubmitting}>Simpan</Button>

                                                    </Form>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>)}}
        </Formik>)
}