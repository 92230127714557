import Axios from "axios";
import { setQueryString } from "./../../utils/common";
import { handleError } from "./app";

export const REQUEST_REG_PROVINCE = "REQUEST_REG_PROVINCE";
export const REQUEST_REG_CITY = "REQUEST_REG_CITY";
export const REQUEST_REG_SUBDISTRICT = "REQUEST_REG_SUBDISTRICT";
export const REQUEST_REG_AREA = "REQUEST_REG_AREA";
export const REQUEST_REG_BY_LAT_LNG = "REQUEST_REG_BY_LAT_LNG";
export const RESULT_REG_PROVINCE = "RESULT_REG_PROVINCE";
export const RESULT_REG_CITY = "RESULT_REG_CITY";
export const RESULT_REG_SUBDISTRICT = "RESULT_REG_SUBDISTRICT";
export const RESULT_REG_AREA = "RESULT_REG_AREA";
export const RESULT_REG_BY_LAT_LNG = "RESULT_REG_BY_LAT_LNG";

const searchFieldType = {
  ProvinceID: "int",
  CityID: "int",
  SubDistrictIDShipper: "int",
};

// Default Sorting for product
const defaultSorting = ["Name", "asc"];

const requestRegProvince = () => ({
  type: REQUEST_REG_PROVINCE,
});

const requestRegCity = () => ({
  type: REQUEST_REG_CITY,
});

const requestRegSubDistrict = () => ({
  type: REQUEST_REG_SUBDISTRICT,
});

const requestRegArea = () => ({
  type: REQUEST_REG_AREA,
});

const resultRegProvince = (data) => ({
  type: RESULT_REG_PROVINCE,
  payload: {
    data,
  },
});

const resultRegCity = (data) => ({
  type: RESULT_REG_CITY,
  payload: {
    data,
  },
});

const resultRegSubDistrict = (data) => ({
  type: RESULT_REG_SUBDISTRICT,
  payload: {
    data,
  },
});

const resultRegArea = (data) => ({
  type: RESULT_REG_AREA,
  payload: {
    data,
  },
});

const requestRegByLatLng = (data) => ({
  type: REQUEST_REG_BY_LAT_LNG,
  payload: {
    data,
  },
});

const resultRegByLatLng = (data) => ({
  type: RESULT_REG_BY_LAT_LNG,
  payload: {
    data,
  },
});

export const fetchRegProvince = (props) => {
  return (dispatch) => {
    const [sortColumn, sortDirection] = defaultSorting;

    props = typeof props !== "undefined" ? props : {};
    props.searchFieldType = searchFieldType;
    props.sorting = sortColumn + "-" + sortDirection;

    let queryString = setQueryString(props);

    dispatch(requestRegProvince());

    return Axios.get("/province?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultRegProvince(data.data));
      })
      .catch((error) => {
        dispatch(resultRegProvince([]));
        return dispatch(handleError(error));
      });
  };
};

export const fetchRegCity = (props) => {
  return (dispatch) => {
    const [sortColumn, sortDirection] = defaultSorting;
    props = typeof props !== "undefined" ? props : {};
    props.searchFieldType = searchFieldType;
    props.sorting = sortColumn + "-" + sortDirection;

    let queryString = setQueryString(props);

    dispatch(requestRegCity());

    return Axios.get("/city?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultRegCity(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

export const fetchRegSubDistrict = (props) => {
  return (dispatch) => {
    const [sortColumn, sortDirection] = defaultSorting;
    props = typeof props !== "undefined" ? props : {};
    props.searchFieldType = searchFieldType;
    props.sorting = sortColumn + "-" + sortDirection;

    let queryString = setQueryString(props);

    dispatch(requestRegSubDistrict());

    return Axios.get("/subdistrict?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultRegSubDistrict(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

export const fetchRegArea = (props) => {
  return (dispatch) => {
    const [sortColumn, sortDirection] = defaultSorting;
    props = typeof props !== "undefined" ? props : {};
    props.searchFieldType = searchFieldType;
    props.sorting = sortColumn + "-" + sortDirection;

    let queryString = setQueryString(props);

    dispatch(requestRegArea());

    return Axios.get("/area?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultRegArea(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

export const fetchRegByLatLng = ({ Latitude, Longitude }) => {
  return (dispatch) => {
    dispatch(requestRegByLatLng());

    return Axios.get("/areadetails", {
      params: { Latitude, Longitude },
    })
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultRegByLatLng(data.data));
      })
      .catch((error) => {
        console.log("error");
        dispatch(resultRegByLatLng(null));
      });
  };
};
