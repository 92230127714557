import React from 'react';
import { 
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux'; 
import{ addCartDigital } from '../../../redux/actions/digital_cart';
import BPJSComponent from './BPJS.Component';

class BPJSContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      breadcrumb: null,
      
    }

    this.handleChangeProductDigital = this.handleChangeProductDigital.bind(this);
    this.handleChangeBPJSNo = this.handleChangeBPJSNo.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    
    
  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    this.loadData();
  }

 //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    const{isLoading} = this.state;

    let{breadcrumb} = this.state;

    
    if (isLoading) {
      
      breadcrumb = [
        { link: '/beranda', text: 'Digital' },
        { link: '#', text: 'BPJS' },
      ]
    
        this.setState({
          isLoading: false,
          breadcrumb,
        
          })

        }

       
  }

  loadData(param) {
    const { breadcrumb} = this.props.match;
    
    
    this.setState({
      isLoading: true,
      breadcrumb: breadcrumb,    
      
    });
  }

  handleChangeBPJSNo(values){
    if(values.length >= 10){
        var newReference ={Reference:values}
    
        this.handleChangeProductDigital(newReference);
            
        }
        
  }
  
  handleChangeProductDigital(values){

    this.props.addCartDigital({...values, CategoryName: "BPJS", CategoryURL: "/digital/bpjs"});
       
  }

  handleClearForm(){
    let newdata = {Reference:''}
    
    this.handleChangeProductDigital(newdata);
    
    
    
  }


  
  render() {
    const{isLoading, breadcrumb}= this.state;
    let renderComponent = null;
    
      renderComponent =
      <BPJSComponent
      isLoading={isLoading}
      breadcrumb={breadcrumb}

      onChangeBPJSNo = {this.handleChangeBPJSNo}
      onChangeProductDigital = {this.handleChangeProductDigital}
      onClearForm ={this.handleClearForm}
    />
      

        
  return (<div>{renderComponent}</div>);
    }


}


const mapStateToProps = (state) => {
    const { store } = state;
    return { store }
  };
  export default withRouter(connect(mapStateToProps, {
    addCartDigital
    
    //list of dispatch action
  })(BPJSContainer));
  
// export default PLNPrepaidContainer;