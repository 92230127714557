import Axios from "axios";
import { addToTopup, setDataSubmitOrderTopup } from "../../utils/cart";
import { getLocalData, setQueryString } from "./../../utils/common";
import { displayMessage } from "./app";
export const REQUEST_WALLET_TRANSACTION_LIST =
  "REQUEST_WALLET_TRANSACTION_LIST";
export const REQUEST_WALLET_TRANSACTION = "REQUEST_WALLET_TRANSACTION";
export const REQUEST_WALLET_BALANCE = "REQUEST_WALLET_BALANCE";
export const REQUEST_WALLET_TOPUP_BALANCE = "REQUEST_WALLET_TOPUP_BALANCE";
export const CANCELING_WALLET_TOPUP = "CANCELING_WALLET_TOPUP";
export const UPLOADING_TOPUP_BUKTI_BAYAR = "UPLOADING_TOPUP_BUKTI_BAYAR";
export const RESULT_WALLET_TRANSACTION_LIST = "RESULT_WALLET_TRANSACTION_LIST";
export const RESULT_WALLET_TOTAL_TRANSACTION =
  "RESULT_WALLET_TOTAL_TRANSACTION";
export const RESULT_WALLET_TRANSACTION = "RESULT_WALLET_TRANSACTION";
export const RESULT_WALLET_BALANCE = "RESULT_WALLET_BALANCE";
export const RESULT_WALLET_TOPUP_BALANCE = "RESULT_WALLET_TOPUP_BALANCE";
export const RESULT_WALLET_TOPUP_FORM = "RESULT_WALLET_TOPUP_FORM";
export const UPDATE_WALLET_TOPUP_FORM = "UPDATE_WALLET_TOPUP_FORM";
export const REQUEST_SUBMIT_TOPUP = "REQUEST_SUBMIT_TOPUP";
export const RESULT_SUBMIT_TOPUP = "RESULT_SUBMIT_TOPUP";
export const REMOVE_CART_TOPUP = "REMOVE_CART_TOPUP";
export const CANCELED_WALLET_TOPUP = "CANCELED_WALLET_TOPUP";
export const UPLOADED_TOPUP_BUKTI_BAYAR = "UPLOADED_TOPUP_BUKTI_BAYAR";

const searchFieldType = { Status: "int" };

const requestWalletTransactionList = () => ({
  type: REQUEST_WALLET_TRANSACTION_LIST,
});
const requestWalletTransaction = () => ({
  type: REQUEST_WALLET_TRANSACTION,
});
const requestWalletBalance = () => ({
  type: REQUEST_WALLET_BALANCE,
});
const requestWalletTopupBalance = () => ({
  type: REQUEST_WALLET_TOPUP_BALANCE,
});
const cancelingTopup = () => ({
  type: CANCELING_WALLET_TOPUP,
});
const uploadingTopupBuktiBayar = () => ({
  type: UPLOADING_TOPUP_BUKTI_BAYAR,
});
const resultWalletTransactionList = (data) => ({
  type: RESULT_WALLET_TRANSACTION_LIST,
  payload: {
    data,
  },
});
const resultWalletTotalTransaction = (data) => ({
  type: RESULT_WALLET_TOTAL_TRANSACTION,
  payload: {
    data: data.data,
    count: data.count,
  },
});
const resultWalletTransaction = (data) => ({
  type: RESULT_WALLET_TRANSACTION,
  payload: {
    data,
  },
});
const resultWalletBalance = (data) => ({
  type: RESULT_WALLET_BALANCE,
  payload: {
    data,
  },
});
const resultWalletTopupBalance = (data) => ({
  type: RESULT_WALLET_TOPUP_BALANCE,
  payload: {
    data,
  },
});
const resultTopupForm = (data) => ({
  type: RESULT_WALLET_TOPUP_FORM,
  payload: {
    data,
  },
});
const requestUpdateTopupForm = (data) => ({
  type: UPDATE_WALLET_TOPUP_FORM,
  payload: {
    data,
  },
});
const requestSubmitTopup = () => ({
  type: REQUEST_SUBMIT_TOPUP,
});
const resultSubmitTopup = (data, isError, errorNote) => ({
  type: RESULT_SUBMIT_TOPUP,
  payload: {
    data,
    isError: typeof isError !== "undefined" && isError ? isError : false,
    message: typeof errorNote !== "undefined" ? errorNote : null,
  },
});
const canceledTopup = () => ({
  type: CANCELED_WALLET_TOPUP,
});
const uploadedTopupBuktiBayar = () => ({
  type: UPLOADED_TOPUP_BUKTI_BAYAR,
});

// Request mart product from API
export const removeCartTopup = (data) => ({
  type: REMOVE_CART_TOPUP,
  payload: {
    data,
  },
});
// Request mart order
export const fetchWalletTransaction = (props, withTotalCount) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;
  withTotalCount = typeof withTotalCount !== "undefined" && withTotalCount;
  let queryString = setQueryString(props);
  return (dispatch) => {
    dispatch(requestWalletTransactionList());
    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_WALLET_TRANSACTION");
    // if(localData.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultWalletTotalTransaction({data: localData, count: 16}));
    // }
    return Axios.get("/me/accounttransaction?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        // kalau pake total count, maka jalankan query count data
        if (withTotalCount) {
          return dispatch(
            fetchWalletTotalTransaction({
              ...props,
              items: data.data,
              message: data.message,
            })
          );
        }
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedTopup
        return dispatch(resultWalletTransactionList(data.data));
      })
      .catch((error) => console.log("error"));
  };
};
// Request mart order
export const fetchTopupById = (id) => {
  return (dispatch) => {
    dispatch(requestWalletTransaction());
    // LOCAL DATA
    let localData = getLocalData("AGREGO_USER_ORDER", id);
    if (localData != null && process.env.REACT_APP_LOCAL_DATA === "true") {
      return dispatch(resultWalletTransaction(localData));
    }
    return Axios.get("/me/wallettopup/" + id)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultWalletTransaction(data.data));
      })
      .catch((error) => console.log("error"));
  };
};
// Request wallet total transaction
export const fetchWalletTotalTransaction = ({
  keyword,
  operand,
  items,
  message,
  queryString,
}) => {
  return (dispatch) => {
    return dispatch(resultWalletTotalTransaction({ data: items, count: 10 }));
    // keyword = typeof(keyword) !== "undefined" ? keyword : "";
    // queryString = setFieldSearchQuery(keyword, searchFieldType);
    // return Axios.get('/me/accounttransaction?Page=1&Size=1000000'+queryString,{
    //         headers: {
    //             'Authorization' : 'bearer '+localStorage.getItem('AGREGO_USER_TOKEN')
    //         }
    // })
    // .then(response => response.data)
    // .then(data => {
    //     // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedTopup
    //     return dispatch(resultWalletTotalTransaction({data: items, count: data.data.length}))
    // })
    // .catch(error => console.log('error'));
  };
};
// get my wallet balance
export const fetchWalletBalance = () => {
  return (dispatch) => {
    dispatch(requestWalletBalance());
    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_WALLET");
    // if(localData != null && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return setTimeout(() => dispatch(resultWalletBalance({EndBalance: localData})), 1000);
    // }
    return Axios.get("/me/wallet")
      .then((response) => response.data)
      .then((data) => {
        let EndBalance =
          typeof data.data.EndBalance !== "undefined"
            ? data.data.EndBalance
            : null;
        return dispatch(resultWalletBalance(EndBalance));
      })
      .catch((error) => console.log("error"));
  };
};
export const updateTopupForm = (data) => {
  return (dispatch) => {
    addToTopup({
      ...data,
      CategoryName: "Deposit",
      CategoryURL: "/wallet/topup",
    });
    return dispatch(requestUpdateTopupForm(data));
  };
};
export const getTopupForm = () => {
  return (dispatch) => {
    return dispatch(resultTopupForm());
  };
};
// get my wallet balance
export const topupWalletBalance = (props) => {
  return (dispatch) => {
    dispatch(requestWalletTopupBalance());

    props = typeof props !== "undefined" ? props : {};
    props.searchFieldType = searchFieldType;
    props.sorting = "ID-desc";

    let queryString = setQueryString(props);

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_WALLET");
    // if(localData != null && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return setTimeout(() => dispatch(resultWalletBalance({EndBalance: localData})), 1000);
    // }
    return Axios.get("/me/wallettopup?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultWalletTopupBalance(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

export const submitTopup = () => {
  return (dispatch) => {
    dispatch(requestSubmitTopup());
    let data = setDataSubmitOrderTopup();

    return Axios.post("/me/wallettopup", data)
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;
        let message = data.message;

        if (data.status === false) {
          // message = (message === "Insufficient stock" ? "Top Up Gagal" : message);
          console.log(message);
          dispatch(displayMessage(message, true));
          return dispatch(resultSubmitTopup({}, true));
        }

        // dispatch(removeCartTopup());
        return dispatch(resultSubmitTopup(responseData));
      })
      .catch((error) => {
        console.log(error);
        // message = responseData.Note;
        return dispatch(resultSubmitTopup({}, true, "Terjadi kesalahan"));
      });
  };
};

export const cancelTopup = (id) => {
  return (dispatch) => {
    if (typeof id === "undefined" || isNaN(parseInt(id))) {
      return dispatch(displayMessage("ERROR"));
    }

    dispatch(cancelingTopup());

    return Axios.put("/me/cancelwallettopup/" + id, {})
      .then((response) => response.data)
      .then((data) => {
        return dispatch(canceledTopup(data.data.OrderStatus));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
export const uploadBuktiBayar = (File, FileName, Order) => {
  return (dispatch) => {
    dispatch(uploadingTopupBuktiBayar());

    return Axios.get("/tools/s3/upload", {
      headers: {
        Path:
          "agrego/asset/" + localStorage.getItem("AGREGO_USER_OID") + "/image",
        ContentType: "image",
        FileName:
          typeof FileName !== "undefined" && FileName !== "" && FileName != null
            ? FileName
            : "",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;

        let form = new FormData();
        for (var key in responseData) {
          form.set(key, responseData[key]);
        }

        let imageUrl = responseData.endpoint + responseData.key;

        form.append("file", File, responseData["x-amz-meta-uuid"]);

        // // respon atau data dari server ada di variable data
        // let message = responseData.message;

        // // Order gagal saat wallet tidak cukup
        // if(parseInt(responseData.Status) === 7) {
        //     message = responseData.Note;
        //     return dispatch(resultSubmitOrder(responseData, true, message));
        //     // return dispatch(displayMessage(message, true));
        // }

        // // remove item from cart
        // dispatch(removeCart());
        // return dispatch(resultSubmitOrder(responseData));
        return dispatch(uploadToBucket(form, imageUrl, Order));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
const uploadToBucket = (form, imageUrl, Order) => {
  return (dispatch) => {
    // dispatch(requestUploadBuktiBayar());

    return Axios.post("https://agrego.s3.eu-central-1.wasabisys.com", form, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Request-Headers": "origin",
        "Access-Control-Request-Method": "POST",
      },
    })
      .then((resp) => {
        if (resp.status === 200 || resp.status === 204) {
          // return dispatch(uploadedTopupBuktiBayar(imageUrl));
          return dispatch(
            submitPaymentConfirmation({
              Image: imageUrl,
              OrderID: Order.ID,
              Type: Order.Type,
            })
          );
        }
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
export const submitPaymentConfirmation = ({ Image, OrderID, Type }) => {
  return (dispatch) => {
    return Axios.post("/me/paymentconfirmation", {
      OID: localStorage.getItem("AGREGO_USER_OID"),
      TypeID: OrderID,
      Img: Image,
      Type: typeof Type !== "undefined" && !isNaN(parseInt(Type)) ? Type : 1,
    })
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;
        return dispatch(uploadedTopupBuktiBayar(responseData));
      })
      .catch((error) => console.log("error"));
  };
};
