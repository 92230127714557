import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "../../Beranda.css";
import Breadcrumb from "../Layout/Breadcrumb";
import { createMarkup, parseDateTime } from "./../../utils/common";
import Loading from "./../Layout/Loading";
import "./Berita.css";
// import backButton from '../../Images/arrow_forward-material.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.2,
  },
};

// const limit_judul = function (str, length, ending) {
//   if (length == null) {
//     length = 50;
//   }
//   if (ending == null) {
//     ending = '...';
//   }
//   if (str.length > length) {
//     return str.substring(0, length - ending.length) + ending;
//   } else {
//     return str;
//   }
// };

const limit_desk = function (str, length, ending) {
  if (length == null) {
    length = 50;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export default function NewsDetail(props) {
  const { isLoading, newsDetail, beritaLainnya, breadcrumb } = props;

  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [],
      "news_detail"
    );
  }, []);
  return (
    <Container>
      {/* Loading State */}
      {isLoading && <Loading />}

      {!isLoading && (
        <Row>
          {newsDetail != null && (
            <Col md={12} xs={12}>
              {/* <div className="d-none d-sm-block"> */}
              <div style={{ marginTop: "20px", paddingBottom: "0px" }}>
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
              </div>
              {/* </div> */}

              {/* <div className="d-block d-sm-none">
                <div className="backButton"><a href={"/berita/"} ><img src={backButton}></img></a></div>
              </div> */}

              {/* Loading State */}
              {isLoading && <Loading />}

              <div className="containerDetailBerita">
                <div className="contentjudul"> {newsDetail.Title} </div>
                <div className="contentdate">
                  {parseDateTime(newsDetail.PublishedAt)} WIB{" "}
                </div>

                {/* Mengatur gambar dengan wrapper div */}
                {/* <div style={{width:'100%', height:'400px'}}>
                      <div style={{backgroundImage:"url("+newsDetail.Img+")",width:"100%", height:'100%', backgroundSize:'cover', backgroundPosition:"center", backgroundRepeat:"no-repeat"}}>&nbsp;</div>
                  </div> */}

                {/* tidak perlu menulis height agar heightnya dinamis sesuai dimensi gambarnya (tidak ) */}
                <img
                  src={newsDetail.Img}
                  width="100%"
                  alt={"berita " + newsDetail.Title}
                />
                {/* <div className="contentjudul d-block d-sm-none"> {newsDetail.Title} </div>
                <div className="contentdate d-block d-sm-none">{parseDateTime(newsDetail.PublishedAt)} WIB </div> */}

                <p
                  className="contentdesk"
                  dangerouslySetInnerHTML={createMarkup(newsDetail.Content)}
                ></p>

                <Helmet>
                  {/* <title>Page 1</title> */}
                  <meta
                    name="description"
                    content={limit_desk(newsDetail.ShortDesc)}
                  />
                  <meta property="og:title" content={newsDetail.Title} />
                  <meta property="og:image" content={newsDetail.Img} />

                  <meta
                    name="description"
                    content={limit_desk(newsDetail.ShortDesc)}
                  />
                  <meta name="twitter:title" content={newsDetail.Title} />
                  <meta name="twitter:image" content={newsDetail.Img} />
                </Helmet>

                <div className="ShareButton">
                  <Row className="justify-content-md-center">
                    <Col md={2} xs={2}>
                      <FacebookShareButton
                        children={newsDetail.Title}
                        url={
                          process.env.REACT_APP_BASEURL +
                          "/berita/" +
                          newsDetail.ID +
                          "/detail"
                        }
                        quote={newsDetail.Title}
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                    </Col>

                    <Col md={2} xs={2}>
                      <WhatsappShareButton
                        children={newsDetail.Title}
                        url={
                          process.env.REACT_APP_BASEURL +
                          "/berita/" +
                          newsDetail.ID +
                          "/detail"
                        }
                        title={newsDetail.Title}
                      >
                        <WhatsappIcon size={32} round={true} />
                      </WhatsappShareButton>
                    </Col>
                    <Col md={2} xs={2}>
                      <TwitterShareButton
                        children={newsDetail.Title}
                        url={
                          process.env.REACT_APP_BASEURL +
                          "/berita/" +
                          newsDetail.ID +
                          "/detail"
                        }
                        title={newsDetail.Title}
                      >
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}
      {!isLoading && (
        <Row>
          {beritaLainnya.length < 1 ? (
            ""
          ) : (
            <Col md={12} xs={12}>
              <div className="beritaLainnya">
                <Row>
                  <Col md={6} xs={6}>
                    <div className="berita-lain-title">Berita Lainnya</div>
                  </Col>

                  <Col md={6} xs={6} className="berita-lihatsemua">
                    <a
                      href="/berita/"
                      className="float-right popular-link-more"
                    >
                      Lihat semua
                    </a>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="beritaSlideContainer">
                      <Carousel
                        className="berita-carousel"
                        responsive={responsive}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        draggable={true}
                        swipeable={true}
                        minimumTouchDrag={50}
                        keyBoardControl={true}
                      >
                        {beritaLainnya.length > 0 &&
                          !isLoading &&
                          beritaLainnya.map((item) => (
                            <div className="listBeritaLainnya">
                              <div className="berita-lain-img">
                                <img
                                  src={item.Img}
                                  width="100%"
                                  alt="img-news"
                                ></img>
                              </div>
                              <div className="cardnewsdatelainnya">
                                {parseDateTime(item.PublishedAt)}
                              </div>
                              <div className="cardnewsjudullainnya">
                                {item.Title}
                              </div>
                              <div className="cardnewsdetaillainnya">
                                {item.ShortDesc}
                              </div>

                              <Button
                                className="cardbutton"
                                variant="primary"
                                href={"/berita/" + item.ID + "/detail"}
                                block
                              >
                                Baca
                              </Button>
                            </div>
                          ))}
                      </Carousel>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
}
