import { 
    REQUEST_WALLET_TRANSACTION_LIST, 
    REQUEST_WALLET_TRANSACTION, 
    REQUEST_WALLET_BALANCE, 
    REQUEST_WALLET_TOPUP_BALANCE, 
    CANCELING_WALLET_TOPUP, 
    UPLOADING_TOPUP_BUKTI_BAYAR, 
    RESULT_WALLET_TRANSACTION_LIST, 
    RESULT_WALLET_TOTAL_TRANSACTION, 
    RESULT_WALLET_TRANSACTION,
    RESULT_WALLET_BALANCE,
    RESULT_WALLET_TOPUP_BALANCE,
    RESULT_WALLET_TOPUP_FORM,
    UPDATE_WALLET_TOPUP_FORM,
    REQUEST_SUBMIT_TOPUP, 
    RESULT_SUBMIT_TOPUP, 
    REMOVE_CART_TOPUP,
    CANCELED_WALLET_TOPUP, 
    UPLOADED_TOPUP_BUKTI_BAYAR, 
} from "./../actions/wallet";
import { getTopup ,removeFromCartTopup} from "../../utils/cart";
const initialState = { 
    isFetchingWalletTransactionList: false, 
    isFetchingWalletTransaction: false,  
    isFetchingWalletTopupBalance: false,  
    isFetchingWalletBalance: false,
    isSubmittingWalletTopup: false,  
    isCancelingTopup: false,  
    isUploadingTopupBuktiBayar: false,  
    wallet_transactions: [],
    wallet_transaction: null,
    wallet_total_transaction: null,
    wallet_balance: null,
    wallet_topup_request: null,
    wallet_topup_form: getTopup(),
    wallet_submit_data: null,
};
export default function(state = initialState, action) { 
    switch (action.type) {  
        case REQUEST_WALLET_TRANSACTION_LIST: { 
            return {
                ...state,
                isFetchingWalletTransactionList: true,
                wallet_transactions: []
            };
        }
        case REQUEST_WALLET_TRANSACTION: { 
            return {
                ...state,
                isFetchingWalletTransaction: true,
                wallet_transaction: null,
            };
        }
        case REQUEST_WALLET_BALANCE: { 
            return {
                ...state,
                isFetchingWalletBalance: true,
                wallet_balance: null,
            };
        }
        case REQUEST_WALLET_TOPUP_BALANCE: { 
            return {
                ...state,
                isFetchingWalletTopupBalance: true,
                wallet_topup_request: null,
            };
        }
        case CANCELING_WALLET_TOPUP: { 
            return {
                ...state,
                isCancelingTopup: true, 
            };
        }
        case UPLOADING_TOPUP_BUKTI_BAYAR: { 
            return {
                ...state,
                isUploadingTopupBuktiBayar: true, 
            };
        }
        case RESULT_WALLET_TRANSACTION_LIST: { 
            // local data
            // if(process.env.REACT_APP_LOCAL_DATA === "true") {
            //     localStorage.setItem('AGREGO_USER_ORDER', JSON.stringify(action.payload.data)); 
            // }
            return {
                ...state,
                isFetchingWalletTransactionList: false,
                wallet_transactions : action.payload.data
            };
        }
        case RESULT_WALLET_TOTAL_TRANSACTION: { 
            // local data
            // if(process.env.REACT_APP_LOCAL_DATA === "true") {
            //     localStorage.setItem('AGREGO_USER_ORDER', JSON.stringify(action.payload.data));
            //     localStorage.setItem('AGREGO_USER_ORDER_COUNT', JSON.stringify(action.payload.count));
            // }
            return {
                ...state,
                isFetchingWalletTransactionList: false,
                wallet_transactions : action.payload.data,
                wallet_total_transaction: action.payload.count
            };
        }
        case RESULT_WALLET_TRANSACTION: { 
            return {
                ...state,
                isFetchingWalletTransaction: false,
                wallet_transaction : action.payload.data
            };
        }
        case RESULT_WALLET_BALANCE: { 
            localStorage.setItem('AGREGO_USER_WALLET', JSON.stringify(action.payload.data));
            return {
                ...state, 
                isFetchingWalletBalance: false,
                wallet_balance: action.payload.data, 
            };
        }
        case RESULT_WALLET_TOPUP_BALANCE: { 
            return {
                ...state,
                isFetchingWalletTopupBalance: false,
                wallet_topup_request: action.payload.data,
            };
        }
        case RESULT_WALLET_TOPUP_FORM: {  
            return {
                ...state, 
                wallet_topup_form: getTopup(),
            };
        }
        case UPDATE_WALLET_TOPUP_FORM: {  
            return {
                ...state, 
                wallet_topup_form: getTopup(),
            };
        }
        case REQUEST_SUBMIT_TOPUP: { 
            return {
                ...state,
                isSubmittingWalletTopup: true
            }
        }
        case RESULT_SUBMIT_TOPUP: {  
            // removeFromCart();
            return {
                ...state,
                isSubmittingWalletTopup: false,
                wallet_submit_data: action.payload.data
            }
        }
        case REMOVE_CART_TOPUP: { 
            removeFromCartTopup(); 
            return {
                ...state,
                carts: getTopup()
            }
        }
        case CANCELED_WALLET_TOPUP: {  
            return {
                ...state, 
                isCancelingTopup: false
            }
        }
        case UPLOADED_TOPUP_BUKTI_BAYAR: {  
            return {
                ...state, 
                isUploadingTopupBuktiBayar: false
            }
        }
        default:
            return state;
  }
}