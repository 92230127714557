import React from 'react';
import PasswordComponent from './ProfilePassword.component';
import { updatePassword } from './../../redux/actions/profile';
import { 
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';

class ProfilePassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            breadcrumb: null,
            isLoading: true,
            submittingPassword: false
        }

        this.loadData = this.loadData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        const {submittingPassword} = this.state; 
        const {isUpdatingPassword} = this.props.profile;

        if(submittingPassword && !isUpdatingPassword) {
            this.setState({submittingPassword: false})
        } 
    }

    loadData() {    
        let breadcrumb = [
            { link: '/beranda', text: 'Beranda' },
            { link: '/profile/change-password', text: 'Ubah Password' },
        ]

        this.setState({
            isLoading: false,
            breadcrumb
        })
    }

    handleSubmit(values) {
        console.log(values)
        this.props.updatePassword(values)
        this.setState({submittingPassword: true})
    }

    render() {
        const {isLoading, breadcrumb, submittingPassword} = this.state;
        console.log(breadcrumb)

        return <PasswordComponent 
            isLoading={isLoading} 
            submittingPassword={submittingPassword} 
            breadcrumb={breadcrumb}

            onSubmit={this.handleSubmit}
        />
    }
} 

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile }
};
export default withRouter(connect(mapStateToProps, {
    updatePassword 
})(ProfilePassword));