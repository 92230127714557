import { Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import ReactGA from "react-ga";
import * as Yup from "yup";
import Breadcrumb from "./../Layout/Breadcrumb";
import Loading from "./../Layout/Loading";

const ChangePasswordSchema = Yup.object().shape({
  Password: Yup.string()
    .min(4, "Password Lama terlalu pendek, minimal 4 karakter")
    .required("Password Lama harus diisi"),
  NewPassword: Yup.string()
    .min(4, "Password Baru terlalu pendek, minimal 4 karakter")
    .required("Password Baru harus diisi"),
  ConfirmNewPassword: Yup.string()
    .min(4, "Konfirmasi Password terlalu pendek, minimal 4 karakter")
    .oneOf(
      [Yup.ref("NewPassword"), null],
      "Konfirmasi password harus sama dengan password"
    )
    .required("Konfirmasi Password harus diisi"),
});

function ProfilePassword({
  isLoading,
  submittingPassword,
  breadcrumb,
  ...props
}) {
  useEffect(() => {
    ReactGA.set({ title: "change_password" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container>
      {/* Loading State */}
      {isLoading && <Loading />}

      {!isLoading && (
        <div style={{ marginTop: "66px" }}>
          <div style={{ marginBottom: "16px" }}>
            {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
          </div>

          <div
            style={{
              fontSize: "24px",
              color: "##4F4F4F",
              marginBottom: "24px",
            }}
          >
            Ubah Password
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={{
              Password: "",
              NewPassword: "",
              ConfirmNewPassword: "",
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values, { setSubmitting }) => {
              // ketika di submit data form di variable values dikirim ke parent component lewat props

              // kirim form ke parent lewat onSubmitProfile

              ReactGA.event({
                category: "membership",
                event: "change_password",
              });

              setSubmitting(true);
              props.onSubmit(values);
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label for="Password">Password Lama</Form.Label>
                    <Form.Control
                      type="password"
                      name="Password"
                      value={values["Password"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Password"
                    />
                    {errors["Password"] && touched["Password"] && (
                      <Form.Text className="text-danger">
                        {errors["Password"]}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label for="NewPassword">Password Baru</Form.Label>
                    <Form.Control
                      type="password"
                      name="NewPassword"
                      value={values["NewPassword"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="NewPassword"
                    />
                    {errors["NewPassword"] && touched["NewPassword"] && (
                      <Form.Text className="text-danger">
                        {errors["NewPassword"]}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label for="ConfirmNewPassword">
                      Konfirmasi Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="ConfirmNewPassword"
                      value={values["ConfirmNewPassword"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="ConfirmNewPassword"
                    />
                    {errors["ConfirmNewPassword"] &&
                      touched["ConfirmNewPassword"] && (
                        <Form.Text className="text-danger">
                          {errors["ConfirmNewPassword"]}
                        </Form.Text>
                      )}
                  </Form.Group>

                  <Button
                    onClick={handleSubmit}
                    className="float-right d-none d-md-block"
                    style={{ marginTop: "100px", marginBottom: "50px" }}
                    disabled={submittingPassword}
                  >
                    {submittingPassword ? "Memproses" : "Ubah Password"}
                  </Button>

                  <div style={{ height: "100px" }}>&nbsp;</div>

                  {/* Mobile view fixed in bottom */}
                  <div className="product-summary-bottom d-sm-block d-md-none">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={submittingPassword}
                      block
                    >
                      Lanjutkan
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </Container>
  );
}

export default ProfilePassword;
