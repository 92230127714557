import React, {Component} from 'react'; 
import {Container, Row, Col, Button} from 'react-bootstrap'; 
import Loading from './../../Layout/Loading';
import './Order.css';  
import imgCompletedOrder from './../../../Images/success-order.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

export default class OrderCompleted extends Component {

    render() {   
        const {isLoading, order} = this.props;   

        return (
            <Container> 

                {isLoading && <Loading />} 

                <Row> 
                <Col md={{span: 10, offset:1}}>

                    {/* Pesanan Berhasil */}
                    {order != null && parseInt(order.Status) !== 7 && parseInt(order.Status) !== 8 &&
                    <Row className="order-completed-card justify-content-center">
                        <Col md={12} className="section-img"><img src={imgCompletedOrder} width="180" alt="completed order" /></Col>
                        <Col md={12} className="section-title"> Pesanan Berhasil Dilakukan </Col>
                        <Col md={12} className="section-info"> 
                            Anda memilih metode pembayaran <b>{order.PaymentType}</b> dengan pilihan pengantaran <b>{order.DeliveryOption}</b>. 
                            Cek halaman Pesanan untuk memeriksa status pesanan lebih lanjut
                        </Col> 

                        <Col md={12} className="section-btn"><Button variant="primary" href="/beranda">Selesai</Button></Col>
                    </Row>} 

                    {/* Pesanan Gagal */}
                    {order != null && (parseInt(order.Status) === 7 || parseInt(order.Status) === 8) &&
                    <Row className="order-completed-card justify-content-center">
                        <Col md={12} className="section-img text-danger"><FontAwesomeIcon icon={faBan} size="3x" /></Col>
                        <Col md={12} className="section-title"> Pesanan Gagal </Col>
                        <Col md={12} className="section-info"> 
                            Mohon maaf pesanan anda tidak dapat kami proses. "{order.Note}"
                        </Col> 

                        <Col md={12} className="section-btn"><Button variant="primary" href="/beranda">Selesai</Button></Col>
                    </Row>} 
                </Col>    
                </Row>
            </Container>
        )
    }
}