import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchWalletTransaction, fetchWalletBalance, getTopupForm, updateTopupForm, topupWalletBalance, cancelTopup, uploadBuktiBayar } from './../../redux/actions/wallet'; 
import { submitTopup } from './../../redux/actions/wallet';
import { Form, Modal, Button } from 'react-bootstrap';
import { isChangeView, setupView } from './../../utils/common';
import Wallet from './Wallet.component';
import Topup from './Topup.component'; 
import TopupPayment from './TopupPayment.component'; 
import ErrorPage from '../Layout/ErrorPage';

class WalletContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            breadcrumb: null,
            submittingTopup: false,
            updatingForm: false,

            balance: null,
            transactions: [],
            transaction: null,
            totalTransaction: null,
            walletTopupForm: [],
            pendingTopupRequest: null,

            // Modal
            showModal: false,
            modalTitle: null,
            modalBody: null,
            modalAction: null,
            modalBtnContinueText: "Lanjutkan",
            modalBtnContinueVariant: "primary",
            loadingConfirm: false,
            loadingUpload: false,

            fileBuktiBayar: null,

            action: null,
            activePage: 1,
            itemPerPage: 5, 

            allowPath: { extra: ['detail', 'topup', 'payment'] }
        }

        this.loadData = this.loadData.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleTopupBalance = this.handleTopupBalance.bind(this); 
        this.handleChangeTopupNominal = this.handleChangeTopupNominal.bind(this);
        this.handleChangeInputNominal = this.handleChangeInputNominal.bind(this);
        this.handleDeleteTopupNominal = this.handleDeleteTopupNominal.bind(this);
        this.handleSubmitTopup = this.handleSubmitTopup.bind(this);

    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        const { isLoading, currentView, allowPath, submittingTopup, updatingForm, loadingConfirm, loadingUpload } = this.state;
        const { isFetchingWalletTransactionList, isFetchingWallet, isFetchingWalletBalance, isCancelingTopup, isUploadingTopupBuktiBayar,
            isFetchingWalletTopupBalance,  wallet_topup_request, wallet_transactions, wallet_transaction, wallet_total_transaction, wallet_balance, 
            wallet_topup_form, wallet_submit_data, isSubmittingWalletTopup } = this.props.wallet; 
        let { breadcrumb, action } = this.state; 

        if (!isSubmittingWalletTopup && submittingTopup) {  

            if(wallet_submit_data != null && !isNaN(wallet_submit_data['ID']) && parseInt(wallet_submit_data['Status']) <= 1) {
                this.props.history.push('/wallet/payment'); 
            } else {
                this.props.history.push('/wallet');
            }

            this.setState({ submittingTopup: false });
        } 

        if(updatingForm) {
            this.setState({updatingForm: false, walletTopupForm: wallet_topup_form})
        }

        if(loadingConfirm && !isCancelingTopup ) { 
            this.setState({loadingConfirm: false, showModal: false});
            this.props.history.push('/wallet');
        }

        if(loadingUpload && !isUploadingTopupBuktiBayar) { 
            this.setState({loadingUpload: false, showModal: false}); 
            this.loadData();
        }


        if (!isFetchingWalletTransactionList && !isFetchingWallet && !isFetchingWalletBalance && 
            !isFetchingWalletTopupBalance && isLoading) {

            if (action === "topup") {
                breadcrumb = [
                    { link: '/beranda', text: 'Beranda' },
                    { link: '/wallet', text: 'Deposit' },
                    { link: '#', text: 'Isi Saldo' },
                ]

            } else if (action === "payment") {
                breadcrumb = [
                    { link: '/beranda', text: 'Beranda' },
                    { link: '/wallet', text: 'Deposit' },
                    { link: '#', text: 'Detail Pembayaran' },
                ]

            }

            else {
                breadcrumb = [
                    { link: '/beranda', text: 'Beranda' },
                    { link: '/wallet', text: 'Deposit' },
                ]
            } 

            this.setState({
                isLoading: false,
                submittingTopup: false,
                transactions: wallet_transactions,
                transaction: wallet_transaction,
                totalTransaction: wallet_total_transaction,
                balance: wallet_balance, 
                walletTopupForm: wallet_topup_form,
                pendingTopupRequest: wallet_topup_request != null && wallet_topup_request[0] != null && 
                                    !isNaN(parseInt(wallet_topup_request[0]['ID'])) ? wallet_topup_request[0] : null,
                breadcrumb,
                action
            })
        }

        // reload component
        let isReloadComponent = isChangeView(this.props.match.params, currentView, allowPath);
        if (isReloadComponent) {
            this.loadData();
        } 
    }


    loadData(newPage) {

        let { action, activePage, itemPerPage, allowPath } = this.state;
        let currentView = setupView(this.props.match.params, allowPath);

        activePage = typeof (newPage) !== "undefined" ? newPage : activePage;
        // let withTotalCount = totalTransaction == null ? true : false;

        if (currentView != null && typeof currentView['extra'] !== "undefined") {
            if (currentView['extra'] === "NOT_FOUND") {
                action = "NOT_FOUND";
            } else {

                if (currentView['extra'] === "payment") {
                    this.props.topupWalletBalance({search: {'Status':1, 'UserID': localStorage.getItem('AGREGO_USER_ID')}});
                }

                this.props.getTopupForm();
                
                // this.props.fetchWalletBalance(); 
                action = currentView['extra'];
            }
        } else {
            this.props.topupWalletBalance({search: {'Status':1, 'UserID': localStorage.getItem('AGREGO_USER_ID')}});
            this.props.fetchWalletBalance();
            this.props.fetchWalletTransaction({ page: activePage, size: itemPerPage }, true);
            action = "list";
        }

        this.setState({
            isLoading: true,
            action: action,
            currentView,
            activePage
        });
    }

    handleChangePage(page) {
        this.loadData(page);
    }

    handleChangeInputNominal(nominal) {   
        this.props.updateTopupForm({ Nominal: nominal });
        this.setState({updatingForm: true})
    }

    handleChangeTopupNominal(nominal) { 
        this.props.updateTopupForm({ Nominal: nominal });
        this.setState({ updatingForm: true });
    }

    handleDeleteTopupNominal(nominal) {
        this.props.updateTopupForm({ Nominal: '', PaymentType: '' });
        this.setState({ isLoading: true });
    } 

    handleTopupBalance(form) { 
    }

    handleSubmitTopup() {
        // const {wallet_topup_form} = this.props.wallet;
        // console.log(wallet_topup_form)
        this.props.submitTopup();
        this.setState({ submittingTopup: true });
    }

    
    toggleModal(openModal, action, confirm) {

        let {modalAction, modalTitle, modalBody, modalBtnContinueVariant, modalBtnContinueText, 
            fileBuktiBayar, isLoading, loadingConfirm, loadingUpload} = this.state;

        if(typeof(action) !== "undefined" && action != null && typeof(action.type) !== "undefined") {
            modalAction = action;
        } else if (!openModal) {
            modalAction = null;
        }

        if(modalAction != null) {
            switch(modalAction.type) {
                case "CANCEL_ORDER": 
                    modalTitle = "Batalkan Topup";
                    modalBody = "Anda yakin ingin membatalkan topup anda?";
                    modalBtnContinueVariant = "danger";
                    modalBtnContinueText = "Lanjutkan membatalkan topup";

                    if(typeof(confirm) !== "undefined" && confirm && typeof(modalAction.id) !== "undefined") {
                        this.props.cancelTopup(modalAction.id);
                        loadingConfirm = true;
                    }
                    break;
                
                case "UPLOAD_BUKTI_BAYAR": 
                    modalTitle = "Upload Bukti Bayar";
                    modalBody = <Form>  
                        <input type="file" name="bukti-bayar" className="form-control" onChange={(e) => {
                            // Create an object of formData 
                            let selectedFile = e.target.files[0]; 

                            this.setState({fileBuktiBayar: selectedFile}) 
                        }} />
                    </Form>; 
                    modalBtnContinueVariant = "primary";
                    modalBtnContinueText = "Lanjutkan";

                    if(typeof(confirm) !== "undefined" && confirm && fileBuktiBayar != null) {
                        this.props.uploadBuktiBayar(fileBuktiBayar,'', {ID: modalAction.id, Type: 2}); 
                        loadingUpload = true;
                    }
                    break;

                default:
                    modalBtnContinueVariant = "primary";
                    modalBtnContinueText = "Lanjutkan";
                    break;
            }
        }

        
        this.setState({
            showModal: openModal,
            modalAction,
            modalTitle,
            modalBody,
            modalBtnContinueVariant,
            modalBtnContinueText,
            isLoading,
            loadingConfirm,
            loadingUpload
        })
    }


    render() {
        const { isLoading, balance, transactions, transaction, totalTransaction, pendingTopupRequest,
            activePage, itemPerPage, breadcrumb, action, walletTopupForm, submittingTopup, 
            showModal, modalTitle, modalBody, modalAction, modalBtnContinueText, modalBtnContinueVariant, loadingConfirm, 
            loadingUpload, fileBuktiBayar} = this.state; 

        let renderView = null;

        switch (action) {
            case "payment":
                renderView = <TopupPayment
                    order={pendingTopupRequest}
                    isLoading={isLoading}
                    transaction={transaction}
                    breadcrumb={breadcrumb}

                    onOpenModal={this.toggleModal}
                />
                break;

            case "topup":
                renderView = <Topup
                    isLoading={isLoading}
                    breadcrumb={breadcrumb}
                    walletTopupForm={walletTopupForm}
                    submittingTopup={submittingTopup}
                    
                    onChangeInputNominal={this.handleChangeInputNominal}
                    onChangeNominal={this.handleChangeTopupNominal}
                    onTopupBalance={this.handleTopupBalance}
                    onDeleteNominal={this.handleDeleteTopupNominal}
                    onSubmitTopup={this.handleSubmitTopup}
                />
                break; 

            case "list":
                renderView = <Wallet
                    isLoading={isLoading}
                    balance={balance}
                    transactions={transactions}
                    breadcrumb={breadcrumb}
                    itemPerPage={itemPerPage}
                    totalTransaction={totalTransaction}
                    activePage={activePage}
                    pendingTopupRequest={pendingTopupRequest}

                    onChangePage={this.handleChangePage}

                />
                break;

            default:
                renderView = <ErrorPage />;
                break;
        }

        return (<React.Fragment>

            {renderView}

            <Modal show={showModal} onHide={(e) => this.toggleModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => this.toggleModal(false)}>
                        Kembali
                    </Button>
                    <Button variant={modalBtnContinueVariant} disabled={loadingConfirm || loadingUpload || (modalAction && modalAction.type === "UPLOAD_BUKTI_BAYAR" && !fileBuktiBayar)} onClick={(e) => this.toggleModal(true,null,true)}>
                        {loadingConfirm || loadingUpload ? 'Memproses...' : modalBtnContinueText}
                    </Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>);
    }
}

const mapStateToProps = (state) => {
    const { wallet } = state;
    return { wallet }
};

export default withRouter(connect(mapStateToProps, {
    fetchWalletTransaction,
    fetchWalletBalance, 
    getTopupForm,
    submitTopup,
    updateTopupForm,
    topupWalletBalance,
    cancelTopup,
    uploadBuktiBayar
})(WalletContainer));

