import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import GoogleMap from './../../GMap';
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Loading from "./../../Layout/Loading";

const AlamatSchema = Yup.object().shape({
  Recipient: Yup.string().required("Nama Penerima harus diisi"),

  Phone: Yup.number()
    .typeError("Nomor Telp harus berupa angka")
    .required("Nomor Telp harus diisi")
    .min(10000000, "Nomor Telepon minimal 9 karakter")
    .max(999999999999, "Nomor Telepon maksimal 13 karakter"),

  Address: Yup.string()
    .min(5, "Alamat terlalu pendek, minimal 5 karakter")
    .required("Alamat harus diisi"),

  ProvinceID: Yup.string().nullable().required("Provinsi harus diisi"),

  CityID: Yup.string().nullable().required("Kota/Kabupaten harus diisi"),

  SubDistrictID: Yup.string().nullable().required("Kecamatan harus diisi"),

  AreaID: Yup.string().nullable().required("Kelurahan harus diisi"),

  PostalCode: Yup.string()
    .nullable()
    .max(5, "Kode Pos terlalu panjang, maksimal 5 karakter")
    .required("Kode Pos harus diisi"),
});

export default function AlamatAdd(props) {
  const {
    isLoading,
    loadingAddress,
    addressID,
    addressDetail,
    loadingProvince,
    loadingCity,
    loadingSubDistrict,
    loadingArea,
    provinces,
    cities,
    subdistricts,
    area,
    addressLatLng,
    formAddress,
  } = props;
  // const [showAlert, setShowAlert] = useState(true);
  // const [location, setLocation] = useState(null)
  const [detail, setDetail] = useState(null);
  const [showRegion, setShowRegion] = useState(false);
  const history = useHistory();

  useEffect(() => {
    ReactGA.set({ title: "set_address" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleFormSubmit = (values) => {
    props.onSubmitAddress(values);
    // localStorage.removeItem(process.env.REACT_APP_PREFIX+'ADDRESS_FORM');
  };

  const handleChangeMap = (values) => {
    values["TmpAddress"] = true;

    // save form temporary
    localStorage.setItem(
      process.env.REACT_APP_PREFIX + "ADDRESS_FORM",
      JSON.stringify(values)
    );
    history.push(
      props.addressUrl + (values["ID"] ? values["ID"] : "create") + "/map"
    );
  };

  useEffect(() => {
    let detail = addressDetail;
    setDetail(detail);
  }, [isLoading, props]);

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={AlamatSchema}
      initialValues={{
        Address: "",
        AreaID: "",
        Latitude: "",
        ID: "create",
        ...(detail ? detail : {}),
      }}
      onSubmit={(values, { setSubmitting }) => {
        // ketika di submit data form di variable values dikirim ke parent component lewat props

        // kirim form ke parent lewat onSubmitProfile
        setSubmitting(true);
        handleFormSubmit(values);
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {
        const onChangeProvince = (e) => {
          const { name, value } = e.target;

          props.onChangeProvince(value);
          setFieldValue(name, value);
          setFieldValue("CityID", "");
          setFieldValue("SubDistrictID", "");
          setFieldValue("AreaID", "");

          setFieldTouched(name, true, true);
        };

        const onChangeCity = (e) => {
          const { name, value } = e.target;
          props.onChangeCity(value);
          setFieldValue(name, value);
          setFieldValue("SubDistrictID", "");
          setFieldValue("AreaID", "");
        };

        const onChangeSubdistrict = (e) => {
          const { name, value } = e.target;
          props.onChangeSubdistrict(value);
          setFieldValue(name, value);
          setFieldValue("AreaID", "");
          setFieldTouched("AreaID", true, true);

          setFieldTouched(name, true, true);
        };

        const onChangeArea = (e) => {
          const { name, value } = e.target;
          setFieldValue(name, value);
          setFieldTouched(name, true, true);

          let selectedArea = area.find((obj) => {
            return parseInt(obj["ID"]) === parseInt(value);
          });

          setFieldValue(
            "PostalCode",
            selectedArea && selectedArea["PostalCode"]
          );
          setFieldTouched("PostalCode", true, true);
        };

        const handleChangePhone = (e) => {
          let phoneNumber = e.target.value;

          if (phoneNumber.slice(0, 2) === "62") {
            phoneNumber = "0" + phoneNumber.slice(2);
          } else if (phoneNumber.slice(0, 3) === "+62") {
            phoneNumber = "0" + phoneNumber.slice(3);
          }
          // cek jika nomer telpon tidak diawali 0 yang panjang nomer telepon lebih dari 3,
          // agar bisa di cek dulu di kondisi awal apakah diawali 62 dan +62
          else if (
            !isNaN(parseInt(phoneNumber)) &&
            phoneNumber.slice(0, 1) !== "0" &&
            phoneNumber.length > 2
          ) {
            phoneNumber = "0" + phoneNumber;
          }

          setFieldValue("Phone", phoneNumber);
          setFieldTouched("Phone", true, true);
        };

        if (
          errors &&
          touched &&
          errors["ProvinceID"] &&
          touched["ProvinceID"] &&
          values["Latitude"] &&
          !showRegion
        ) {
          setShowRegion(true);
        }

        return (
          <Form className="address-container">
            <Row>
              <Col md={12} xs={12}>
                {/* Loading State */}
                {loadingAddress && <Loading />}

                {/* State berhasil update */}
                {/* {!loadingAddress && successAdd && 
                                        <Alert show={showAlert} onClose={() => setShowAlert(false)} dismissible variant="success">
                                            <FontAwesomeIcon icon={faCheckCircle} /> Alamat berhasil di tambahkan
                                        </Alert>}
                                     */}
                {/* Form Data diri */}
                {!loadingAddress && (
                  <Form onSubmit={handleSubmit} id="alamatForm">
                    {/* <Form.Group controlId="formLabels">
                                        <Form.Label><div style={{fontSize:'14px'}}>Labels</div></Form.Label>
                                        <Form.Control type="text"
                                            placeholder="Labels" 
                                            name="Labels" 
                                            value={values.Labels}
                                            onChange={handleChange}
                                            onBlur={handleBlur} />

                                        {errors.Labels && touched.Labels && 
                                        <Form.Text className="text-danger">
                                            {errors.Labels}
                                        </Form.Text>}
                                    </Form.Group>  
                                     */}
                    <Row>
                      <Col md={6} xs={12}>
                        <Form.Group controlId="formRecipient">
                          <Form.Label>
                            <div style={{ fontSize: "14px" }}>
                              Nama Penerima
                            </div>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Nama Penerima"
                            name="Recipient"
                            value={values.Recipient}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {errors.Recipient && touched.Recipient && (
                            <Form.Text className="text-danger">
                              {errors.Recipient}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6} xs={12}>
                        <Form.Group controlId="formPhone">
                          <Form.Label>
                            <div style={{ fontSize: "14px" }}>
                              No.Telp Penerima
                            </div>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="No.telp Penerima"
                            name="Phone"
                            value={values.Phone}
                            onChange={handleChangePhone}
                            onBlur={handleBlur}
                          />

                          {errors.Phone && touched.Phone && (
                            <Form.Text className="text-danger">
                              {errors.Phone}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group controlId="formAlamat">
                      <Form.Label>
                        <div style={{ fontSize: "14px" }}>Alamat Lengkap</div>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Alamat Lengkap"
                        name="Address"
                        value={values.Address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></Form.Control>

                      {errors.Address && touched.Address && (
                        <Form.Text className="text-danger">
                          {errors.Address}
                        </Form.Text>
                      )}
                    </Form.Group>

                    {/* Toggle Button */}
                    <div style={{ position: "relative", marginTop: "20px" }}>
                      <Button
                        variant="link"
                        style={{
                          textAlign: "left",
                          color: "#5A5A5A",
                          fontWeight: "bold",
                          paddingLeft: "0",
                        }}
                        block
                        onClick={(e) => setShowRegion(!showRegion)}
                      >
                        Alamat Lengkap
                        <div style={{ float: "right" }}>
                          {showRegion ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}
                        </div>
                      </Button>
                    </div>

                    {showRegion && (
                      <>
                        <Form.Group controlId="formProvinsi">
                          <Form.Label>
                            <div style={{ fontSize: "14px" }}>Provinsi</div>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            placeholder="Provinsi"
                            name="ProvinceID"
                            value={values.ProvinceID}
                            onChange={onChangeProvince}
                            onBlur={handleBlur}
                          >
                            {loadingProvince ? (
                              <option value={0}>Memuat data...</option>
                            ) : (
                              <option value={0}>Pilih Provinsi</option>
                            )}
                            {provinces != null &&
                              provinces.length > 0 &&
                              provinces.map((item, index) => (
                                <option key={index} value={item.ID}>
                                  {item.Name}
                                </option>
                              ))}
                          </Form.Control>

                          {errors.ProvinceID && touched.ProvinceID && (
                            <Form.Text className="text-danger">
                              {errors.ProvinceID}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formKabupaten">
                          <Form.Label>
                            <div style={{ fontSize: "14px" }}>
                              Kota/Kabupaten
                            </div>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            placeholder="Kota/Kabupaten"
                            name="CityID"
                            onChange={onChangeCity}
                            value={values.CityID}
                            onBlur={handleBlur}
                          >
                            {loadingCity ? (
                              <option value="">Memuat data...</option>
                            ) : (
                              <option value="">Pilih Kota</option>
                            )}
                            {cities != null &&
                              cities.length > 0 &&
                              cities.map((item, index) => (
                                <option key={index} value={item.ID}>
                                  {item.Type === "Kota" ? item.Type : ""}{" "}
                                  {item.Name}
                                </option>
                              ))}
                          </Form.Control>

                          {errors.CityID && touched.CityID && (
                            <Form.Text className="text-danger">
                              {errors.CityID}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formKecamatan">
                          <Form.Label>
                            <div style={{ fontSize: "14px" }}>Kecamatan</div>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            placeholder="Kecamatan"
                            name="SubDistrictID"
                            onChange={onChangeSubdistrict}
                            value={values.SubDistrictID}
                            onBlur={handleBlur}
                          >
                            {loadingSubDistrict ? (
                              <option value="">Memuat data...</option>
                            ) : (
                              <option value="">Pilih Kecamatan</option>
                            )}
                            {subdistricts != null &&
                              subdistricts.length > 0 &&
                              subdistricts.map((item, index) => (
                                <option key={index} value={item.ID}>
                                  {item.Name}
                                </option>
                              ))}
                          </Form.Control>
                          {errors.SubDistrictID && touched.SubDistrictID && (
                            <Form.Text className="text-danger">
                              {errors.SubDistrictID}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formKelurahan">
                          <Form.Label>
                            <div style={{ fontSize: "14px" }}>Kelurahan</div>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            placeholder="Kelurahan"
                            name="AreaID"
                            onChange={onChangeArea}
                            value={values != null ? values["AreaID"] : null}
                            onBlur={handleBlur}
                          >
                            {loadingArea ? (
                              <option value="">Memuat data...</option>
                            ) : (
                              <option value="">Pilih Kelurahan</option>
                            )}
                            {area != null &&
                              area.length > 0 &&
                              area.map((item, index) => (
                                <option key={index} value={item.ID}>
                                  {item.Name}
                                </option>
                              ))}
                          </Form.Control>
                          {errors.AreaID && touched.AreaID && (
                            <Form.Text className="text-danger">
                              {errors.AreaID}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="KodePos">
                          <Form.Label>
                            <div style={{ fontSize: "14px" }}>Kode Pos</div>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Kode Pos"
                            name="PostalCode"
                            value={values.PostalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />

                          {errors.PostalCode && touched.PostalCode && (
                            <Form.Text className="text-danger">
                              {errors.PostalCode}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </>
                    )}

                    <div
                      style={{
                        borderBottom: "1px solid #d1d1d1",
                        height: "1px",
                        margin: "8px 0 36px 0",
                      }}
                    >
                      &nbsp;
                    </div>

                    <div style={{ fontSize: "20px" }}>Pin Point Alamat</div>
                    {!values["Latitude"] && (
                      <div style={{ margin: "16px 0" }}>
                        Lokasi Anda belum ditentukan
                      </div>
                    )}
                    {values["Latitude"] && (
                      <Form.Group style={{ margin: "16px 0 31px 0" }}>
                        <Form.Label>Lokasi Anda: </Form.Label>
                        {detail && detail["AddressLatLng"] && (
                          <div
                            style={{
                              padding: "8px 0px 16px 0 ",
                              maxWidth: "450px",
                            }}
                          >
                            {detail["AddressLatLng"]}
                          </div>
                        )}
                        <div>
                          {values["Latitude"]},{values["Longitude"]}
                        </div>
                      </Form.Group>
                    )}
                    <Button
                      variant="link"
                      className="d-none d-md-block"
                      onClick={(e) => handleChangeMap(values)}
                    >
                      Tentukan Pin Point Alamat
                    </Button>
                  </Form>
                )}

                {/* saat submit kirim data ke parent komponent lewat method onSubmit */}
                {/* submit data */}
                {!isLoading && (
                  <Button
                    onClick={handleSubmit}
                    className="float-right d-none d-md-block"
                    style={{ margin: "16px 0 49px 0" }}
                    form="alamatForm"
                    disabled={isSubmitting}
                  >
                    Simpan
                  </Button>
                )}
              </Col>
            </Row>

            <div style={{ height: "100px" }}>&nbsp;</div>

            {/* Mobile view fixed in bottom */}
            {!isLoading && (
              <div className="product-summary-bottom d-sm-block d-md-none relative">
                <Button
                  variant="outline-primary"
                  onClick={(e) => handleChangeMap(values)}
                  block
                >
                  Tentukan Pin Point Alamat
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  block
                >
                  Simpan
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
