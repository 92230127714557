import { REQUEST_MARTCOURIER_LIST, RESULT_MARTCOURIER_LIST, REQUEST_MARTCOURIER_FEE, RESULT_MARTCOURIER_FEE } from "./../actions/mart_courier";

const initialState = { 
    isFetchingMartCourierList: false, 
    isFetchingMartCourierFee: false, 
    mart_couriers: [],
    mart_courier_fees: null,
};

export default function(state = initialState, action) {
    switch (action.type) { 
        case REQUEST_MARTCOURIER_LIST: { 
            return {
                ...state,
                isFetchingMartCourierList: true
            };
        }
       
        case RESULT_MARTCOURIER_LIST: {  
            
            return {
                ...state,
                isFetchingMartCourierList: false,
                mart_couriers : action.payload.data
            };
        }
        
        case REQUEST_MARTCOURIER_FEE: { 
            return {
                ...state,
                isFetchingMartCourierFee: true
            };
        }
       
        case RESULT_MARTCOURIER_FEE: {  
            
            return {
                ...state,
                isFetchingMartCourierFee: false,
                mart_courier_fees : action.payload.data
            };
        }

        default:
            return state;
  }
}