import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { fetchMartProduct } from "../../../redux/actions/mart_product";
import EmptyState from "./../../Layout/EmptyState";
import Loading from "./../../Layout/Loading";
import ProductItem from "./../Product/ProductItem.component";
import "./ProductByCategory.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.5,
  },
};

function ProductByCategory({ isLoading, data, ...props }) {
  let filterData = { Status: 1 };

  // const productState = useSelector(state =>  state.martproduct);
  const productState = useSelector((state) => state.martproduct);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [category, setCategory] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data) {
      dispatch(fetchMartProduct({ page: 1, size: 10, search: filterData }));
      setLoadingProduct(true);
    } else {
      let catID =
        data && data[0] && data[0]["CategoryID"] ? data[0]["CategoryID"] : null;
      setCategory(data);
      setCategoryID(catID);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (loadingProduct && !productState.isFetchingMartProductList) {
      let CategoryName =
        productState.mart_products && productState.mart_products[0]
          ? productState.mart_products[0]["CategoryName"]
          : null;

      let listProduct =
        productState.mart_products && productState.mart_products.length
          ? productState.mart_products.filter((obj) => parseInt(obj.Stock) > 0)
          : null;

      setCategory(listProduct);
      setTitle(CategoryName);
      setLoadingProduct(false);
    }
  }, [productState, loadingProduct]);

  return (
    <div
      className={"product-terbaru-card " + props.className}
      style={props.style}
    >
      <a
        href={`/shop/product?type=cat&cat_id=${categoryID}`}
        className="float-right terbaru-link-more"
      >
        Lihat Semua
      </a>
      <div className="product-terbaru-title">{title}</div>

      {(productState.isFetchingMartProductList || isLoading) && <Loading />}

      {!isLoading && !productState.isFetchingMartProductList && !category && (
        <div className="text-center">
          <EmptyState style={{ margin: "auto" }} />
        </div>
      )}

      {!productState.isFetchingMartProductList &&
      category &&
      category.length ? (
        <Carousel
          responsive={responsive}
          autoPlay={false}
          deviceType={props.deviceType}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="react-multi-carousel-dot-list"
          keyBoardControl={true}
          swipeable={true}
          draggable={true}
          infinite={true}
        >
          {category.map((item, index) => {
            return (
              <div key={index}>
                <ProductItem
                  product={item}
                  style={props.styleItem}
                  className={props.classNameItem}
                />
              </div>
            );
          })}
        </Carousel>
      ) : null}
    </div>
  );
}

ProductByCategory.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmpty: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  classNameItem: PropTypes.string,
  styleItem: PropTypes.object,
};

ProductByCategory.defaultProps = {
  title: "Produk Terbaru",
  isLoading: false,
  onEmpty: () => {},
  className: "",
  style: {},
  classNameItem: "",
  styleItem: {},
};

export default ProductByCategory;
