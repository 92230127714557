import Axios from "axios";
import { setFieldSearchQuery, setQueryString } from "./../../utils/common";
import { handleError } from "./app";

export const REQUEST_MARTPRODUCT_LIST = "REQUEST_MARTPRODUCT_LIST";
export const REQUEST_MARTPRODUCT = "REQUEST_MARTPRODUCT";
export const REQUEST_MARTPRODUCT_POPULAR_LIST =
  "REQUEST_MARTPRODUCT_POPULAR_LIST";
export const REQUEST_MARTPRODUCT_LATEST_LIST =
  "REQUEST_MARTPRODUCT_LATEST_LIST";
export const REQUEST_MARTPRODUCT_CHOICE_LIST =
  "REQUEST_MARTPRODUCT_CHOICE_LIST";
export const RESULT_MARTPRODUCT_LIST = "RESULT_MARTPRODUCT_LIST";
export const RESULT_MARTPRODUCT_POPULAR_LIST =
  "RESULT_MARTPRODUCT_POPULAR_LIST";
export const RESULT_MARTPRODUCT_LATEST_LIST = "RESULT_MARTPRODUCT_LATEST_LIST";
export const RESULT_MARTPRODUCT_CHOICE_LIST = "RESULT_MARTPRODUCT_CHOICE_LIST";
export const RESULT_MART_TOTAL_PRODUCT = "RESULT_MART_TOTAL_PRODUCT";
export const RESULT_MART_TOTAL_PRODUCT_POPULAR =
  "RESULT_MART_TOTAL_PRODUCT_POPULAR";
export const RESULT_MARTPRODUCT = "RESULT_MARTPRODUCT";

const searchFieldType = {
  CategoryID: "int",
  Status: "int",
  ID: "int",
  StoreID: "int",
};

const requestMartProductList = () => ({
  type: REQUEST_MARTPRODUCT_LIST,
});

const requestMartProduct = () => ({
  type: REQUEST_MARTPRODUCT,
});

const requestMartProductPopularList = () => ({
  type: REQUEST_MARTPRODUCT_POPULAR_LIST,
});

const requestMartLatestProductList = () => ({
  type: REQUEST_MARTPRODUCT_LATEST_LIST,
});

const requestMartChoiceProductList = () => ({
  type: REQUEST_MARTPRODUCT_CHOICE_LIST,
});

const resultMartProductList = (data) => ({
  type: RESULT_MARTPRODUCT_LIST,
  payload: {
    data,
  },
});

const resultMartProductPopularList = (data) => ({
  type: RESULT_MARTPRODUCT_POPULAR_LIST,
  payload: {
    data,
  },
});

const resultMartProductLatestList = (data) => ({
  type: RESULT_MARTPRODUCT_LATEST_LIST,
  payload: {
    data,
  },
});

const resultMartProductChoiceList = (data, count) => ({
  type: RESULT_MARTPRODUCT_CHOICE_LIST,
  payload: {
    data,
    count: count && count["count"] ? count["count"] : null,
  },
});

const resultMartTotalProduct = (data) => ({
  type: RESULT_MART_TOTAL_PRODUCT,
  payload: {
    data: data.data,
    count: data.count,
  },
});

const resultMartTotalProductPopular = (data) => ({
  type: RESULT_MART_TOTAL_PRODUCT_POPULAR,
  payload: {
    data: data.data,
    count: data.count,
  },
});

const resultMartProduct = (data) => ({
  type: RESULT_MARTPRODUCT,
  payload: {
    data,
  },
});

// Request mart product from API
export const fetchMartProduct = (props, withTotalCount) => {
  return (dispatch) => {
    dispatch(requestMartProductList());

    // LOCAL DATA
    // let localProduct = getLocalData("AGREGO_PRODUCTS");
    // if(localProduct.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartTotalProduct({data: localProduct, count: 16}));
    // }

    props = typeof props !== "undefined" ? props : {};
    let type = "";

    if (typeof props["search"] === "undefined") {
      props["search"] = {};
    }

    if (typeof props["sorting"] === "undefined") {
      props["sorting"] = "Updated_At-desc";
    }

    if (typeof props["search"]["TYPE"] !== "undefined") {
      type = props["search"]["TYPE"];
      delete props["search"]["TYPE"];
    }

    // hapus jika sudah dihandle API, mendapatkan data hanya berstatus 1
    if (typeof props["search"]["Status"] === "undefined") {
      props["search"]["Status"] = 1;
    }

    props.searchFieldType = searchFieldType;

    let URL = "/product/" + process.env.REACT_APP_OID;
    if (type === "popular") {
      props["sorting"] = "TotalPurchase-desc";
      URL = "/popularproduct/" + process.env.REACT_APP_OID;
    } else if (type === "latest") {
      props["sorting"] = "ID-desc";
      URL = "/latestproduct/" + process.env.REACT_APP_OID;
    } else if (type === "choice") {
      props["sorting"] = "ID-desc";
      URL = "/productchoice/" + process.env.REACT_APP_OID;
    }

    withTotalCount = typeof withTotalCount !== "undefined" && withTotalCount;

    let queryString = setQueryString(props);

    return Axios.get(URL + "?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        if (type === "popular") {
          data["data"] =
            typeof data.data.PopularProduct !== "undefined"
              ? data.data.PopularProduct
              : [];
        } else if (type === "latest") {
          data["data"] =
            typeof data.data.LatestProduct !== "undefined"
              ? data.data.LatestProduct
              : [];
        } else if (type === "choice") {
          data["data"] =
            typeof data.data.ProductChoices !== "undefined"
              ? data.data.ProductChoices
              : [];

          console.log(data);
        }

        // kalau pake total count, maka jalankan query count data
        if (withTotalCount) {
          return dispatch(
            fetchMartTotalProduct({
              ...props,
              items: data.data,
              message: data.message,
              type,
            })
          );
        }

        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(resultMartProductList(data.data));
      })
      .catch((error) => {
        dispatch(resultMartProductList(null));
        return dispatch(handleError(error));
      });
  };
};

// Request mart product from API
export const fetchMartProductById = (id) => {
  return (dispatch) => {
    dispatch(requestMartProduct());

    // LOCAL DATA
    // let localProduct = getLocalData("AGREGO_PRODUCTS",id);
    // if(localProduct != null && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartProduct(localProduct));
    // }

    return Axios.get("/product/" + process.env.REACT_APP_OID + "/" + id)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartProduct(data.data));
      })
      .catch((error) => {
        dispatch(resultMartProduct(null));
        return dispatch(handleError(error));
      });
  };
};

// Request mart product from API
export const fetchMartTotalProduct = ({
  search,
  operand,
  items,
  message,
  type,
}) => {
  return (dispatch) => {
    search = typeof search !== "undefined" ? search : "";
    let queryString = setFieldSearchQuery(search, "", searchFieldType);

    let URL = "/productcount/" + process.env.REACT_APP_OID;
    if (type === "popular") {
      URL = "/popularproductcount/" + process.env.REACT_APP_OID;
    } else if (type === "latest") {
      URL = "/latestproductcount/" + process.env.REACT_APP_OID;
    } else if (type === "choice") {
      URL = "/productchoicecount/" + process.env.REACT_APP_OID;
    }

    return Axios.get(URL + "?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(
          resultMartTotalProduct({ data: items, count: data.data.count })
        );
      })
      .catch((error) => {
        dispatch(resultMartTotalProduct({ data: null, count: 0 }));
        return dispatch(handleError(error));
      });
  };
};

// PRODUK POPULAR
export const fetchMartProductPopular = (props, withTotalCount) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;
  props.sorting = "TotalPurchase-desc";

  if (typeof props["search"] === "undefined") {
    props["search"] = {};
  }

  if (typeof props["search"]["Status"] === "undefined") {
    props["search"]["Status"] = 1;
  }

  withTotalCount = typeof withTotalCount !== "undefined" && withTotalCount;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartProductPopularList());

    // LOCAL DATA
    // let localProduct = getLocalData("AGREGO_PRODUCTS");
    // if(localProduct.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartTotalProduct({data: localProduct, count: 16}));
    // }

    return Axios.get(
      "/popularproduct/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        // kalau pake total count, maka jalankan query count data
        if (withTotalCount) {
          return dispatch(
            fetchMartTotalProductPopular({
              ...props,
              items: data.data,
              message: data.message,
            })
          );
        }

        let PopularProduct =
          typeof data.data.PopularProduct !== "undefined"
            ? data.data.PopularProduct
            : [];

        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(resultMartProductPopularList(PopularProduct));
      })
      .catch((error) => {
        dispatch(resultMartProductPopularList(null));
        return dispatch(handleError(error));
      });
  };
};

// Request mart product from API
export const fetchMartTotalProductPopular = ({
  search,
  operand,
  items,
  message,
}) => {
  return (dispatch) => {
    search = typeof search !== "undefined" ? search : "";
    let queryString = setFieldSearchQuery(search, "", searchFieldType);

    return Axios.get(
      "/popularproductcount/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(
          resultMartTotalProductPopular({ data: items, count: data.data.count })
        );
      })
      .catch((error) => {
        dispatch(resultMartTotalProductPopular({ data: null, count: 0 }));
        return dispatch(handleError(error));
      });
  };
};

// LATEST PRODUCT
export const fetchMartProductLatest = (props) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;
  props.sorting = "ID-desc";

  if (typeof props["search"] === "undefined") {
    props["search"] = {};
  }

  if (typeof props["search"]["Status"] === "undefined") {
    props["search"]["Status"] = 1;
  }

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartLatestProductList());

    // LOCAL DATA
    // let localProduct = getLocalData("AGREGO_PRODUCTS");
    // if(localProduct.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartTotalProduct({data: localProduct, count: 16}));
    // }

    return Axios.get(
      "/latestproduct/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        let LatestProduct =
          typeof data.data.LatestProduct !== "undefined"
            ? data.data.LatestProduct
            : [];

        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(resultMartProductLatestList(LatestProduct));
      })
      .catch((error) => {
        dispatch(resultMartProductLatestList(null));
        return dispatch(handleError(error));
      });
  };
};

// CHOICE PRODUCT
export const fetchMartProductChoice = (props, withoutCount) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;
  props.sorting = "ID-desc";

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartChoiceProductList());

    return Axios.get(
      "/productchoice/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        if (withoutCount) {
          return dispatch(resultMartProductChoiceList(data.data));
        }

        return dispatch(
          fetchMartProductChoiceCount({ items: data.data, query: queryString })
        );
      })
      .catch((error) => {
        dispatch(resultMartProductChoiceList(null));
        return dispatch(handleError(error));
      });
  };
};

// CHOICE PRODUCT COUNT
export const fetchMartProductChoiceCount = ({ items, query }, withoutCount) => {
  return (dispatch) => {
    dispatch(requestMartChoiceProductList());

    return Axios.get(
      "/productchoicecount/" + process.env.REACT_APP_OID + "?" + query
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartProductChoiceList(items, data.data));
      })
      .catch((error) => {
        dispatch(resultMartProductChoiceList(null));
        return dispatch(handleError(error));
      });
  };
};
