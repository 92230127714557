import React from 'react';
import { Container, Row, Col, Button, Form, Tab, Nav, Table } from 'react-bootstrap';
import './Pulsa.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Breadcrumb from '../../Layout/Breadcrumb';
import Loading from './../../Layout/Loading'; 
import { parseCurrency } from './../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';



const NotelpSchema = Yup.object().shape({
    Notelp: Yup.number()
        .typeError('Nomor Telepon harus berupa angka')
        .required('Nomor Telepon harus diisi')
        .min(10000000000, 'Nomor Telepon minimal 12 karakter')
        .max(999999999999, 'Nomor Telepon maksimal 13 karakter')

});


export default function ListData(props) {
    const { isLoading, providerProduct, providerResults, breadcrumb, cekProductID } = props;
    const iconClose = <FontAwesomeIcon className="btn-qty" icon={faTimesCircle} />
    var disabledbtn; 

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={NotelpSchema}
            initialValues={{ Notelp: '' }}
            onSubmit={(values) => {
                // ketika di submit data form di variable values dikirim ke parent component lewat props

                // kirim form ke parent lewat onSubmitProfile
                props.onChangeProvider(values);
            }}


        >
            {({ errors, touched, handleChange, handleBlur, handleSubmit, values, isSubmitting, setFieldValue, setFieldTouched }) => {

                const onChangeProvider = (e) => {

                    let convert = '';
                    let newNotelp = '';

                    if (e.target.value.slice(0, 2) === '62') {
                        newNotelp = e.target.value.slice(2);
                        convert = '0' + newNotelp;

                        props.onChangeProvider(convert)
                        setFieldValue('Notelp', convert)
                        setFieldTouched('Notelp', true, true)
                    }

                    else if (e.target.value.slice(0, 3) === '+62') {
                        newNotelp = e.target.value.slice(3);
                        convert = '0' + newNotelp;

                        props.onChangeProvider(convert)
                        setFieldValue('Notelp', convert)
                        setFieldTouched('Notelp', true, true)
                    }

                    else {

                        props.onChangeProvider(e.target.value)
                        setFieldValue('Notelp', e.target.value)


                    }

                }

                const onClearNotelp = (e) => {

                    props.onClearForm(e.target.value)
                    setFieldValue('Notelp', '')
                    setFieldTouched('Notelp', true, true)

                }

                if (errors['Notelp'] !== undefined) {
                    disabledbtn = true;
                }
                else {

                    disabledbtn = cekProductID;
                }

                return (
                    <Container fluid>
                        {/* Tab di hide sementara */}
                        {/* TAB MENU PPOB */}
                        <div className="d-none d-sm-block">
                                <Row style={{marginLeft:'54px',background:'#FFFF'}} >
                                    <Col md={2} className="ppob-custom-tab">
                                        <div className="ppob-custom-tab-Nav-Item-active">
                                            <a href="/digital/pulsa">Pulsa dan Data</a>
                                            </div>
                                    </Col>

                                    <Col md={2} className="ppob-custom-tab">
                                        <div className="ppob-custom-tab-Nav-Item">
                                            <a href="/digital/plnprepaid">Token Listrik</a>
                                            </div>
                                    </Col>

                                    <Col md={2} className="ppob-custom-tab">
                                        <div className="ppob-custom-tab-Nav-Item">
                                            <a href="/digital/plnpascabayar">Tagihan Listrik</a>
                                            </div>
                                    </Col>


                                    {/* <Col md={2} className="ppob-custom-tab">
                                        <div className="ppob-custom-tab-Nav-Item">
                                            <a href="/digital/bpjs">BPJS</a>
                                            </div>
                                    </Col>  */}
                                </Row>
                            </div>

                             {/* TAMPILAN MOBILE */}
                        <div className="d-block d-sm-none">

                        <div className="scrollmenu">
                            <a className="scrollmenu-active" href="/digital/pulsa">Pulsa dan Data</a>
                            <a href="/digital/plnprepaid">Token Listrik</a>
                            <a href="/digital/plnpascabayar">Tagihan Listrik</a>
                        
                            {/* <a href="/digital/bpjs">BPJS</a> */}
                            </div>

                            </div>


                        <Row className="RowPulsa">

                            {/* FORM PULSA  */}
                            <Col md={5}>
                                <div style={{ marginTop: '20px', paddingBottom: '0px' }}>
                                    {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                                </div>

                                <div className="pulsaContainer">
                                    <div className="pulsaJudul">Isi Pulsa dan Paket Data</div>
                                    <Form.Group className="form-pulsa" id="formpulsa">
                                        <Form.Label className="pulsaLabel-form">Nomor Telepon</Form.Label>

                                        <Form.Control type="text"
                                            name="Notelp"
                                            onChange={onChangeProvider}
                                            onBlur={handleBlur}
                                            value={localStorage.AGREGO_USER_PHONE}

                                        >

                                        </Form.Control>

                                        {localStorage.AGREGO_USER_PHONE !== '' && <Button variant="link" className="btnClose" onClick={onClearNotelp} >{iconClose}</Button>}

                                    </Form.Group>

                                    {errors.Notelp && 
                                        <div className="errorText">
                                            <Form.Text className="text-danger">
                                                {errors.Notelp}
                                            </Form.Text>
                                        </div>}


                                    {/* Loading State */}
                                    {isLoading && !localStorage.AGREGO_PROVIDER_PRODUCT && <Loading />}

                                    {/* {!isLoading && digitalBrand.length <= 0 && <div>Provider tidak ditemukan</div>} */}

                                    {/* TAMPILAN MOBILE */}

                                    <div className="d-block d-sm-none">

                                        <div className="providerResult">
                                            <img src={localStorage.AGREGO_PROVIDER_PRODUCTIMG} width="41px" alt="img-provider" />&nbsp;{localStorage.AGREGO_PROVIDER_PRODUCT}
                                        </div>

                                    </div>

                                    {/* TAMPILAN WEB */}

                                    <div className="d-none d-sm-block">
                                        <div className="providerResult">
                                            <img src={localStorage.AGREGO_PROVIDER_PRODUCTIMG} height="60px" alt="img-provider"></img>
                                        </div>


                                    </div>


                                </div>


                            </Col>



                            {/* LIST PULSA */}
                            <Col md={7}>

                                <div className="pulsaTabsContainer">

                                    <Tab.Container >
                                        <Row >
                                            <Nav className="pulsa-custom-tab">

                                                <Col className="pulsa-custom-tab-Nav-Item" >

                                                    <Nav.Item >
                                                        {providerResults !== null &&
                                                            <Nav.Link className="pulsa-custom-tab-Nav-Link" eventKey="first" href={"/digital/pulsa"}>Pulsa</Nav.Link>
                                                        }
                                                    </Nav.Item>
                                                </Col>



                                                <Col className="pulsa-custom-tab-Nav-Item-active">
                                                    <Nav.Item>
                                                        <Nav.Link className="pulsa-custom-tab-Nav-Link" href={"/digital/pulsa/listdata"}>Data</Nav.Link>
                                                    </Nav.Item>

                                                </Col>

                                            </Nav>

                                        </Row>


                                        <Tab.Content>
                                            <div className="listContainer">

                                                <Table className="pulsaListContainer">

                                                    <tbody>
                                                        {/* Loading State */}
                                                        {isLoading && <Loading />}

                                                        {!isLoading && providerProduct.length <= 0 && <div style={{ textAlign: 'center', marginTop: '20px' }}>Produk Tidak Tersedia</div>}
                                                        {providerProduct.length > 0 && !isLoading && providerProduct.map(item => (

                                                            <tr className="trBorder">
                                                                <td>
                                                                <div>
                                                                   
                                                                    <Form.Group controlid="formSelectPulsa" >

                                                                        <Form.Check onChange={(e) => props.onChangeProductDigital({ Reference: localStorage.AGREGO_USER_PHONE, ProductID: item.ID, Name: item.Name, Price: item.Price, Description: item.Description, Image: localStorage.AGREGO_PROVIDER_PRODUCTIMG, ProviderName: localStorage.AGREGO_PROVIDER_PRODUCT })} type="radio" name="formSelectPulsa" id={item.ID} />

                                                                    </Form.Group>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="dataListJudul">{item.Name}</div>
                                                                    <div className="pulsaListDesk">{item.Description}</div>

                                                                </td>

                                                                <td><div className="dataPrice">Rp&nbsp;{parseCurrency(item.Price)}</div></td>

                                                            </tr>


                                                        ))}
                                                    </tbody></Table>
                                            </div>


                                        </Tab.Content>

                                    </Tab.Container>
                                </div>


                                {/* TAMPILAN MOBILE */}
                                {!isLoading && providerProduct.length > 0 &&
                                    <div className="product-summary-bottom d-sm-block d-md-none">
                                        <Row>
                                            <Col md={4} xs={12}>

                                                <Button variant="primary" className="btn-Pulsa" href={"/digital/confirmation"} disabled={disabledbtn} >Lanjut</Button>

                                            </Col>
                                        </Row>
                                    </div>


                                }

                                {/* TAMPILAN WEB */}

                                {!isLoading && providerProduct.length > 0 &&

                                    <div className="d-none d-sm-block">

                                        <Row>
                                            <Col md={4} xs={12}>
                                                <div className="btnStylePulsa">
                                                    <Button variant="primary" className="btn-Pulsa" href={"/digital/confirmation"} disabled={disabledbtn} >Lanjut</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                }




                            </Col>
                        </Row>

                    </Container>)
            }}


        </Formik>)
}