import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { parseCurrency, parseDateTime } from './../../utils/common';
import Breadcrumb from './../Layout/Breadcrumb';
import Loading from './../Layout/Loading';
import CopyButton from './../Layout/CopyButton';
import './../Mart/Order/Order.css';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OrderPayment(props) {

    const { isLoading, order, breadcrumb, includeComponent } = props; 
    const uniqueNum = order != null && !isNaN(parseInt(order.UniqueNum)) ? parseInt(order.UniqueNum) : 0;
    const totalPrice = order != null ? parseInt(order.Nominal) + parseInt(uniqueNum) : 0; 
    const lastTotal = order != null ? (totalPrice + "").substr(-3, 3) : "";
    const firstTotal = order != null ? (totalPrice + "").substr(0, (totalPrice + "").length - 3) : 0; 

    const layout = {
        span: typeof (includeComponent) !== 'undefined' ? 12 : 10,
        offset: typeof (includeComponent) !== 'undefined' ? 0 : 1
    }

    return (
        <Container>

            {isLoading && <Loading />}

            {!isLoading && <div style={{ marginTop: typeof (includeComponent) !== 'undefined' ? '0px' : '40px' }}>
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
            </div>}

            <Row>
                <Col md={layout}>
                    {order != null &&
                        <div className="order-payment-card" style={{maxWidth:'657px', margin:'auto', padding:'32px 48px'}}>

                            <div className="section-title-detail">Detail Pembayaran</div>
                            <div className="info-detail-pembayaran">
                                <Row>
                                    <Col>Total Pembayaran</Col>
                                    <Col className="item-price">Rp {parseCurrency(order.Nominal)}</Col>
                                </Row>  
                                <Row>
                                    <Col>Kode Unik</Col>
                                    <Col className="item-price">{order.UniqueNum}</Col>
                                </Row>

                                <div style={{ margin: '16px 0', borderBottom: '1px solid #ccc', height: '1px' }}>&nbsp;</div>

                                <Row>
                                    <Col className="font-weight-bold">Total Transfer</Col>
                                    <Col className="item-price">Rp {parseCurrency(totalPrice)}</Col>
                                </Row>
                            </div>

                            {/* <Row>
                                <Col>Nomor Pesanan</Col>
                                <Col className="order-number">#{order.ID}</Col>
                            </Row> */}

                            <div className="section-title">Transfer Sejumlah</div>
                            <div className="text-center" style={{position:'relative', margin:'10px auto', maxWidth:'345px'}}> 
                                <span className="order-price">Rp  {parseCurrency(firstTotal)}.<span className="text-danger">{lastTotal}</span></span>
                                {/* <span className="order-price">Rp {parseCurrency(totalPrice)}</span> */}
                                <span><CopyButton style={{position:'absolute',right:0,top:0}} variant="outline-success" size="sm" copyText={parseInt(totalPrice)}>Salin</CopyButton></span>
                            </div>
                            <div className="info-transfer">
                                <span className="text-danger"><FontAwesomeIcon icon={faExclamationCircle} /> Penting!</span> Mohon transfer tepat sampai 3 angka terakhir
                            </div>

                            {/* <div className="line">&nbsp;</div>

                            <div className="section-title">Sebelum tanggal</div>
                            <div className="transfer-deadline">{parseDateTime(order.TransferDeadline)} WIB</div> */}

                            <div className="line">&nbsp;</div>

                            <div className="section-title" style={{ paddingBottom: "16px" }}>Transfer ke</div>
                            <div className="text-center">  
                                <img src={order.BankImage} height={64} alt={"image " + order.BankName} />
                                <div className="account-number" style={{position:'relative', margin:'8px auto', maxWidth:'345px'}}>
                                    {order.BankAccountNumber} 
                                    <span><CopyButton style={{position:'absolute',right:0,top:'-12px'}} variant="outline-success" size="sm" copyText={order.BankAccountNumber} >Salin</CopyButton></span>
                                </div>
                                <div className="account-name" style={{maxWidth:'300px', margin:'auto'}}>(a/n {order.BankAccountName} - {order.BankAccountBranch})</div>
                            </div>

                            <div style={{paddingTop:'15px', maxWidth:'355px',margin:'auto'}} className="text-center"> Transfer sebelum <span style={{fontWeight:'bold'}}>{parseDateTime(order.TransferDeadline)} WIB</span> atau transaksi Anda dibatalkan oleh sistem</div>
                             
                            {order != null && parseInt(order.PaymentStatus) === 0 && isNaN(parseInt(order.PaymentConfirmationID)) && order.PaymentType === "Bank" && <div className="text-center" style={{marginTop:'55px'}}>
                                <Button variant="link" onClick={(e) => props.onOpenModal(true, { type: "UPLOAD_BUKTI_BAYAR", id: order.ID })}> Upload Bukti Pembayaran</Button>
                            </div>} 

                            {/* <div className="line">&nbsp;</div> */} 
                            {typeof (includeComponent) === "undefined" && <div className="text-center"><Button style={{maxWidth:'311px', marginBottom:'10px'}} variant="primary" className="btn-mart" href="/wallet">Selesai</Button></div>}
                            {order != null && parseInt(order.Status) <= 1 && parseInt(order.PaymentStatus) === 0 && <div className="text-center">
                                <Button variant="outline-danger" size="sm" onClick={(e) => props.onOpenModal(true, { type: "CANCEL_ORDER", id: order.ID })}> Batalkan Isi Saldo</Button>
                            </div>}
                        </div>}
                </Col>
            </Row>
        </Container>
    )
}

export default OrderPayment;