import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import Pagination from "react-js-pagination";
import locationset from "./../../../Images/set-location.png";
import locationunset from "./../../../Images/unset-location.png";
import EmptyState from "./../../Layout/EmptyState";

export default function Alamat({
  profile,
  addressList,
  loadingAddress,
  handleDeleteAddress,
  onSelectAddress,
  currentPage,
  itemPerPage,
  totalAddress,
  ...props
}) {
  // const [isLoading, setIsLoading] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    // setIsLoading(false)
    // localStorage.removeItem(process.env.REACT_APP_PREFIX+'ADDRESS_FORM');

    ReactGA.set({ title: "address_list" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <div className="address-container">
            <div className="address-header">
              <Button
                variant="link"
                className="btn-add-address"
                href={props.addressUrl + "create"}
              >
                + Tambah Alamat Baru
              </Button>
              <div className="clearfix"></div>
            </div>

            {!loadingAddress && addressList && addressList.length <= 0 && (
              <div className="text-center">
                <EmptyState teks="Belum ada data" />
              </div>
            )}

            <div className="address-list mt-5">
              {!loadingAddress &&
                addressList &&
                addressList.length > 0 &&
                addressList.map((address, index) => {
                  return (
                    <div key={index}>
                      <Row>
                        <Col md={6}>
                          {onSelectAddress && (
                            <Form.Check
                              type="radio"
                              label={
                                <>
                                  <div className="Address-Labels">
                                    {address.Recipient}
                                  </div>
                                  <div className="Address-Phone">
                                    {address.Phone}
                                  </div>
                                  <div className="Address-Address">
                                    {address.Address}
                                    {address.PostalCode !== "" &&
                                      ", " + address.PostalCode}

                                    <p>
                                      {address.SubDistrict}
                                      {address.City !== "" &&
                                        ", " + address.City}
                                      {address.Province !== "" &&
                                        ", " + address.Province}
                                    </p>
                                  </div>
                                </>
                              }
                              name="address"
                              checked={
                                selectedAddress &&
                                parseInt(selectedAddress.ID) ===
                                  parseInt(address.ID)
                              }
                              onChange={(e) => setSelectedAddress(address)}
                              id={"address" + address.ID}
                            />
                          )}

                          {!onSelectAddress && (
                            <>
                              <div className="Address-Labels">
                                {address.Recipient}
                              </div>
                              <div className="Address-Phone">
                                {address.Phone}
                              </div>
                              <div className="Address-Address">
                                {address.Address}
                                {address.PostalCode !== "" &&
                                  ", " + address.PostalCode}

                                <p>
                                  {address.SubDistrict}
                                  {address.City !== "" && ", " + address.City}
                                  {address.Province !== "" &&
                                    ", " + address.Province}
                                </p>
                              </div>
                            </>
                          )}
                        </Col>
                        <Col md={6} xs={12} style={{ position: "relative" }}>
                          <Button
                            className="Address-Edit-btn"
                            href={props.addressUrl + address.ID}
                          >
                            Ubah
                          </Button>
                          <Button
                            className="Address-Delete-btn"
                            onClick={(e) =>
                              props.onOpenModal(true, {
                                type: "DELETE_ADDRESS",
                                id: address.ID,
                              })
                            }
                          >
                            Hapus
                          </Button>

                          {address && (
                            <div
                              className={
                                (address["Latitude"]
                                  ? "text-primary"
                                  : "text-secondary") + " address-pinpoint-flag"
                              }
                            >
                              Pinpoint Alamat{" "}
                              <img
                                style={{ marginLeft: "8px" }}
                                src={
                                  address["Latitude"]
                                    ? locationset
                                    : locationunset
                                }
                                alt="img-pinpoint"
                                width={16}
                                height={16}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  );
                })}

              {/* {!isLoading && addressList != null && addressList.length > 0 && parseInt(totalOrder) > parseInt(itemPerPage) && <Row className="justify-content-center" style={{margin:'10px 0px'}}><Pagination */}
              {((addressList &&
                addressList.length > 0 &&
                parseInt(totalAddress) > parseInt(itemPerPage)) ||
                parseInt(currentPage) > 1) && (
                <Row
                  className="justify-content-center"
                  style={{ margin: "10px 0px" }}
                >
                  <Pagination
                    // activePage={activePage}
                    activePage={currentPage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={totalAddress}
                    pageRangeDisplayed={3}
                    onChange={props.onChangePage}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </Row>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {onSelectAddress && addressList && addressList.length > 0 && (
        <>
          <Button
            className="float-right d-none d-md-block"
            onClick={(e) => onSelectAddress(selectedAddress)}
            disabled={!selectedAddress}
          >
            {" "}
            Pilih Alamat
          </Button>

          <div style={{ height: "100px" }}>&nbsp;</div>

          {/* Mobile view fixed in bottom */}
          <div className="product-summary-bottom d-sm-block d-md-none">
            <Button
              variant="primary"
              onClick={(e) => onSelectAddress(selectedAddress)}
              disabled={!selectedAddress}
              block
            >
              Pilih Alamat
            </Button>
          </div>
        </>
      )}

      {/* <BottomNav activeKey={5} /> */}
    </>
  );
}
