import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './OrderDigital.css'; 
import Breadcrumb from '../../Layout/Breadcrumb'; 
import { parseCurrency } from './../../../utils/common'; 
import { isCompletedParamOrderDigital } from '../../../utils/cart';


export default function DigitalConfirmation(props) {
  const { breadcrumb, CartDigital, paymentNote } = props; 
  let isAllowOrder = false;

  // cek apakah sudah bisa melakukan order
  if(CartDigital != null) {
    isAllowOrder = isCompletedParamOrderDigital(CartDigital);
  }

  
  return ( 
    <Container>
      <Row>
        <Col>
          <div style={{ marginTop: '30px', paddingBottom: '0px' }}>
            {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
          </div>



          <div className="RincianPesananContainer">

            {/* {CartDigital.length > 0 && !isLoading && CartDigital.map(item => (
                         */}

            {CartDigital != null &&

              <Row>

                <Col md={12} xs={12}>
                  <div className="RincianPesananJudul">Rincian Pesanan</div>

                  <div style={{ marginTop: '0px', marginLeft: '45px' }}>

                    <Row>
                      <Col md={1} xs={4}><img src={CartDigital.Image} width="70px" alt="img-digital"></img></Col>

                      <Col md={7} xs={8}>
                        <div className="RincianpulsaListJudul">{localStorage.AGREGO_USER_PHONE}</div>
                        <div className="RincianpulsaListDesk">{CartDigital.Name}</div>
                      </Col>
                      <Col md={4} xs={10}><div className="rincianPrice">Rp &nbsp;{parseCurrency(CartDigital.Price)}</div></Col>
                    </Row>

                  </div>

                </Col>

              </Row>
            }
            {/* ))}
                      */}
          </div>

        </Col>
      </Row>

      <div className="PaymentContainer">

        {/* {CartDigital.length > 0 && !isLoading && CartDigital.map(item => (
                         */}


        <Row>

          <Col md={12} xs={12}>

            <div className="RincianPesananJudul">Metode Pembayaran</div>

            <div style={{ marginTop: '0px', marginLeft: '45px' }}>

              {CartDigital != null && typeof (CartDigital.PaymentType) !== "undefined" && CartDigital.PaymentType != null ?
                <div style={{ marginTop: '0px' }}>
                  {CartDigital.PaymentType === "Bank" ?

                    <Row>
                      <Col md={2} xs={4}><img src={CartDigital.BankImage} width="100%" alt="img-bank" /></Col>
                      <Col md={6} xs={8}><div className="payment-box">{CartDigital.BankName}</div></Col>
                      <Col md={4} xs={12}><div className="paymentLink"><a href={"/shop/payment?type=digital"}>Ubah Metode Pembayaran</a></div></Col>
                    </Row> :

                    <Row>
                      {/* <Col md={1} xs={4} style={{paddingLeft:'4%'}} ><FontAwesomeIcon icon={faWallet} size="2x" /></Col> */}
                        <Col md={8} xs={12}><div className="payment-box">{CartDigital.PaymentType}&nbsp;({CartDigital.Payment})
                        <div className="payment-note"> {paymentNote} </div>
                      </div>
                      </Col>
                      <Col md={4} xs={12}><div className="paymentLink"><a  href={"/shop/payment?type=digital"}>Ubah Metode Pembayaran</a></div></Col>

                    </Row>}</div> :

                <div style={{ marginTop: '20px', marginLeft: '0px' }}>

                  <Row>

                    <Col md={8} xs={12}>
                      <div style={{ marginBottom: '40px' }}>Metode pembayaran belum ditentukan</div></Col>
                    <Col md={4} xs={12}><a href={"/shop/payment?type=digital"}>Pilih Metode Pembayaran</a></Col>

                  </Row>
                </div>
              }

            </div>

          </Col>
        </Row>
      </div> 
      
      {/* TAMPILAN MOBILE */}
      <div className="product-summary-bottom d-sm-block d-md-none">
               
      <Row>
        <Col md={4}>
            <Button variant="primary" className="Pulsa-btn" onClick={e => props.onSubmitOrder()} disabled={!isAllowOrder} >Lanjut</Button>
          
        </Col>
      </Row>
      </div>

    {/* TAMPILAN WEB */}
    <div className="d-none d-sm-block">

      <Row>
        <Col md={4}>
          <div className="btnStyle">
            <Button variant="primary" className="Pulsa-btn" onClick={e => props.onSubmitOrder()}  disabled={!isAllowOrder}>Lanjut</Button>
          </div>
        </Col>
      </Row>

      </div>

    </Container>

  );
}