import _ from "lodash";
import qs from "query-string";
import React from "react";
// import Navbarlogo from './../../Images/logo-white.png';
import {
  Badge,
  Button,
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import icpesanan from "../../Images/ic-cart-white.png";
import icsearchnav from "../../Images/ic-search-white.png";
import ickeanggotaan from "../../Images/ickeanggotaan.png";
import icsearch from "../../Images/icsearch.png";
import icdeposit from "./../../Images/deposit-icon.png";
import icbantuan from "./../../Images/ic-anggota-bantuan.png";
import iclogout from "./../../Images/ic-anggota-logout.png";
import icnews from "./../../Images/ic-anggota-news.png";
import icorder from "./../../Images/ic-anggota-pesanan.png";
import icpassword from "./../../Images/ic-anggota-ubah-password.png";
import icsearchcategory from "./../../Images/ic-search-category.png";
import icsearchproduct from "./../../Images/ic-search-product.png";
import icsearchstore from "./../../Images/ic-search-store.png";
import { closeSearch, openSearch } from "./../../redux/actions/app";
import { getCart } from "./../../redux/actions/mart_cart";
import { parsePhoneNumber } from "./../../utils/common";
import { isLogin } from "./../../utils/JWTAuth";
import Keanggotaan from "./Keanggotaan";
import "./TopNav.css";

var searchTimeout;
// import image tambahkan variable dari env sebelum nama image
var Navbarlogo = require(`./../../${process.env.REACT_APP_THEME_IMAGE}logo-white.png`);

class TopNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      carts: [],
      cartCount: 0,
      searching: false,
      searchingKeyword: "",
      path: null,
      openSuggestion: false,
      openMobileSearch: false,
      hideTopCartInPath: [
        "/shop/delivery",
        "/shop/cart",
        "/shop/courier",
        "/shop/payment",
        "/shop/order-confirmation",
      ],
    };

    this.loadData = this.loadData.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.toggleSearchMobile = this.toggleSearchMobile.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const { cartCount, path } = this.state;
    const { carts } = this.props.martcart;
    const { items } = carts;
    const { pathname } = this.props.location;
    let { hideTopCartInPath } = this.state;

    // order completed
    if (pathname != null) {
      let exppath = pathname.split("/");

      if (exppath[2] === "order" && exppath[4] === "completed") {
        hideTopCartInPath.push(pathname);
      }
    }

    if (pathname !== path) {
      this.setState({ path: pathname });
    }

    let count = 0;

    if (typeof items !== "undefined" && items != null && items.length > 0) {
      count = _.sumBy(items, (o) => parseInt(o.Qty));
    }

    if (!isNaN(count) && parseInt(cartCount) !== parseInt(count)) {
      this.setState({ isLoading: true, cartCount: count });

      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 3000);
    }
  }

  loadData() {
    this.props.getCart();
    let { keyword } = qs.parse(this.props.location.search);

    this.setState({
      isLoading: true,
      searchingKeyword: keyword,
    });
  }

  handleChangeSearch(e) {
    let value = e.target.value;
    let openSuggestion = false;

    if (value !== "") {
      openSuggestion = true;
    }

    this.setState({
      searching: false,
      searchingKeyword: value,
      openSuggestion: openSuggestion,
    });

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      // this.props.onSearch(value);
      // this.setState({searching: true});
      // this.handleSearch(e);
    }, 3000);
  }

  handleSearch(e, url) {
    e.preventDefault();
    const { searchingKeyword } = this.state;

    ReactGA.event({
      category: "engagement",
      action: "search",
      value: searchingKeyword,
    });

    this.setState({ openSuggestion: false });

    clearTimeout(searchTimeout);
    if (typeof url !== "undefined") {
      this.props.history.push(url);
    } else if (searchingKeyword !== "" && searchingKeyword !== " ") {
      this.props.history.push(
        "/shop/product?type=search&keyword=" + searchingKeyword
      );
    }
  }

  toggleSearchMobile(isOpen) {
    if (isOpen) {
      this.props.openSearch();
      this.setState({ openMobileSearch: true });
    } else {
      this.props.closeSearch();
      this.setState({ openMobileSearch: false, openSuggestion: false });
    }
  }

  render() {
    const {
      cartCount,
      isLoading,
      searchingKeyword,
      path,
      hideTopCartInPath,
      openSuggestion,
      openMobileSearch,
    } = this.state;
    let csphone = localStorage.getItem("AGREGO_CSPHONE");
    if (csphone != null && typeof csphone !== "undefined") {
      csphone = parsePhoneNumber(csphone);
    }

    return (
      <React.Fragment>
        <Navbar
          bg="primary"
          expand="lg"
          variant="dark"
          fixed="top"
          className="app-topnav"
        >
          <Navbar.Brand href="/beranda" className="ml-md-3">
            <img src={Navbarlogo} height={32} alt="agrego" />
          </Navbar.Brand>
          <Nav
            className={
              path != null && hideTopCartInPath.includes(path)
                ? "d-none"
                : "d-lg-none"
            }
          >
            <Nav.Link href="/shop/cart" className="top-menu-cart">
              <div className="app-menu-cart">
                {cartCount > 0 && (
                  <Badge pill variant="danger" className="cart-count">
                    {cartCount}
                  </Badge>
                )}
                {!isLoading && (
                  <img
                    src={icpesanan}
                    alt="keranjang"
                    className={"bounceEffect"}
                    height={20}
                  />
                )}

                {isLoading && (
                  <img src={icpesanan} alt="keranjang" height={20} />
                )}
              </div>
            </Nav.Link>
            <Nav.Link
              onClick={(e) => this.toggleSearchMobile(true)}
              className="top-menu-search"
            >
              <img src={icsearchnav} alt="keranjang" height={20} />
            </Nav.Link>
          </Nav>

          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" children={<FontAwesomeIcon icon={faSearch} />} /> */}
          <Navbar.Collapse id="responsive-navbar-nav" className="mr-lg-3">
            <Form
              onSubmit={(e) => this.handleSearch(e)}
              className={
                "mr-auto ml-lg-5 topnav-form " +
                (openSuggestion ? "top-search-active" : "")
              }
            >
              <FormControl
                type="text"
                placeholder="Cari Produk"
                name="keyword"
                onChange={(e) => this.handleChangeSearch(e)}
                value={searchingKeyword}
                className="mr-sm-2 topnav-form"
              />
              <img
                src={icsearch}
                alt="search"
                className="topnav-search-icon"
                width={16}
              />
              <Button
                onClick={(e) => this.handleSearch(e)}
                variant="link"
                className="topnav-search-btn"
              >
                Cari
              </Button>

              <ul
                className={
                  "search-suggestion-web " + (!openSuggestion && "d-none")
                }
              >
                <li>
                  <div
                    className="link"
                    onClick={(e) =>
                      this.handleSearch(
                        e,
                        "/shop/product?type=search&keyword=" + searchingKeyword
                      )
                    }
                  >
                    <img src={icsearchproduct} alt="cari produk" />
                    Pencarian Produk "{searchingKeyword}"
                  </div>
                </li>
                <li>
                  <div
                    className="link"
                    onClick={(e) =>
                      this.handleSearch(e, "/store?keyword=" + searchingKeyword)
                    }
                  >
                    <img src={icsearchstore} alt="cari toko" />
                    Pencarian Toko "{searchingKeyword}"
                  </div>
                </li>
                <li>
                  <div
                    className="link"
                    onClick={(e) =>
                      this.handleSearch(
                        e,
                        "/shop/product?type=cat&keyword=" + searchingKeyword
                      )
                    }
                  >
                    <img src={icsearchcategory} alt="cari kategori" />
                    Pencarian Kategori "{searchingKeyword}"
                  </div>
                </li>
              </ul>
            </Form>

            <Nav className="topnav-nav">
              <Nav.Link href="/promo">Promo</Nav.Link>
              <Nav.Link
                href="/shop/cart"
                className={
                  path != null && hideTopCartInPath.includes(path)
                    ? "d-none"
                    : "d-lg-block"
                }
              >
                <div className="app-menu-cart">
                  {cartCount > 0 && (
                    <Badge pill variant="danger" className="cart-count">
                      {cartCount}
                    </Badge>
                  )}
                  {!isLoading && (
                    <img
                      src={icpesanan}
                      alt="keranjang"
                      className={"bounceEffect"}
                      height={20}
                    />
                  )}

                  {isLoading && (
                    <img src={icpesanan} alt="keranjang" height={20} />
                  )}
                </div>
              </Nav.Link>

              {/* jika sudah login tampilkan menu profil kalau belum tampilkan login dan daftar */}
              {isLogin() ? (
                <NavDropdown
                  className="dropdown-anggota"
                  title={
                    <img src={ickeanggotaan} alt="keanggotaan" height={20} />
                  }
                  id="basic-nav-dropdown2"
                >
                  <NavDropdown.Item
                    className="dropdown-item-kartu"
                    href="/profile"
                  >
                    {" "}
                    <Keanggotaan />{" "}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    className="dropdown-title"
                    disabled
                    style={{ color: "#000" }}
                  >
                    Lainnya
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/shop/order">
                    <img src={icorder} alt="pesanan" />
                    <div className="dropdown-item-text">Pesanan</div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/berita">
                    <img src={icnews} alt="berita" />
                    <div className="dropdown-item-text">Berita</div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/profile/change-password">
                    <img src={icpassword} alt="berita" />
                    <div className="dropdown-item-text">Ubah Password</div>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/wallet">
                    <img src={icdeposit} alt="deposit" />
                    <div className="dropdown-item-text">Deposit</div>
                  </NavDropdown.Item>

                  {/* <NavDropdown.Item href="/shop/order">Riwayat Transaksi</NavDropdown.Item>  */}
                  {/* <NavDropdown.Item href="/profile">Ubah Password</NavDropdown.Item>  */}
                  <NavDropdown.Item
                    target="_blank"
                    disabled={csphone == null || typeof csphone === "undefined"}
                    onClick={(e) => {
                      ReactGA.event({
                        category: "customercare",
                        action: "contact_cs",
                      });

                      return (window.location.href =
                        "https://wa.me/" + csphone + "?text=Hallo");
                    }}
                  >
                    <img src={icbantuan} alt="bantuan" />
                    <div className="dropdown-item-text">Bantuan</div>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={(e) => {
                      ReactGA.event({
                        category: "membership",
                        action: "logout",
                      });

                      return this.props.history.push("/logout");
                    }}
                  >
                    <img src={iclogout} alt="keluar" />
                    <div className="dropdown-item-text">Keluar</div>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavDropdown
                  className="dropdown-anggota-guest"
                  title={
                    <img src={ickeanggotaan} alt="keanggotaan" height={20} />
                  }
                  id="basic-nav-dropdown2"
                >
                  <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                  <NavDropdown.Item href="/register">Register</NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* jarak agar content tidak tertutup navbar fixed */}
        <div style={{ height: "55px" }}>&nbps;</div>

        {/* Mobile Search */}
        {openMobileSearch && (
          <div className="mobile-search-container d-lg-none">
            <Form
              onSubmit={(e) => this.handleSearch(e)}
              className={
                "mr-auto topnav-form-mobile " +
                (openSuggestion ? "top-search-active" : "")
              }
            >
              <FormControl
                type="text"
                placeholder="Cari Produk"
                name="keyword"
                onChange={(e) => this.handleChangeSearch(e)}
                value={searchingKeyword}
                className="mr-sm-2 topnav-form-mobile"
              />
              <img
                src={icsearch}
                alt="search"
                className="topnav-search-icon"
                width={16}
              />
              <Button
                onClick={(e) => this.toggleSearchMobile(false)}
                variant="link"
                className="topnav-search-btn"
              >
                Tutup
              </Button>
            </Form>
          </div>
        )}

        {openSuggestion && (
          <ul className={"search-suggestion-web d-lg-none"}>
            <li>
              <div
                className="link"
                onClick={(e) =>
                  this.handleSearch(
                    e,
                    "/shop/product?type=search&keyword=" + searchingKeyword
                  )
                }
              >
                <img src={icsearchproduct} alt="cari produk" />
                Pencarian Produk "{searchingKeyword}"
              </div>
            </li>
            <li>
              <div
                className="link"
                onClick={(e) =>
                  this.handleSearch(e, "/store?keyword=" + searchingKeyword)
                }
              >
                <img src={icsearchstore} alt="cari toko" />
                Pencarian Toko "{searchingKeyword}"
              </div>
            </li>
            <li>
              <div
                className="link"
                onClick={(e) =>
                  this.handleSearch(
                    e,
                    "/shop/product?type=cat&keyword=" + searchingKeyword
                  )
                }
              >
                <img src={icsearchcategory} alt="cari kategori" />
                Pencarian Kategori "{searchingKeyword}"
              </div>
            </li>
          </ul>
        )}

        {openSuggestion && <div className="app-bg-dark">&nbsp;</div>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { martcart, app } = state;
  return { martcart, app };
};

export default withRouter(
  connect(mapStateToProps, {
    getCart,
    openSearch,
    closeSearch,
  })(TopNav)
);
