import Axios from "axios";
import { setFieldSearchQuery, setQueryString } from "./../../utils/common";
import { handleError } from "./app";

export const REQUEST_MARTSTORE_LIST = "REQUEST_MARTSTORE_LIST";
export const REQUEST_MARTSTORE = "REQUEST_MARTSTORE";
export const REQUEST_MARTSTORE_NEARBY = "REQUEST_MARTSTORE_NEARBY";
export const RESULT_MARTSTORE_LIST = "RESULT_MARTSTORE_LIST";
export const RESULT_MARTSTORE = "RESULT_MARTSTORE";
export const RESULT_MARTSTORE_NEARBY = "RESULT_MARTSTORE_NEARBY";
export const RESULT_MARTSTORE_TOTAL_STORE = "RESULT_MARTSTORE_TOTAL_STORE";

const searchFieldType = { ID: "int" };

const requestMartStoreList = () => ({
  type: REQUEST_MARTSTORE_LIST,
});
const requestMartStore = () => ({
  type: REQUEST_MARTSTORE,
});
const requestNearbyStore = () => ({
  type: REQUEST_MARTSTORE_NEARBY,
});

const resultMartStoreList = (data) => ({
  type: RESULT_MARTSTORE_LIST,
  payload: {
    data,
  },
});
const resultMartStore = (data) => ({
  type: RESULT_MARTSTORE,
  payload: {
    data,
  },
});
const resultNearbyStore = (data) => ({
  type: RESULT_MARTSTORE_NEARBY,
  payload: {
    data,
  },
});

const resultMartStoreTotalStore = (data) => ({
  type: RESULT_MARTSTORE_TOTAL_STORE,
  payload: {
    data: data.data,
    count: data.count,
  },
});

// Request mart category from API
export const fetchMartStore = (props, withTotalCount) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  if (typeof props["sorting"] === "undefined") {
    props["sorting"] = "Name-asc";
  }

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartStoreList());

    return Axios.get("/store/" + process.env.REACT_APP_OID + "?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        if (withTotalCount) {
          return dispatch(
            fetchMartTotalProduct({
              ...props,
              items: data.data,
              message: data.message,
            })
          );
        }

        return dispatch(resultMartStoreList(data.data));
      })
      .catch((error) => {
        dispatch(resultMartStoreTotalStore({ data: [] }));
        return dispatch(handleError(error));
      });
  };
};

// Request mart product from API
export const fetchMartTotalProduct = ({ search, operand, items, message }) => {
  return (dispatch) => {
    search = typeof search !== "undefined" ? search : "";
    let queryString = setFieldSearchQuery(search, "", searchFieldType);

    let URL = "/storecount/" + process.env.REACT_APP_OID;

    return Axios.get(URL + "?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(
          resultMartStoreTotalStore({ data: items, count: data.data.count })
        );
      })
      .catch((error) => {
        dispatch(resultMartStoreTotalStore({ data: items, count: null }));
        return dispatch(handleError(error));
      });
  };
};

// Request mart category from API
export const fetchMartStoreByID = (ID) => {
  return (dispatch) => {
    dispatch(requestMartStore());

    return Axios.get("/store/" + process.env.REACT_APP_OID + "/" + ID)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartStore(data.data));
      })
      .catch((error) => {
        dispatch(resultMartStore({ data: null }));
        return dispatch(handleError(error));
      });
  };
};

// Request mart category from API
export const fetchNearbyStores = (props, withTotalCount) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  // if (typeof props["sorting"] === "undefined") {
  //   props["sorting"] = "Name-asc";
  // }

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestNearbyStore());

    return Axios.get(
      "/nearbystore/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultNearbyStore(data.data));
      })
      .catch((error) => {
        dispatch(resultNearbyStore({ data: null }));
        return dispatch(handleError(error));
      });
  };
};
