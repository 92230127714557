import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../Beranda.css";
import { fetchNews, fetchNewsDetailById } from "./../../redux/actions/news";
import News from "./Berita.component";
import "./Berita.css";
import NewsDetail from "./BeritaDetail.component";

class BeritaContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      action: null,
      newsID: null,
      news: [],
      newsDetail: null,
      breadcrumb: null,
      beritaLainnya: [],

      activePage: 1,
      itemPerPage: 9,
      totalNews: null,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    console.log("mount");
    this.loadData();
  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    //cek state dari news
    const {
      fetchingNews,
      fetchingNewsDetailById,
      news,
      newsDetail,
      news_total,
    } = this.props.news;
    const { isLoading, action, newsID, itemPerPage } = this.state;
    const { id, extra } = this.props.match.params;
    let { breadcrumb, beritaLainnya } = this.state;

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (!fetchingNews && !fetchingNewsDetailById && isLoading) {
      if (action === "detail") {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          { link: "/berita", text: "Berita" },
          { link: "/berita/" + newsID + "/detail", text: "Detail Berita" },
        ];
      } else {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          { link: "/profile/index", text: "Keanggotaan" },
          { link: "/berita", text: "Berita" },
        ];
      }

      if (newsDetail != null && news != null && news.length > 0) {
        beritaLainnya = _.filter(
          news,
          (o) => parseInt(o.ID) !== parseInt(newsDetail.ID)
        );
      }

      this.setState({
        isLoading: false,
        totalNews: news_total != null ? news_total : itemPerPage,
        news,
        newsDetail,
        beritaLainnya,
        breadcrumb,
      });
    }

    if (
      typeof extra !== "undefined" &&
      extra === "detail" &&
      parseInt(id) !== parseInt(newsID)
    ) {
      this.loadData();
    }

    if (action === "detail" && typeof extra === "undefined") {
      this.loadData();
    }
  }

  loadData(param) {
    let { action, activePage, itemPerPage } = this.state;
    const { breadcrumb } = this.props.match;
    const { id, extra } = this.props.match.params;

    if (
      typeof param !== "undefined" &&
      typeof param.activePage !== "undefined" &&
      !isNaN(parseInt(param.activePage))
    ) {
      activePage = param.activePage;
    }

    if (typeof id !== "undefined" && typeof extra !== "undefined") {
      this.props.fetchNewsDetailById(id);
      this.props.fetchNews();
      action = "detail";
    } else {
      this.props.fetchNews({ page: activePage, size: itemPerPage }, true);
      action = "view";
    }

    this.setState({
      isLoading: true,
      action: action,
      newsID: id,
      breadcrumb: breadcrumb,
      activePage,
    });
  }

  handleChangePage(pageNumber) {
    this.loadData({ activePage: pageNumber });
  }

  render() {
    const {
      news,
      newsDetail,
      isLoading,
      action,
      activePage,
      itemPerPage,
      totalNews,
      breadcrumb,
      beritaLainnya,
    } = this.state;
    return (
      <div>
        {" "}
        {action != null && action === "detail" ? (
          <NewsDetail
            newsDetail={newsDetail}
            news={news}
            beritaLainnya={beritaLainnya}
            breadcrumb={breadcrumb}
            isLoading={isLoading}
          />
        ) : (
          <News
            news={news}
            breadcrumb={breadcrumb}
            isLoading={isLoading}
            //paging
            activePage={activePage}
            itemPerPage={itemPerPage}
            totalNews={totalNews}
            onChangePage={this.handleChangePage}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { news, newsDetail } = state;
  return { news, newsDetail };
};
export default withRouter(
  connect(mapStateToProps, {
    fetchNews,
    fetchNewsDetailById,
    //list of dispatch action
  })(BeritaContainer)
);
