import { cleanup } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import icToastSuccess from './../../Images/ic-toast-success.png';

var timeout;

const StyledModal = styled.div`
    position: fixed;
    width: 640px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #fff;
    padding: 16px;

    background: #323232;
    opacity: 0.9;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
`;

export default function ModalToast({success, children, show, autoHide, ...props}) {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        let delay = props && !isNaN(parseInt(props.delay)) ? parseInt(props.delay) : 5000;

        setShowModal(show || show === "true" ? true : false)

        if((show || show === "true") && autoHide) {
            timeout = setTimeout(() => {
                setShowModal(false) 
                props.onHide()
            }, delay) 
        }

        return cleanup(() => clearTimeout(timeout))
    },[show])  
  
    return (showModal ? <div style={{position: 'relative'}}>
            <StyledModal> 
                <div className="text-center" style={{marginBottom:'24px'}}>
                    {success && <img src={icToastSuccess} width={90} />}
                </div>

                {children}
            </StyledModal>
        </div> : null);
  } 