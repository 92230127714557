import _ from 'lodash'; 
import { parseCurrency, calculateDimensi } from './common'; 

// mengkalkulasi product
export const calculateProduct = (param) => {
    let {type, product, value} = param
    let {PromoPrice} = product
    if(typeof(PromoPrice) === "undefined") {
        
        if(process.env.REACT_APP_DEBUG) {
            console.log("Parameter Salah"); 
        }

        return null;
    }
    
    if(isNaN(product['Qty'])) {
        product['Qty'] = 0;
    } else if(product['Qty'] == null) {
        product['Qty'] = 0;
    } 

    let extra = {};

    if(type === "INCREMENT") {
        if(parseInt(product['Stock']) > parseInt(product['Qty'])) {
            product['Qty'] = parseInt(product['Qty']) + 1;
        } else {
            // send message stock terbatas
            extra = {error: true, message: "Gagal menambahkan ke keranjang, stok tidak mencukupi"};
        }
    } else if(type === "DECREMENT" && product['Qty'] >= 1) {
        product['Qty'] = parseInt(product['Qty']) - 1;
    } else {
        if(parseInt(product['Stock']) >= parseInt(value)) {
            product['Qty'] = typeof value !== "undefined" && !isNaN(parseInt(value)) ? parseInt(value) : 0;
        } else { 
            extra = {error: true, message: "Gagal menambahkan ke keranjang, stok tidak mencukupi"};
        }
    } 

    product['Total'] = parseInt(product['Qty'] * product['PromoPrice']); 

    return {...product, ProductID: product.ID, ...extra};
}

// menambahkan product ke cart, fungsi ini dipanggil dari action mart_cart addToCart
export const addToCart = (product) => {
    let carts = getCarts();
    let {items, summary} = carts;
    let {ProductID, Price, Qty, Total, Stock, Note} = product; 

    if(typeof(ProductID) === "undefined" || typeof(Price) === "undefined" ||
        typeof(Qty) === "undefined" || typeof(Total) === "undefined") {
            
            if(process.env.REACT_APP_DEBUG) {
                console.log("Parameter Salah");  
            }

            return null;

        }

    let index = indexProductInCart(product.ProductID);  
    Qty = Qty == null ? 0 : Qty;
    if(Qty <= 0) { 
        return removeFromCart(product.ProductID);
    }

    if(parseInt(Qty) > 0 && parseInt(Stock) < parseInt(Qty)) {
        return {status: false, error: "INSUFFICIENT_STOCK", message: "Stock tidak mencukupi"}
    } 

    if(index >= 0) {
        let item = items[index]; 
        items[index] = {...item, Price, Qty, Note, Total};
    } else {
        items.push(product);
    } 

    // Remove payment state ketika update produk keranjang
    summary = removePaymentState(summary)
    summary = removeCourierState(summary);

    return saveToCart({...carts, items, summary});
}

export const saveToCart = ({items, summary}) => {  

    let TotalPrice = _.sumBy(items,(o) => parseInt(o.Total));
    let TotalQty = _.sumBy(items,(o) => parseInt(o.Qty));
    let TotalWeight = _.sumBy(items,(o) => !isNaN(parseInt(o.Weight)) && !isNaN(parseInt(o.Qty)) && o.Unit === "gr" ? parseInt(o.Weight) * parseInt(o.Qty) : 0);

    let Total = parseInt(TotalPrice) + parseInt(summary != null && typeof(summary['CourierServiceFee']) !== "undefined" ? summary['CourierServiceFee'] : 0); 

    let StoreID = items != null && typeof(items[0]) !== "undefined" && typeof(items[0]['StoreID']) !== "undefined" ? items[0]['StoreID'] : null;
    let PurchaseType = items != null && typeof(items[0]) !== "undefined" && typeof(items[0]['PurchaseType']) !== "undefined" ? items[0]['PurchaseType'] : null;

    let Dimensi = calculateDimensi(items);

    summary = {...summary, TotalPrice, TotalQty, TotalWeight, Total, StoreID, PurchaseType, Dimensi};

    let carts = {summary, items};

    if(typeof(carts) !== "undefined") {
        localStorage.setItem('AGREGO_CART',JSON.stringify(carts));
        return {status: true, error: null, message: "Berhasil menambah produk ke keranjang"};
    }

    return {status: false, error: "EMPTY_CART", message: "Keranjang kosong"};
} 

// menambahkan product ke cart, fungsi ini dipanggil dari action mart_cart addToCart
export const addToDigital = (product) => {
    let digital = getDigital(); 
    let {Reference, ProductID, Name, Price, Fee, Description, Image, ProviderName, 
        ProviderImage, ProviderDescription, CategoryName, CategoryURL, OrderID, TransactionID} = product; 

    digital = digital == null ? {} : digital;
    
    Reference = (Reference != null ? Reference : (digital['Reference'] != null ? digital['Reference'] : null));
    ProductID = (ProductID != null ? ProductID : (digital['ProductID'] != null ? digital['ProductID'] : null));
    Name = (Name != null ? Name : (digital['Name'] != null ? digital['Name'] : null));
    Fee = (!isNaN(Fee) ? parseInt(Fee) : (!isNaN(digital['Fee']) ? parseInt(digital['Fee']) : 0));
    Price = (!isNaN(Price) ? parseInt(Price) : (!isNaN(digital['Price']) ? parseInt(digital['Price']) : 0));

    // optional field
    Description = (Description != null ? Description : (digital['Description'] != null ? digital['Description'] : null));
    Image = (Image != null ? Image : (digital['Image'] != null ? digital['Image'] : null));
    ProviderName = (ProviderName != null ? ProviderName : (digital['ProviderName'] != null ? digital['ProviderName'] : null));
    ProviderImage = (ProviderImage != null ? ProviderImage : (digital['ProviderImage'] != null ? digital['ProviderImage'] : null));
    ProviderDescription = (ProviderDescription != null ? ProviderDescription : (digital['ProviderDescription'] != null ? digital['ProviderDescription'] : null));
    
    // digunakan untuk inquiry 
    OrderID = (OrderID != null ? OrderID : (digital['OrderID'] != null ? digital['OrderID'] : null));
    TransactionID = (TransactionID != null ? TransactionID : (digital['TransactionID'] != null ? digital['TransactionID'] : null));
    
    // Breadcrumb purpose
    CategoryName = (CategoryName != null ? CategoryName : (digital['CategoryName'] != null ? digital['CategoryName'] : null));
    CategoryURL = (CategoryURL != null ? CategoryURL : (digital['CategoryURL'] != null ? digital['CategoryURL'] : null));
    
    let Total = (!isNaN(Price) ? parseInt(Price) + (!isNaN(Fee) ? parseInt(Fee) : 0) : (!isNaN(digital['Total']) ? parseInt(digital['Total']) : 0));

    // Remove payment state ketika update produk keranjang
    // digital = removePaymentState(digital)

    return saveToDigital({
        ...digital, Reference, ProductID, Name, Fee, Price, Total, Description, Image, ProviderName, 
        ProviderImage, ProviderDescription, CategoryName, CategoryURL, OrderID, TransactionID
    });
}

export const saveToDigital = (digital) => { 

    if(typeof(digital) !== "undefined") {
        localStorage.setItem('AGREGO_CART_DIGITAL',JSON.stringify(digital));
        return {status: true, error: null, message: "Berhasil menambah produk ke keranjang"};
    }

    return {status: false, error: "EMPTY_CART_DIGITAL", message: "Keranjang kosong"};
} 


// menambahkan product ke cart, fungsi ini dipanggil dari action mart_cart addToCart
export const addToTopup = (product) => {
    let topup = getTopup(); 
    let {Nominal, CategoryName, CategoryURL, OrderID, TransactionID} = product; 

    topup = topup == null ? {} : topup;

    Nominal = (Nominal != null ? Nominal : (topup['Nominal'] != null ? topup['Nominal'] : null));  

    // digunakan untuk inquiry 
    OrderID = (OrderID != null ? OrderID : (topup['OrderID'] != null ? topup['OrderID'] : null));
    TransactionID = (TransactionID != null ? TransactionID : (topup['TransactionID'] != null ? topup['TransactionID'] : null));
    
    // Breadcrumb purpose
    CategoryName = (CategoryName != null ? CategoryName : (topup['CategoryName'] != null ? topup['CategoryName'] : null));
    CategoryURL = (CategoryURL != null ? CategoryURL : (topup['CategoryURL'] != null ? topup['CategoryURL'] : null));
    
    let Total = (Nominal != null ? Nominal : (topup['Total'] != null ? topup['Total'] : null));

    // Remove payment state ketika update produk keranjang
    // topup = removePaymentState(topup)

    return saveToTopup({
        ...topup, Nominal, Total, CategoryName, CategoryURL, OrderID, TransactionID
    });
}

export const saveToTopup = (topup) => { 

    if(typeof(topup) !== "undefined") { 
        localStorage.setItem('AGREGO_CART_TOPUP',JSON.stringify(topup)); 
    }

    return {status: false, error: "EMPTY_TOPUP", message: "Topup kosong"};
} 

export const removeFromCart = (productID) => {
    let carts = getCarts(); 
    let {items} = carts; 

    // delete all
    if(typeof(productID) === "undefined") { 
        return saveToCart({items:[], summary:null}); 
    }

    let index = indexProductInCart(productID); 

    if(index >= 0) {
        items = _.remove(items, function(o) {
            return parseInt(o.ProductID) !== parseInt(productID);
        })

        carts = {...carts, items};

        return saveToCart(carts); 
    }  

    return false;
    
} 

export const removeFromCartDigital = () => { 
    return saveToDigital(null); 
} 

export const removeFromCartTopup = () => { 
    return saveToTopup(null); 
} 

export const isProductInCart = (productID) => {
    if(indexProductInCart(productID) >= 0) {
        return true;
    }

    return false;
}

export const indexProductInCart = (productID, items) => {
    if(typeof(carts) === "undefined") {
        let carts = getCarts(); 
        items = carts.items;
    } 

    if(items != null && items.length > 0) {
        return _.findIndex(items, (o) => parseInt(o.ProductID) === parseInt(productID));
    }

    return -1;
}

// get digital storage
export const getDigital = () => {
    let cartStorage = localStorage.getItem('AGREGO_CART_DIGITAL'); 
    let digital = typeof(cartStorage) !== "undefined" && cartStorage != null ? JSON.parse(cartStorage) : [];
    
    return digital;
} 

export const getTopup = () => {
    let cartStorage = localStorage.getItem('AGREGO_CART_TOPUP'); 
    let digital = typeof(cartStorage) !== "undefined" && cartStorage != null ? JSON.parse(cartStorage) : [];
    
    return digital;
} 

export const getCarts = (productID) => {
    let cartStorage = localStorage.getItem('AGREGO_CART'); 
    let carts = typeof(cartStorage) !== "undefined" && cartStorage != null ? JSON.parse(cartStorage) : [];

    let {items, summary} = carts;
    items = typeof(items) !== "undefined" ? items : [];
    summary = typeof(summary) !== "undefined" ? summary : null;
 
    if(typeof(productID) !== "undefined") {   

        if(items.length <= 0) {
            return null;
        }
        
        let index = _.findIndex(items, (o) => parseInt(o.ProductID) === parseInt(productID)); 

        if(index >= 0) {
            return items[index];
        }

        return null;
    } 

    return {items, summary};
} 

export const mergeProductCart = (products) => { 

    let results = null;
    if(Array.isArray(products)) {
        results = [];
        products.forEach(prod => {
            let cart = getCarts(prod.ID); 
    
            if(cart != null) {
                results.push({...prod, Qty: cart.Qty, Total: cart.Total});
            } else {
                results.push({...prod, Qty: 0, Total: 0});
            }

        });  
    } else if (products != null ) {
        let cart = getCarts(products.ID);  
            
        if(cart != null) {
            results = {...products, Qty: cart.Qty, Total: cart.Total};
        } else {
            results = {...products, Qty: 0, Total: 0};
        }
    }

    return results;
}

export const updateDeliveryAddress = ({ID, 
    Address, 
    Labels, 
    Recipient, 
    Phone, 
    SubDistrict, 
    SubDistrictID, 
    City, 
    CityID, 
    Area, 
    AreaID, 
    Province, 
    ProvinceID, 
    PostalCode}) => {

    let {items, summary} = getCarts();

    // Inject delivery as default delivery option 
    summary['DeliveryOption'] = summary['DeliveryOption'] ? summary['DeliveryOption'] : "Delivery";
    summary['DeliveryOptionID'] = summary['DeliveryOptionID'] ? summary['DeliveryOptionID'] : 1;
    
    summary = {...summary,
        DeliveryID: ID,
        DeliveryAddress: Address,
        DeliveryLabels: Labels,
        DeliveryRecipient: Recipient,
        DeliveryPhone: Phone, 
        DeliverySubDistrict: SubDistrict, 
        DeliverySubDistrictID: SubDistrictID, 
        DeliveryArea: Area, 
        DeliveryAreaID: AreaID, 
        DeliveryCity: City, 
        DeliveryCityID: CityID, 
        DeliveryProvince: Province, 
        DeliveryProvinceID: ProvinceID, 
        DeliveryPostalCode: PostalCode};  

    if(summary['DeliverySubDistrictID'] !== summary['CourierDestination']) {

        // Hapus data courier sebelumnya, karena sudah tidak valid beda wilayah
        summary = removeCourierState(summary);
    }
        
    saveToCart({items, summary});
}  

export const updateDeliveryOption = (DeliveryOption) => {
    let {items, summary} = getCarts(); 

    if(DeliveryOption !== "Delivery") {
        
        // Delivery
        summary = removeDeliveryState(summary);

        // Courier
        summary = removeCourierState(summary);


    }

    summary = {...summary, DeliveryOption}; 
    saveToCart({items, summary});
} 

export const updatePaymentMethod = ({PaymentType, Payment, QRISPaymentID, BankAccountID, BankName, BankImage, BankAccountNumber, BankAccountName, WalletBalance, type}) => {
    
    let {items, summary} = getCarts();

    // Inject delivery as default delivery option 
    summary['DeliveryOption'] = summary['DeliveryOption'] ? summary['DeliveryOption'] : "Delivery";
    summary['DeliveryOptionID'] = summary['DeliveryOptionID'] ? summary['DeliveryOptionID'] : 1;

    if(type != null && type === "digital") {  
        summary = getDigital();
    } else if(type != null && type === "topup") {  
        summary = getTopup(); 
    } 
        
    if(PaymentType.toLowerCase() === "bank") { 
        summary = {...summary, Payment, PaymentType, BankAccountID, BankName, BankImage, BankAccountNumber, BankAccountName};

        delete summary['WalletBalance'];
        delete summary['QRISPaymentID'];
    } else if(PaymentType.toLowerCase() === "qris") { 
        summary = {...summary, Payment, PaymentType, QRISPaymentID};

        delete summary['WalletBalance']; 
        summary = removeBankState(summary);
    } else if(PaymentType === "Wallet") { 
        summary = {...summary, Payment, PaymentType, WalletBalance};

        delete summary['QRISPaymentID'];
        summary = removeBankState(summary);
    } else {
        summary = {...summary, Payment, PaymentType};
        
        delete summary['QRISPaymentID'];
        delete summary['WalletBalance'];
        summary = removeBankState(summary);
    } 

    if(type != null && type === "digital") {  
        saveToDigital(summary);
    } else if(type != null && type === "topup") {  
        saveToTopup(summary);
    } else {
        saveToCart({items, summary}); 
    } 
}

export const updateDeliveryCourier = ({CourierID, CourierCode, CourierName, CourierImage, CourierServiceName, 
    CourierServiceDescription, CourierServiceETA, CourierServiceFee, CourierServiceFeeAdjustment, CourierServiceRateID}) => {
    let {items, summary} = getCarts();   

    // Origin & Dest
    let CourierOrigin = summary['StoreAreaID'];
    let CourierOriginType = "AreaID";
    let CourierDestination = summary['DeliveryAreaID'];
    let CourierDestinationType = "AreaID";
    
    // let CourierDeliveryWeight = summary != null && typeof(summary['TotalWeight']) !== "undefined" ? summary['TotalWeight'] : 1;

    // Inject delivery as default delivery option  
    summary['DeliveryOption'] = summary['DeliveryOption'] ? summary['DeliveryOption'] : "Delivery";
    summary['DeliveryOptionID'] = summary['DeliveryOptionID'] ? summary['DeliveryOptionID'] : 1;

    // hard code delivery option dan id dari schema
    // todo sesuaikan dengan schema
    if(CourierCode === "StoreCourier") {
        summary["DeliveryOption"] = "Store Courier"
        summary["DeliveryOptionID"] = 4
    } else {
        summary["DeliveryOption"] = "Delivery"
        summary["DeliveryOptionID"] = 1
    }

    summary = {...summary, 
        CourierID, 
        CourierCode, 
        CourierName, 
        CourierImage, 
        CourierOrigin, 
        CourierOriginType, 
        CourierDestination, 
        CourierDestinationType, 
        CourierServiceName, 
        CourierServiceDescription, 
        CourierServiceETA,
        CourierServiceRateID,
        // CourierDeliveryWeight,
        CourierServiceFee,
        CourierServiceFeeAdjustment};  

    saveToCart({items, summary});
}

export const updateStoreDeliveryAddress = (Store) => {
    let {items, summary} = getCarts();   

    // let StoreSubDistrictID = Store != null && typeof(Store['Address']) !== "undefined" && 
    //     Store['Address'] != null && typeof(Store['Address']['SubDistrictID']) !== "undefined" && 
    //     !isNaN(parseInt(Store['Address']['SubDistrictID'])) ? Store['Address']['SubDistrictID'] : null;

    let getStore = Store != null && typeof(Store['Address']) !== "undefined" && 
        Store['Address'] != null ? Store['Address'] : null; 

    let StoreSubDistrictID = getStore != null && typeof(getStore['SubDistrictID']) !== "undefined" ? getStore['SubDistrictID'] : null
    let StoreAreaID = getStore != null && typeof(getStore['AreaID']) !== "undefined" ? getStore['AreaID'] : null
    let StoreAddressID = getStore != null && typeof(getStore['ID']) !== "undefined" ? getStore['ID'] : null 
    let StoreCourier = Store && Store['StoreCourier'] ? Store['StoreCourier'] : false 
    let StoreName = Store && Store['Name'] ? Store['Name'] : false  

    // true antar ke agen, false pickup kurir (shipper)
    let CourierDelivery = Store && Store['CourierDelivery'] ? true : false;

    // Set Default DeliveryOption = "Delivery 
    let DeliveryOption = "Delivery"

    summary = {...summary, CourierDelivery, DeliveryOption, StoreSubDistrictID, StoreAreaID, StoreAddressID, StoreCourier, StoreName};   

    saveToCart({items, summary});
}

export const isCompletedParamOrder = ({Payment, PaymentType, DeliveryOption, DeliveryID, CourierID, CourierName, WalletBalance, Total}) => {
    if(typeof(DeliveryOption) !== "undefined" && 
        typeof(PaymentType) !== "undefined" && 
        typeof(Payment) !== "undefined") {  

        if(['delivery','store courier'].includes(DeliveryOption.toLowerCase()) && !CourierName) { 
            return false;
        } 

        if(PaymentType === "Wallet") {
            if(typeof(WalletBalance) !== "undefined" && WalletBalance != null && 
                parseInt(WalletBalance) >= parseInt(Total))  {
                    return true
            } else {
                return false;
            }
        } else {
            return true;
        } 

    } 
    
    return false;
}

export const setDataSubmitOrder = () => {
    let {items, summary} = getCarts();

    let OrderItems = [];

    if(items != null && items.length > 0) {       
        items.forEach(item => {
            if(typeof(item['ProductID']) !== "undefined" && typeof(item['Qty']) !== "undefined") {
                OrderItems.push({ProductID: item['ProductID'], Quantity: item['Qty'], Note: item['Note'] ? item['Note'] : ''})
            }
        });
    }

    let data = {
        "OID":localStorage.getItem("AGREGO_USER_OID"),
        "Payment": typeof(summary['Payment']) !== "undefined" ? summary['Payment'] : null,
        "PaymentType": typeof(summary['PaymentType']) !== "undefined" ? summary['PaymentType'] : null, 
        "DeliveryTime": typeof(summary['DeliveryTime']) !== "undefined" ? summary['DeliveryTime'] : "", 
        "DeliveryOption": typeof(summary['DeliveryOption']) !== "undefined" ? summary['DeliveryOption'] : null, 
        "DeliveryOptionID": typeof(summary['DeliveryOptionID']) !== "undefined" ? summary['DeliveryOptionID'] : null, 
        // "DeliveryAddress": typeof(summary['DeliveryAddress']) !== "undefined" ? summary['DeliveryAddress'] : null, 
        "Status":1, 
        "OrderItem": OrderItems
    }

    if(summary['PaymentType'].toLowerCase() === "bank" && typeof(summary['BankAccountID']) !== "undefined"){
        data['BankAccountID'] = summary['BankAccountID'];
        data['BankAccountNumber'] = summary['BankAccountNumber'];
        data['BankAccountName'] = summary['BankAccountName'];
        data['BankName'] = summary['BankName'];
        data['BankImage'] = summary['BankImage'];
    }
    if(summary['PaymentType'].toLowerCase() === "qris" && typeof(summary['QRISPaymentID']) !== "undefined"){ data['QRISPaymentID'] = summary['QRISPaymentID']; }
    if(typeof(summary['DeliveryID']) !== "undefined"){ data['DeliveryID'] = summary['DeliveryID']; } 

    if(["delivery","store courier"].includes(data['DeliveryOption'].toLowerCase()))  {
        data = {...data, 
            "Courier": typeof(summary['CourierName']) !== "undefined" ? summary['CourierName'] : null, 
            // "CourierID": typeof(summary['CourierID']) !== "undefined" ? summary['CourierID'] : null,
            "CourierID": "",
            "CourierETA": typeof(summary['CourierServiceETA']) !== "undefined" ? summary['CourierServiceETA'] : null,
            "CourierService": typeof(summary['CourierServiceName']) !== "undefined" ? summary['CourierServiceName'] : null,
            "PostalFee": typeof(summary['CourierServiceFee']) !== "undefined" ? summary['CourierServiceFee']+"" : null,
            "PostalFeeAdjustment": typeof(summary['CourierServiceFeeAdjustment']) !== "undefined" ? summary['CourierServiceFeeAdjustment']+"" : null,
            "StoreAddressID": typeof(summary['StoreAddressID']) !== "undefined" ? summary['StoreAddressID'] : null,
            "RateID": parseInt(summary['DeliveryOptionID']) === 1 && summary['CourierServiceRateID'] ? summary['CourierServiceRateID'] : null,
        }
    }

    // hapus rate id jika : deliveryoption tidak sama dengan 1 atau seller mengantar pesanan ke agen 
    if(parseInt(summary['DeliveryOptionID']) !== 1 || summary['CourierDelivery']) {
        delete data['RateID']
    }

    return data;
}

// mengecek apakah order bisa di lanjutkan
export const isCompletedParamOrderDigital = ({Payment, PaymentType, Price, Total, ProductID, Reference, WalletBalance}) => {
    if(typeof(PaymentType) !== "undefined" && PaymentType !== "" &&
        typeof(Payment) !== "undefined" && Payment !== "" && 
        typeof(Total) !== "undefined" && Total !== "" && !isNaN(Total) && parseInt(Total) > 0 && 
        typeof(ProductID) !== "undefined" && ProductID !== "" &&
        typeof(Reference) !== "undefined" && Reference !== ""
        ) {    

        if(PaymentType === "Wallet") {  
            if(typeof(WalletBalance) !== "undefined" && WalletBalance != null && 
                parseInt(WalletBalance) >= parseInt(Total))  {
                    return true;
            } else {
                return false;
            }
        } else {
            return true;
        } 

    } 
    
    return false;
}

// Menyiapkan data digital untuk submit order
export const setDataSubmitOrderDigital = (input) => { 
    let digital = getDigital(); 
    
    // merge digital and input for newer data
    if(input != null) {
        digital = {...digital, ...input}
    }

    let data = {
        // "OID":localStorage.getItem("AGREGO_USER_OID"),
        "Payment": typeof(digital['Payment']) !== "undefined" ? digital['Payment'] : "Wallet",
        "PaymentType": typeof(digital['PaymentType']) !== "undefined" ? digital['PaymentType'] : "Wallet",   
        "Reference": typeof(digital['Reference']) !== "undefined" ? digital['Reference'] : null,   
        "ProductID": typeof(digital['ProductID']) !== "undefined" ? digital['ProductID'] : null,   
    }

    if(data['PaymentType'].toLowerCase() === "bank") {
        data['BankAccountID'] = typeof(digital['BankAccountID']) !== "undefined" ? digital['BankAccountID'] : null;
    } else if(data['PaymentType'].toLowerCase() === "qris") {
        data['QRISPaymentID'] = typeof(digital['QRISPaymentID']) !== "undefined" ? digital['QRISPaymentID'] : null;
    } 
    
    // data['Payment'] = data['PaymentType'] != null && data['PaymentType'] === "Wallet" ? data['PaymentType'] : data['Payment'];

    return data;
}

// mengecek apakah order bisa di lanjutkan
export const isCompletedParamOrderTopup = ({Payment, PaymentType, Nominal, Total}) => {
    if(PaymentType != null && PaymentType !== "" &&
        Payment != null && Payment !== "" && 
        Total != null && !isNaN(parseInt(Total)) && 
        Nominal != null && !isNaN(parseInt(Nominal))
        ) {
                 
        return true;
    } 
    
    return false;
}

export const setDataSubmitOrderTopup = (input) => { 
    let topup = getTopup(); 
    
    // merge topup and input for newer data
    if(input != null) {
        topup = {...topup, ...input}
    }

    let data = {
        // "OID":localStorage.getItem("AGREGO_USER_OID"),
        "Payment": typeof(topup['Payment']) !== "undefined" ? topup['Payment'] : "Wallet",
        "PaymentType": typeof(topup['PaymentType']) !== "undefined" ? topup['PaymentType'] : "Wallet",   
        "Nominal": typeof(topup['Nominal']) !== "undefined" ? topup['Nominal'] : null,    
    }

    if(data['PaymentType'] === "Bank") {
        data['BankAccountID'] = typeof(topup['BankAccountID']) !== "undefined" ? topup['BankAccountID'] : null;
    } 
    
    return data;
}

// mendapatkan payment note
export const getPaymentNote = (type) => {

    let carts = getCarts()
    let digital = getDigital()
    let topup = getTopup()
    let source = null;
    let paymentNote = '';  
    
    if(typeof(type) !== "undefined" && type.toLowerCase() === "digital") {
        source = digital; 
    } else if(typeof(type) !== "undefined" && type.toLowerCase() === "topup") {
        source = topup; 
    } else {
        source = carts['summary'] 
    }

    if(typeof(source) === "undefined" || source == null) {
        return "";
    } 

    if(source['PaymentType'] === "Wallet" && !isNaN(source['WalletBalance'])) { 
        if(parseInt(source['WalletBalance']) >= parseInt(source['Total'])) {
            paymentNote = "Saldo Dompet Komunitas Anda saat ini sebesar Rp "+parseCurrency(source['WalletBalance']);
        } else {
            paymentNote = "Saldo Dompet Anda tidak mencukupi untuk melanjutkan belanja, saldo saat ini Rp "+parseCurrency(source['WalletBalance']);
        }
    } else if(source['PaymentType'] === "Bank") {
        paymentNote = "Lakukan pembayaran sesuai petunjuk pembayaran melalui transfer Bank";
    } else if(source['PaymentType'] === "Cash") {
        paymentNote = "Siapkan uang Anda untuk mempercepat transaksi"; 
    }  
    
    return paymentNote;
}

const removeDeliveryState = (summary) => { 

    if(summary != null) {
        delete summary['DeliveryID'];
        delete summary['DeliveryLabels'];
        delete summary['DeliveryAddress'];
        delete summary['DeliveryRecipient'];
        delete summary['DeliveryPhone'];
        delete summary['DeliverySubDistrict'];
        delete summary['DeliverySubDistrictID'];
        delete summary['DeliveryArea'];
        delete summary['DeliveryAreaID'];
        delete summary['DeliveryCity'];
        delete summary['DeliveryCityID'];
        delete summary['DeliveryProvince'];
        delete summary['DeliveryProvinceID'];
        delete summary['DeliveryPostalCode'];
    }

    return summary; 
}

const removeCourierState = (summary) => { 

    if(summary != null) {
        delete summary['CourierID'];
        delete summary['CourierImage'];
        delete summary['CourierCode'];
        delete summary['CourierName'];
        delete summary['CourierOrigin'];
        delete summary['CourierOriginType'];
        delete summary['CourierDestination'];
        delete summary['CourierDestinationType'];
        delete summary['CourierServiceName'];
        delete summary['CourierServiceDescription'];
        delete summary['CourierServiceETA'];
        delete summary['CourierServiceRateID'];
        delete summary['CourierServiceFee'];
        delete summary['CourierDelivery'];
        delete summary['CourierDeliveryWeight'];
    }

    return summary; 
}

const removePaymentState = (summary) => { 

    if(summary != null) {
        delete summary['PaymentType'];
        delete summary['Payment'];
        delete summary['WalletBalance'];

        removeBankState(summary)
    }

    return summary; 
}

const removeBankState = (summary) => { 

    if(summary != null) {
        delete summary['BankAccountID'];
        delete summary['BankName'];
        delete summary['BankImage'];
        delete summary['BankAccountName'];
        delete summary['BankAccountNumber'];
    }

    return summary; 
}