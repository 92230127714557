import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import './PLNPascabayar.css';
import '../ppob.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Breadcrumb from '../../Layout/Breadcrumb'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'; 

const PLNPascabayarSchema = Yup.object().shape({
    NoPlnPascabayar: Yup.number()
        .typeError('Nomor meter/ID Pelanggan harus berupa angka')
        .required('Nomor meter/ID Pelanggan harus diisi')
        .min(1000000000, 'Nomor Meter/ID Pelanggan harus 10 digit atau lebih'),

});
 
export default function PLNPascaBayar(props) {
    const { isLoading, breadcrumb } = props;
    const iconClose = <FontAwesomeIcon className="btn-qty" icon={faTimesCircle} />
    var disabledbtn;
    return (
        <Formik
            enableReinitialize={true}
            validationSchema={PLNPascabayarSchema}
            initialValues={{ NoPlnPascabayar: '' }}
            onSubmit={(values) => {
                // ketika di submit data form di variable values dikirim ke parent component lewat props

                // kirim form ke parent lewat onSubmitProfile
                props.onChangeProductDigital(values);
            }}


        >
            {({ errors, touched, handleChange, handleBlur, handleSubmit, values, isSubmitting, setFieldValue, setFieldTouched }) => {

                const onHandleProductDigital = (e) => { 
                    props.onChangePLNPascabayarNo(e.target.value)
                    setFieldValue('NoPlnPascabayar', e.target.value) 
                } 

                const onClearForm = (e) => {

                    props.onClearForm(e.target.value) 
                    setFieldValue('NoPlnPascabayar', '')
                    setFieldTouched('NoPlnPascabayar', true, true) 

                }

                if (values['NoPlnPascabayar'] == null || values['NoPlnPascabayar'] === '' || isLoading ) {
                    disabledbtn = true;
                } else {
                    disabledbtn = false;
                } 

                return (
                    <Container fluid>

                        {/* TAB MENU PPOB */}

                        <div className="d-none d-sm-block">

                            <Row style={{ marginLeft: '54px', background: '#FFFF' }} >
                                <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item">
                                        <a href="/digital/pulsa">Pulsa dan Data</a>
                                    </div>
                                </Col>

                                <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item">
                                        <a href="/digital/plnprepaid">Token Listrik</a>
                                    </div>
                                </Col>

                                <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item-active">
                                        <a href="/digital/plnpascabayar">Tagihan Listrik</a>
                                    </div>
                                </Col>


                                {/* <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item">
                                        <a href="/digital/bpjs">BPJS</a>
                                    </div>
                                </Col> */}

                            </Row>
                        </div>

                         {/* TAMPILAN MOBILE */}
                         <div className="d-block d-sm-none">

                            <div className="scrollmenu">
                                <a href="/digital/pulsa">Pulsa dan Data</a>
                                <a href="/digital/plnprepaid">Token Listrik</a>
                                <a className="scrollmenu-active" href="/digital/plnpascabayar">Tagihan Listrik</a>
                            
                                {/* <a href="/digital/bpjs">BPJS</a> */}
                                </div>

                                </div>


                        <Row>
                            <Col>
                                <div style={{ marginTop: '20px', paddingBottom: '0px', marginLeft: '8%' }}>
                                    {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="PLNPascabayarContainer">

                                <Col md={12}><div className="PLNPascabayarJudul">Bayar Tagihan Listrik</div></Col>
                                <Col md={12}>
                                    <div className="col-md-4" style={{ paddingLeft: '0px' }}>
                                        <Form >
                                            <Form.Group>
                                                <Form.Label className="labelPLNPascabayar">ID Pelanggan</Form.Label>
                                                <Form.Control
                                                    name="NoPlnPascabayar"
                                                    onBlur={handleBlur}
                                                    value={values.NoPlnPascabayar}
                                                    onChange={onHandleProductDigital}

                                                />
                                                {values['NoPlnPascabayar'] !== '' && <Button variant="link" className="btnplnClose" onClick={onClearForm} >{iconClose}</Button>}

                                            </Form.Group>

                                            {errors.NoPlnPascabayar &&
                                                <div className="errorTextPLN">
                                                    <Form.Text className="text-danger">
                                                        {errors.NoPlnPascabayar}
                                                    </Form.Text>
                                                </div>}

                                        </Form>
                                    </div>
                                </Col>


                                <Col md={4} className="btnPLNPascabayar">
                                    <Button variant="primary" block disabled={disabledbtn} onClick={e => props.onClickSubmit(values)}>Lanjut</Button>
                                </Col>


                            </div>
                        </Row>





                    </Container>)
            }}

        </Formik>)
}