import React, {useEffect} from 'react';  
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMartStore } from '../../../redux/actions/mart_store'; 
import './PopularStore.css';
import Carousel from "react-multi-carousel";  
import Loading from './../../Layout/Loading';
import EmptyState from './../../Layout/EmptyState';
import StoreItem from './StoreItem.component';
import { filterLimit } from './../../../utils/common';

const responsive = { 
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2.7
    }
};

function PopularStore(props) { 

    const {title, isLoading} = props;
 
    const productState = useSelector(state => {
        // tampilkan product id selain yang terpilih di halaman detail 
        if(state.martstore.mart_stores.length > 0) {
            let productList = filterLimit(state.martstore.mart_stores, (o) => {
                return !isNaN(parseInt(o.ID))
            },10); 
            
            state.martstore = {...state.martstore, mart_stores: productList};
        } 

        // onEmpty Product
        if(!isLoading && !state.martstore.isFetchingMartStoreList) { 
            if(state.martstore.mart_stores == null || state.martstore.mart_stores.length <= 1) { 
                props.onEmpty(true);
            } else {
                props.onEmpty(false);
            } 
        } 

        return state.martstore;
    });

    
    const dispatch = useDispatch(); 

    useEffect(() => {

        let filterData = {};
        // filterData['Status'] = 1;
        // if(CategoryID != null) {
        //     filterData['CategoryID'] = CategoryID;
        // } else if (typeof(filterData['CategoryID']) !== "undefined") {
        //     delete filterData['CategoryID'];
        // } 
        
        dispatch(fetchMartStore({page: 1, size: 10, search:filterData}));  

    },[isLoading])
 
    return (
        <div style={props.style} className={"popular-store-card "+props.className}> 
            <a href="/store" className="float-right popular-store-link-more">Lihat Semua</a>
            <div className="popular-store-title">{title}</div>

            {(productState.isFetchingMartStoreList || isLoading) && <Loading />}

            {!isLoading && !productState.isFetchingMartStoreList && (productState.mart_stores == null || productState.mart_stores.length <= 0) &&  <div className="text-center"><EmptyState style={{margin:'auto'}} /></div>}

            <Carousel responsive={responsive} autoPlay={false}
            deviceType={props.deviceType}
            
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="react-multi-carousel-dot-list"
            keyBoardControl={true}
            swipeable={true}
            draggable={true}
        > 
            {!isLoading && !productState.isFetchingMartStoreList && productState.mart_stores != null && productState.mart_stores.length > 0 && productState.mart_stores.map((item, index) => {
                return (<StoreItem key={index} store={item} />)})} 
            </Carousel>
        </div>
    )
}


PopularStore.propTypes = {
    title: PropTypes.string, 
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    classNameItem: PropTypes.string,
    style: PropTypes.object,
    styleItem: PropTypes.object,
    onEmpty: PropTypes.func,
}


PopularStore.defaultProps = {
    title: "Toko Populer", 
    isLoading: false,
    className: "",
    classNameItem: "",
    style: {},
    styleItem: {},
    onEmpty: () => {},
}


export default PopularStore;