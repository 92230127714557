import React from 'react';
import { Container } from 'react-bootstrap';
import './Profile.css';  
import Keanggotaan from './../Layout/Keanggotaan';
import Loading from './../Layout/Loading';
import BottomNav from './../Layout/BottomNav'; 
import icdeposit from './../../Images/deposit-icon.png';
import iclogout from './../../Images/ic-anggota-logout.png'; 
import icbantuan from './../../Images/ic-anggota-bantuan.png';
import icnews from './../../Images/ic-anggota-news.png';
import icpassword from './../../Images/ic-change-password.png';
import { parsePhoneNumber } from './../../utils/common';  

export default function Profile(props) {

    const { isLoading } = props; 
    
    let csphone = localStorage.getItem('AGREGO_CSPHONE');
    if(csphone != null && typeof(csphone) !== "undefined") {
        csphone = parsePhoneNumber(csphone);
    }

    return (

        <Container>
            {/* Loading State */}
            {isLoading && <Loading />} 

            <div className="keanggotaan-container">

                {/* <div>Kartu Anggota</div> */}
                <div className="keanggotaan-mobile" style={{margin:'16px 0'}}>
                    <Keanggotaan style={{margin:'auto'}} />
                </div>

                <div>
                    <ul className="list-item-keanggotaan">
                        {/* <li class="list-item-title">Lainnya</li> */}
                        <li><a href="/berita"><img src={icnews} alt="berita" /> <div className="list-item-text">Berita</div></a></li>
                        <li><a href="/profile/change-password"><img src={icpassword} alt="berita" /> <div className="list-item-text">Ubah Password</div></a></li>
                        <li><a href="/wallet"><img src={icdeposit} alt="deposit" /> <div className="list-item-text">Deposit</div></a></li>
                        {/* <li><a href="/shop/order">Riwayat Transaksi</a></li>
                        <li><a href="/shop/order">Ubah Password</a></li> */}
                        <li><a href={"https://wa.me/"+csphone+"?text=Hallo"}><img src={icbantuan} alt="bantuan" /> <div className="list-item-text">Bantuan</div></a></li>
                        <li><a href="/logout"><img src={iclogout} alt="keluar" /> <div className="list-item-text">Keluar</div></a></li>
                    </ul>
                </div>

            </div>

            <BottomNav activeKey={5} />

        </Container>


    );
}