export const validateAddress = (form) => { 
    let validation = [ 
        {key: "Recipient", label: "Nama penerima", required: true},
        {key: "Address", label: "Alamat", required: true},
        {key: "Phone", label: "Nomor Telpon", required: true, type:"number"},
        {key: "ProvinceID", label: "Provinsi", required: true},
        {key: "CityID", label: "Kota", required: true},
        {key: "SubDistrictID", label: "Kecamatan", required: true},
        {key: "AreaID", label: "Kelurahan", required: true},
        {key: "PostalCode", label: "Kodepos", len:5, required: true},
        // {key: "Latitude", label: "Lokasi", required: true},
    ]

    let error = null;
    let pass = true;

    validation.forEach(valid => {

        const {key, required, type, len, label} = valid; 

        if(typeof(form[key]) !== "undefined") {
            // cek required
            if(typeof(required) !== "undefined") { 
                if(form[key] == null || form[key] === "" || form[key] === 0 || form[key] === "<nil>") { 
                    error = error != null ? error : {}; 
                    error[key] = `${label} harus diisi`; 
                } else if(error != null && typeof(error[key]) !== "undefined") {
                    delete error[key];
                }
            }
            
            // cek type
            if(typeof(type) !== "undefined") {
                if(type === "number" && isNaN(form[key]))  { 
                    error = error != null ? error : {}; 
                    error[key] = `${label} harus berupa angka`; 
                } else if(error != null && typeof(error[key]) !== "undefined") {
                    delete error[key];
                }
            } 

            // cek karakter
            if(typeof(len) !== "undefined") {
                if(form[key].length !== parseInt(len)) { 
                    error = error != null ? error : {}; 
                    error[key] = `${label} harus diisi ${len} karakter`; 
                } else if(error != null && typeof(error[key]) !== "undefined") {
                    delete error[key];
                }
            } 

            if(typeof(len) !== "undefined") {
                if(form[key].length !== parseInt(len)) { 
                    error = error != null ? error : {}; 
                    error[key] = `${label} harus diisi ${len} karakter`; 
                } else if(error != null && typeof(error[key]) !== "undefined") {
                    delete error[key];
                }
            }  
        } else if(error != null && typeof(error[key]) !== "undefined") {
            delete error[key];
        }
    }) 

    if(error != null) {
        pass = false;
    }

    return {pass, error};
}