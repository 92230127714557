import React from 'react';
import { Container } from 'react-bootstrap';
import './Profile.css';
import Breadcrumb from '../Layout/Breadcrumb';
import Loading from '../Layout/Loading';

export default function AlamatAdd(props) {

    const {
        isLoading,
        loadingAddress,
        breadcrumb,
    } = props;

    return (
        <Container> 
            {(isLoading || loadingAddress) && <Loading />} 
            <div className="TopHeader"></div>  
            {!isLoading && !loadingAddress && <div className="profileContainer"> 
                <div style={{ paddingTop: '30px' }}>
                    {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                </div> 
                {props.pageTitle ? <div style={{fontSize:'24px', marginBottom:'24px'}}>{props.pageTitle}</div> : ''}
                {!isLoading && <>{props.children}</>} 
            </div>}
        </Container>);
}