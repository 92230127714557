import { 
    REQUEST_MARTDELIVERY_ADDRESSLIST, 
    REQUEST_MARTDELIVERY_ADDRESS, 
    REQUEST_MARTDELIVERY_OPTION, 
    SAVING_MARTDELIVERY_ADDRESS, 
    DELETING_MARTDELIVERY_ADDRESS, 
    RESULT_MARTDELIVERY_ADDRESSLIST, 
    RESULT_MARTDELIVERY_ADDRESS,
    RESULT_MARTDELIVERY_OPTION,
    SAVED_MARTDELIVERY_ADDRESS,
    DELETED_MARTDELIVERY_ADDRESS
 } from "./../actions/mart_delivery";

const initialState = { 
    isFetchingMartDeliveryAddressList: false, 
    isFetchingMartDeliveryAddress: false, 
    isFetchingMartDeliveryOption: false,
    isSavingMartDeliveryAddress: false, 
    isDeletingMartDeliveryAddress: false, 
    mart_delivery_addresslist: [],
    mart_delivery_addresslist_total: null,
    mart_delivery_address: null,
    mart_delivery_option: null,
};

export default function(state = initialState, action) {
    switch (action.type) {  
        case REQUEST_MARTDELIVERY_ADDRESSLIST: { 
            return {
                ...state,
                isFetchingMartDeliveryAddressList: true,
                mart_delivery_addresslist: []
            };
        }

        case REQUEST_MARTDELIVERY_ADDRESS: { 
            return {
                ...state,
                mart_delivery_address: null,
                isFetchingMartDeliveryAddress: true
            };
        }

        case REQUEST_MARTDELIVERY_OPTION: { 
            return {
                ...state,
                mart_delivery_option: null,
                isFetchingMartDeliveryOption: true
            };
        }

        case SAVING_MARTDELIVERY_ADDRESS: { 
            return {
                ...state,
                mart_delivery_address: null,
                isSavingMartDeliveryAddress: true
            };
        }

        case DELETING_MARTDELIVERY_ADDRESS: { 
            return {
                ...state, 
                isDeletingMartDeliveryAddress: true
            };
        }
       
        case RESULT_MARTDELIVERY_ADDRESSLIST: {

            
            // local data
            // if(process.env.REACT_APP_LOCAL_DATA === "true") {
            //     localStorage.setItem('AGREGO_USER_ADDRESS', JSON.stringify(action.payload.data));
            // }

            return {
                ...state,
                isFetchingMartDeliveryAddressList: false,
                mart_delivery_addresslist : action.payload.data,
                mart_delivery_addresslist_total: action.payload.total
            };
        }
       
        case RESULT_MARTDELIVERY_ADDRESS: { 
            return {
                ...state,
                isFetchingMartDeliveryAddress: false,
                mart_delivery_address : action.payload.data
            };
        } 

        case RESULT_MARTDELIVERY_OPTION: { 
            
            // local data
            if(process.env.REACT_APP_LOCAL_DATA === "true") {
                localStorage.setItem('AGREGO_DELIVERY_OPTION', JSON.stringify(action.payload.data));
            }

            return {
                ...state,
                isFetchingMartDeliveryOption: false,
                mart_delivery_option : action.payload.data
            };
        }

        case SAVED_MARTDELIVERY_ADDRESS: { 
            return {
                ...state,
                isSavingMartDeliveryAddress: false,
                mart_delivery_address : action.payload.data
            };
        }

        case DELETED_MARTDELIVERY_ADDRESS: { 
            return {
                ...state,
                isDeletingMartDeliveryAddress: false
            };
        }

        default:
            return state;
  }
}