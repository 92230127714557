import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import alfaTrexLogo from "./../../../Images/courier/ATX.png";
// import EmptyState from './../../Layout/EmptyState';
import gosendLogo from "./../../../Images/courier/GOSEND.png";
import grabexpressLogo from "./../../../Images/courier/GRABEXPRESS.png";
import jneLogo from "./../../../Images/courier/JNE.png";
import jntLogo from "./../../../Images/courier/JNT.png";
import lionLogo from "./../../../Images/courier/LPA.png";
import ninjaLogo from "./../../../Images/courier/NIN.png";
import sapLogo from "./../../../Images/courier/SAP.png";
import sicepatLogo from "./../../../Images/courier/SCP.png";
import tikiLogo from "./../../../Images/courier/TIK.png";
import wahanaLogo from "./../../../Images/courier/WHN.png";
import imgLight from "./../../../Images/ic-light.png";
import UnsetPinpointImage from "./../../../Images/unset-pinpoint.png";
import {
  maxValidateDimensi,
  parseCurrency,
  validateDimensi,
} from "./../../../utils/common";
import Breadcrumb from "./../../Layout/Breadcrumb";
import Loading from "./../../Layout/Loading";
import "./../Product/Product.css";
import "./Courier.css";

export default class Courier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        CourierCode: null,
      },
      logoList: {
        jne: jneLogo,
        "j&t": jntLogo,
        tiki: tikiLogo,
        sicepat: sicepatLogo,
        "ninja xpress": ninjaLogo,
        "lion parcel": lionLogo,
        "go-send": gosendLogo,
        wahana: wahanaLogo,
        alfatrex: alfaTrexLogo,
        "grab express": grabexpressLogo,
        sap: sapLogo,
      },

      isLoading: true,
    };

    this.handleChangeCourier = this.handleChangeCourier.bind(this);
  }

  componentDidMount() {
    ReactGA.set({ title: "delivery_options" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleChangeCourier(Value) {
    let { values } = this.state;
    values = Value;

    this.setState({ values });
  }

  render() {
    const {
      isLoading,
      isLoadingFee,
      courierFees,
      couriers,
      breadcrumb,
      DeliveryID,
      summary,
      storeCourierFee,
    } = this.props;
    const { values, logoList } = this.state;

    return (
      <Container>
        {/* loading state */}
        {isLoading && <Loading />}

        {!isLoading && (
          <div className="courier-page-title">Opsi Pengiriman</div>
        )}

        <div style={{ marginTop: "28px", marginBottom: "22px" }}>
          {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
        </div>

        {!isLoading && couriers && Object.entries(couriers).length > 0 && (
          <div
            style={{
              border: "1px solid #848484",
              borderRadius: "8px",
              padding: "12px 24px",
              marginTop: "40px",
              marginBottom: "36px",
            }}
          >
            <img
              src={imgLight}
              height={20}
              style={{ marginRight: "24px" }}
              alt="icon light"
            />
            Perkiraan tiba dihitung sejak pesanan dikirim.{" "}
            <a href="/terms" target="_blank">
              Lihat Ketentuan Layanan
            </a>
          </div>
        )}

        {/* Empty Store Courier */}
        {!isLoading &&
          !storeCourierFee &&
          summary &&
          summary["StoreCourier"] &&
          couriers &&
          Object.entries(couriers).length > 0 && (
            // <div style={{marginTop:'50px',marginBottom:'25px', marginLeft:'45px', marginRight:'45px', width:'550px'}}>
            <div className="courier-info-layanan">
              Layanan <b>Pengiriman Oleh Toko</b> tersedia.{" "}
              <Button
                href={"/shop/address/" + DeliveryID + "/map?reference=courier"}
                variant="link"
                className="p-0"
              >
                Atur Pin Point Alamat Anda
              </Button>{" "}
              untuk mengaktifkan layanan ini
            </div>
          )}

        <Form>
          {!isLoading && storeCourierFee != null && (
            <div className={"courier-card"} style={{ position: "relative" }}>
              <div className="courier-info">
                <div className="courier-name">Pengiriman oleh Toko</div>
              </div>

              <Row className="courier-service">
                <Col xs={12} md={8} className="service-item">
                  <Form.Check
                    onChange={(e) =>
                      this.handleChangeCourier({
                        CourierID: "",
                        CourierCode: "StoreCourier",
                        CourierName: "StoreCourier",
                        CourierImage: "",
                        CourierServiceName: "",
                        CourierServiceDescription: "",
                        CourierServiceETA: "",
                        CourierServiceFee: storeCourierFee
                          ? storeCourierFee
                          : 0,
                        CourierServiceFeeAdjustment: "",
                        CourierServiceRateID: "",
                      })
                    }
                    inline
                    name="courier_service"
                    label={
                      // Form Label
                      <div className="service-name">
                        Toko{" "}
                        {summary &&
                          summary["StoreName"] &&
                          "(" + summary["StoreName"] + ")"}
                      </div>
                    }
                    type="radio"
                    id="StoreCourier"
                  />
                </Col>
                <Col xs={12} md={4} className="service-price">
                  Rp {parseCurrency(storeCourierFee)}
                </Col>
              </Row>
            </div>
          )}

          {!isLoading &&
            couriers &&
            Object.entries(couriers).length > 0 &&
            Object.entries(couriers).map((objCourier, index) => {
              const activeCourier = true;
              const [courierCode, courier] = objCourier;
              let validateCourier = [];
              let validateInfo = [];

              let courierLogo = Object.keys(logoList).includes(courierCode)
                ? logoList[courierCode]
                : "";

              // courierCode = courierCode.replace("jnt", "J&T");
              let costs =
                courierFees != null &&
                typeof courierFees[courierCode] !== "undefined"
                  ? courierFees[courierCode]
                  : [];

              return (
                <div
                  className={
                    "courier-card " +
                    (!isLoadingFee && (costs == null || costs.length <= 0)
                      ? "d-none"
                      : "")
                  }
                  style={{ position: "relative" }}
                  key={index}
                >
                  <div className="courier-info">
                    <div className="courier-name">{courier.name}</div>
                    <div className="courier-image">
                      <img src={courierLogo} alt={"Logo " + courier.name} />
                    </div>
                  </div>

                  {/* Load Service */}
                  {activeCourier && isLoadingFee && <Loading />}

                  {/* Service */}
                  {activeCourier &&
                    !isLoadingFee &&
                    costs.length > 0 &&
                    costs.map((fee, i) => {
                      let isValidate = validateDimensi(
                        fee["dimensi"],
                        summary["Dimensi"]
                      );
                      if (!isValidate) {
                        validateCourier.push({
                          dimensi: fee["dimensi"],
                          courier: {
                            service: fee.rate_name,
                            courier: courier.name,
                          },
                        });
                        validateInfo = maxValidateDimensi(validateCourier);
                      }

                      return (
                        <Row key={i} className="courier-service">
                          <Col xs={12} md={8} className="service-item">
                            <Form.Check
                              onChange={(e) =>
                                this.handleChangeCourier({
                                  CourierID: fee.rate_id,
                                  CourierCode: courierCode,
                                  CourierName: courier.name,
                                  CourierImage: courier.logo,
                                  CourierServiceName: fee.rate_name,
                                  CourierServiceDescription: fee.rate_desc,
                                  CourierServiceETA: fee.eta,
                                  CourierServiceFee: fee.finalRate,
                                  CourierServiceFeeAdjustment:
                                    fee.adjustmentFinalRate,
                                  CourierServiceRateID: fee.rate_id,
                                })
                              }
                              inline
                              name="courier_service"
                              label={
                                // Form Label
                                <div
                                  className="service-name"
                                  style={{
                                    color: !isValidate ? "#A0A0A0" : "#4F4F4F",
                                  }}
                                >
                                  {fee.rate_name}{" "}
                                  {fee.rate_desc !== "" && (
                                    <span>({fee.rate_desc})</span>
                                  )}
                                  <div
                                    className="service-etd"
                                    style={{
                                      color: !isValidate
                                        ? "#A0A0A0"
                                        : "#4F4F4F",
                                    }}
                                  >
                                    {fee.desc}
                                  </div>
                                </div>
                              }
                              type="radio"
                              id={`${courierCode}-${fee.rate_name}`}
                              disabled={!isValidate}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="service-price"
                            style={{
                              color: !isValidate ? "#A0A0A0" : "#212529",
                            }}
                          >
                            Rp {parseCurrency(fee.finalRate)}
                          </Col>
                        </Row>
                      );
                    })}

                  {validateInfo &&
                    validateInfo["dimensi"] &&
                    validateInfo["service"] && (
                      <div className="courier-info-layanan">
                        <div>
                          Dimensi produk melebihi ketentuan layanan{" "}
                          {validateInfo["service"]}. Batasan dimensi produk
                          yaitu {validateInfo["dimensi"]["Length"]} cm x{" "}
                          {validateInfo["dimensi"]["Width"]} cm x{" "}
                          {validateInfo["dimensi"]["Height"]} cm.{" "}
                          <Button
                            variant="link"
                            href="/terms"
                            target="_blank"
                            style={{ padding: "0" }}
                          >
                            Lihat Syarat dan Ketentuan Layanan
                          </Button>
                        </div>

                        <Button
                          variant="link"
                          href="/shop/cart"
                          className="float-right"
                        >
                          Kembali Ke Keranjang
                        </Button>
                        <div className="clearfix"></div>
                      </div>
                    )}
                </div>
              );
            })}

          {/* saat empty courier kemungkinan user belom set pinpoint */}
          {!isLoading &&
            !storeCourierFee &&
            parseInt(storeCourierFee) !== 0 &&
            (!couriers || Object.entries(couriers).length <= 0) && (
              <div className="text-center">
                {/* <EmptyState style={{margin:'auto'}} /> */}
                <div style={{ marginTop: "53px" }}>
                  <img
                    src={UnsetPinpointImage}
                    width={250}
                    className="mx-auto"
                    alt="icon pinpoint"
                  />
                  <div
                    style={{
                      marginTop: "59px",
                      fontSize: "24px",
                      color: "#000",
                    }}
                  >
                    Opsi pengiriman tidak tersedia. Atur Pin Point alamat Anda
                  </div>
                  <Button
                    href={
                      "/shop/address/" + DeliveryID + "/map?reference=courier"
                    }
                    style={{ marginTop: "39px" }}
                  >
                    Atur Pin Point Alamat
                  </Button>
                </div>
              </div>
            )}
        </Form>
        {!isLoading &&
          ((couriers && Object.entries(couriers).length) ||
            storeCourierFee) && (
            <Button
              variant="primary"
              disabled={values.CourierCode == null}
              onClick={(e) => this.props.onChangeCourier(values)}
              className="btn-simpan d-none d-md-block"
            >
              Pilih
            </Button>
          )}

        <div style={{ height: "100px" }}>&nbsp;</div>

        {/* Mobile view fixed in bottom */}
        {!isLoading && (
          <div className="product-summary-bottom d-sm-block d-md-none">
            <Button
              variant="primary"
              disabled={values.CourierCode == null}
              onClick={(e) => this.props.onChangeCourier(values)}
              block
            >
              Pilih
            </Button>
          </div>
        )}
      </Container>
    );
  }
}
