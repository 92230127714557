import { 
    ADD_TO_CART_DIGITAL, 
    GET_ALL_CART_DIGITAL, 
    RESULT_ADD_CART_DIGITAL, 
    REMOVE_CART_DIGITAL
} from "./../actions/digital_cart";

import {
    // getCarts, 
    getDigital,
    removeFromCartDigital
     } from "./../../utils/cart";
 
const initialState = {  
    stateCart: null,
    cartsDigital: null,
    cartDigital: getDigital(),
    cart: null,
    // isSubmitOrder: false, 
    // successOrder: false,
    // orderData: null
};

export default function(state = initialState, action) {
    switch (action.type) {  
        case ADD_TO_CART_DIGITAL: {
            // addToCart(action.payload.product);

            return {
                ...state, 
                stateCart: 'addcartDigital', 
                // cartDigital: getDigital()
                // carts: getCarts()
            };
        }

        case GET_ALL_CART_DIGITAL: {  
            return {
                ...state,
                stateCart: 'getAllCartDigital',
                cartDigital: getDigital()
            }
        }
        
        case RESULT_ADD_CART_DIGITAL: { 
            return {
                ...state, 
                stateCart: 'result-addcartDigital',
                cartsDigital: getDigital()
            };
        }

        case REMOVE_CART_DIGITAL: { 
            removeFromCartDigital();  

            return {
                ...state, 
                stateCart: 'result-addcartDigital',
                cartsDigital: getDigital()
            };
        }

        default:
            return state;
  }
}