import { faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { useHistory } from "react-router";
import imgEmptyCart from "./../../../Images/emptycart.png";
import icNoteOff from "./../../../Images/ic-note-off.png";
import icNoteOn from "./../../../Images/ic-note-on.png";
import { parseCurrency } from "./../../../utils/common";
import Breadcrumb from "./../../Layout/Breadcrumb";
import "./../Product/Product.css";
import ProductLain from "./../ProductLain";
import "./Cart.css";

function Cart({ isLoading, cartItems, cartSummary, breadcrumb, ...props }) {
  const iconPlus = <FontAwesomeIcon className="btn-qty" icon={faPlus} />;
  const iconMinus = <FontAwesomeIcon className="btn-qty" icon={faMinus} />;

  const history = useHistory();

  useEffect(() => {
    ReactGA.set({ title: "cart" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container>
      <div className="cart-page-title">Keranjang Saya</div>

      <Row>
        {!isLoading && (
          <Col xs={12} md={6}>
            {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
          </Col>
        )}

        {typeof cartItems !== "undefined" &&
          cartItems != null &&
          cartItems.length > 0 && (
            <Col className="text-right">
              <Button
                variant="link"
                onClick={(e) => props.onOpenModal(true, { type: "EMPTY_CART" })}
              >
                Hapus Semua
              </Button>
            </Col>
          )}
      </Row>

      <div className="clearfix"></div>

      {/* state keranjang kosong */}
      {!isLoading &&
        (typeof cartItems === "undefined" ||
          cartItems == null ||
          cartItems.length <= 0) && (
          <div className="empty-cart" style={{ marginBottom: "48px" }}>
            <img src={imgEmptyCart} alt="keranjang kosong" />
            <div className="empty-title">Keranjang anda masih kosong</div>
            <div className="empty-text">
              Pilih “Kembali ke Beranda” untuk menambahkan barang belanjaan Anda
            </div>
            <Button variant="primary" href="/beranda">
              Kembali ke Beranda
            </Button>
          </div>
        )}

      {typeof cartItems !== "undefined" &&
        cartItems != null &&
        cartItems.length > 0 &&
        cartItems.map((product, index) => {
          return (
            <Row key={index} className="cart-item-card" noGutters>
              <Col xs={4} md={3} style={{ position: "relative" }}>
                {/* TAMPILAN WEB */}

                <div className="d-none d-sm-block">
                  <a href={"/shop/product/" + product.ID + "/detail"}>
                    <img src={product.Img} alt="produk" width="100%" />
                    {parseInt(product.Discount) > 0 && (
                      <div className="discount-image">{product.Discount}%</div>
                    )}
                  </a>
                </div>

                {/* TAMPILAN MOBILE */}

                <div className="d-block d-sm-none">
                  <a href={"/shop/product/" + product.ID + "/detail"}>
                    <img src={product.Img} alt="produk" width="100%" />
                    {parseInt(product.Discount) > 0 && (
                      <div className="discount-image">{product.Discount}%</div>
                    )}
                  </a>
                </div>
              </Col>

              <Col xs={8} md={9} style={{ paddingLeft: "23px" }}>
                <Row noGutters>
                  <Col md={4} xs={12}>
                    <div className="product-title">
                      <a href={"/shop/product/" + product.ID + "/detail"}>
                        {product.Name}
                      </a>
                    </div>

                    {/* Medium Device */}
                    <div
                      className="d-none d-md-block"
                      style={{ paddingTop: "1px" }}
                    >
                      <span
                        className={
                          "product-price " +
                          (parseInt(product.Discount) > 0 &&
                            "product-price-discount")
                        }
                      >
                        Rp {parseCurrency(product.PromoPrice)}
                      </span>
                      {parseInt(product.Discount) > 0 && (
                        <span className="product-strip-price">
                          {" "}
                          Rp {parseCurrency(product.Price)}
                        </span>
                      )}
                    </div>

                    {/* {product.Weight > 0 && product.Unit !== "" && <div className="product-unit d-none d-md-block"> {product.Unit === "gr" ? "Berat : " : "Volume : "} {product.Weight} {product.Unit}</div>} */}
                    {product.Width && product.Height && product.Length ? (
                      <div className="product-unit d-none d-md-block">
                        Dimensi Satuan : {product.Length} cm x {product.Width}{" "}
                        cm x {product.Height} cm{" "}
                      </div>
                    ) : (
                      ""
                    )}

                    {parseInt(product.PurchaseType) === 2 && (
                      <div
                        className="btnPreordercart"
                        style={{ marginTop: "10px" }}
                      >
                        Pre Order
                      </div>
                    )}
                    {parseInt(product.PurchaseType) === 3 && (
                      <div
                        className="btn-madetoorder-cart"
                        style={{ marginTop: "10px" }}
                      >
                        Made To Order{" "}
                        {product.MinDays && ": " + product.MinDays + " hari"}
                      </div>
                    )}
                  </Col>

                  {/* Small Device */}
                  <Col xs={6} className="d-md-none mt-2">
                    <span
                      className={
                        "product-price-mobile " +
                        (parseInt(product.Discount) > 0 &&
                          "product-price-discount")
                      }
                    >
                      Rp {parseCurrency(product.PromoPrice)}{" "}
                    </span>
                    {parseInt(product.Discount) > 0 && (
                      <span className="product-strip-price">
                        Rp {parseCurrency(product.Price)}{" "}
                      </span>
                    )}
                  </Col>
                  <Col xs={6} className="text-right d-md-none mt-2">
                    <Row noGutters>
                      <Col md={2} xs={4}>
                        <Button
                          variant="link"
                          className="font-weight-bold btn-product"
                          disabled={product == null}
                          onClick={(e) =>
                            props.onChangeQty({
                              type: "DECREMENT",
                              product,
                            })
                          }
                          block
                          size="sm"
                        >
                          {iconMinus}
                        </Button>
                      </Col>

                      <Col md={8} xs={4}>
                        <Form.Control
                          type="text"
                          name="Qty"
                          disabled={product == null}
                          onChange={(e) =>
                            props.onChangeQty({
                              type: "CHANGE",
                              product,
                              value: e.target.value,
                            })
                          }
                          className="text-center input-product-qty input-space"
                          value={product.Qty}
                          placeholder="Enter Qty"
                          size="sm"
                        />
                      </Col>

                      <Col md={2} xs={4}>
                        <Button
                          variant="link"
                          className="font-weight-bold btn-product"
                          disabled={product == null}
                          onClick={(e) =>
                            props.onChangeQty({
                              type: "INCREMENT",
                              product,
                            })
                          }
                          block
                          size="sm"
                        >
                          {iconPlus}
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  {/* Medium Device */}
                  <Col md={4} className="text-right d-none d-md-block">
                    <Row noGutters>
                      <Col md={2} xs={4}>
                        <Button
                          variant="primary"
                          className="font-weight-bold btn-product"
                          disabled={product == null}
                          onClick={(e) =>
                            props.onChangeQty({
                              type: "DECREMENT",
                              product,
                            })
                          }
                          block
                          size="sm"
                        >
                          {iconMinus}
                        </Button>
                      </Col>

                      <Col md={8} xs={4}>
                        <Form.Control
                          type="text"
                          name="Qty"
                          disabled={product == null}
                          onChange={(e) =>
                            props.onChangeQty({
                              type: "CHANGE",
                              product,
                              value: e.target.value,
                            })
                          }
                          className="text-center input-product-qty input-space"
                          value={product.Qty}
                          placeholder="Enter Qty"
                          size="sm"
                        />
                      </Col>

                      <Col md={2} xs={4}>
                        <Button
                          variant="primary"
                          className="font-weight-bold btn-product"
                          disabled={product == null}
                          onClick={(e) =>
                            props.onChangeQty({
                              type: "INCREMENT",
                              product,
                            })
                          }
                          block
                          size="sm"
                        >
                          {iconPlus}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4} className="d-none d-md-block">
                    <div className="product-total-price">
                      Rp {parseCurrency(product.Total)}
                    </div>
                  </Col>

                  {/* Small device */}
                  <Col xs={6} className="d-md-none mt-2">
                    <Button
                      variant="link"
                      className="btn-delete"
                      size="md"
                      onClick={(e) =>
                        props.onOpenModal(true, {
                          type: "NOTE_PRODUCT",
                          id: product.ProductID,
                          ...product,
                        })
                      }
                    >
                      <img
                        src={
                          product.Note && product.Note !== ""
                            ? icNoteOn
                            : icNoteOff
                        }
                        title="catatan"
                        alt="catatan"
                        width={24}
                      />{" "}
                    </Button>
                    <Button
                      variant="link"
                      className="btn-delete"
                      size="md"
                      onClick={(e) => props.onDeleteProduct(product.ProductID)}
                    >
                      <FontAwesomeIcon icon={faTrash} />{" "}
                    </Button>
                  </Col>

                  <Col xs={6} className="d-md-none mt-2">
                    <div className="product-total-price">
                      Rp {parseCurrency(product.Total)}
                    </div>
                  </Col>
                </Row>
              </Col>

              <div className="btn-text-delete d-none d-md-block">
                <Button
                  variant="link"
                  className={
                    "float-left " +
                    (product.Note && product.Note !== ""
                      ? "text-success"
                      : "text-muted")
                  }
                  size="md"
                  onClick={(e) =>
                    props.onOpenModal(true, {
                      type: "NOTE_PRODUCT",
                      id: product.ProductID,
                      ...product,
                    })
                  }
                >
                  Catatan{" "}
                  <img
                    src={
                      product.Note && product.Note !== "" ? icNoteOn : icNoteOff
                    }
                    alt="note"
                    width={24}
                  />
                </Button>
                <Button
                  variant="link"
                  className="float-left"
                  size="md"
                  onClick={(e) => props.onDeleteProduct(product.ProductID)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </Row>
          );
        })}

      {/* <div className="product-line">&nbsp;</div> */}

      {typeof cartItems !== "undefined" &&
        cartItems != null &&
        cartItems.length > 0 && (
          <div className="cart-note d-none d-md-block">
            <Row>
              <Col md={9}>
                Total Dimensi Paket :{" "}
                {cartSummary && cartSummary["Dimensi"]
                  ? cartSummary["Dimensi"]["Length"] +
                    " cm" +
                    " x " +
                    cartSummary["Dimensi"]["Width"] +
                    " cm" +
                    " x " +
                    cartSummary["Dimensi"]["Height"] +
                    " cm"
                  : ""}
              </Col>
              <Col md={3}>
                <div className="cart-total-item">
                  Total Harga (
                  {cartSummary != null && cartSummary["TotalQty"] + " item"})
                </div>
                <div className="cart-total-price">
                  {cartSummary != null &&
                    "Rp " + parseCurrency(cartSummary["TotalPrice"])}
                </div>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    ReactGA.event({
                      category: "shopping",
                      action: "begin_checkout",
                    });

                    return history.push("/shop/delivery");
                  }}
                  disabled={isLoading}
                  block
                >
                  Lanjut
                </Button>
              </Col>
            </Row>
          </div>
        )}

      {typeof cartItems !== "undefined" &&
        cartItems != null &&
        cartItems.length > 0 && (
          <div className="cart-note d-block d-md-none">
            <Row className="text-center">
              <Col xs={12}>Total Dimensi Paket :</Col>
              <Col xs={12}>
                {cartSummary && cartSummary["Dimensi"]
                  ? cartSummary["Dimensi"]["Length"] +
                    " cm" +
                    " x " +
                    cartSummary["Dimensi"]["Width"] +
                    " cm" +
                    " x " +
                    cartSummary["Dimensi"]["Height"] +
                    " cm"
                  : ""}
              </Col>
            </Row>
          </div>
        )}

      {/* desktop view */}
      {/* <Col className="d-none d-md-block">
                        <div className="product-summary-card">
                            <p className="font-weight-bold">Ringkasan Belanja</p>   
                            <div className="box-total mb-3">
                                <div className="label-total">Total Harga ({cartSummary.TotalQty} produk)</div>
                                {cartSummary != null && <div className="total-price">Rp{parseCurrency(cartSummary.TotalPrice)}</div>} 
                            </div>
                            <Button variant="primary" href="/shop/delivery" block>Lanjutkan</Button> 
                        </div>
                    </Col>  */}

      {/* Mobile view fixed in bottom */}
      <div className="product-summary-bottom d-sm-block d-md-none">
        <Row>
          <Col xs={8}>
            <div className="label-total">Total Harga</div>
            <div className="label-price">
              {cartSummary != null && (
                <div className="total-price">
                  Rp {parseCurrency(cartSummary.TotalPrice)}
                </div>
              )}
            </div>
          </Col>
          <Col xs={4}>
            <Button
              variant="primary"
              onClick={(e) => {
                ReactGA.event({
                  category: "shopping",
                  action: "begin_checkout",
                });

                return history.push("/shop/delivery");
              }}
              block
            >
              Lanjutkan
            </Button>
          </Col>
        </Row>
      </div>

      {/* Product Lain */}
      {!isLoading && cartSummary && cartSummary["StoreID"] && (
        <div style={{ background: "#fff", marginTop: "32px" }}>
          <ProductLain
            className="produklain-carousel"
            minimalItem={2}
            storeID={cartSummary && cartSummary["StoreID"]}
          />
        </div>
      )}

      <div style={{ height: "80px" }}>&nbsp;</div>
    </Container>
  );
}

export default Cart;
