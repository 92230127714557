import Axios from "axios";
import { setQueryString } from "./../../utils/common";
import { handleError } from "./app";

// const searchFieldType = {"newsID": "int"}

// baris di bawah ini di hapus aja gpp sama function laen yang ga diperluin lagi ndri

// 1. buat action type untuk mengambil data berita dari API
// 2. buat action creator / function untuk menghandle yang menjalankan request untuk mengambil data dari API

// Action type untuk news, ini bisa inline di file ini atau di import dari actionTypes.js
export const REQUEST_PROVIDER = "REQUEST_PROVIDER";
export const RESULT_PROVIDER = "RESULT_PROVIDER";
export const REQUEST_DIGITAL_BRAND = "REQUEST_DIGITAL_BRAND";
export const RESULT_DIGITAL_BRAND = "RESULT_DIGITAL_BRAND";

// Action Creator
// state ketika sedang loading request data
export const requestProvider = () => ({
  type: REQUEST_PROVIDER,
});

export const requestDigitalBrand = () => ({
  type: REQUEST_DIGITAL_BRAND,
});

// state ketika data sudah berhasil di dapat
export const resultProvider = (data) => ({
  type: RESULT_PROVIDER,
  payload: {
    data,
  },
});

export const resultDigitalBrand = (data) => ({
  type: RESULT_DIGITAL_BRAND,
  payload: {
    data,
  },
});

export const fetchProvider = () => {
  return (dispatch) => {
    dispatch(requestProvider());

    return Axios.get("/customdata/0=7/providerscheme")
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultProvider(data.data));
      })
      .catch((error) => dispatch(handleError(error)));
  };
};

export const fetchDigitalBrand = (props) => {
  props = typeof props !== "undefined" ? props : {};
  // props.searchFieldType = searchFieldType;
  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestDigitalBrand());

    return Axios.get(
      "/digitalbrand/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultDigitalBrand(data.data));
      })
      .catch((error) => dispatch(handleError(error)));
  };
};
