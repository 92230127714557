import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import './PLNPrepaid.css';
import '../ppob.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Breadcrumb from '../../Layout/Breadcrumb';
import { parseCurrency } from './../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'; 

const PLNPrepaidSchema = Yup.object().shape({
    NoPlnPrepaid: Yup.number()
        .typeError('ID Pelanggan harus berupa angka')
        .required('ID Pelanggan harus diisi')
        .min(1000000000, 'ID Pelanggan harus 10 digit atau lebih'),

});


export default function PLNPrepaid(props) {
    const { isLoading, breadcrumb, providerProduct, cekProduct, CartDigital } = props;
    const iconClose = <FontAwesomeIcon className="btn-qty" icon={faTimesCircle} />
    var disabledbtn; 


    return (
        <Formik
            enableReinitialize={true}
            validationSchema={PLNPrepaidSchema}
            initialValues={{ NoPlnPrepaid: '' }}
            onSubmit={(values) => {
                // ketika di submit data form di variable values dikirim ke parent component lewat props

                // kirim form ke parent lewat onSubmitProfile
                props.onHandlePlnPrepaidNo(values);
            }}


        >
            {({ errors, touched, handleChange, handleBlur, handleSubmit, values, isSubmitting, setFieldValue, setFieldTouched }) => {



                const onChangePlnPrepaidNo = (e) => {

                    props.onHandlePlnPrepaidNo(e.target.value)
                    setFieldValue('NoPlnPrepaid', e.target.value)

                }

                const onClearForm = (e) => {

                    props.onClearForm(e.target.value)

                    setFieldValue('NoPlnPrepaid', '')
                    setFieldTouched('NoPlnPrepaid', true, true) 

                } 


                if(values['NoPlnPrepaid'] !== '' && !errors['NoPlnPrepaid']){
                    if( cekProduct !== undefined && !cekProduct){
                        disabledbtn = false
                    }

                    else{
                        disabledbtn = true;
                    }
                    
                } else{
                    disabledbtn = true;
                } 


                 if(values['NoPlnPrepaid'] !== ''){
                    //  console.log('isi');
                 }

                 else{
                    //  console.log('kosong')
                 }

                return (
                    <Container fluid>

                        {/* TAB MENU PPOB */}

                        <div className="d-none d-sm-block">

                            <Row style={{ marginLeft: '54px', background: '#FFFF' }} >
                                <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item">
                                        <a href="/digital/pulsa">Pulsa dan Data</a>
                                    </div>
                                </Col>

                                <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item-active">
                                        <a href="/digital/plnprepaid">Token Listrik</a>
                                    </div>
                                </Col>

                                <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item">
                                        <a href="/digital/plnpascabayar">Tagihan Listrik</a>
                                    </div>
                                </Col>

                                {/* <Col md={2} className="ppob-custom-tab">
                                    <div className="ppob-custom-tab-Nav-Item">
                                        <a href="/digital/bpjs">BPJS</a>
                                    </div>
                                </Col> */}

                            </Row>
                        </div>

                         {/* TAMPILAN MOBILE */}
                         <div className="d-block d-sm-none">

                            <div className="scrollmenu">
                                <a href="/digital/pulsa">Pulsa dan Data</a>
                                <a className="scrollmenu-active" href="/digital/plnprepaid">Token Listrik</a>
                                <a href="/digital/plnpascabayar">Tagihan Listrik</a>
                            
                                {/* <a href="/digital/bpjs">BPJS</a> */}
                                </div>

                                </div>


                        <Row>
                            <Col>
                                <div style={{ marginTop: '20px', paddingBottom: '0px', marginLeft: '8%' }}>
                                    {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {/* FORM TOKEN */}
                            <div className="tokenContainer">

                                <Col md={12}><div className="tokenJudul">Beli Token</div></Col>
                                <Col md={12}>
                                    <div className="col-md-4" style={{ paddingLeft: '0px' }}>
                                        <Form >
                                            <Form.Group>
                                                <Form.Label className="labelToken">Nomor meter/ID Pelanggan</Form.Label>
                                                <Form.Control
                                                    name="NoPlnPrepaid"
                                                    onBlur={handleBlur}
                                                    onChange={onChangePlnPrepaidNo}
                                                    value={CartDigital !== null ? values.NoPlnPrepaid : ''}
                                                />
                                                {values['NoPlnPrepaid'] !== '' && <Button variant="link" className="btnplnClose" onClick={onClearForm} >{iconClose}</Button>}

                                            </Form.Group>

                                            {errors.NoPlnPrepaid && touched.NoPlnPrepaid &&
                                                <div className="errorTextPLN">
                                                    <Form.Text className="text-danger">
                                                        {errors.NoPlnPrepaid}
                                                    </Form.Text>
                                                </div>} 
                                        </Form>
                                    </div>
                                </Col>

                                <Col md={12}><div className="nominalFont">Nominal</div></Col>
                                <Col md={12}>
                                    <div className="priceListContainerPrepaid">
                                        {CartDigital !== null &&
                                            <Row> 
                                                {!isLoading && providerProduct.length <= 0 && <div style={{ textAlign: 'center' }}>Produk Tidak Tersedia</div>}
                                                {providerProduct.length > 0 && !isLoading && providerProduct.map(item => (


                                                    item.Name === 'Inquiry PLN' ? '' :
                                                        <Col md={4} xs={12}>


                                                            <Button className={CartDigital.ProductID === item.ID ? "priceContainerSelected" : "priceContainer"} onClick={(e) => props.onHandleChangePlnPrepaidProduct({ Name: item.Name, Price: item.Price, Fee: item.Fee, Total: parseInt(item.Price) + parseInt(item.Fee), ProductID: item.ID, Description: item.Description, Image: '', ProviderName: '', ProviderImage: '', ProviderDescription: '' })}  >
                                                                <div className="btnPriceContainer">
                                                                    <div className="nameFont">{item.Name}</div><div className="priceFont">Rp {parseCurrency(item.Price)} </div>
                                                                </div>
                                                            </Button>

                                                        </Col> 

                                                ))}

                                            </Row>
                                        }
                                    </div> 
                                </Col>

                                <Col md={4} className="btnToken">
                                    <Button variant="primary" block disabled={disabledbtn} onClick={e => props.onClickSubmit(values)}>Lanjut</Button>
                                </Col> 
                            </div>
                        </Row> 
                    </Container>)
            }}

        </Formik>)
}