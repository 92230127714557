import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
// import Geocode from "react-geocode";
import MarkerImg from './../../Images/map-marker.svg'; 
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarked, faSearch } from '@fortawesome/free-solid-svg-icons';

const defaultCenter = [-6.399486, 106.822700]
const defaultZoom = 18

const Marker = (props) => (<React.Fragment>
  <div style={{ position: 'relative' }}>
    <img src={MarkerImg} height={32} alt="marker" style={{ top: '-16px', left: '-16px', position: 'absolute' }} />
    {props.showInfo && typeof (props.SHOWADDRESSTITLE) !== "undefined" && (
      <div style={{
        width: '250px',
        height: '50px',
        padding: '8px',
        position: 'absolute',
        bottom: '18px',
        left: '-120px',
        backgroundColor: "#fff"
      }}
      >
        {/* <h6>{props.title}</h6> */}
        {props.address}
      </div>
    )}
  </div>
</React.Fragment>);

function GMap(props) {

  const [init, setInit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [position, setPosition] = useState(null)
  const [center, setCenter] = useState(null)
  const [zoom, setZoom] = useState(10)

  const input = useRef(null);
  const searchBox = useRef(null);  

  const handleApiLoaded = (map, maps) => { 
    setInit(true)
    searchBox.current = new maps.places.SearchBox(input.current);
    searchBox.current.addListener('places_changed', handleOnPlacesChanged);
  } 

  const handleOnPlacesChanged = () => {  
    let places = searchBox.current.getPlaces();
    let location = places != null && places[0] != null && places[0]['geometry'] != null && places[0]['geometry']['location'] ? places[0]['geometry']['location'] : null;
     
    let lat = location.lat();
    let lng = location.lng();

    setCenter([lat, lng])
    setPosition([lat, lng])
    setZoom(parseInt(process.env.REACT_APP_GMAP_FOCUS_ZOOM)) 
    props.onChange(lat, lng)
  }

  const handleSearchLocation = (search) => {
    setIsLoading(true)
    navigator.geolocation.getCurrentPosition(function (position) {
      let { latitude, longitude } = position.coords;
      latitude = parseFloat(latitude);
      longitude = parseFloat(longitude);
      
      if(!isNaN(latitude) && !isNaN(longitude)) {
        setCenter([latitude, longitude])
        setPosition([latitude, longitude])
        setZoom(parseInt(process.env.REACT_APP_GMAP_FOCUS_ZOOM)) 
        
        setIsLoading(false) 
        setInit(true)

        if(search) {
          props.onChange(latitude, longitude)
        }
      } 
      
    });
  } 

  const _onDrag = (map) => {  
    let lat = map.center.lat();
    let lng = map.center.lng();
    setPosition([lat, lng]) 
  }

  const _onDragEnd = (map) => {

    let lat = map.center.lat();
    let lng = map.center.lng(); 

    setCenter([lat, lng]) 
    props.onChange(lat, lng)

    // this.loadGeocode(lat, lng)
  }

  useEffect(() => {
    if (props.data && !isNaN(parseFloat(props.data.Latitude)) && !isNaN(parseFloat(props.data.Longitude)) && 
    ((position && parseFloat(position[0]) !== parseFloat(props.data.Latitude) && parseFloat(position[1]) !== parseFloat(props.data.Longitude)) || !position)) {
      let {Latitude, Longitude} = props.data
      Latitude = parseFloat(Latitude);
      Longitude = parseFloat(Longitude); 

      setCenter([Latitude, Longitude])
      setPosition([Latitude, Longitude]) 
      setZoom(parseInt(process.env.REACT_APP_GMAP_FOCUS_ZOOM))
    }
  }, [props.data])   

  return (<>
    <div> Geser peta untuk menyesuaikan lokasi </div> 
    
    <Button variant="link" title="Temukan Lokasi Saya" className="float-right mt-3" onClick={e => handleSearchLocation(true)} disabled={isLoading}>
      {<div className="d-none d-lg-block"> <FontAwesomeIcon icon={faMapMarked} /> {isLoading ? 'Mencari Lokasi...' : 'Temukan Lokasi Saya'}</div>}
      {<div className="d-block d-lg-none"> <FontAwesomeIcon icon={faMapMarked} /> </div>}
    </Button>
    <div style={{width:'70%', margin:'18px 0', position:'relative'}}>
      
      <input ref={input} placeholder="Cari Lokasi" className="form-control" type="text" />
      <FontAwesomeIcon icon={faSearch} style={{position:'absolute', right:'10px', top:'10px'}} />
    </div>
    
    <div style={{ height: '400px', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_KEY, libraries: ["map","places"] }}
      defaultCenter={defaultCenter}
      center={center}
      defaultZoom={parseInt(defaultZoom)}
      zoom={zoom}
      onDrag={_onDrag}
      onDragEnd={_onDragEnd}
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      {position && <Marker
        lat={position[0]}
        lng={position[1]}
        text="My Marker"
      />}
    </GoogleMapReact>
    </div>
    
    
  </>)
}

export default GMap;