import React, {useState} from 'react'; 
import {Container, Row, Col, Button, Modal} from 'react-bootstrap';  
import {parseCurrency, parseDateTime} from './../../../utils/common';   
import {colorOrderStatus} from './../../../utils/order';   
import Breadcrumb from './../../Layout/Breadcrumb';   
import Pagination from "react-js-pagination";
import Loading from './../../Layout/Loading';
import EmptyState from './../../Layout/EmptyState';
import BottomNav from './../../Layout/BottomNav';
import './Order.css'; 
import { useHistory } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import PlnPrepaid from './OrderDetailPlnPrepaid.component';
import PlnPostpaid from './OrderDetailPlnPostpaid.component';
import BpjsKes from './OrderDetailBpjsKes.component';

// function CustomToggle({ children, eventKey, className, variant }) {
//     const [isToggle, setIsToggle] = useState(false);
//     const decoratedOnClick = useAccordionToggle(eventKey, () =>
//         setIsToggle(!isToggle)
//     );
  
//     return (
//       <Button
//         variant={variant}
//         className={className}
//         style={{marginLeft:isToggle ? '-35px' : '-85px'}}
//         onClick={decoratedOnClick}
//       >
//         {isToggle ? <span>Tutup <FontAwesomeIcon icon={faChevronUp}/></span> : children}
//       </Button>
//     );
//   }

// custom label status order
// const labelStatusOrder = (status, schema) => {

//     let label = ""

//     switch(parseInt(status)) {
//         // Siap diantar
//         // case 4:
//         //     label = "Pesanan Diproses";
//         // break;

//         case 5:
//             label = "Pesanan Dikirim";
//         break;
        
//         case 6:
//             label = "Pesanan Tiba";
//         break;

//         default:
//             label = schema[status];
//         break;
//     }
    
//     return label.toUpperCase();
// }

export function Order (props) { 

    const {isLoading, orders, orderStatus, orderStatusSchema, totalOrder, activePage, itemPerPage, filterData, breadcrumb} = props;
    const history = useHistory();     
    // const noCollapseProduct = 2;   
    const [modalDetailOrder, setModalDetailOrder] = useState(false);
    const [dataDetailOrder, setDataDetailOrder] = useState(null);

    const toggleModal = (openModal, data) => { 

        data = openModal ? data : null;

        setDataDetailOrder(data)
        setModalDetailOrder(openModal) 
    }

    const responsiveMart = { 
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
        }
      };  
    
    return (
        <Container> 

            {<div className="digital-order-page-title">Pesanan Saya</div>}
            
            {<div className="digital-order-breadcrumb">
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
            </div>}

            {/* {<div className="digital-order-filter-text">Filter Status Pesanan</div>} */}

            {/* Tab Status */}
            {!isLoading && <ul className="tab-digital-order">
                <li style={{width:'50%'}}><a href="/shop/order">Daftar Belanja</a></li>
                <li style={{width:'50%'}} className="active"><a href="/digital/order">Pulsa, Data, & PPOB </a></li> 
            </ul>} 

            <Carousel
                responsive={responsiveMart} className="digital-order-carousel" > 
                {orderStatus != null && orderStatus.length > 0 && orderStatus.map((item, index) => {  

                let url = "/digital/order" + (!isNaN(parseInt(item['Value'])) ? "?status=" + item['Value'] : "");

                return ( 
                <div key={index} className={"digital-order-carousel-item "+(!isLoading && typeof(filterData['OrderStatus']) !== "undefined" && parseInt(filterData['OrderStatus']) === parseInt(item['Value']) ? 'active' : (typeof(filterData['OrderStatus']) === "undefined" && item['Value'] === "all" ? 'active' : '') )} onClick={e => history.push(url)}>  
                    <div>{item['TextTranslate']}</div> 
                </div>)  

                })} 
            </Carousel> 

            {isLoading && <Loading />} 

            {!isLoading && (orders == null || orders.length <= 0) && <EmptyState className="text-center" style={{marginTop:'5rem'}} teks="Belum ada data" />}

            <div className="digital-order-content">
            {!isLoading && typeof(orders) !== "undefined" && orders != null && orders.length > 0 && orders.map((order,index) => { 
            // let TotalBelanja = order != null ? parseInt(order.TotalPrice) : 0;
            
            return (          
            <div className="digital-order-item-card" key={index}> 
                <div className="digital-order-item-header">
                    <Row>
                        <Col xs={12}> 
                            <div className="digital-order-product-date">{parseDateTime(order.CreatedAt)} WIB</div>
                        </Col>
                        {/* <Col md={6} xs={12}> 
                            <div className="digital-order-payment-status">
                                Status Pembayaran: <span style={{color:colorPaymentStatus(order.PaymentStatus) }}>{labelOrderPayment(order.PaymentStatus)}</span>
                                <span className="text-success">{orderStatusSchema != null && orderStatusSchema[order.OrderStatus] != null && labelStatusOrder(order.OrderStatus, orderStatusSchema)}</span>
                            </div>
                        </Col> */}
                    </Row>  
                </div>
                <div className="digital-order-item-line">&nbsp;</div>
                <div className="digital-order-item-body"> 
                    <Row>
                        <Col md={3} xs={4}>
                            <div className="digital-order-product-img">
                                <img src={order.ProductImg} alt={"Image "+order.ProductName} />
                            </div>
                        </Col>
                        <Col md={9} xs={8}>
                            <Row>
                                <Col md={6}>
                                    {/* <div className="font-weight-bold">{order.ProductCategory} {order.ProductName}</div> */}
                                    <div className="font-weight-bold">{order.ProductName}</div>
                                    <div>{[28,29].includes(parseInt(order.CategoryID)) ? 
                                            <span>{order.Reference}</span> : 
                                            <Button variant="link" size="sm" style={{paddingLeft:'0'}} onClick={e => toggleModal(true, order)}>Lihat Detail Pesanan</Button>}
                                    </div>
                                </Col> 
                                <Col md={6}>
                                    <div className="order-digital-item-price">Rp {parseCurrency(order.Price)}</div>
                                </Col> 
                            </Row>
                            <Row style={{marginTop:'36px'}}>
                                <Col md={6} style={{marginBottom:'8px'}}>
                                    <div>Status</div>
                                    <div className="font-weight-bold">{orderStatusSchema != null && typeof orderStatusSchema[order.OrderStatus] !== "undefined" && 
                                        <Button variant="link" className="text-left" style={{padding:'0', color:colorOrderStatus(order.OrderStatus)}} href={"/digital/order/"+order['TransactionID']+"/status"}>{orderStatusSchema[order.OrderStatus]}</Button>}</div>
                                </Col> 
                                <Col md={6}>
                                    <div>Pilihan Pembayaran</div>
                                    <div className="font-weight-bold">{order.Payment}</div>
                                    {order.PaymentType === "Bank" && parseInt(order.OrderStatus) === 1 && 
                                        <Button style={{paddingLeft:'0px',paddingTop:'8px'}} variant="link" size="sm" href={"/digital/order/"+order.TransactionID+"/payment"}>Lihat Detail Pembayaran</Button>}
                                </Col> 
                            </Row>
                        </Col> 
                    </Row> 
                </div>  
            </div>)})} 

            </div>

            {/* {!isLoading && orders != null && orders.length > 0 && parseInt(totalOrder) > parseInt(itemPerPage) && <Row className="justify-content-center" style={{margin:'10px 0px'}}><Pagination */}
            {!isLoading && parseInt(totalOrder) > parseInt(itemPerPage) && <Row className="justify-content-center" style={{margin:'10px 0px'}}><Pagination
                activePage={activePage}
                itemsCountPerPage={itemPerPage}
                totalItemsCount={totalOrder}
                pageRangeDisplayed={3}
                onChange={props.onChangePage}
                itemClass="page-item"
                linkClass="page-link"
                
                /></Row>}

            
            {/* Bottom Nav */}
            <BottomNav activeKey={2} />

            <Modal show={modalDetailOrder} size="lg" onHide={e => toggleModal(false)}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Detail Pesanan</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="digital-order-detail-pesanan">
                    <Button className="close-btn-detail-pesanan" variant="link" onClick={e => toggleModal(false)}><FontAwesomeIcon icon={faTimes} /> </Button>
                    <div className="text-center title-detail-pesanan">Detail Pesanan</div>
                    {dataDetailOrder != null && dataDetailOrder['CategoryID'] === 56 && <PlnPostpaid order={dataDetailOrder} />}
                    {dataDetailOrder != null && dataDetailOrder['CategoryID'] === 30 && <PlnPrepaid order={dataDetailOrder} />}
                    {dataDetailOrder != null && dataDetailOrder['CategoryID'] === 27 && <BpjsKes order={dataDetailOrder} />}
                </Modal.Body>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={e => toggleModal(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={e => toggleModal(false)}>
                    Save Changes
                </Button>
                </Modal.Footer> */}
            </Modal>


        </Container>
    )
}

export default Order;