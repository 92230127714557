import { REQUEST_MARTCATEGORY, RESULT_MARTCATEGORY } from "./../actions/mart_category";

const initialState = { 
    isFetchingMartCategory: false, 
    mart_category: [],
};

export default function(state = initialState, action) {
    switch (action.type) { 
        case REQUEST_MARTCATEGORY: { 
            return {
                ...state,
                isFetchingMartCategory: true
            };
        }
       
        case RESULT_MARTCATEGORY: {
            const { data } = action.payload;  
            
            // local data
            if(process.env.REACT_APP_LOCAL_DATA === "true") {
                localStorage.setItem('AGREGO_CATEGORY', JSON.stringify(data));
            }

            return {
                ...state,
                isFetchingMartCategory: false,
                mart_category : data
            };
        }

        default:
            return state;
  }
}