import React from 'react';
import { 
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import DigitalConfirmation from './DigitalConfirmation.component';
import DigitalConfirmationPPOB from './DigitalConfirmationPPOB.component';
import DigitalConfirmationPlnPostpaid from './DigitalConfirmationPlnPostpaid.component';
import DigitalConfirmationPlnPrepaid from './DigitalConfirmationPlnPrepaid.component';
import DigitalConfirmationBpjskes from './DigitalConfirmationBpjskes.component'; 
import { submitDigitalOrder, fetchDigitalOrderById } from '../../../redux/actions/digital_order';
import { getPaymentNote } from './../../../utils/cart';
// import {fetchProvider, fetchDigitalBrand} from '../../../redux/actions/digital_brand';
import{getCartDigital, addCartDigital} from '../../../redux/actions/digital_cart';
// import {fetchProviderProduct} from '../../../redux/actions/digital_product';
// import { string } from 'yup'; 
import { normalizePrice } from '../../../utils/common';

class DigitalConfirmationContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      digitalCarts: [],
      digitalOrder: null,
      paymentNote: null,
      submittingOrder: false,
      action: null,
      cekWalletBalance: true,

      reloadCountInquiry: 0,
      maxReloadInquiry: 60, //60 kali reload setiap 5 detik = 5 menit
      loadInquiry: false,
      successInquiry: false,
      intervalId: null,
      timeIntervalInquiry: 5000,
      paymentTypeInfoBayar: ['bank', 'qris']
    }

    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.reloadInquiry = this.reloadInquiry.bind(this);

  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    this.loadData();

  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    let { breadcrumb, paymentNote, cekWalletBalance } = this.props.match;
    const { cartDigital } = this.props.digitalcart;
    const { isSubmitMartOrder, isFetchingDigitalOrder, digital_order } = this.props.digitalorder;
    const { submittingOrder, timeIntervalInquiry, paymentTypeInfoBayar } = this.state;
    let { isLoading, reloadCountInquiry, loadInquiry, intervalId, action } = this.state; 

    // State ketika selesai order
    if (submittingOrder && !isSubmitMartOrder && digital_order != null && digital_order.TransactionID != null) {
      
      // redirect ke petunjuk pembayaran
      if(paymentTypeInfoBayar.includes(digital_order['PaymentType'].toLowerCase())) {
        this.props.history.push('/digital/order/' + digital_order.TransactionID + '/payment');
      } 
      // redirect ke status
      else {
        this.props.history.push('/digital/order/' + digital_order.TransactionID + '/status');
      }

    }   
    
    if (isLoading && !isFetchingDigitalOrder) { 

      // cek category name inquiry 
      let inquiry = ["token","tagihan","bpjs"].filter(cat => { 
        let lowerCat = cartDigital['CategoryName'].toLowerCase(); 
        return lowerCat.indexOf(cat) >= 0;
      });   

      if (inquiry.length > 0) {

        action = "inquiry";

        let link = cartDigital != null && cartDigital['CategoryURL'] ? cartDigital['CategoryURL'] : '/digital/plnprepaid';
        let text = cartDigital != null && cartDigital['CategoryName'] ? cartDigital['CategoryName'] : 'PPOB';

        breadcrumb = [
          { link: '/beranda', text: 'Beranda' },
          { link: link, text: text },
          { link: '#', text: 'Atur Pesanan' }, 
        ]

      }
      else {

        breadcrumb = [
          { link: '/beranda', text: 'Beranda' },
          { link: '/digital/pulsa/', text: 'Pulsa & Data' },
          { link: '#', text: 'Atur Pesanan' },

        ]
      }     

      isLoading = false;

      // Load data order
      if(action === "inquiry" && cartDigital != null &&  
        cartDigital['TransactionID'] != null && intervalId == null) { 
        intervalId = setInterval(this.reloadInquiry, timeIntervalInquiry); 
      }  

      paymentNote = getPaymentNote("digital")  

      this.setState({
        CartDigital: cartDigital,
        digitalOrder: digital_order,
        isLoading,
        breadcrumb,
        paymentNote,
        cekWalletBalance,
        reloadCountInquiry,
        loadInquiry,
        intervalId,
        action,
        inquiry

      })


    }

  }
  
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  loadData(param) {
    const { breadcrumb } = this.props.match;
    let { action } = this.state;
    const { extra } = this.props.match.params;
    this.props.getCartDigital();

    if (extra != null) {
      action = "inquiry";
    } 

    this.setState({
      isLoading: true,
      breadcrumb: breadcrumb,
      action,
    });
  }

  reloadInquiry() {
    const {CartDigital, reloadCountInquiry, maxReloadInquiry} = this.state; 
    const { isFetchingDigitalOrder, digital_order } = this.props.digitalorder; 
    let {successInquiry} = this.state; 

    // Cek jika digital order sudah mendapat respon yang sesuai dari DR
    if(digital_order != null && !isFetchingDigitalOrder && (
        (digital_order['Note'] != null && digital_order['Note'] !== "" && digital_order['Note'].indexOf("proses") === -1) || 
        (reloadCountInquiry >= maxReloadInquiry)
      )) { 

      if(digital_order['Note'] != null && digital_order['Note'] !== "" && digital_order['Note'].indexOf("proses") === -1) {
        successInquiry = true;
      }
 
      if(CartDigital['CategoryName'].toLowerCase() === "tagihan listrik" && digital_order['ParseData'] != null) {
          
        let Total = digital_order['ParseData']['Total'] != null ? normalizePrice(digital_order['ParseData']['Total']) : 0;
        let Fee = digital_order['ParseData']['AdminFee'] != null ? normalizePrice(digital_order['ParseData']['AdminFee']) : 0;
        let Price = parseInt(Total) - parseInt(Fee); 

        this.props.addCartDigital({ ...CartDigital, Price, Fee, Total}); 
        
        this.props.getCartDigital()
      }

      // TODO 
      // PARSING data kolom NOTE agar bisa terbaca (parser) 

      // console.log('selesai');

      this.setState({isLoading: true, loadInquiry: true, successInquiry})
      return clearInterval(this.state.intervalId); 
    }  
    
    this.props.fetchDigitalOrderById(CartDigital['TransactionID'])
    this.setState(prev => ({reloadCountInquiry: prev.reloadCountInquiry + 1}))
  }

  handleSubmitOrder() {
    const { CartDigital } = this.state;

    this.props.submitDigitalOrder(CartDigital)
    this.setState({ submittingOrder: true })
  }

  render() {
    const { isLoading, breadcrumb, CartDigital, paymentNote, action, inquiry, cekWalletBalance, digitalOrder, loadInquiry, successInquiry } = this.state;

    let renderComponent = null; 

    if (action === "inquiry") {
      renderComponent =
        <DigitalConfirmationPPOB
          isLoading={isLoading}
          digitalOrder={digitalOrder}
          breadcrumb={breadcrumb}
          CartDigital={CartDigital}
          paymentNote={paymentNote}
          loadInquiry={loadInquiry}
          successInquiry={successInquiry}

          onSubmitOrder={this.handleSubmitOrder}
        />;
    }

    if (action === "inquiry" && inquiry[0] === "tagihan") {
      renderComponent =
        <DigitalConfirmationPlnPostpaid
          isLoading={isLoading}
          digitalOrder={digitalOrder}
          breadcrumb={breadcrumb}
          CartDigital={CartDigital}
          paymentNote={paymentNote}
          loadInquiry={loadInquiry}
          successInquiry={successInquiry}

          onSubmitOrder={this.handleSubmitOrder}
        />;
    } 
    
    if (action === "inquiry" && inquiry[0] === "token") {
      renderComponent =
        <DigitalConfirmationPlnPrepaid 
          isLoading={isLoading}
          digitalOrder={digitalOrder}
          breadcrumb={breadcrumb}
          CartDigital={CartDigital}
          paymentNote={paymentNote}
          loadInquiry={loadInquiry}
          successInquiry={successInquiry}

          onSubmitOrder={this.handleSubmitOrder}
        />;
    } 
    
    if (action === "inquiry" && inquiry[0] === "bpjs") {
      renderComponent =
        <DigitalConfirmationBpjskes
          isLoading={isLoading}
          digitalOrder={digitalOrder}
          breadcrumb={breadcrumb}
          CartDigital={CartDigital}
          paymentNote={paymentNote}
          loadInquiry={loadInquiry}
          successInquiry={successInquiry}

          onSubmitOrder={this.handleSubmitOrder}
        />;
    } 

    if(action == null) {

      renderComponent =
        <DigitalConfirmation
          isLoading={isLoading}
          breadcrumb={breadcrumb}
          CartDigital={CartDigital}
          paymentNote={paymentNote}
          cekWalletBalance={cekWalletBalance}
          onSubmitOrder={this.handleSubmitOrder}
        />;

    }

    return (<div>{renderComponent}</div>);
  }


}


const mapStateToProps = (state) => {
  const { digitalcart, digitalorder } = state;
  return { digitalcart, digitalorder }
};
export default withRouter(connect(mapStateToProps, {
  //list of dispatch action
  getCartDigital,
  submitDigitalOrder,
  fetchDigitalOrderById,
  addCartDigital
})(DigitalConfirmationContainer));

  // export default DigitalConfirmationContainer;
