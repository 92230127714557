import { QRCanvas } from "qrcanvas-react";
import React from "react";
import { Button } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchProfile } from "./../../redux/actions/profile";
import "./TopNav.css";

const limit_name = function (str, length, ending) {
  if (str != null) {
    if (length == null) {
      length = 24;
    }
    if (ending == null) {
      ending = "";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }
};

class Keanggotaan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    };
  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    //get news dari api

    this.props.fetchProfile();

    //saat call API update state menjadi isloading true menandakan sedang loading
    this.setState({
      isLoading: true,
    });
  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    const { fetchingProfile, profile } = this.props.profile;
    const { isLoading } = this.state;

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (!fetchingProfile && isLoading) {
      this.setState({
        isLoading: false,
        data: profile,
      });
    }
  }

  render() {
    const { isLoading, data } = this.state;

    const options = { data: data.UserID, size: 40 };
    return (
      <div className="kartu-anggota">
        <div className="kartu-anggota-barcode">
          {!isLoading && <QRCanvas options={options} />}
        </div>

        <div className="kartu-anggota-title">Kartu Anggota </div>

        {data != null && (
          <div className="kartu-anggota-name">{limit_name(data.FullName)}</div>
        )}
        {data != null && (
          <div className="kartu-anggota-phone">
            {data.Phone !== "" ? data.Phone : "-"}
          </div>
        )}

        <Button
          className="kartu-anggota-btn"
          variant="outline-primary"
          href={"/profile"}
        >
          Lihat Detail
        </Button>

        {data != null && (
          <div className="kartu-anggota-email">{data.Email}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile } = state;
  return { profile };
};
export default withRouter(
  connect(mapStateToProps, {
    fetchProfile,
    //list of dispatch action
  })(Keanggotaan)
);
