import Axios from "axios";
import { setFieldSearchQuery, setQueryString } from "./../../utils/common";
const searchFieldType = { newsID: "int" };

// baris di bawah ini di hapus aja gpp sama function laen yang ga diperluin lagi ndri

// 1. buat action type untuk mengambil data berita dari API
// 2. buat action creator / function untuk menghandle yang menjalankan request untuk mengambil data dari API

// Action type untuk news, ini bisa inline di file ini atau di import dari actionTypes.js
export const REQUEST_NEWS = "REQUEST_NEWS";
export const RESULT_NEWS = "RESULT_NEWS";
export const RESULT_TOTAL_NEWS = "RESULT_TOTAL_NEWS";
export const REQUEST_NEWS_DETAIL = "REQUEST_NEWS_DETAIL";
export const RESULT_NEWS_DETAIL = "RESULT_NEWS_DETAIL";
// Action Creator
// state ketika sedang loading request data
export const requestNews = () => ({
  type: REQUEST_NEWS,
});

export const requestNewsDetail = () => ({
  type: REQUEST_NEWS_DETAIL,
});

// state ketika data sudah berhasil di dapat
export const resultNews = (data) => ({
  type: RESULT_NEWS,
  payload: {
    data,
  },
});

export const resultNewsDetail = (data) => ({
  type: RESULT_NEWS_DETAIL,
  payload: {
    data,
  },
});

const resultTotalNews = (data) => ({
  type: RESULT_TOTAL_NEWS,
  payload: {
    data: data.data,
    count: data.count,
  },
});

// fetch news
export const fetchNews = (props, withTotalCount) => {
  // ketika method / action creator ini di panggil kita panggil api news dengan Axios

  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  withTotalCount = typeof withTotalCount !== "undefined" && withTotalCount;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestNews());

    return Axios.get("/news/" + process.env.REACT_APP_OID + "?" + queryString)

      .then((response) => response.data)
      .then((data) => {
        // respon atau data dari server ada di variable data
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedNews
        if (withTotalCount) {
          return dispatch(
            fetchTotalNews({
              ...props,
              items: data.data,
              message: data.message,
            })
          );
        }

        return dispatch(resultNews(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

// Request news detail dari API
export const fetchNewsDetailById = (id) => {
  return (dispatch) => {
    dispatch(requestNewsDetail());

    return Axios.get(
      "/news/" + process.env.REACT_APP_OID + "/" + id + "?Page=1&Size=8"
    )
      .then((response) => response.data)
      .then((data) => {
        // respon atau data dari server ada di variable data
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(resultNewsDetail(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

export const fetchTotalNews = ({
  keyword,
  operand,
  items,
  message,
  queryString,
}) => {
  return (dispatch) => {
    keyword = typeof keyword !== "undefined" ? keyword : "";
    queryString = setFieldSearchQuery(keyword, searchFieldType);

    return Axios.get(
      "/newscount/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(
          resultTotalNews({ data: items, count: data.data.count })
        );
      })
      .catch((error) => console.log("error"));
  };
};
