import { normalizePrice } from "./common";

// pengecekan awal apakah string kosong atau mengandung kata / karakter yang menandakan transaksi gagal
export const validateParse = (string) => {
        
    if(string == null) {
        return {success: false, data: string}
    }
    
    string = string.toLowerCase();
    let cekTransaksiGagal = string.indexOf("gagal");

    // todo cek apakah berhasil atau gagal
    // msg bisa di ambil di delimiter terakhir
    if(cekTransaksiGagal >= 0) {

        let message = string.substring(parseInt(cekTransaksiGagal) + 6);

        return {success: false, data: string, message: message}
    }

    return {success: true, data: string, message: "Berhasil"};
}

export const extractIDPelanggan = (string) => {
    let keyword = ['id pelanggan','id'];

    let pos = -1;
    keyword.forEach(key => {
        pos = string.search("/"+key+"/i")
    })
}

// Parse data pln prepaid inquiry DR
// RC:00@CEK METER PLN PREPAID@ NO.METER:86012190632@IDPEL:547200464299@NAMA:SUHAMAD@TD:R1M-900
export const parseInquiryPlnPrepaid = (string) => {
    let validate = validateParse(string)
    if(!validate['success']) {
        return validate;
    }

    let split = string.split("@");  
    const [RCNO, LABEL, NOMETER, IDPEL, NAMAPEL, METER] = split; 

    const [lblNoMeter, noMeter] = NOMETER.split(":");
    const [lblIdPelanggan, idPelanggan] = IDPEL.split(":");
    const [lblNamaPelanggan, namaPelanggan] = NAMAPEL.split(":");
    const [lblMeter, meter] = METER.split(":");
 
    let data = {success: true, 
        data: {
            NoMeter: noMeter,
            IDPelanggan: idPelanggan,
            NamaPelanggan: namaPelanggan,
            Daya : meter
        },
        message: "Transaksi Berhasil"
    }

    return data;

}

// parse plnpasca inquiry DR
// RC:00|SUCCESS INQ|PLN|ID PELANGGAN:538710731499|NAMA:HJ AAN SURYANI|PERIODE:202009|TD:|STANDMETER:14702-15960|ADMIN:3000|DENDA:|TOTAL TAG:2.131.714|
// RC:00|SUCCESS PAY|PLN|ID PELANGGAN:547101751330|NAMA:A KHAIDAR ABD HAIR|PERIODE:|TD:2200VA|STANDMETER:00031311-00031589|ADMIN:3.000|DENDA:|REFF:0MAS2520185047000000000749106835|TOTAL TAG:423694|
// Respon berbeda
// SUKSES, CEK PLN ID: 538710731499 A/N:HJ AAN SURYANI/R3/16500/RP2131714/Periode:202009/1Bln/StandMeter:14702-15960/Adm:3000. TOTAL TAG:2.131.714
export const parseInquiryPlnPostpaid = (string) => {
    let validate = validateParse(string)
    if(!validate['success']) {
        return validate;
    }

    // REGEX pln pascaa sesuai 2 respon berbeda dari dr 
    let IDPELANGGAN = string.match(/\b(id:|id pelanggan:)(\d|\s)+/ig);
    let NAMAPELANGGAN = string.match(/\b(a\/n:|nama:)(\w|\s)+/ig);
    let STANDMETER = string.match(/\bstandmeter:(\w|\s|-)+/ig);
    let METER = string.match(/\b(r\d\/|td:)(\d|\s)+/ig);
    let ADMIN = string.match(/\b(adm:|admin:)\d+/ig);
    let PERIODE = string.match(/\bperiode:\d+/ig);
    let DENDA = string.match(/\bdenda:\d+/ig);
    let TOTALTAGIHAN = string.match(/\btotal tag:(\d|.)+/ig);
    let JUMLAHBLN = string.match(/\dbln+/ig);  

    let [lblIdPelanggan, idPelanggan] = IDPELANGGAN != null ? IDPELANGGAN[0].split(":") : [null, null]; 
    let [lblNamaPelanggan, namaPelanggan] = NAMAPELANGGAN != null ? NAMAPELANGGAN[0].split(":") : [null, null]; 
    let [lblStandMeter, standMeter] = STANDMETER != null ? STANDMETER[0].split(":") : [null, null]; 
    let meter = METER != null ? METER[0] : null; 
    let [lblAdmin, admin] = ADMIN != null ? ADMIN[0].split(":") : [null, null]; 
    let [lblPeriode, periode] = PERIODE != null ? PERIODE[0].split(":") : [null, null]; 
    let [lblDenda, denda] = DENDA != null ? DENDA[0].split(":") : [null, null]; 
    let [lblTotal, total] = TOTALTAGIHAN != null ? TOTALTAGIHAN[0].split(":") : [null, null]; 
    let [lblJumlahBulan, jumlahBulan] = JUMLAHBLN != null ? JUMLAHBLN[0].split(":") : [null, null]; 

    meter = meter.replace("TD:",""); 

    let data = {success: true, 
        data: { 
            IDPelanggan: idPelanggan,
            NamaPelanggan: namaPelanggan,
            Daya : meter,
            StandMeter : standMeter,
            AdminFee : admin,
            Periode : periode,
            Denda : denda,
            Total : total,
            JumlahBulan : jumlahBulan,
        },
        message: "Transaksi Berhasil"
    } 

    return data;
}

// SUKSES, CEK BPJSKS ID: 0001314066115 A/N:NURTINA/Nama2:ABDUL RAHMAN/RP1874500/202009/ADM2500. TOTAL TAG:1.874.500
export const parseInquiryBPJSKES = (string) => {
    let validate = validateParse(string)
    if(!validate['success']) {
        return validate;
    } 

    return {success: true, data: string, message: "Berhasil"}
}

export const parsePeriodePlnPostpaid = (number) => {
    let string = number.toString()
    let year = "";
    let month = "";

    if(string != null && string.length === 6) {
        year = string.substring(0,4);
        month = string.substring(4);
    }

    return month+"-"+year;
}


// Respon berbeda
// REFF#2hnjnjb5243km27 Trx PLN20.32011325399 BERHASIL,Harga: 20.150 SN: 3969-0416-8685-8624-7737/DRS ZAINAL ARIFIN/R2/3500/13,2
export const parsePlnPrepaid = (string) => {
    let validate = validateParse(string)
    if(!validate['success']) {
        return validate;
    } 

    // REGEX pln prepaid dr 
    // let TOTAL = string.match(/\bharga:(\d|.)+/ig); 
    let SN = string.match(/\bsn: (\d|-)+/ig);  

    let splitSlash = string.split("/"); 

    // let IDPELANGGAN = string.match(/\b(id:|id pelanggan:)(\d|\s)+/ig);
    // let NAMAPELANGGAN = string.match(/\b(a\/n:|nama:)(\w|\s)+/ig);
    // let STANDMETER = string.match(/\bstandmeter:(\w|\s|-)+/ig);
    // let METER = string.match(/\b(r\d\/|td:)(\d|\s)+/ig);
    // let ADMIN = string.match(/\b(adm:|admin:)\d+/ig); 

    // console.log(SN)

    // let [lblIdPelanggan, idPelanggan] = IDPELANGGAN != null ? IDPELANGGAN[0].split(":") : [null, null]; 
    let namaPelanggan = splitSlash != null ? splitSlash[1] : null; 
    // let [lblStandMeter, standMeter] = STANDMETER != null ? STANDMETER[0].split(":") : [null, null]; 
    let meter = splitSlash != null ? splitSlash[2]+"/"+splitSlash[3] : null; 
    // let [lblAdmin, admin] = ADMIN != null ? ADMIN[0].split(":") : [null, null];  
    let [lblSn, sn] = SN != null ? SN[0].split(":") : [null, null];  
    // let [lblTotal, total] = TOTAL != null ? TOTAL[0].split(":") : [null, null];  


    let data = {success: true, 
        data: { 
            // IDPelanggan: idPelanggan,
            NamaPelanggan: namaPelanggan,
            Daya : meter,
            // StandMeter : standMeter,
            SN : sn,
            // AdminFee : admin, 
            // Total : normalizePrice(total)
        },
        message: "Transaksi Berhasil"
    } 

    return data;
}