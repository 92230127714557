import ProductPromotion from "components/Mart/ProductPromotion";
import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "../../Beranda.css";
import Breadcrumb from "../Layout/Breadcrumb";
import { createMarkup, parseDate, parseDateTime } from "./../../utils/common";
import "./Promo.css";

const PromoLain = ({ isLoading, promoLainnya, ...props }) => {
  return (
    <>
      {!isLoading ? (
        <Row>
          {promoLainnya.length < 1 ? (
            ""
          ) : (
            <Col md={12} xs={12}>
              <div className="promo-lainnya">
                <Row>
                  <Col md={6} xs={6}>
                    <div className="promo-lainnya-title">Promo Lainnya</div>
                  </Col>

                  <Col md={6} xs={6}>
                    <div className="promo-lihatsemua">
                      <a
                        href="/promo/"
                        className="float-right popular-link-more"
                      >
                        Lihat semua
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="promoSlideContainer">
                      <Carousel
                        className="promo-carousel"
                        responsive={responsive}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        draggable={true}
                        swipeable={true}
                        minimumTouchDrag={50}
                        keyBoardControl={true}
                      >
                        {promoLainnya.length > 0 &&
                          !isLoading &&
                          promoLainnya.map((item) => (
                            <div className="listPromoLainnya">
                              <div className="promo-lain-img">
                                <img
                                  src={item.Img}
                                  width="100%"
                                  alt="img-promolain"
                                />
                              </div>

                              <div className="cardpromojudullain">
                                {item.Title}
                              </div>
                              <div className="cardpromodetaillain">
                                {item.ShortDesc}
                              </div>
                              <div className="periodepromo">
                                {parseDate(item.StartDate)} -{" "}
                                {parseDate(item.DueDate)}{" "}
                              </div>

                              <Button
                                variant="primary"
                                className="cardpromobutton"
                                href={"/promo/" + item.ID + "/detail"}
                                block
                              >
                                Lihat Promo
                              </Button>
                            </div>
                          ))}
                      </Carousel>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
      ) : null}
    </>
  );
};

const limit_desk = function (str, length, ending) {
  if (length == null) {
    length = 50;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.2,
  },
};

export default function PromoDetail({
  isLoading,
  promoDetail,
  promoLainnya,
  breadcrumb,
  ...props
}) {
  const { Products } = { ...promoDetail };

  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [],
      "promo_detail"
    );
  }, []);

  return (
    <Container>
      {/* Tampilan Web */}
      <div className="d-none d-sm-block">
        {promoDetail != null && (
          <Row>
            <Col md={8}>
              <div style={{ width: "100%", paddingTop: "10px" }}>
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
              </div>

              <div className="detailContainer">
                <img
                  src={promoDetail.Img}
                  width="100%"
                  height="100%"
                  alt="img-promo"
                ></img>
              </div>
            </Col>
            <Col md={4}>
              <div className="infopromoContainer">
                <b style={{ marginLeft: "13px" }}>Info Promo</b>
                <div className="infopromoDate">
                  <p style={{ paddingTop: "5px", paddingLeft: "10px" }}>
                    Masa Berlaku
                  </p>
                  <p style={{ paddingLeft: "10px", paddingTop: "0px" }}>
                    <b>
                      {parseDate(promoDetail.StartDate)} -{" "}
                      {parseDate(promoDetail.DueDate)}
                    </b>
                  </p>
                </div>
              </div>
            </Col>

            <Col md={8}>
              <div className="deskContainer">
                <div className="deskJudul">{promoDetail.Title}</div>
                <div className="deskdate">
                  {parseDateTime(promoDetail.PublishedAt)} WIB
                </div>

                <div
                  className="deskPromo"
                  dangerouslySetInnerHTML={createMarkup(promoDetail.Content)}
                ></div>
                <Helmet>
                  {/* <title>Page 1</title> */}
                  <meta
                    name="description"
                    content={limit_desk(promoDetail.ShortDesc)}
                  />
                  <meta property="og:title" content={promoDetail.Title} />
                  <meta property="og:image" content={promoDetail.Img} />
                  <meta
                    property="og:url"
                    content={
                      process.env.REACT_APP_BASEURL +
                      "/promo/" +
                      promoDetail.ID +
                      "/detail"
                    }
                  />

                  <meta
                    name="description"
                    content={limit_desk(promoDetail.ShortDesc)}
                  />
                  <meta name="twitter:title" content={promoDetail.Title} />
                  <meta name="twitter:image" content={promoDetail.Img} />
                </Helmet>

                <div className="ShareButton">
                  <Row className="justify-content-md-center">
                    <Col md={2} xs={2}>
                      <FacebookShareButton
                        children={promoDetail.Title}
                        url={
                          process.env.REACT_APP_BASEURL +
                          "/promo/" +
                          promoDetail.ID +
                          "/detail"
                        }
                        quote={promoDetail.Title}
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                    </Col>
                    <Col md={2} xs={2}>
                      <WhatsappShareButton
                        children={promoDetail.Title + promoDetail.Img}
                        url={
                          process.env.REACT_APP_BASEURL +
                          "/promo/" +
                          promoDetail.ID +
                          "/detail"
                        }
                        title={promoDetail.Title}
                      >
                        <WhatsappIcon size={32} round={true} />
                      </WhatsappShareButton>
                    </Col>
                    <Col md={2} xs={2}>
                      <TwitterShareButton
                        children={promoDetail.Title + promoDetail.Img}
                        url={
                          process.env.REACT_APP_BASEURL +
                          "/promo/" +
                          promoDetail.ID +
                          "/detail"
                        }
                        title={promoDetail.Title}
                      >
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>

      {/* Tampilan mobile */}

      <div className="d-block d-sm-none">
        {promoDetail != null && (
          <Row>
            <Col xs={12}>
              <div style={{ width: "100%", paddingTop: "10px" }}>
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
              </div>

              <div className="detailContainer">
                <img
                  src={promoDetail.Img}
                  width="100%"
                  height="100%"
                  alt="img-promo"
                ></img>
              </div>

              <div></div>
            </Col>
            <Col xs={12}>
              <div className="deskContainer">
                <div className="deskJudul">{promoDetail.Title}</div>
                <div className="deskdate">
                  {parseDateTime(promoDetail.PublishedAt)} WIB
                </div>
              </div>
            </Col>

            <Col>
              <div className="infopromoDate">
                <p style={{ paddingTop: "0px", marginBottom: "0px" }}>
                  Masa Berlaku Promo
                </p>
                <p style={{ paddingTop: "0px", marginBottom: "0px" }}>
                  <b>
                    {parseDate(promoDetail.StartDate)} -{" "}
                    {parseDate(promoDetail.DueDate)}
                  </b>
                </p>
              </div>

              <div
                className="deskPromo"
                dangerouslySetInnerHTML={createMarkup(promoDetail.Content)}
              ></div>
            </Col>
            <Col xs={12}>
              <div className="ShareButton">
                <Row className="justify-content-md-center">
                  <Col md={2} xs={2}>
                    <FacebookShareButton
                      children={promoDetail.Title}
                      url={
                        process.env.REACT_APP_BASEURL +
                        "/promo/" +
                        promoDetail.ID +
                        "/detail"
                      }
                      quote={promoDetail.Title}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                  </Col>
                  <Col md={2} xs={2}>
                    <WhatsappShareButton
                      children={promoDetail.Title + promoDetail.Img}
                      url={
                        process.env.REACT_APP_BASEURL +
                        "/promo/" +
                        promoDetail.ID +
                        "/detail"
                      }
                      title={promoDetail.Title}
                    >
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </Col>
                  <Col md={2} xs={2}>
                    <TwitterShareButton
                      children={promoDetail.Title + promoDetail.Img}
                      url={
                        process.env.REACT_APP_BASEURL +
                        "/promo/" +
                        promoDetail.ID +
                        "/detail"
                      }
                      title={promoDetail.Title}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </div>

      {/* <ProdukTerkait isLoading={isLoading} products={Products} /> */}
      <ProductPromotion
        isLoading={isLoading}
        title="Produk Terkait"
        products={Products}
      />

      <PromoLain isLoading={isLoading} promoLainnya={promoLainnya} />
    </Container>
  );
}
