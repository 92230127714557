import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch } from "react-redux";
import ProductItem from "./../Product/ProductItem.component";
import "./ProductPromotion.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.5,
  },
};

function ProductPromotion({ isLoading, products, ...props }) {
  const [title, setTitle] = useState(props.title);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (products) {
      setData(products);
    }
  }, [isLoading, products]);

  return (
    <>
      {!isLoading && data && data.length > 0 && (
        <div
          style={props.style}
          className={"product-lain-card " + props.className}
        >
          <div className="product-lain-title">{title}</div>
          <Carousel
            responsive={responsive}
            autoPlay={false}
            deviceType={props.deviceType}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="react-multi-carousel-dot-list"
            keyBoardControl={true}
            swipeable={true}
            draggable={true}
          >
            {!isLoading &&
              data.map((item, index) => {
                return (
                  <div key={index}>
                    <ProductItem
                      product={item}
                      className={props.classNameItem}
                      style={props.styleItem}
                    />
                  </div>
                );
              })}
          </Carousel>
        </div>
      )}
    </>
  );
}

ProductPromotion.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  classNameItem: PropTypes.string,
  style: PropTypes.object,
  styleItem: PropTypes.object,
  onEmpty: PropTypes.func,
};

ProductPromotion.defaultProps = {
  title: "Produk Terkait",
  isLoading: false,
  className: "",
  classNameItem: "",
  style: {},
  styleItem: {},
  onEmpty: () => {},
};

export default ProductPromotion;
