import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './BottomNav.css';
import {Container, Row, Col} from 'react-bootstrap'; 
import { useHistory } from 'react-router-dom'; 
import navhome from './../../Images/nav-bottom-home.png';
import navhomeactive from './../../Images/nav-bottom-home-active.png';
import navpromo from './../../Images/nav-bottom-promo.png';
import navpromoactive from './../../Images/nav-bottom-promo-active.png';
import navpesanan from './../../Images/nav-bottom-pesanan.png';
import navpesananactive from './../../Images/nav-bottom-pesanan-active.png';
import navanggota from './../../Images/nav-bottom-anggota.png';
import navanggotaactive from './../../Images/nav-bottom-anggota-active.png';
import { useSelector } from 'react-redux';

function BottomNav(props) {  

    const history = useHistory();
    const { activeKey } = props;  
    const [showBottomNav, setShowBottomNav] = useState(true);

    const appState = useSelector(state => { 
        return state.app; 
    })  

    useEffect(() => { 
        setShowBottomNav(!appState.isSearchingProduct);
    },[appState.isSearchingProduct])

    return (<div className={"d-lg-none "+(!showBottomNav ? 'd-none ' : ' ' )}>
        {/* Space */}
        <div style={{height:'75px'}}>&nbsp;</div>

        <Container className="app-bottom-nav"> 
            <Row>
                <Col className={parseInt(activeKey) === 1 ? "active" : ""} onClick={e => history.push('/beranda')}>
                    <div className="bottom-nav-wrapper"><div className="bottom-nav-img"><img src={parseInt(activeKey) === 1 ? navhomeactive : navhome} alt="Home" /> </div>
                    <div className="bottom-nav-text">Beranda</div></div>
                </Col>
                <Col className={parseInt(activeKey) === 2 ? "active" : ""} onClick={e => history.push('/shop/order')}>
                    <div className="bottom-nav-wrapper"><div className="bottom-nav-img"><img src={parseInt(activeKey) === 2 ? navpesananactive : navpesanan} alt="Pesanan" /> </div>
                    <div className="bottom-nav-text">Pesanan</div></div>
                </Col>
                {/* <Col className={parseInt(activeKey) === 3 ? "active" : ""} onClick={e => history.push('/shop/cart')}>
                    <div className="bottom-nav-wrapper"><div className="bottom-nav-img"><img src={ichome} alt="home" /> </div>
                    // <div className="bottom-nav-text">Cart</div></div>
                </Col> */}
                <Col className={parseInt(activeKey) === 4 ? "active" : ""} onClick={e => history.push('/promo')}>
                    <div className="bottom-nav-wrapper"><div className="bottom-nav-img"><img src={parseInt(activeKey) === 4 ? navpromoactive : navpromo} alt="Info" /> </div>
                    <div className="bottom-nav-text">Promo</div></div>
                </Col>
                <Col className={parseInt(activeKey) === 5 ? "active" : ""} onClick={e => history.push('/profile/index')}>
                    <div className="bottom-nav-wrapper"><div className="bottom-nav-img"><img src={parseInt(activeKey) === 5 ? navanggotaactive : navanggota} alt="Keanggotaan" /> </div>
                    <div className="bottom-nav-text">Keanggotaan</div></div>
                </Col>
            </Row>
        </Container>
    </div>
    ); 
} 

BottomNav.propTypes = {
    activeKey: PropTypes.number, 
}


BottomNav.defaultProps = {
    activeKey: null,  
}

export default BottomNav;