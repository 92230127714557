import React from 'react';
import {Container, Form, Button, ButtonGroup} from 'react-bootstrap';
import './Delivery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function ListAddress(props) {

    const {isLoading, loadingAddress, addressList, selectedAddressID} = props; 

    return (<Container>
        {isLoading || loadingAddress ? 'Memuat data...' : ''}

        <Form className="address-list-box">
        {!isLoading && !loadingAddress && addressList.length > 0 && addressList.map((address,index) => { 
            return ( 
                <Form.Check key={index} className="address-list-card" style={{position:'relative',marginBottom:'20px'}} type="radio" id={'input-radio-'+index}>
                    
                    <ButtonGroup aria-label="Basic example" style={{position:'absolute', top:'0', right:'0'}}> 
                        <Button size="sm" variant="outline-primary"  onClick={(e) => props.onEditAddress(address.ID)}>Ubah</Button>
                        <Button size="sm" variant="link"  onClick={(e) => props.onModalConfirm(true, {type:"DELETE_ADDRESS",id:address.ID})}><FontAwesomeIcon icon={faTrash} /></Button>
                    </ButtonGroup>

                    <Form.Check.Input value={address.ID} checked={parseInt(address.ID) === parseInt(selectedAddressID)} name="selectedAddressID" onChange={(e) => props.onChangeSelectedAddress(address)} type="radio" />
                    <Form.Check.Label>
                        {/* <div className="address-label">{address.Labels != null && address.Labels !== "" ? address.Labels : " - "}</div> */}
                        <div className="address-label">{address.Recipient}</div>
                        <div className="address-phone">{address.Phone} s</div>
                        <div className="address-address">
                            {address.Address} 
                            {address.SubDistrict !== "" && ", "+address.SubDistrict}
                            {address.City !== "" && ", "+address.City}
                            {address.Province !== "" && ", "+address.Province}
                            {address.PostalCode !== "" && ", "+address.PostalCode}
                        </div>
                    </Form.Check.Label>

                    <div className="float-right">Pinpoint Alamat</div>
                    {/* <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback> */}
                </Form.Check> 
            )
        })}
        </Form>

    </Container>);
}