import Axios from "axios";
import { setQueryString } from "./../../utils/common";
import { handleError } from "./app";

export const REQUEST_MARTBANK_ACCOUNT = "REQUEST_MARTBANK_ACCOUNT";
export const RESULT_MARTBANK_ACCOUNT = "RESULT_MARTBANK_ACCOUNT";

const searchFieldType = { ID: "int" };

const requestMartBankAccount = () => ({
  type: REQUEST_MARTBANK_ACCOUNT,
});

const resultMartBankAccount = (data) => ({
  type: RESULT_MARTBANK_ACCOUNT,
  payload: {
    data,
  },
});

// Request mart category from API
export const fetchMartBankAccount = (props) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartBankAccount());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_BANK_ACCOUNT");
    // if(localData.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartBankAccount(localData));
    // }

    return Axios.get("/bankaccount/" + 0 + "?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartBankAccount(data.data));
      })
      .catch((error) => dispatch(handleError(error)));
  };
};
