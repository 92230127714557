import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";

// Loading state
export default function Loading({ text }) {
  return (
    <Row className="justify-content-center">
      <Col style={{ margin: "50px 0" }} className="text-center ">
        <FontAwesomeIcon icon={faSpinner} spin />{" "}
        {text ? text : "Memuat data..."}
      </Col>
    </Row>
  );
}
