import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCart, submitOrder } from "./../../../redux/actions/mart_cart";
import { isCompletedParamOrder } from "./../../../utils/cart";
import Confirmation from "./Confirmation.component";

class ConfirmationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submittingOrder: false,
      filterData: {},
      cartItems: [],
      cartSummary: null,
      breadcrumb: null,
      view: null,
      orderData: null,
    };

    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
  }

  componentDidMount() {
    this.props.getCart();

    this.setState({
      isLoading: true,
    });
  }

  componentDidUpdate() {
    const { isLoading, submittingOrder } = this.state;
    const {
      carts,
      isSubmitOrder,
      orderData,
      successOrder,
    } = this.props.martcart;
    const { items, summary } = carts;
    let { breadcrumb } = this.state;

    // cek jika order sudah tidak valid, maka redirect
    if (
      !submittingOrder &&
      summary != null &&
      !isCompletedParamOrder(summary)
    ) {
      this.props.history.push("/shop/order");
    }

    // order
    if (!isSubmitOrder && submittingOrder) {
      if (
        successOrder &&
        ["qris", "bank"].includes(orderData.PaymentType.toLowerCase())
      ) {
        this.props.history.push("/shop/order/" + orderData.ID + "/payment");
      } else if (successOrder) {
        this.props.history.push("/shop/order/" + orderData.ID + "/completed");
      }

      this.setState({ submittingOrder: false });
    }

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (isLoading) {
      breadcrumb = [
        // {link:'/beranda', text:'Beranda'},
        // {link:'/shop/cart', text: 'Keranjang'},
        { link: "/shop/delivery", text: "Atur Pesanan" },
        { link: "/shop/order-confirmation", text: "Konfirmasi Pesanan" },
      ];

      this.setState({
        isLoading: false,
        submittingOrder: false,
        cartItems: items,
        cartSummary: summary,
        breadcrumb,
      });
    }
  }

  handleSubmitOrder() {
    this.props.submitOrder();
    this.setState({ submittingOrder: true });
  }

  render() {
    const {
      cartItems,
      cartSummary,
      isLoading,
      submittingOrder,
      breadcrumb,
    } = this.state;

    return (
      <Confirmation
        cartItems={cartItems}
        cartSummary={cartSummary}
        isLoading={isLoading}
        submittingOrder={submittingOrder}
        breadcrumb={breadcrumb}
        onChangeDeliveryOption={this.handleChangeDeliveryOption}
        onChangeAddress={this.toggleModalAddress}
        onSubmitOrder={this.handleSubmitOrder}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { martproduct, martcart, martdelivery } = state;
  return { martproduct, martcart, martdelivery };
};

export default withRouter(
  connect(mapStateToProps, {
    getCart,
    submitOrder,
    //list of dispatch action
  })(ConfirmationContainer)
);
