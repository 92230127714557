import React, {useEffect, useState} from 'react';  
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchMartProductLatest } from './../../redux/actions/mart_product'; 
import { Form } from 'react-bootstrap';
import './Search.css';     
import icsearchstore from './../../Images/ic-search-store.png';
import icsearchproduct from './../../Images/ic-search-product.png';
import icsearchcategory from './../../Images/ic-search-category.png'; 

function Search(props) { 

    const {isLoading} = props; 
    let filterData = {Status: 1};

    const [keyword, setKeyword] = useState(""); 
    const dispatch = useDispatch();  

    useEffect(() => {  
        dispatch(fetchMartProductLatest({page: 1, size: 50, search: filterData}));  
    },[isLoading]) 


    return (
        <div className={"search-card "+props.className} style={props.style}>   
            <Form>
                <Form.Control name="keyword" placeholder="Cari" onChange={e => setKeyword(e.target.value)} value={keyword} />
                <ul className={"search-suggestion "+(keyword === "" && "d-none")}>
                    <li><a href={"/shop/product?type=search&keyword="+keyword}><img src={icsearchproduct} alt="cari produk" />Pencarian Produk "{keyword}"</a></li>
                    <li><a href={"/store?keyword="+keyword}><img src={icsearchstore} alt="cari toko" />Pencarian Toko "{keyword}"</a></li>
                    <li><a href={"/shop/product?type=cat&keyword="+keyword}><img src={icsearchcategory} alt="cari kategori" />Pencarian Category "{keyword}"</a></li>
                </ul>
            </Form>
        </div>
    )
}


Search.propTypes = { 
    isLoading: PropTypes.bool,
    onEmpty: PropTypes.func, 
    className: PropTypes.string,
    style: PropTypes.object,
    classNameItem: PropTypes.string,
    styleItem: PropTypes.object,
}


Search.defaultProps = { 
    isLoading: false,
    onEmpty: () => {},
    className: "",
    style: {},
    classNameItem: "",
    styleItem: {},
}
 
export default Search;