import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import {
  createMarkup,
  humanDate,
  parseCurrency,
  parseDate,
} from "./../../../utils/common";
import ImageCarousel from "./../../ImageCarousel";
// import 'moment/locale/id';
import Breadcrumb from "./../../Layout/Breadcrumb";
import Loading from "./../../Layout/Loading";
import ProductLain from "./../ProductLain";
import "./Product.css";

// temporary style
const styles = {
  madeOrderBox: {
    backgroundColor: "#ECEFFF",
    padding: "4px 24px",
    display: "inline-block",
    marginBottom: "20px",
    fontSize: "18px",
    color: "#001580",
    border: "1.5px solid #6E85FF",
    borderRadius: "8px",
    fontWeight: "bold",
  },
};

export default function ProductDetail(props) {
  const { isLoading, product } = props;
  var currentDate = moment();
  const iconPlus = <FontAwesomeIcon className="btn-qty" icon={faPlus} />;
  const iconMinus = <FontAwesomeIcon className="btn-qty" icon={faMinus} />;
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (product) {
      ReactGA.pageview(
        window.location.pathname + window.location.search,
        [],
        "product_detail"
      );

      let imgs = [];
      if (product["Img"]) {
        imgs.push(product["Img"]);
      }

      if (product["Images"]) {
        let extraImgs = [];
        if (Array.isArray(product["Images"])) {
          extraImgs = product["Images"];
        } else {
          extraImgs = product["Images"].split(",");
        }

        imgs = imgs.concat(extraImgs);
      }

      // filter yang memiliki image saja yang tampil yang null / kosong tidak perlu di bawa
      imgs = imgs && imgs.length > 0 && imgs.filter((img) => img);

      setImages(imgs);
    }
  }, [product]);

  return (
    <Container>
      {isLoading && <Loading />}

      {!isLoading && product != null && (
        <React.Fragment>
          {/* Breadcrumb */}
          <div style={{ paddingTop: "29px" }}>
            {props.breadcrumb != null && <Breadcrumb url={props.breadcrumb} />}
          </div>

          <div className="product-detail-card">
            <Row noGutters>
              <Col md={6} xs={12}>
                {/* <img src={product.Img} className="detailImg" style={{ opacity: (parseInt(product.Stock) <= 0 || isNaN(parseInt(product.Stock))) ? '0.2' : '1' }} width="90%" alt="produk" />                       */}
                <ImageCarousel images={images} className="detailImg" />
              </Col>
              <Col md={6} xs={12}>
                <div className="product-content">
                  <div className="product-title">{product.Name}</div>
                  <div className="product-price">
                    <div className="d-none d-sm-block">
                      {parseInt(product.Discount) > 0 ? (
                        <Row noGutters>
                          <Col md={2}>
                            <div className="label-discount">
                              {product.Discount}%
                            </div>
                          </Col>
                          <Col>
                            <span className="strip-price">
                              Rp {parseCurrency(product.Price)}
                            </span>
                            <span className="product-price-discount">
                              Rp {parseCurrency(product.PromoPrice)}
                            </span>
                          </Col>
                        </Row>
                      ) : (
                        <div>Rp {parseCurrency(product.PromoPrice)}</div>
                      )}
                    </div>

                    {/* Tampilan Mobile */}
                    <div className="d-block d-sm-none">
                      {parseInt(product.Discount) > 0 ? (
                        <Row noGutters>
                          <Col xs={3}>
                            <div className="label-discount">
                              {product.Discount}%
                            </div>
                          </Col>
                          <Col style={{ paddingTop: "2px" }} xs={4}>
                            <span className="strip-price">
                              Rp {parseCurrency(product.Price)}
                            </span>
                          </Col>
                          <Col xs={4}>
                            <span className="product-price-discount">
                              Rp {parseCurrency(product.PromoPrice)}
                            </span>
                          </Col>
                        </Row>
                      ) : (
                        <div>Rp {parseCurrency(product.PromoPrice)}</div>
                      )}
                    </div>
                  </div>
                  <Row className="product-unit-box">
                    <Col md={4} xs={6}>
                      <span className="label-unit">
                        {product.Unit !== "" && product.Unit === "ml"
                          ? "Volume"
                          : "Berat"}
                      </span>{" "}
                      {parseInt(product.Weight) > 0 && product.Unit !== "" ? (
                        <div className="product-unit">
                          {product.Weight} {product.Unit}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Col>
                    <Col md={4} xs={6} className="product-detail-stock">
                      <span className="label-unit">Stok</span>{" "}
                      <div className="product-unit">{product.Stock} pcs</div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col md={4} xs={4}>
                      <div className="label-unit">Nama Toko</div>
                    </Col>
                    <Col md={6} xs={8}>
                      <div className="product-unit">
                        <a
                          href={
                            "/store/" +
                            product.StoreID +
                            "/" +
                            product.StoreName
                          }
                        >
                          {product.StoreName}
                        </a>
                      </div>{" "}
                    </Col>
                  </Row>

                  <Row className="product-address-store-box">
                    <Col md={4} xs={4}>
                      <div className="label-unit">Dikirim dari</div>
                    </Col>
                    <Col md={8} xs={8}>
                      <div className="product-unit">
                        {product.StoreSubDistrict}, &nbsp;{product.StoreCity}
                        ,&nbsp;{product.StoreProvince}
                      </div>{" "}
                    </Col>
                  </Row>

                  {parseInt(product.PurchaseType) === 3 && (
                    <Row>
                      <Col style={{ textAlign: "center" }}>
                        <div style={styles.madeOrderBox}>
                          Made to Order :{" "}
                          {product.MinDays ? product.MinDays : 8} Hari
                        </div>
                      </Col>
                    </Row>
                  )}

                  <div className="d-none d-md-block">
                    {/* <div className="font-weight-bold">Jumlah</div>  */}

                    {/* bentuk tombol ketika qty 0 */}

                    {product.Status &&
                    product.Stock &&
                    parseInt(product.PurchaseType) === 1 &&
                    !isLoading &&
                    product &&
                    !product.Qty ? (
                      <Button
                        variant="primary"
                        className="btnprodukDetail"
                        block
                        onClick={(e) =>
                          props.onChangeQty({
                            type: "CHANGE",
                            product: product,
                            value: 1,
                          })
                        }
                      >
                        Beli Sekarang
                      </Button>
                    ) : (
                      ""
                    )}

                    {!product.Status ? (
                      <Button
                        variant="outline"
                        style={{ background: "#fff" }}
                        className=" btn-product stock-kosong-btn"
                        block
                      >
                        Tidak Tersedia
                      </Button>
                    ) : null}
                    {product.Status &&
                    !product.Stock &&
                    !isLoading &&
                    product ? (
                      <Button
                        variant="outline"
                        style={{ background: "#fff" }}
                        className=" btn-product stock-kosong-btn"
                        block
                      >
                        Stok Kosong
                      </Button>
                    ) : null}

                    {product.Status &&
                    parseInt(product.PurchaseType) === 2 &&
                    moment(product.ClosedDate).diff(currentDate, "Days") < 0 ? (
                      <Button
                        variant="outline"
                        style={{ background: "#fff" }}
                        className=" btn-product stock-kosong-btn"
                        block
                      >
                        Pre Order Berakhir
                      </Button>
                    ) : product.Status &&
                      parseInt(product.PurchaseType) === 2 &&
                      product.Stock &&
                      !isLoading &&
                      product &&
                      !product.Qty ? (
                      <Button
                        variant="primary"
                        className="btnprodukDetail"
                        block
                        onClick={(e) =>
                          props.onChangeQty({
                            type: "CHANGE",
                            product: product,
                            value: 1,
                          })
                        }
                      >
                        Pre Order
                      </Button>
                    ) : null}

                    {product.Stock &&
                    parseInt(product.PurchaseType) === 3 &&
                    !isLoading &&
                    product &&
                    !product.Qty ? (
                      <Button
                        variant="primary"
                        className="btnprodukDetail"
                        block
                        onClick={(e) =>
                          props.onChangeQty({
                            type: "CHANGE",
                            product: product,
                            value: 1,
                          })
                        }
                      >
                        Pesan
                      </Button>
                    ) : null}

                    {/* bentuk tombol ketika qty 0 */}
                    {/* {parseInt(product.Stock) > 0 && !isLoading && product != null && (!product.Qty || parseInt(product.Qty) <= 0) && <Button variant="primary" className="btnprodukDetail" block onClick={(e) => props.onChangeQty({ type: "CHANGE", product: product, value: 1 })}>Pesan</Button>} */}

                    {/* bentuk form ketika qty > 0 */}
                    {parseInt(product.Stock) > 0 &&
                    !isLoading &&
                    product != null &&
                    parseInt(product.Qty) > 0 ? (
                      <Row noGutters>
                        <Col xs={2}>
                          <Button
                            variant="primary"
                            className="btn-product"
                            disabled={product == null}
                            onClick={(e) =>
                              props.onChangeQty({
                                type: "DECREMENT",
                                product: product,
                              })
                            }
                            block
                          >
                            {iconMinus}
                          </Button>
                        </Col>

                        <Col xs={8}>
                          <Form.Control
                            name="Qty"
                            disabled={product == null}
                            onChange={(e) =>
                              props.onChangeQty({
                                type: "CHANGE",
                                product: product,
                                value: e.target.value,
                              })
                            }
                            className="text-center input-product-qty"
                            style={{ margin: "0 5%", width: "90%" }}
                            value={product != null ? product.Qty : 0}
                            placeholder="Enter Qty"
                          />
                        </Col>

                        <Col xs={2}>
                          <Button
                            variant="primary"
                            className="btn-product"
                            disabled={
                              product == null ||
                              parseInt(product.Qty) >= parseInt(product.Stock)
                            }
                            onClick={(e) =>
                              props.onChangeQty({
                                type: "INCREMENT",
                                product: product,
                              })
                            }
                            block
                          >
                            {iconPlus}
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                    {/* <div className="box-total mb-3 mt-2">
                                        <div className="label-total">Total Harga</div>
                                        <div className="total-price">Rp{parseCurrency(product != null ? product.Total : 0)}</div>
                                    </div>   */}
                  </div>
                </div>
              </Col>
            </Row>
            <div
              className="product-line"
              style={{ paddingBottom: "12px !important" }}
            >
              {" "}
              &nbsp;
            </div>

            {parseInt(product.PurchaseType) === 2 && (
              <Row>
                <Col md={3}>
                  <div className="descFont">Periode Pre Order</div>
                </Col>
                <Col className="descFont2">
                  <div>
                    Open Pre Order sampai {parseDate(product.ClosedDate)}
                  </div>
                  <div>Tanggal Kirim {parseDate(product.DeliveryDate)}</div>
                </Col>
              </Row>
            )}

            {parseInt(product.PurchaseType) === 3 && (
              <Row>
                <Col md={3}>
                  <div className="descFont">Made to Order</div>
                </Col>
                <Col md={9} className="descFont2">
                  Produk ini memerlukan proses pembuatan selama{" "}
                  {product.MinDays ? product.MinDays : "beberapa"} hari dan akan
                  dibuat setelah pesanan diterima. Perkiraan produk dikirim pada{" "}
                  {humanDate(
                    moment()
                      .add(
                        product.MinDays ? parseInt(product.MinDays) : 8,
                        "days"
                      )
                      .format("YYYY-MM-DD")
                  )}
                </Col>
              </Row>
            )}

            <Row style={{ marginBottom: "36px" }}>
              <Col md={3}>
                <div className="descFont">Deskripsi Produk</div>
              </Col>
              <Col md={9} className="descFont2">
                <div
                  dangerouslySetInnerHTML={createMarkup(product.Description)}
                ></div>
              </Col>
            </Row>
            <div className="product-line"></div>
          </div>
        </React.Fragment>
      )}

      {/* Product Lain */}
      {!isLoading && product != null && (
        <ProductLain
          className="produklain-carousel"
          excludeProductID={parseInt(props.productID)}
          CategoryID={parseInt(product.CategoryID)}
          style={{ marginTop: "32px" }}
        />
      )}

      {/* Mobile view fixed in bottom */}
      {!isLoading && product != null && (
        <div className="product-summary-bottom d-sm-block d-md-none">
          {parseInt(product.Qty) > 0 ? (
            <Row noGutters>
              <Col>
                <Row className="mt-2" noGutters>
                  <Col xs={4}>
                    <Button
                      variant="link"
                      disabled={product == null}
                      onClick={(e) =>
                        props.onChangeQty({
                          type: "DECREMENT",
                          product: product,
                        })
                      }
                      block
                    >
                      {iconMinus}
                    </Button>
                  </Col>

                  <Col xs={4}>
                    <Form.Control
                      name="Qty"
                      disabled={product == null}
                      onChange={(e) =>
                        props.onChangeQty({
                          type: "CHANGE",
                          product: product,
                          value: e.target.value,
                        })
                      }
                      className="text-center"
                      value={product != null ? product.Qty : 0}
                      placeholder="Enter Qty"
                    />
                  </Col>

                  <Col xs={4}>
                    <Button
                      variant="link"
                      disabled={
                        product == null ||
                        parseInt(product.Qty) >= parseInt(product.Stock)
                      }
                      onClick={(e) =>
                        props.onChangeQty({
                          type: "INCREMENT",
                          product: product,
                        })
                      }
                      block
                    >
                      {iconPlus}
                    </Button>
                  </Col>
                </Row>
                {/* {parseInt(product.Qty) <= 0 && <Button variant="primary" disabled={product == null} onClick={(e) => props.onChangeQty({type:"CHANGE", product: product, value:1})} block>Beli</Button>} */}
              </Col>
              <Col xs={4} style={{ padding: "0 8px" }}>
                <div className="label-total">Total Harga</div>
                <div className="label-price">
                  Rp {parseCurrency(product["Total"])}
                </div>
              </Col>
              <Col xs={3}>
                <Button
                  className="mt-2"
                  variant="primary"
                  href="/shop/cart"
                  block
                >
                  Pesan
                </Button>
              </Col>
            </Row>
          ) : product.Status === 0 &&
            (parseInt(product.Stock) <= 0 || isNaN(parseInt(product.Stock))) &&
            !isLoading &&
            product != null ? (
            ""
          ) : parseInt(product.PurchaseType) === 2 &&
            moment(product.ClosedDate).diff(currentDate, "Days") < 0 &&
            (parseInt(product.Stock) <= 0 || isNaN(parseInt(product.Stock))) &&
            !isLoading &&
            product != null ? (
            ""
          ) : (
            (parseInt(product.Stock) <= 0 || isNaN(parseInt(product.Stock))) &&
            !isLoading &&
            product != null && (
              <Row>
                <Col xs={12}>
                  <Button
                    variant="outline"
                    style={{ background: "#fff" }}
                    className="stock-kosong-btn"
                    block
                  >
                    Stok Kosong
                  </Button>
                </Col>
              </Row>
            )
          )}

          {parseInt(product.Status) === 0 && (
            <Row>
              <Col xs={12}>
                <Button
                  variant="outline"
                  style={{ background: "#fff" }}
                  className="stock-kosong-btn"
                  block
                >
                  Tidak Tersedia
                </Button>
              </Col>
            </Row>
          )}

          {parseInt(product.Status) === 1 &&
            parseInt(product.PurchaseType) === 1 &&
            parseInt(product.Stock) > 0 &&
            !isLoading &&
            product &&
            !product.Qty && (
              <Row>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    block
                    onClick={(e) =>
                      props.onChangeQty({
                        type: "CHANGE",
                        product: product,
                        value: 1,
                      })
                    }
                  >
                    Beli Sekarang
                  </Button>
                </Col>
              </Row>
            )}

          {parseInt(product.Status) === 1 &&
          parseInt(product.PurchaseType) === 2 &&
          moment(product.ClosedDate).diff(currentDate, "Days") < 0 ? (
            <Row>
              <Col xs={12}>
                <Button
                  variant="outline"
                  style={{ background: "#fff" }}
                  className="stock-kosong-btn"
                  block
                >
                  Pre Order Berakhir
                </Button>
              </Col>
            </Row>
          ) : (
            parseInt(product.Status) === 1 &&
            parseInt(product.PurchaseType) === 2 &&
            parseInt(product.Stock) > 0 &&
            !isLoading &&
            product &&
            !product.Qty && (
              <Row>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    block
                    onClick={(e) =>
                      props.onChangeQty({
                        type: "CHANGE",
                        product: product,
                        value: 1,
                      })
                    }
                  >
                    Pre Order
                  </Button>
                </Col>
              </Row>
            )
          )}

          {parseInt(product.Status) === 1 &&
            parseInt(product.PurchaseType) === 3 &&
            parseInt(product.Stock) > 0 &&
            !isLoading &&
            product &&
            !product.Qty && (
              <Row>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    block
                    onClick={(e) =>
                      props.onChangeQty({
                        type: "CHANGE",
                        product: product,
                        value: 1,
                      })
                    }
                  >
                    Pesan
                  </Button>
                </Col>
              </Row>
            )}

          {/* <Button variant="primary" disabled={product == null} onClick={(e) => props.onChangeQty({ type: "CHANGE", product: product, value: 1 })} block>Pesan</Button>
           */}
        </div>
      )}

      {/* Spacing in mobile view */}
      <div className="mt-5 d-md-none">&nbsp;</div>
    </Container>
  );
}
