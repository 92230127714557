// import action type newsnya
import { REQUEST_PROVIDER_PRODUCT, RESULT_PROVIDER_PRODUCT, RESULT_ADD_PRODUCT } from "./../actions/digital_product";
import{getDigital } from "./../../utils/cart";
// state untuk menyimpan data 
const initialState = {
    // state ini sebagai tanda apakah sedang loading mengambil data atau tidak
    fetchingProviderProduct: false,
    cartDigital: getDigital(),
    // // nantinya data news dari API disimpan di state news
    providerProduct: [],
    
};

export default function(state = initialState, action) {
  switch (action.type) {
    
    // Ketika action yang berjalan request news, artinya sedang loading data news
    case REQUEST_PROVIDER_PRODUCT: { 
      return {
        ...state,
        fetchingProviderProduct: true
      };
    }

    
    // data selesai di load, maka fetchingNews = false (selesai loading), dan datanya di simpan di state news
    
    case RESULT_PROVIDER_PRODUCT: {
      return {
        ...state,
        fetchingProviderProduct: false,
        providerProduct: action.payload.data
      };
    }
    case RESULT_ADD_PRODUCT: { 
      return {
        ...state,
        stateCart: 'getAllCartDigital',
        cartDigital: getDigital()
      };
  }
   

    default:
      return state;
  }
}