import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { toSlug } from "../../../utils/common";
import defaultLogo from "./../../../Images/store.png";
import "./StoreItem.css";

function StoreItem(props) {
  const { store, pageType } = props;
  const history = useHistory();

  // define default Logo
  const logo = store && store.Logo ? store.Logo : defaultLogo;

  return (
    <div className="store-item-card">
      <div className="store-item-image">
        <img
          src={logo}
          alt={store.Name}
          onClick={(e) =>
            history.push(
              "/store/" +
                store.ID +
                "/" +
                toSlug(store.Name) +
                (pageType && pageType === "nearby" ? "?type=" + pageType : "")
            )
          }
        />
      </div>
      <div className="store-item-name limit-1-line">
        <a href={"/store/" + store.ID + "/" + toSlug(store.Name)}>
          {store.Name}
        </a>
      </div>
    </div>
  );
}

StoreItem.propTypes = {
  store: PropTypes.object,
};

StoreItem.defaultProps = {
  store: null,
};

export default StoreItem;
