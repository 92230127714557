import Axios from "axios";
import { handleError } from "./../actions/app";

export const REQUEST_MARTCOURIER_LIST = "REQUEST_MARTCOURIER_LIST";
export const REQUEST_MARTCOURIER_FEE = "REQUEST_MARTCOURIER_FEE";
export const RESULT_MARTCOURIER_LIST = "RESULT_MARTCOURIER_LIST";
export const RESULT_MARTCOURIER_FEE = "RESULT_MARTCOURIER_FEE";

const requestMartCourierList = () => ({
  type: REQUEST_MARTCOURIER_LIST,
});

const resultMartCourierList = (data) => ({
  type: RESULT_MARTCOURIER_LIST,
  payload: {
    data,
  },
});

const requestMartCourierFee = () => ({
  type: REQUEST_MARTCOURIER_FEE,
});

const resultMartCourierFee = (data) => ({
  type: RESULT_MARTCOURIER_FEE,
  payload: {
    data,
  },
});

export const fetchMartCourierList = ({
  StoreID,
  DeliveryID,
  ProductIDs,
  Quantities,
  DeliveryOption,
  Courier,
}) => {
  ProductIDs = Array.isArray(ProductIDs) ? ProductIDs.join(",") : ProductIDs;
  Quantities = Array.isArray(Quantities) ? Quantities.join(",") : Quantities;

  let data = {
    StoreID,
    DeliveryID,
    ProductIDs,
    Quantities,
    DeliveryOption: DeliveryOption ? DeliveryOption : 4,
  };

  return (dispatch) => {
    dispatch(requestMartCourierList());

    return Axios.post("/postalfee", data)
      .then((response) => response.data)
      .then((data) => {
        let results = data.data;

        return dispatch(resultMartCourierList(results));
      })
      .catch((error) => dispatch(handleError(error)));
  };
};

export const fetchMartCourierFeeShipper = ({
  StoreID,
  DeliveryID,
  ProductIDs,
  Quantities,
  Courier,
}) => {
  ProductIDs = Array.isArray(ProductIDs) ? ProductIDs.join(",") : ProductIDs;
  Quantities = Array.isArray(Quantities) ? Quantities.join(",") : Quantities;

  let data = {
    // key: process.env.REACT_APP_RAJA_ONGKIR_KEY,
    // OriginType: typeof(OriginType) !== "undefined" ? OriginType : "subdistrict",
    // DestinationType : typeof(DestinationType) !== "undefined" ? DestinationType : "subdistrict",
    // Weight: parseInt(typeof(Weight) !== "undefined" ? Weight : "1"),
    // Courier: typeof(Courier) !== "undefined" ? Courier : "jne",
    StoreID,
    DeliveryID,
    ProductIDs,
    Quantities,
  };

  return (dispatch) => {
    dispatch(requestMartCourierFee());

    return Axios.post("/postalfee", data)
      .then((response) => response.data)
      .then((data) => {
        // let results = data.data;
        let results = {};
        let couriers = {};
        let services = {};

        if (!data.status) {
          dispatch(resultMartCourierFee({ couriers: {}, services: {} }));
          // console.log(data)
          return dispatch(
            handleError(
              true,
              typeof data.data.statusCode !== "undefined" &&
                parseInt(data.data.statusCode) === 404
                ? "Kurir tidak tersedia untuk pesanan ini. cek kembali jumlah pesanan atau wilayah anda"
                : ""
            )
          );
        }

        // let costs = {};
        if (data.data != null && data.data !== "") {
          data.data.rates &&
            data.data.rates.logistic &&
            data.data.rates.logistic.length > 0 &&
            data.data.rates.logistic.forEach((list, i) => {
              // console.log(list)

              // let [type, lists] = log;

              // Cargo dihilangkan, cargo itu di dalam trucking typenya
              // if(Array.isArray(lists) && type.toLowerCase() !== "trucking") {

              // lists.forEach(list => {
              let courierName = list.name.toLowerCase();

              let listName = list.name;

              if (listName === "GO-SEND") {
                listName = "GoSend";
              } else if (listName === "Grab Express") {
                listName = "GrabExpress";
              } else if (listName === "Tiki") {
                listName = "TIKI";
              }

              if (typeof couriers[courierName] === "undefined") {
                couriers[courierName] = {
                  name: listName,
                  logo: list.logo_url,
                };
              }

              if (typeof services[courierName] === "undefined") {
                services[courierName] = [];
              }

              let rate_desc =
                list.min_day !== list.max_day && parseInt(list.min_day) > 0
                  ? list.min_day + " - " + list.max_day
                  : list.max_day;
              rate_desc += " hari";
              let rate_name = list.rate_name;
              let desc = "";
              let dimensi = null;

              if (
                courierName === "go-send" &&
                list.rate_name.toLowerCase() === "instant"
              ) {
                rate_desc = "1 - 2 Jam";
                dimensi = { Length: 70, Width: 50, Height: 50 };
              } else if (
                courierName === "grab express" &&
                list.rate_name.toLowerCase() === "instant"
              ) {
                rate_desc = "1 - 2 Jam";
                dimensi = { Length: 40, Width: 40, Height: 20 };
              } else if (
                courierName === "grab express" &&
                list.rate_name.toLowerCase().search("same day") >= 0
              ) {
                rate_desc = "6 - 8 Jam";
                desc = "Jam Layanan : 09.00 - 16.00";
                rate_name = "SameDay";
                dimensi = { Length: 40, Width: 40, Height: 20 };
              } else if (
                courierName === "go-send" &&
                list.rate_name.toLowerCase().search("same day") >= 0
              ) {
                rate_desc = "6 - 8 Jam";
                desc = "Jam Layanan : 08.00 - 15.00";
                rate_name = "SameDay";
                dimensi = { Length: 40, Width: 40, Height: 17 };
              }

              services[courierName].push({
                rate_id: list.rate_id,
                rate_name,
                rate_desc,
                desc,
                dimensi,
                itemPrice: list.itemPrice,
                finalWeight: list.finalWeight,
                finalRate: list.finalRate,
                adjustmentFinalRate: list.adjustmentFinalRate,
                eta:
                  list.min_day !== list.max_day
                    ? list.min_day + " - " + list.max_day
                    : list.max_day,
                eta_unit:
                  courierName === "go-send" || courierName === "grab express"
                    ? "jam"
                    : "hari",
              });
              // })
              // }
            });
        }

        // console.log('couriers')
        // console.log(couriers)
        // console.log(services)

        // let results = [];
        // console.log(services)

        results = { couriers, services };

        // if(results != null && results.length > 0) {
        //     results.forEach(result => {
        //         costs[result['code']] = result['costs'];
        //     })
        // }

        return dispatch(resultMartCourierFee(results));
      })
      .catch((error) => dispatch(handleError(error)));
  };
};
