import { 
    REQUEST_DIGITALORDER_LIST,  
    REQUEST_DIGITALORDER,  
    REQUEST_DIGITALORDER_INQUIRY,  
    REQUEST_DIGITALORDER_SUBMIT,  
    REQUEST_DIGITALORDER_STATUS, 
    CANCELING_DIGITALORDER,
    CONFIRMING_DIGITALORDER,
    UPLOADING_DIGITALORDER_BUKTIBAYAR,
    RESULT_DIGITALORDER_LIST, 
    RESULT_DIGITALORDER_TOTAL_ORDER,  
    RESULT_DIGITALORDER_STATUS,
    RESULT_DIGITALORDER,
    RESULT_DIGITALORDER_INQUIRY,
    RESULT_DIGITALORDER_SUBMIT,
    CANCELED_DIGITALORDER,
    CONFIRMED_DIGITALORDER,
    UPLOADED_DIGITALORDER_BUKTIBAYAR } from "./../actions/digital_order";

const initialState = { 
    isFetchingDigitalOrderList: false, 
    isFetchingDigitalOrder: false, 
    isFetchingDigitalOrderWallet: false, 
    isFetchingDigitalOrderStatus: false, 
    isCancelingDigitalOrder: false,
    isConfirmingDigitalOrder: false,
    isUploadingDigitalOrderBuktiBayar: false,
    isSubmitDigitalOrder: false,
    isInquiryDigitalOrder: false,
    digital_orders: [],
    digital_order_total: null,
    digital_order: null,
    digital_order_inquiry: null,
    digital_order_wallet: null,
    digital_order_status: null,
    digital_order_bukti_bayar: null
};

export default function(state = initialState, action) {
    switch (action.type) {  
        case REQUEST_DIGITALORDER_LIST: { 
            return {
                ...state,
                isFetchingDigitalOrderList: true,
                digital_orders: []
            };
        }
 
        case REQUEST_DIGITALORDER: { 
            return {
                ...state,
                digital_order: null,
                isFetchingDigitalOrder: true
            };
        } 
 
        case REQUEST_DIGITALORDER_SUBMIT: { 
            return {
                ...state,
                isSubmitDigitalOrder: true,
                digital_order: null, 
            };
        } 
 
        case REQUEST_DIGITALORDER_INQUIRY: { 
            return {
                ...state,
                isInquiryDigitalOrder: true,
                digital_order_inquiry: null, 
            };
        } 
 
        case REQUEST_DIGITALORDER_STATUS: { 
            return {
                ...state,
                digital_order_status: null,
                isFetchingDigitalOrderStatus: true
            };
        }
 
        case CANCELING_DIGITALORDER: { 
            return {
                ...state, 
                isCancelingDigitalOrder: true
            };
        }
 
        case CONFIRMING_DIGITALORDER: { 
            return {
                ...state, 
                isConfirmingDigitalOrder: true
            };
        }

        case UPLOADING_DIGITALORDER_BUKTIBAYAR: { 
            return {
                ...state, 
                isUploadingDigitalOrderBuktiBayar: true
            };
        }
       
        case RESULT_DIGITALORDER_LIST: { 

            // local data
            if(process.env.REACT_APP_LOCAL_DATA === "true") {
                localStorage.setItem('AGREGO_USER_ORDER', JSON.stringify(action.payload.data)); 
            }

            return {
                ...state,
                isFetchingDigitalOrderList: false,
                digital_orders : action.payload.data
            };
        }
       
        case RESULT_DIGITALORDER_TOTAL_ORDER: { 
            
            // local data
            if(process.env.REACT_APP_LOCAL_DATA === "true") {
                localStorage.setItem('AGREGO_USER_ORDER', JSON.stringify(action.payload.data));
                localStorage.setItem('AGREGO_USER_ORDER_COUNT', JSON.stringify(action.payload.count));
            }

            return {
                ...state,
                isFetchingDigitalOrderList: false,
                digital_orders : action.payload.data,
                digital_total_order: action.payload.count
            };
        }
       
        case RESULT_DIGITALORDER: { 
            return {
                ...state,
                isFetchingDigitalOrder: false,
                digital_order : action.payload.data
            };
        }
       
        case RESULT_DIGITALORDER_INQUIRY: { 
            return {
                ...state,
                isInquiryDigitalOrder: false,
                digital_order_inquiry: action.payload.data, 
            };
        }
       
        case RESULT_DIGITALORDER_SUBMIT: { 
            return {
                ...state,
                isSubmitDigitalOrder: false, 
                digital_order: action.payload.data
            };
        }
        
        case RESULT_DIGITALORDER_STATUS: { 
            localStorage.setItem('AGREGO_ORDER_STATUS', JSON.stringify(action.payload.data)); 

            return {
                ...state,
                isFetchingDigitalOrderStatus: false,
                digital_order_status : action.payload.data
            };
        }
       
        case CANCELED_DIGITALORDER: { 
            
            return {
                ...state,
                isCancelingDigitalOrder: false
            };
        }
       
        case CONFIRMED_DIGITALORDER: { 
            
            return {
                ...state,
                isConfirmingDigitalOrder: false
            };
        }
       
        case UPLOADED_DIGITALORDER_BUKTIBAYAR: {  
            return {
                ...state,
                isUploadingDigitalOrderBuktiBayar: false,
                digital_order_bukti_bayar: action.payload.data
            };
        }

        default:
            return state;
  }
}