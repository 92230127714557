import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import Pagination from "react-js-pagination";
import Breadcrumb from "../Layout/Breadcrumb";
import { parseDateTime } from "./../../utils/common";
import BottomNav from "./../Layout/BottomNav";
import EmptyState from "./../Layout/EmptyState";
import Loading from "./../Layout/Loading";
import "./Berita.css";

ReactGA.set({ page: "/berita", title: "Berita" });
ReactGA.pageview("/berita", [], "News");

const limit_judul = function (str, length, ending) {
  if (length == null) {
    length = 60;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const limit_desk = function (str, length, ending) {
  if (length == null) {
    length = 70;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export default function News(props) {
  const { isLoading, news, itemPerPage, activePage, totalNews, breadcrumb } =
    props;

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [],
      "news_list"
    );
  }, []);

  return (
    <Container>
      {/* Loading State */}
      {isLoading && <Loading />}

      {!isLoading && (
        <div style={{ marginTop: "20px", paddingBottom: "0px" }}>
          {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
        </div>
      )}

      {/* Sekarang tab di hilangkan */}
      {/* {!isLoading &&

        <ul className="custom-tab berita-tab">
          <li className="active"><a href="/berita">Berita</a></li>
          <li><a href="/promo">Promosi</a></li>

        </ul>

      } */}

      {/* Tampilan Mobile */}
      <div className="d-block d-sm-none">
        {/* Empty State */}
        {!isLoading && news.length <= 0 && (
          <EmptyState teks="Belum ada data" className="emptyState" />
        )}

        {news.length > 0 &&
          !isLoading &&
          news.map((item) => (
            <div className="listBerita">
              <Row>
                <Col xs={4}>
                  <div
                    className={`${isLoaded ? "d-none" : ""}`}
                    style={{
                      width: "127px",
                      height: "95px",
                      background: "#f3f3f3",
                    }}
                    // alt="image default"
                    // src={thumbImg}
                  />
                  <img
                    src={item.Img}
                    onLoad={() => {
                      setIsLoaded(true);
                    }}
                    className={`${isLoaded ? "" : "d-none"} imgContentNews`}
                    alt="img-news"
                  />
                </Col>
                <Col xs={8}>
                  <a href={"/berita/" + item.ID + "/detail"}>
                    <div className="cardnewsjudul">{item.Title}</div>{" "}
                  </a>
                  <div className="cardnewsdetail">{item.ShortDesc}</div>
                </Col>
              </Row>
            </div>
          ))}

        {!isLoading && parseInt(totalNews) > parseInt(itemPerPage) && (
          <Row
            className="justify-content-center"
            style={{ margin: "10px 0px" }}
          >
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalNews}
              pageRangeDisplayed={3}
              onChange={props.onChangePage}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Row>
        )}
      </div>

      {/* Tampilan WEB */}

      <div className="d-none d-sm-block">
        {!isLoading && (
          <Row className="contentContainer">
            {/* Empty State */}
            {!isLoading && news.length <= 0 && (
              <EmptyState teks="Belum ada data" className="emptyState" />
            )}

            {news.length > 0 &&
              !isLoading &&
              news.map((item) => (
                <Col sm={4} xs={12}>
                  <div className="listBerita">
                    <div className="imgContentNews">
                      <div
                        className={`${isLoaded ? "d-none" : ""}`}
                        style={{
                          width: "100%",
                          height: "210px",
                          background: "#f3f3f3",
                        }}
                        // alt="image default"
                        // src={thumbImg}
                      />

                      <img
                        onLoad={() => {
                          setIsLoaded(true);
                        }}
                        className={`${isLoaded ? "" : "d-none"}`}
                        src={item.Img}
                        width="100%"
                        alt="img-news"
                      />
                    </div>
                    <div className="cardnewsdate d-none d-sm-block">
                      {parseDateTime(item.PublishedAt)} WIB
                    </div>
                    <div className="cardnewsjudul d-none d-sm-block">
                      {limit_judul(item.Title)}
                    </div>
                    <div className="cardnewsdetail d-none d-sm-block">
                      {limit_desk(item.ShortDesc)}
                    </div>
                    <div className=" d-none d-sm-block">
                      <Button
                        className="cardbutton"
                        variant="primary"
                        href={"/berita/" + item.ID + "/detail"}
                        block
                      >
                        Baca
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        )}
        {!isLoading && parseInt(totalNews) > parseInt(itemPerPage) && (
          <Row
            className="justify-content-center"
            style={{ margin: "10px 0px" }}
          >
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalNews}
              pageRangeDisplayed={3}
              onChange={props.onChangePage}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Row>
        )}
      </div>
      {/* Bottom Nav */}
      <BottomNav />
    </Container>
  );
}
