import Loading from "components/Layout/Loading";
import React, { Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { fetchSetting } from "redux/actions/app";
import App from "./App";
import Forgot from "./components/Auth/Forgot";
import Login from "./components/Auth/Login";
import WelcomeMessage from "./components/Auth/WelcomeMessage";
import Search from "./components/Search";

export default function Routes() {
  const stateSetting = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [googleAnalyticID, setGoogleAnalitycID] = useState(null);

  useEffect(() => {
    let analyticID = localStorage.getItem(
      process.env.REACT_APP_PREFIX + "GANALYTIC_ID"
    );

    if (!analyticID || analyticID == null || analyticID == "null") {
      dispatch(fetchSetting(6));
    } else {
      ReactGA.initialize(analyticID, {
        titleCase: false,
        debug: false,
      });
      setGoogleAnalitycID(analyticID);
    }
  }, []);

  useEffect(() => {
    if (!stateSetting.isFetchingSetting && stateSetting.settings) {
      let analyticID =
        stateSetting.settings &&
        stateSetting.settings["Key"] &&
        stateSetting.settings["Value"] &&
        stateSetting.settings["Key"] === "GoogleAnalytics" &&
        stateSetting.settings["Value"].indexOf("UA-") >= 0 &&
        stateSetting.settings["Value"].length >= 14
          ? stateSetting.settings["Value"]
          : process.env.REACT_APP_GANALYTIC_ID;

      if (analyticID) {
        ReactGA.initialize(analyticID, {
          titleCase: false,
          debug: false,
        });

        setGoogleAnalitycID(analyticID);
        localStorage.setItem(
          process.env.REACT_APP_PREFIX + "GANALYTIC_ID",
          analyticID
        );
      }
    }
  }, [stateSetting]);

  return (
    <>
      {!googleAnalyticID && <Loading />}
      {googleAnalyticID && (
        <Router>
          <Suspense fallback={<Loading />}>
            {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/login" exact={true} component={Login} />
              <Route path="/forgot-password" exact={true} component={Forgot} />
              <Route path="/search" exact={true} component={Search} />
              <Route path="/forgot-password" exact={true} component={Forgot} />
              <Route path="/welcome" exact={true} component={WelcomeMessage} />

              {/* jika sudah login redirect ke app */}
              <Route
                path="/"
                render={({ location }, ...props) => {
                  return <App {...props} />;
                }}
              />
            </Switch>
          </Suspense>
        </Router>
      )}
    </>
  );
}
