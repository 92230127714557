import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { fetchMartProductLatest } from "../../../redux/actions/mart_product";
import { filterLimit } from "./../../../utils/common";
import EmptyState from "./../../Layout/EmptyState";
import Loading from "./../../Layout/Loading";
import ProductItem from "./../Product/ProductItem.component";
import "./ProductTerbaru.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.5,
  },
};

function ProductTerbaru(props) {
  const { title, isLoading } = props;
  let filterData = { Status: 1 };

  // const productState = useSelector(state =>  state.martproduct);
  const productState = useSelector((state) => {
    // tampilkan product id selain yang terpilih di halaman detail
    if (state.martproduct.mart_product_latest_list.length > 0) {
      let productList = filterLimit(
        state.martproduct.mart_product_latest_list,
        (o) => {
          return !isNaN(parseInt(o.Stock)) && parseInt(o.Stock) > 0;
        },
        10
      );

      state.martproduct = {
        ...state.martproduct,
        mart_product_latest_list: productList,
      };
    }

    // onEmpty Product
    if (
      !isLoading &&
      !state.martproduct.isFetchingMartProductLatestList &&
      state.martproduct.mart_product_latest_list != null
    ) {
      if (state.martproduct.mart_product_latest_list.length <= 0) {
        props.onEmpty(true);
      } else {
        props.onEmpty(false);
      }
    }

    return state.martproduct;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMartProductLatest({ page: 1, size: 50, search: filterData }));
  }, [isLoading]);

  return (
    <div
      className={"product-terbaru-card " + props.className}
      style={props.style}
    >
      <a
        href="/shop/product?type=latest"
        className="float-right terbaru-link-more"
      >
        Lihat Semua
      </a>
      <div className="product-terbaru-title">{title}</div>

      {(productState.isFetchingMartProductLatestList || isLoading) && (
        <Loading />
      )}

      {!isLoading &&
        !productState.isFetchingMartProductLatestList &&
        productState.mart_product_latest_list.length <= 0 && (
          <div className="text-center">
            <EmptyState style={{ margin: "auto" }} />
          </div>
        )}
      <Carousel
        responsive={responsive}
        autoPlay={false}
        deviceType={props.deviceType}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="react-multi-carousel-dot-list"
        keyBoardControl={true}
        swipeable={true}
        draggable={true}
        infinite={true}
      >
        {!productState.isFetchingMartProductLatestList &&
          productState.mart_product_latest_list.length > 0 &&
          productState.mart_product_latest_list.map((item, index) => {
            return (
              <div key={index}>
                <ProductItem
                  product={item}
                  style={props.styleItem}
                  className={props.classNameItem}
                />
              </div>
            );
          })}
      </Carousel>
    </div>
  );
}

ProductTerbaru.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmpty: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  classNameItem: PropTypes.string,
  styleItem: PropTypes.object,
};

ProductTerbaru.defaultProps = {
  title: "Produk Terbaru",
  isLoading: false,
  onEmpty: () => {},
  className: "",
  style: {},
  classNameItem: "",
  styleItem: {},
};

export default ProductTerbaru;
