import {
  REQUEST_MARTSTORE,
  REQUEST_MARTSTORE_LIST,
  REQUEST_MARTSTORE_NEARBY,
  RESULT_MARTSTORE,
  RESULT_MARTSTORE_LIST,
  RESULT_MARTSTORE_NEARBY,
  RESULT_MARTSTORE_TOTAL_STORE,
} from "./../actions/mart_store";

const initialState = {
  isFetchingMartStoreList: false,
  isFetchingMartStore: false,
  isFetchingMartStoreNearby: false,
  mart_stores: [],
  mart_nearby_stores: [],
  mart_store: null,
  mart_store_total_store: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_MARTSTORE_LIST: {
      return {
        ...state,
        isFetchingMartStoreList: true,
      };
    }

    case REQUEST_MARTSTORE: {
      return {
        ...state,
        isFetchingMartStore: true,
      };
    }

    case REQUEST_MARTSTORE_NEARBY: {
      return {
        ...state,
        isFetchingMartStoreNearby: true,
      };
    }

    case RESULT_MARTSTORE_LIST: {
      const { data } = action.payload;

      // local data
      // if(process.env.REACT_APP_LOCAL_DATA === "true") {
      //     localStorage.setItem('AGREGO_STORE_LIST', JSON.stringify(data));
      // }

      return {
        ...state,
        isFetchingMartStoreList: false,
        mart_stores: data,
      };
    }

    case RESULT_MARTSTORE: {
      return {
        ...state,
        isFetchingMartStore: false,
        mart_store: action.payload.data,
      };
    }

    case RESULT_MARTSTORE_NEARBY: {
      return {
        ...state,
        isFetchingMartStoreNearby: false,
        mart_nearby_stores: action.payload.data,
      };
    }

    case RESULT_MARTSTORE_TOTAL_STORE: {
      const { data } = action.payload;

      // local data
      // if(process.env.REACT_APP_LOCAL_DATA === "true") {
      //     localStorage.setItem('AGREGO_STORE_LIST', JSON.stringify(data));
      // }

      return {
        ...state,
        isFetchingMartStoreList: false,
        mart_stores: data,
        mart_store_total_store: action.payload.count,
      };
    }

    default:
      return state;
  }
}
