import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskedInput from 'react-text-mask'
import { stripCharacter } from './../../utils/common';

// First, you need to create the `numberMask` with your desired configurations
const numberMask = createNumberMask({
  prefix: '',
  suffix: '', // This will put the dollar sign at the end, with a space.
  thousandsSeparatorSymbol: '.'
})

class InputMask extends React.Component {

	constructor(props) {
		super(props); 

		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this); 
	}

	handleBlur() {
		this.props.onBlur(this.props.name,true);
	}

	handleChange(event) { 
		let cleanValue = stripCharacter(event.target.value, '.')
		// console.log(event.target.value)
		// this.props.onBlur(this.props.name,true);
		this.props.onChange(this.props.name,cleanValue);
	} 

	render() { 

		let value = !isNaN(parseInt(this.props.value)) ?
					parseInt(this.props.value) : (this.props.value != null ? '' : 0); 

		return (<MaskedInput
		  mask={numberMask}
		  className={this.props.className+" form-control"}
		  placeholder={this.props.placeholder}
		  value={value}
		  id={this.props.id}
		  disabled={this.props.disabled}
		  onBlur={this.handleBlur}
		  onChange={this.handleChange} 
		/>);
	}
}

export default InputMask;