import Axios from "axios";
import { setFieldSearchQuery, setQueryString } from "./../../utils/common";
const searchFieldType = { promoID: "int" };

// baris di bawah ini di hapus aja gpp sama function laen yang ga diperluin lagi ndri

// 1. buat action type untuk mengambil data berita dari API
// 2. buat action creator / function untuk menghandle yang menjalankan request untuk mengambil data dari API

// Action type untuk news, ini bisa inline di file ini atau di import dari actionTypes.js
export const REQUEST_PROMO = "REQUEST_PROMO";
export const RESULT_PROMO = "RESULT_PROMO";
export const RESULT_TOTAL_PROMO = "RESULT_TOTAL_PROMO";
export const REQUEST_PROMO_DETAIL = "REQUEST_PROMO_DETAIL";
export const RESULT_PROMO_DETAIL = "RESULT_PROMO_DETAIL";
// Action Creator
// state ketika sedang loading request data
export const requestPromo = () => ({
  type: REQUEST_PROMO,
});

export const requestPromoDetail = () => ({
  type: REQUEST_PROMO_DETAIL,
});

// state ketika data sudah berhasil di dapat
export const resultPromo = (data) => ({
  type: RESULT_PROMO,
  payload: {
    data,
  },
});

export const resultPromoDetail = (data) => ({
  type: RESULT_PROMO_DETAIL,
  payload: {
    data,
  },
});

const resultTotalPromo = (data) => ({
  type: RESULT_TOTAL_PROMO,
  payload: {
    data: data.data,
    count: data.count,
  },
});

// fetch news
export const fetchPromo = (props, withTotalCount) => {
  // ketika method / action creator ini di panggil kita panggil api news dengan Axios

  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  withTotalCount = typeof withTotalCount !== "undefined" && withTotalCount;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestPromo());

    return Axios.get(
      "/promotion/" + process.env.REACT_APP_OID + "?" + queryString
    )

      .then((response) => response.data)
      .then((data) => {
        // respon atau data dari server ada di variable data
        console.log(withTotalCount);
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedNews
        if (withTotalCount) {
          return dispatch(
            fetchTotalPromo({
              ...props,
              items: data.data,
              message: data.message,
            })
          );
        }
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedNews
        return dispatch(resultPromo(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

// Request news detail dari API
export const fetchPromoDetailById = (id) => {
  return (dispatch) => {
    dispatch(requestPromoDetail());

    return Axios.get(
      "/promotion/" + process.env.REACT_APP_OID + "/" + id + "?Page=1&Size=8"
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultPromoDetail(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

export const fetchTotalPromo = ({
  keyword,
  operand,
  items,
  message,
  queryString,
}) => {
  return (dispatch) => {
    keyword = typeof keyword !== "undefined" ? keyword : "";
    queryString = setFieldSearchQuery(keyword, searchFieldType);

    return Axios.get(
      "/promotioncount/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedMartProduct
        return dispatch(
          resultTotalPromo({ data: items, count: data.data.count })
        );
      })
      .catch((error) => console.log("error"));
  };
};
