import imgBca from "Images/bank-bca.png";
import imgMandiri from "Images/bank-mandiri.png";
import imgAlfatrex from "Images/courier-alfatrex.png";
import imgGosend from "Images/courier-gosend.png";
import imgGrab from "Images/courier-grab.png";
import imgIndah from "Images/courier-indah.png";
//import { Item } from 'react-bootstrap/lib/Breadcrumb';
import imgJne from "Images/courier-jne.png";
import imgJnt from "Images/courier-jnt.png";
import imgLion from "Images/courier-lion.png";
import imgNinja from "Images/courier-ninja.png";
import imgPos from "Images/courier-pos.png";
import imgSap from "Images/courier-sap.png";
import imgSicepat from "Images/courier-sicepat.png";
import imgTiki from "Images/courier-tiki.png";
import imgWahana from "Images/courier-wahana.png";
import imgQris from "Images/payment-qris.png";
import imgWallet from "Images/payment-wallet.png";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createMarkup } from "utils/common";
import "./Beranda.css";
import Banner from "./components/Layout/Banner";
import BottomNav from "./components/Layout/BottomNav";
import Loading from "./components/Layout/Loading";
import ProductByCategory from "./components/Mart/ProductByCategory";
import ProductChoice from "./components/Mart/ProductChoice";
import ProductPopular from "./components/Mart/ProductPopular";
import ProductTerbaru from "./components/Mart/ProductTerbaru";
import PopularStore from "./components/Mart/Store/PopularStore.component";
import NearbyStore from "./components/NearbyStore/NearbyStore.component";
import ImgPPOB from "./Images/beranda-category-ppob.png";
// import ichelp from './Images/ic-help-white.png'
import ichelp from "./Images/ic-whatsapp.png";
import { fetchHomeDescription, fetchSetting } from "./redux/actions/app";
import { fetchMartCategory } from "./redux/actions/mart_category";
import { fetchMartProduct } from "./redux/actions/mart_product";

const responsiveMart = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 5,
  },
};

const paymentList = [imgQris, imgWallet];
const bankList = [imgMandiri, imgBca];

const courierList = [
  imgJne,
  imgTiki,
  imgPos,
  imgWahana,
  imgSicepat,
  imgNinja,
  imgSap,
  imgJnt,
  imgGosend,
  imgGrab,
  imgAlfatrex,
  imgLion,
  imgIndah,
];

class Beranda extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      csPhone: null,
      emptyPopulerProduct: false,
      emptyLatestProduct: false,
      emptyProductChoice: false,
      emptyPopulerStore: false,
      emptyNearbyStore: false,
      myLocation: null,
      homeDesc: null,

      categories: null,
      categoryID: null,
      queueCategory: [],
      loadingCategory: false,
    };

    this.handleSearchMyLocation = this.handleSearchMyLocation.bind(this);
  }

  componentDidMount() {
    ReactGA.set({ title: "home" });
    ReactGA.pageview("/beranda");

    this.props.fetchMartCategory({
      page: 1,
      size: 100,
      sorting: "Updated_At-desc",
    });

    this.props.fetchHomeDescription();
    this.props.fetchSetting(5);

    this.setState({
      isLoading: true,
    });

    this.handleSearchMyLocation();
  }

  componentDidUpdate() {
    const { isFetchingMartCategory, mart_category } = this.props.martcategory;
    const {
      isFetchingHomeDescription,
      isFetchingSetting,
      settings,
      homeDescription,
    } = this.props.app;
    const {
      isLoading,
      myLocation,
      queueCategory,
      loadingCategory,
      categoryID,
    } = this.state;
    let { csPhone, categories } = this.state;
    const { isFetchingMartProductList, mart_products } = this.props.martproduct;

    let getCsPhone = localStorage.getItem("AGREGO_CSPHONE");
    if (csPhone == null && getCsPhone != null) {
      getCsPhone = getCsPhone != null ? "62" + getCsPhone.slice(1) : getCsPhone;
      this.setState({ csPhone: getCsPhone });
    }

    if (loadingCategory && !isFetchingMartProductList) {
      categories = categories ? categories : [];
      let title =
        mart_products && mart_products[0] && mart_products[0]["CategoryName"]
          ? mart_products[0]["CategoryName"]
          : null;

      let products =
        mart_products && mart_products.length
          ? mart_products.filter((obj) => parseInt(obj.Stock) > 0)
          : null;
      categories.push({ id: categoryID, title, data: products });
      this.setState({ loadingCategory: false, categories });
    }

    if (!loadingCategory && queueCategory && queueCategory.length) {
      let categoryID = queueCategory.pop();
      this.props.fetchMartProduct({
        Page: 1,
        Size: 10,
        search: { CategoryID: categoryID },
      });

      this.setState({ loadingCategory: true, categoryID });
    }

    if (
      !isFetchingMartCategory &&
      !isFetchingHomeDescription &&
      !isFetchingSetting &&
      isLoading
    ) {
      let CategoryID = settings && settings["Value"] ? settings["Value"] : null;
      let category = [
        {
          ID: null,
          Name: "Pulsa, Data, PPOB",
          Img: ImgPPOB,
        },
      ].concat(mart_category);

      this.setState({
        isLoading: false,
        data: category,
        homeDesc: homeDescription,
        queueCategory: CategoryID ? CategoryID.split(",") : [],
      });
    }
  }

  handleSearchMyLocation() {
    let myLocation = localStorage.getItem(
      process.env.REACT_APP_PREFIX + "MY_LOCATION"
    );

    myLocation = myLocation ? JSON.parse(myLocation) : null;

    let th = this;

    if (!myLocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let { latitude, longitude } = position.coords;

        th.setState({ myLocation: { latitude, longitude } });

        localStorage.setItem(
          process.env.REACT_APP_PREFIX + "MY_LOCATION",
          JSON.stringify({ latitude, longitude })
        );
      });
    } else {
      let { latitude, longitude } = myLocation;
      th.setState({ myLocation: { latitude, longitude } });
    }
  }

  render() {
    const {
      isLoading,
      loadingCategory,
      data,
      emptyPopulerProduct,
      emptyLatestProduct,
      emptyProductChoice,
      emptyPopulerStore,
      emptyNearbyStore,
      csPhone,
      myLocation,
      homeDesc,
      categories,
    } = this.state;

    return (
      <div>
        {isLoading && (
          <Container>
            <Loading />
          </Container>
        )}

        {!isLoading && (
          <Container>
            <div className="bannercontainer">
              <Banner isLoading={isLoading} />
            </div>
          </Container>
        )}

        {/* <Row>
          <Col md={12} xs={12}>
          <ProdukVirtual/>
          </Col>
        </Row> */}

        {!isLoading && (
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <div className="pinmartContainer">
                  {/* <div className="pinmartJudul">Kategori</div> */}
                  <div className="slideContainer">
                    <Carousel
                      responsive={responsiveMart}
                      className="beranda-category-carousel"
                      deviceType={this.props.deviceType}
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="react-multi-carousel-dot-list"
                      draggable={true}
                      swipeable={true}
                      minimumTouchDrag={50}
                      keyBoardControl={true}
                    >
                      {data != null &&
                        data.length > 0 &&
                        !isLoading &&
                        data.map((item, index) => {
                          // atur link jika pulsa dan paket data
                          let href = "/shop/product?type=cat&cat_id=" + item.ID;
                          let name =
                            item != null && item.Name != null
                              ? item.Name.toLowerCase()
                              : "";

                          // urlnya berbeda saat kategorinya pulsa dan paket data
                          if (name.includes("pulsa")) {
                            href = "/digital/pulsa";
                          }

                          return (
                            <div
                              key={index}
                              className="pinmartKotak"
                              onClick={(e) => this.props.history.push(href)}
                            >
                              <img
                                src={item.Img}
                                height="60"
                                alt={"kategori " + item.Name}
                              ></img>
                              <div className="pinmartLink">{item.Name}</div>
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}

        <div style={{ height: "10px", marginTop: "10px" }}>
          {/* balancing */}
        </div>

        {/* Product Popular */}
        {!isLoading && (
          <Container>
            <div className={emptyPopulerProduct ? "d-none" : ""}>
              <ProductPopular
                className="beranda-carousel"
                isLoading={isLoading}
                style={{ marginTop: "24px" }}
                onEmpty={(val) =>
                  val !== emptyPopulerProduct &&
                  this.setState({ emptyPopulerProduct: val })
                }
              />
            </div>
          </Container>
        )}

        {/* Product Terbaru */}
        {!isLoading && (
          <Container>
            <div className={emptyLatestProduct ? "d-none" : ""}>
              <ProductTerbaru
                className="beranda-carousel"
                isLoading={isLoading}
                style={{ marginTop: "24px" }}
                onEmpty={(val) =>
                  val !== emptyLatestProduct &&
                  this.setState({ emptyLatestProduct: val })
                }
              />
            </div>
          </Container>
        )}

        {/* Product Pilihan */}
        {!isLoading && (
          <div
            className={
              emptyProductChoice ? "d-none" : "product-choice-home bg-secondary"
            }
          >
            <Container>
              <ProductChoice
                className="beranda-carousel-choice"
                isLoading={isLoading}
                onEmpty={(val) =>
                  val !== emptyProductChoice &&
                  this.setState({ emptyProductChoice: val })
                }
              />
            </Container>
          </div>
        )}

        {/* store */}
        {!isLoading && (
          <Container>
            <div className={emptyPopulerStore ? "d-none" : ""}>
              <PopularStore
                className="beranda-carousel"
                title="Daftar Toko"
                isLoading={isLoading}
                style={{ marginTop: "24px", marginBottom: "48px" }}
                onEmpty={(val) =>
                  val !== emptyPopulerStore &&
                  this.setState({ emptyPopulerStore: val })
                }
              />
            </div>
          </Container>
        )}

        {/* Toko Terdekat */}
        {!isLoading &&
          myLocation &&
          myLocation["latitude"] &&
          myLocation["longitude"] && (
            <Container>
              <div
                className={emptyNearbyStore ? "d-none" : "nearby-store-home"}
              >
                <NearbyStore
                  className="beranda-carousel"
                  title={<div style={{ color: "#fff" }}>Toko Terdekat</div>}
                  isLoading={isLoading}
                  latitude={myLocation["latitude"]}
                  longitude={myLocation["longitude"]}
                  style={{
                    marginTop: "24px",
                    background: "var(--primary)",
                    color: "#fff",
                  }}
                  onEmpty={(val) =>
                    val !== emptyNearbyStore &&
                    this.setState({ emptyNearbyStore: val })
                  }
                />
              </div>
            </Container>
          )}

        {/* Product By Category */}
        {loadingCategory && <Loading text="Memuat produk kategori..." />}
        {!isLoading && !loadingCategory && categories && categories.length
          ? categories.map(({ title, data }) => {
              return (
                <>
                  {data && data.length ? (
                    <Container>
                      <div>
                        <ProductByCategory
                          title={title}
                          data={data}
                          className="beranda-carousel"
                          style={{ marginTop: "24px" }}
                        />
                      </div>
                    </Container>
                  ) : null}
                </>
              );
            })
          : null}

        {/* Footer */}
        {!isLoading && (
          <Container
            style={{
              marginTop: "45px",
              padding: "28px auto",
              color: "#A0A0A0",
            }}
          >
            <div
              style={{
                borderBottom: "3px solid #D1D1D1",
                marginBottom: "10px",
              }}
            >
              &nbsp;
            </div>
            {homeDesc && homeDesc.length
              ? homeDesc.map((desc) => (
                  <>
                    <div style={{ paddingTop: "35px", fontSize: "24px" }}>
                      {desc["Title"]}
                    </div>
                    <div
                      style={{ paddingTop: "18px" }}
                      dangerouslySetInnerHTML={createMarkup(
                        desc["Description"]
                      )}
                    />
                  </>
                ))
              : null}

            <Row>
              <Col lg={4} md={6} xs={12} style={{ paddingTop: "35px" }}>
                <div style={{ fontSize: "24px" }}>Metode Pembayaran</div>
                <Row noGutters>
                  {paymentList &&
                    paymentList.length &&
                    paymentList.map((payment) => {
                      return (
                        <Col md={3} xs={3}>
                          <img src={payment} width={75} />
                        </Col>
                      );
                    })}
                </Row>
              </Col>
              <Col lg={4} md={6} xs={12} style={{ paddingTop: "35px" }}>
                <div style={{ fontSize: "24px" }}>Bank Transfer</div>
                <Row noGutters>
                  {bankList &&
                    bankList.length &&
                    bankList.map((bank) => {
                      return (
                        <Col md={3} xs={3}>
                          <img src={bank} width={75} />
                        </Col>
                      );
                    })}
                </Row>
              </Col>
              <Col lg={4} md={6} xs={12} style={{ paddingTop: "35px" }}>
                <div style={{ fontSize: "24px" }}>Jasa Pengiriman</div>
                <Row noGutters>
                  {courierList &&
                    courierList.length &&
                    courierList.map((courier) => {
                      return (
                        <Col md={3} xs={3} style={{ marginBottom: "14px" }}>
                          <img src={courier} width={75} />
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </Container>
        )}

        <div className="d-none d-lg-block" style={{ height: "50px" }}>
          &nbsp;
        </div>

        {/* Bottom Nav */}
        <BottomNav activeKey={1} />

        {/* Floating Button */}
        <div className="help-floating-button">
          <Button
            variant="primary"
            target="_blank"
            style={{ borderRadius: "100%", width: "52px", height: "52px" }}
            disabled={csPhone == null || typeof csPhone === "undefined"}
            onClick={(e) => {
              ReactGA.event({ category: "customercare", action: "contact_cs" });
              window.location.href = "https://wa.me/" + csPhone + "?text=Hallo";
            }}
          >
            <img
              src={ichelp}
              style={{ marginTop: "5px", height: "28px" }}
              alt="icon-help"
            />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { martcategory, martproduct, app } = state;
  return { martcategory, martproduct, app };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchMartCategory,
    fetchHomeDescription,
    fetchSetting,
    fetchMartProduct,
  })(Beranda)
);
