import Axios from "axios";

// baris di bawah ini di hapus aja gpp sama function laen yang ga diperluin lagi ndri

// 1. buat action type untuk mengambil data berita dari API
// 2. buat action creator / function untuk menghandle yang menjalankan request untuk mengambil data dari API

// Action type untuk news, ini bisa inline di file ini atau di import dari actionTypes.js
export const REQUEST_PRODUKVIRTUAL = "REQUEST_PRODUKVIRTUAL";
export const RESULT_PRODUKVIRTUAL = "RESULT_PRODUKVIRTUAL";

// Action Creator
// state ketika sedang loading request data
export const requestProdukVirtual = () => ({
  type: REQUEST_PRODUKVIRTUAL,
});

// state ketika data sudah berhasil di dapat
export const resultProdukVirtual = (data) => ({
  type: RESULT_PRODUKVIRTUAL,
  payload: {
    data,
  },
});

// fetch news
export const fetchProdukVirtual = () => {
  // ketika method / action creator ini di panggil kita panggil api news dengan Axios

  return (dispatch) => {
    dispatch(requestProdukVirtual());

    return Axios.get("/digitalcategory/7?Page=1&Size=100&SortBy=Name-asc")

      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultProdukVirtual(data.data));
      })
      .catch((error) => console.log("error"));
  };
};
