import React from 'react';
import { useState } from 'react';
import {Form, Button} from 'react-bootstrap';
import GoogleMap from './../../GMap';

export default function NewAddress(props) {

    const {values, errorInputAddress, loadingProvince, loadingCity, loadingSubDistrict, loadingArea, provinces, cities, subdistricts, area} =  props; 

    const [location, setLocation] = useState(null)

    const changeMapLocation = (value) => {
      setLocation(value)
    }

    const setUpMapLocation = () => {
      props.onChangeLocationAddress('Latitude', location != null && location['lat'] != null ? location['lat'] : "")
      props.onChangeLocationAddress('Longitude', location != null && location['lng'] != null ? location['lng'] : "")
      
      setLocation(null)
    }

    return (<Form>
        {/* <Form.Group controlId="formBasicLabel">
          <Form.Label>Label</Form.Label>
          <Form.Control type="text" name="Labels" value={values != null && typeof(values.Labels) !== "undefined" && values.Labels} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Label" /> 
          {errorInputAddress != null && errorInputAddress['Labels'] !== "undefined" && <div className="text-danger">{errorInputAddress['Labels']}</div>}
        </Form.Group> */}

        <Form.Group controlId="formBasicRecipient">
          <Form.Label>Nama Penerima *</Form.Label>
          <Form.Control type="text" name="Recipient" value={values != null && typeof(values.Recipient) !== "undefined" &&  values.Recipient} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Nama Penerima" /> 
          {errorInputAddress != null && errorInputAddress['Recipient'] !== "undefined" && <div className="text-danger">{errorInputAddress['Recipient']}</div>}
        </Form.Group>
      
        <Form.Group controlId="formBasicPhone">
          <Form.Label>Nomor Telepon Penerima *</Form.Label>
          <Form.Control type="text" name="Phone" value={values != null && typeof(values.Phone) !== "undefined" && values.Phone} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Nomor Telepon" />
          {errorInputAddress != null && errorInputAddress['Phone'] !== "undefined" && <div className="text-danger">{errorInputAddress['Phone']}</div>}
        </Form.Group> 
      
        <Form.Group controlId="formBasicAddress">
          <Form.Label>Alamat Lengkap *</Form.Label>
          <Form.Control type="textarea" name="Address" value={values != null && typeof(values.Address) !== "undefined" && values.Address} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Alamat" />
          {errorInputAddress != null && errorInputAddress['Address'] !== "undefined" && <div className="text-danger">{errorInputAddress['Address']}</div>}
        </Form.Group>
      
        <Form.Group controlId="formBasicProvince">
          <Form.Label>Provinsi *</Form.Label> 
          <Form.Control as="select" name="ProvinceID" value={values != null ? values['ProvinceID'] : null} onChange={(e) => props.onChangeProvince(e)}>
            {loadingProvince ? <option value="">Memuat data...</option> : <option value="">Pilih Provinsi</option>} 
            {provinces != null && provinces.length > 0 && provinces.map((item,index) => <option key={index} value={item.ID}>{item.Name}</option>)} 
          </Form.Control>
          {/* <Form.Control type="text" name="Province" value={values != null && typeof(values.Province) !== "undefined" && values.Province} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Provinsi" /> */}
          {errorInputAddress != null && errorInputAddress['ProvinceID'] !== "undefined" && <div className="text-danger">{errorInputAddress['ProvinceID']}</div>}
        </Form.Group>
      
        <Form.Group controlId="formBasicDistrict">
          <Form.Label>Kota / Kabupaten *</Form.Label>
          <Form.Control as="select" name="CityID" value={values != null ? values['CityID'] : null} onChange={(e) => props.onChangeCity(e)} disabled={loadingCity}>
            {loadingCity ? <option value="">Memuat data...</option> : <option value="">Pilih Kota</option>} 
            {cities != null && cities.length > 0 && cities.map((item,index) => <option key={index} value={item.ID}>{item.Name}</option>)} 
          </Form.Control>
          {/* <Form.Control type="text" name="CityID" value={values != null && typeof(values.CityID) !== "undefined" && values.CityID} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Kota / Kabupaten" /> */}
          {errorInputAddress != null && errorInputAddress['CityID'] !== "undefined" && <div className="text-danger">{errorInputAddress['CityID']}</div>}
        </Form.Group>
      
        <Form.Group controlId="formBasicKecamatan">
          <Form.Label>Kecamatan *</Form.Label>
          <Form.Control as="select" name="SubDistrictID" value={values != null ? values['SubDistrictID'] : null} onChange={(e) => props.onChangeSubDistrict(e)} disabled={loadingSubDistrict}>
            {loadingSubDistrict ? <option value="">Memuat data...</option> : <option value="">Pilih Kecamatan</option>} 
            {subdistricts != null && subdistricts.length > 0 && subdistricts.map((item,index) => <option key={index} value={item.ID}>{item.Name}</option>)} 
          </Form.Control>
          {/* <Form.Control type="text" name="SubDistrict" value={values != null && typeof(values.SubDistrict) !== "undefined" && values.SubDistrict} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Kecamatan" /> */}
          {errorInputAddress != null && errorInputAddress['SubDistrictID'] !== "undefined" && <div className="text-danger">{errorInputAddress['SubDistrictID']}</div>}
        </Form.Group>
      
        <Form.Group controlId="formBasicKecamatan">
          <Form.Label>Kelurahan *</Form.Label>
          <Form.Control as="select" name="AreaID" value={values != null ? values['AreaID'] : null} onChange={(e) => props.onChangeArea(e)} disabled={loadingArea}>
            {loadingArea ? <option value="">Memuat data...</option> : <option value="">Pilih Kelurahan</option>} 
            {area != null && area.length > 0 && area.map((item,index) => <option key={index} value={item.ID}>{item.Name}</option>)} 
          </Form.Control>
          {/* <Form.Control type="text" name="SubDistrict" value={values != null && typeof(values.SubDistrict) !== "undefined" && values.SubDistrict} onChange={(e) => props.onChangeFormAddress(e)} placeholder="Kecamatan" /> */}
          {errorInputAddress != null && errorInputAddress['AreaID'] !== "undefined" && <div className="text-danger">{errorInputAddress['AreaID']}</div>}
        </Form.Group>
      
        <Form.Group controlId="formBasicLokasi">
          <Form.Label>Lokasi</Form.Label>
          <h6>Geser Peta untuk menyesuaikan lokasi anda</h6>
          <GoogleMap  
            height={300}
            lat={values.Latitude} 
            lng={values.Longitude}
            onChange={changeMapLocation}
            
            />
          {errorInputAddress != null && errorInputAddress['Latitude'] !== "undefined" &&  
            <div className="text-danger">{errorInputAddress['Latitude']}</div> 
          }
          <Button variant="primary" size="sm" style={{marginTop:'8px'}} disabled={location == null} onClick={e => setUpMapLocation(true)}>Simpan lokasi</Button>
        </Form.Group> 
        
        <Form.Group controlId="formBasicKodepos">
          <Form.Label>Kode Pos *</Form.Label>
          <Form.Control type="text" name="PostalCode" value={values != null && typeof(values.PostalCode) !== "undefined" && values.PostalCode} onChange={(e) => props.onChangeFormAddress(e)} disabled placeholder="Kode Pos" />
          {errorInputAddress != null && errorInputAddress['PostalCode'] !== "undefined" && <div className="text-danger">{errorInputAddress['PostalCode']}</div>}
        </Form.Group> 
    </Form>);
}