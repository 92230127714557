import {
  CLOSE_SEARCH_PRODUCT,
  DISPLAY_MESSAGE,
  FETCHED_CS_PHONE,
  FETCHED_HOME_DESCRIPTION,
  FETCHED_SETTING,
  FETCHING_CS_PHONE,
  FETCHING_HOME_DESCRIPTION,
  FETCHING_SETTING,
  HANDLE_ERROR,
  OPEN_SEARCH_PRODUCT,
  RESET_APP_STATE,
} from "./../actions/app";

const initialState = {
  message: null,
  messageExtra: null,
  isError: false,
  errorType: null,
  nextPage: null,

  redirectTo: null,
  isFetchingCsPhone: false,
  isFetchingHomeDescription: false,
  isSearchingProduct: false,
  isFetchingSetting: false,
  csphone: null,
  homeDescription: null,
  settings: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DISPLAY_MESSAGE: {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        isError: action.payload.isError,
        redirectTo: action.payload.redirectTo,
      };
    }

    case HANDLE_ERROR: {
      let error = action.payload.error;
      let message = "";
      let message_extra = "";
      let nextPage = action.payload.nextPage;
      let errorType = null;

      let redirectTo = null;
      if (error["response"]) {
        let resp = error["response"];
        switch (resp["status"]) {
          // UnAuthorized
          case 400:
            message = "Akses Berakhir";
            message_extra = "Silakan login kembali";
            localStorage.removeItem(process.env.REACT_APP_PREFIX + "TOKEN");
            errorType = "ERROR_400";
            break;

          case 401:
            message = "Akses berakhir";
            message_extra = "Silakan login kembali";
            localStorage.removeItem(process.env.REACT_APP_PREFIX + "TOKEN");
            errorType = "ERROR_401";
            break;

          case 403:
            message = "Hi, ada sesuatu nih di akun Anda";
            message_extra = "Hubungi Customer Care untuk bantuan";
            // localStorage.removeItem("AGREGO_USER_TOKEN");
            errorType = "ERROR_403";
            break;

          case 500:
            message = "Layanan tidak tersedia";
            message_extra = "Silakan coba beberapa saat lagi";
            errorType = "ERROR_500";
            break;

          default:
            errorType = "ERROR_UNKNOWN";
            message =
              "Kami tidak dapat memenuhi permintaan anda saat ini, silakan coba lagi nanti";
            break;
        }
      } else if (error["request"]) {
        let req = error["request"];

        switch (parseInt(req["status"])) {
          case 0:
            errorType = "ERROR_TIMEOUT";
            message = "Waktu sambungan habis";
            message_extra = "Silakan coba lagi";
            break;

          default:
            errorType = "ERROR_500";
            message =
              "Kami tidak dapat memenuhi permintaan anda saat ini, silakan coba lagi nanti";
            break;
        }
      }

      if (!message && typeof action.payload.message !== "undefined") {
        message = action.payload.message;
      }

      return {
        ...state,
        isError: true,
        message,
        messageExtra: message_extra,
        errorType,
        nextPage,
        redirectTo,
      };
    }

    case RESET_APP_STATE: {
      return {
        ...state,
        message: null,
        messageExtra: null,
        errorType: null,
        isError: null,
        redirectTo: null,
      };
    }

    case FETCHING_CS_PHONE: {
      return {
        ...state,
        isFetchingCsPhone: false,
      };
    }

    case FETCHED_CS_PHONE: {
      localStorage.setItem("AGREGO_CSPHONE", action.payload.data);
      return {
        ...state,
        isFetchingCsPhone: false,
        csphone: action.payload.data,
      };
    }

    case FETCHING_HOME_DESCRIPTION: {
      return {
        ...state,
        isFetchingHomeDescription: true,
      };
    }

    case FETCHED_HOME_DESCRIPTION: {
      return {
        ...state,
        isFetchingHomeDescription: false,
        homeDescription: action.payload.data,
      };
    }

    case FETCHING_SETTING: {
      return {
        ...state,
        isFetchingSetting: true,
      };
    }

    case FETCHED_SETTING: {
      return {
        ...state,
        isFetchingSetting: false,
        settings: action.payload.data,
      };
    }

    case OPEN_SEARCH_PRODUCT: {
      return {
        ...state,
        isSearchingProduct: true,
      };
    }

    case CLOSE_SEARCH_PRODUCT: {
      return {
        ...state,
        isSearchingProduct: false,
      };
    }

    default:
      return state;
  }
}
