import EmptyState from "components/Layout/EmptyState";
import Loading from "components/Layout/Loading";
import StoreItem from "components/Mart/Store/StoreItem.component";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { fetchNearbyStores } from "redux/actions/mart_store";
import { filterLimit } from "utils/common";
import "./NearbyStore.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2.7,
  },
};

function PopularStore(props) {
  const { title, isLoading, latitude, longitude } = props;

  const productState = useSelector((state) => {
    // tampilkan product id selain yang terpilih di halaman detail
    if (state.martstore.mart_nearby_stores.length > 0) {
      let productList = filterLimit(
        state.martstore.mart_nearby_stores,
        (o) => {
          return !isNaN(parseInt(o.ID));
        },
        10
      );

      state.martstore = { ...state.martstore, mart_nearby_stores: productList };
    }

    // onEmpty Product
    if (!isLoading && !state.martstore.isFetchingMartStoreNearby) {
      if (
        state.martstore.mart_nearby_stores == null ||
        state.martstore.mart_nearby_stores.length <= 1
      ) {
        props.onEmpty(true);
      } else {
        props.onEmpty(false);
      }
    }

    return state.martstore;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let filterData = {};
    // filterData['Status'] = 1;
    // if(CategoryID != null) {
    //     filterData['CategoryID'] = CategoryID;
    // } else if (typeof(filterData['CategoryID']) !== "undefined") {
    //     delete filterData['CategoryID'];
    // }

    dispatch(
      fetchNearbyStores({
        page: 1,
        size: 10,
        search: { Lat: latitude, Long: longitude },
      })
    );
  }, [isLoading]);

  return (
    <div
      style={props.style}
      className={"popular-store-card " + props.className}
    >
      <a
        href="/store?type=nearby"
        className="float-right popular-store-link-more"
      >
        Lihat Semua
      </a>
      <div className="popular-store-title">{title}</div>

      {(productState.isFetchingMartStoreNearby || isLoading) && <Loading />}

      {!isLoading &&
        !productState.isFetchingMartStoreNearby &&
        (productState.mart_nearby_stores == null ||
          productState.mart_nearby_stores.length <= 1) && (
          <div className="text-center">
            <EmptyState style={{ margin: "auto" }} />
          </div>
        )}

      <Carousel
        responsive={responsive}
        autoPlay={false}
        deviceType={props.deviceType}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="react-multi-carousel-dot-list"
        keyBoardControl={true}
        swipeable={true}
        draggable={true}
      >
        {!isLoading &&
          !productState.isFetchingMartStoreNearby &&
          productState.mart_nearby_stores != null &&
          productState.mart_nearby_stores.length > 0 &&
          productState.mart_nearby_stores.map((item, index) => {
            return <StoreItem key={index} store={item} pageType="nearby" />;
          })}
      </Carousel>
    </div>
  );
}

PopularStore.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  classNameItem: PropTypes.string,
  style: PropTypes.object,
  styleItem: PropTypes.object,
  onEmpty: PropTypes.func,
};

PopularStore.defaultProps = {
  title: "Toko Terdekat",
  isLoading: false,
  className: "",
  classNameItem: "",
  style: {},
  styleItem: {},
  onEmpty: () => {},
};

export default PopularStore;
