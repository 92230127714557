import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import ReactGA from "react-ga";
import Pagination from "react-js-pagination";
import Carousel from "react-multi-carousel";
import { useHistory } from "react-router-dom";
import {
  arraySum,
  parseCurrency,
  parseDateTime,
} from "./../../../utils/common";
import BottomNav from "./../../Layout/BottomNav";
import Breadcrumb from "./../../Layout/Breadcrumb";
import EmptyState from "./../../Layout/EmptyState";
import Loading from "./../../Layout/Loading";
import "./Order.css";

function CustomToggle({ children, eventKey, className, variant }) {
  const [isToggle, setIsToggle] = useState(false);
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    setIsToggle(!isToggle)
  );

  return (
    <Button
      variant={variant}
      className={className}
      style={{ marginLeft: isToggle ? "-35px" : "-85px" }}
      onClick={decoratedOnClick}
    >
      {isToggle ? (
        <span>
          Tutup <FontAwesomeIcon icon={faChevronUp} />
        </span>
      ) : (
        children
      )}
    </Button>
  );
}

// custom label status order
const labelStatusOrder = (status, schema) => {
  let label = "";

  switch (parseInt(status)) {
    // Siap diantar
    // case 4:
    //     label = "Pesanan Diproses";
    // break;

    case 5:
      label = "Pesanan Dikirim";
      break;

    case 6:
      label = "Pesanan Tiba";
      break;

    default:
      label = schema[status];
      break;
  }

  return label.toUpperCase();
};

export function Order(props) {
  const {
    isLoading,
    orders,
    orderStatus,
    orderStatusSchema,
    totalOrder,
    activePage,
    itemPerPage,
    filterData,
    breadcrumb,
  } = props;
  const history = useHistory();
  const noCollapseProduct = 2;

  useEffect(() => {
    ReactGA.set({ title: "my_order" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const responsiveMart = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <Container>
      {<div className="order-page-title">Pesanan Saya</div>}

      {
        <div className="order-breadcrumb">
          {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
        </div>
      }

      {/* {<div className="order-filter-text">Filter Status Pesanan</div>} */}

      {/* Tab Status */}
      {!isLoading && (
        <ul className="tab-digital-order">
          <li style={{ width: "50%" }} className="active">
            <a href="/shop/order">Daftar Belanja</a>
          </li>
          <li style={{ width: "50%" }}>
            <a href="/digital/order">Pulsa, Data, & PPOB </a>
          </li>
        </ul>
      )}

      <Carousel responsive={responsiveMart} className="order-carousel">
        {orderStatus != null &&
          orderStatus.length > 0 &&
          orderStatus.map((item, index) => {
            let url =
              "/shop/order" +
              (!isNaN(parseInt(item["Value"]))
                ? "?status=" + item["Value"]
                : "");

            return (
              <div
                key={index}
                className={
                  "order-carousel-item " +
                  (!isLoading &&
                  typeof filterData["Status"] !== "undefined" &&
                  parseInt(filterData["Status"]) === parseInt(item["Value"])
                    ? "active"
                    : typeof filterData["Status"] === "undefined" &&
                      item["Value"] === "all"
                    ? "active"
                    : "")
                }
                onClick={(e) => history.push(url)}
              >
                <div>{item["TextTranslate"]}</div>
              </div>
            );
          })}
      </Carousel>

      {isLoading && <Loading />}

      {!isLoading && (orders == null || orders.length <= 0) && (
        <EmptyState
          className="text-center"
          style={{ marginTop: "5rem" }}
          teks="Belum ada data"
        />
      )}

      {!isLoading &&
        typeof orders !== "undefined" &&
        orders != null &&
        orders.length > 0 &&
        orders.map((order, index) => {
          let TotalBelanja = order != null ? parseInt(order.TotalPrice) : 0;

          return (
            <div className="order-item-card" key={index}>
              <div className="order-item-header">
                <Row>
                  <Col md={6} xs={12}>
                    <div className="order-product-date">
                      {parseDateTime(order.CreatedAt)} WIB
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className="order-payment-status">
                      {/* Status Pembayaran: <span style={{color:colorPaymentStatus(order.PaymentStatus) }}>{labelOrderPayment(order.PaymentStatus)}</span> */}
                      <span className="text-success">
                        {orderStatusSchema != null &&
                          orderStatusSchema[order.Status] != null &&
                          labelStatusOrder(order.Status, orderStatusSchema)}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="order-item-line">&nbsp;</div>
              <div className="order-item-body">
                <Row>
                  {/* Product */}
                  <Col md={8} xs={12}>
                    <Accordion>
                      {order.OrderItems != null &&
                        order.OrderItems.length > 0 &&
                        order.OrderItems.map((item, index) => {
                          return (
                            <div key={index} className="order-item-product">
                              {index < noCollapseProduct && (
                                <Row style={{ paddingTop: "16px" }}>
                                  <Col xs={4} md={3}>
                                    <div
                                      onClick={(e) =>
                                        history.push(
                                          "/shop/product/" +
                                            item.ProductID +
                                            "/detail"
                                        )
                                      }
                                      className="order-product-img"
                                    >
                                      <img
                                        src={item.ProductImage}
                                        alt={item.ProductName}
                                        width="100%"
                                      />

                                      {parseInt(item.ProductDiscount) > 0 && (
                                        <div className="order-discount-img">
                                          {item.ProductDiscount}%
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col md={6} xs={12}>
                                        <div className="order-product-title">
                                          <a
                                            href={
                                              "/shop/product/" +
                                              item.ProductID +
                                              "/detail"
                                            }
                                          >
                                            {item.ProductName}
                                          </a>
                                          {[2, 3].includes(
                                            parseInt(item.ProductPurchaseType)
                                          ) && (
                                            <div style={{ marginTop: "10px" }}>
                                              {parseInt(
                                                item.ProductPurchaseType
                                              ) === 2 && (
                                                <div className="btnPreordercart">
                                                  Pre Order
                                                </div>
                                              )}
                                              {parseInt(
                                                item.ProductPurchaseType
                                              ) === 3 && (
                                                <div
                                                  className="btn-madetoorder-cart"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  Made To Order{" "}
                                                  {item["MinDays"] &&
                                                    ": " +
                                                      item["MinDays"] +
                                                      " hari"}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        {/* Unit sekarang belom ada dari API */}
                                        {/* <div className="order-product-unit">
                                                    Volume: 100ml
                                                </div> */}
                                      </Col>
                                      <Col md={6} xs={12}>
                                        <div className="order-product-qty">
                                          Jumlah : {item.Quantity}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="order-row-price">
                                      <Col md={6} xs={12}>
                                        <div className="order-product-price">
                                          <span
                                            className={
                                              parseInt(item.ProductDiscount) > 0
                                                ? "order-discount-price"
                                                : ""
                                            }
                                          >
                                            Rp{" "}
                                            {parseCurrency(
                                              item.ProductPromoPrice
                                            )}
                                          </span>
                                          {parseInt(item.ProductDiscount) >
                                            0 && (
                                            <span className="order-strip-price">
                                              Rp {parseCurrency(item.Price)}
                                            </span>
                                          )}
                                        </div>
                                      </Col>
                                      <Col>
                                        <div className="order-product-total-price">
                                          Rp {parseCurrency(item.TotalPrice)}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )}

                              {index >= noCollapseProduct && (
                                <Accordion.Collapse
                                  eventKey={"index-" + order.ID}
                                >
                                  <Row style={{ paddingTop: "16px" }}>
                                    <Col xs={4} md={3}>
                                      <div className="order-product-img">
                                        <img
                                          src={item.ProductImage}
                                          alt={item.ProductName}
                                          width="100%"
                                        />

                                        {parseInt(item.ProductDiscount) > 0 && (
                                          <div className="order-discount-img">
                                            {item.ProductDiscount}%
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                    <Col>
                                      <Row>
                                        <Col md={6} xs={12}>
                                          <div className="order-product-title">
                                            {item.ProductName}
                                          </div>
                                          {[2, 3].includes(
                                            parseInt(item.ProductPurchaseType)
                                          ) && (
                                            <div style={{ marginTop: "10px" }}>
                                              {parseInt(
                                                item.ProductPurchaseType
                                              ) === 2 && (
                                                <div className="btnPreordercart">
                                                  Pre Order
                                                </div>
                                              )}
                                              {parseInt(
                                                item.ProductPurchaseType
                                              ) === 3 && (
                                                <div
                                                  className="btn-madetoorder-cart"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  Made To Order{" "}
                                                  {item["MinDays"] &&
                                                    ": " +
                                                      item["MinDays"] +
                                                      " hari"}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </Col>
                                        <Col md={6} xs={12}>
                                          <div className="order-product-qty">
                                            Jumlah : {item.Quantity}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={6} xs={12}>
                                          <div className="order-product-price">
                                            Rp{" "}
                                            {parseCurrency(
                                              item.ProductPromoPrice
                                            )}
                                          </div>
                                        </Col>
                                        <Col>
                                          <div className="order-product-total-price">
                                            Rp {parseCurrency(item.TotalPrice)}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Accordion.Collapse>
                              )}
                            </div>
                          );
                        })}

                      {order.OrderItems.length > noCollapseProduct && (
                        <div className="order-collapse">
                          {/* <div className="order-collase-text"> */}
                          <CustomToggle
                            as={Button}
                            className="order-collase-text"
                            variant="link"
                            eventKey={"index-" + order.ID}
                          >
                            Lihat {order.OrderItems.length - noCollapseProduct}{" "}
                            item lainnya{" "}
                            <FontAwesomeIcon icon={faChevronDown} />
                          </CustomToggle>
                          {/* </div> */}
                        </div>
                      )}
                    </Accordion>
                  </Col>
                </Row>

                <Row style={{ marginTop: "16px", marginBottom: "20px" }}>
                  {/* Summary */}
                  <Col md={{ offset: 8, span: 4 }} xs={12}>
                    <Row>
                      <Col className="product-summary-label" xs={6}>
                        Total Belanja :
                      </Col>
                      <Col className="product-summary-price" xs={6}>
                        Rp {parseCurrency(TotalBelanja)}
                      </Col>
                      {[1, 4, 5].includes(parseInt(order.DeliveryOptionID)) && (
                        <React.Fragment>
                          <Col className="product-summary-label" xs={6}>
                            Biaya Pengiriman :
                          </Col>
                          <Col className="product-summary-price" xs={6}>
                            Rp {parseCurrency(order.PostalFee)}
                          </Col>
                        </React.Fragment>
                      )}
                      <Col className="product-summary-label" xs={6}>
                        Kode Unik :
                      </Col>
                      <Col className="product-summary-price" xs={6}>
                        Rp {parseCurrency(order.UniqueNum)}
                      </Col>
                      <Col
                        className="product-summary-label font-weight-bold"
                        xs={6}
                      >
                        Total :
                      </Col>
                      <Col
                        className="product-summary-price font-weight-bold"
                        xs={6}
                      >
                        Rp{" "}
                        {parseCurrency(
                          arraySum([order.TotalTransaction, order.UniqueNum])
                        )}
                      </Col>
                    </Row>

                    {/* */}
                    {!isLoading &&
                      ["bank", "qris"].includes(
                        order.PaymentType.toLowerCase()
                      ) &&
                      parseInt(order.PaymentStatus) === 0 && (
                        <div className="text-right">
                          <Button
                            variant="link"
                            style={{ marginTop: "20px", paddingRight: "25px" }}
                            href={"/shop/order/" + order.ID + "/payment"}
                          >
                            Lihat Detail Pembayaran
                          </Button>
                        </div>
                      )}

                    {!isLoading && parseInt(order.Status) === 6 && (
                      <div className="text-right">
                        <Button
                          variant="primary"
                          style={{ marginTop: "20px", marginRight: "25px" }}
                          onClick={(e) =>
                            props.onOpenModal(true, {
                              type: "CONFIRM_ORDER",
                              id: order.ID,
                            })
                          }
                        >
                          Konfirmasi Terima Pesanan
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>

                {/* <Row> 
                        <Col xs={6}>
                            <div className="product-price">Rp {parseCurrency(order.TotalPrice)}</div>   
                        </Col>   
                        <Col xs={6}>
                            <div className="badge-status" style={{background: colorOrderStatus(order.Status)}}>{orderStatus[order.Status]['TextTranslate']}</div>
                        </Col>   

                        <Col xs={12} md={6}>  
                            <div className="order-date float-right d-md-none">{parseDateTime(order.CreatedAt)} WIB</div>
                            <Button className="btn-detail" onClick={(e) => history.push('/shop/order/'+order.ID+'/detail')} variant="outline-primary" size="sm">Lihat detail</Button> 
                        </Col> 

                        <Col md={6} className="d-none d-md-block">
                            <div className="order-date">{parseDateTime(order.CreatedAt)} WIB</div>
                        </Col> 
                    </Row>  */}
              </div>
              <div className="order-item-line">&nbsp;</div>
              <div className="order-item-footer">
                <Row>
                  <Col xs={12} md={4} className="order-col-footer">
                    <div>Opsi Pengiriman</div>
                    <div className="order-footer-content">
                      {order.Courier && (
                        <span>
                          {order.Courier === "StoreCourier" ? (
                            "Pengiriman Toko"
                          ) : (
                            <span>
                              {order.Courier}{" "}
                              {order.CourierService ? order.CourierService : ""}
                            </span>
                          )}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className="order-item-vline order-col-footer"
                  >
                    <div>Alamat Pengiriman</div>
                    <div className="order-footer-content">
                      {order.Delivery ? (
                        <React.Fragment>
                          <span>{order.Delivery.Address}</span>
                          <span>
                            {order.Delivery.SubDistrict !== "" &&
                              ", " + order.Delivery.SubDistrict}
                          </span>
                          <span>
                            {order.Delivery.City !== "" &&
                              ", " + order.Delivery.City}
                          </span>
                          <span>
                            {order.Delivery.Province !== "" &&
                              ", " + order.Delivery.Province}
                          </span>
                          <span>
                            {order.Delivery.PostalCode !== "" &&
                              ", " + order.Delivery.PostalCode}
                          </span>
                        </React.Fragment>
                      ) : (
                        " - "
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={4} className="order-col-footer">
                    <div>Pilihan Pembayaran</div>
                    {process.env.REACT_APP_SHOW_BANK_ACCOUNT_NUMBER ?
                    <CustomBankAccount order={order} /> : <BankAccount order={order} />}
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}

      {!isLoading &&
        orders != null &&
        orders.length > 0 &&
        parseInt(totalOrder) > parseInt(itemPerPage) && (
          <Row
            className="justify-content-center"
            style={{ margin: "10px 0px" }}
          >
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalOrder}
              pageRangeDisplayed={3}
              onChange={props.onChangePage}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Row>
        )}

      {/* Bottom Nav */}
      <BottomNav activeKey={2} />
    </Container>
  );
}

const BankAccount = ({order}) => {
  return (<div className="order-footer-content">
    {order.Payment}{" "}
    {order.PaymentType === "Bank"
        ? "(" + order.BankName + ")"
        : ""}
  </div>)
}

const CustomBankAccount = ({order}) => {
  order = {
    ...order,
    ...{
      BankName: process.env.REACT_APP_SHOW_BANK_NAME
    }
  }

  return (<div className="order-footer-content">
    {order.Payment}{" "}
    {order.PaymentType === "Bank"
        ? "(" + order.BankName + ")"
        : ""}
  </div>)
}

export default Order;
