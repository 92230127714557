import Axios from "axios";

// baris di bawah ini di hapus aja gpp sama function laen yang ga diperluin lagi ndri

// 1. buat action type untuk mengambil data berita dari API
// 2. buat action creator / function untuk menghandle yang menjalankan request untuk mengambil data dari API

// Action type untuk news, ini bisa inline di file ini atau di import dari actionTypes.js
export const REQUEST_BANNER = "REQUEST_BANNER";
export const RESULT_BANNER = "RESULT_BANNER";

// Action Creator
// state ketika sedang loading request data
export const requestBanner = () => ({
  type: REQUEST_BANNER,
});

// state ketika data sudah berhasil di dapat
export const resultBanner = (data) => ({
  type: RESULT_BANNER,
  payload: {
    data,
  },
});

// fetch news
export const fetchBanner = () => {
  // ketika method / action creator ini di panggil kita panggil api news dengan Axios

  return (dispatch) => {
    dispatch(requestBanner());

    return Axios.get(
      "/banner/" +
        process.env.REACT_APP_OID +
        "?Page=1&Size=50&IsFeatured=true&IsFeatured=bool"
    )

      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedNews
        return dispatch(resultBanner(data.data));
      })
      .catch((error) => console.log("error"));
  };
};
