import { 
  REQUEST_PROFILE, 
  RESULT_PROFILE, 
  UPDATING_PROFILE,
  UPDATED_PROFILE,
  PROFILE_PASSWORD_UPDATING,
  PROFILE_PASSWORD_UPDATED 
} from "./../actions/profile"; 

const initialState = { 
    fetchingProfile: false, 
    // indikator loading update data
    isUpdatingProfile: false,
    isUpdatingPassword: false,
    successUpdateProfile: false,
    isErrorUpdatePassword: false,

    profile: null,
};

export default function(state = initialState, action) {
  switch (action.type) { 

    case REQUEST_PROFILE: { 
      return {
        ...state,
        fetchingProfile: true
      };
    } 

    case RESULT_PROFILE: {
      const { data } = action.payload; 
      return {
        ...state,
        fetchingProfile: false,
        profile : data
      };
    }

    case UPDATING_PROFILE: { 
      return {
        ...state,
        isUpdatingProfile: true,
        successUpdateProfile: false,
      };
    } 
    
    case UPDATED_PROFILE: {
      const { data, isSuccess } = action.payload; 
      return {
        ...state,
        isUpdatingProfile: false,
        successUpdateProfile: isSuccess,
        profile : data
      };
    }

    case PROFILE_PASSWORD_UPDATING: { 
      return {
        ...state,
        isUpdatingPassword: true, 
      };
    } 
    
    case PROFILE_PASSWORD_UPDATED: { 
      return {
        ...state,
        isUpdatingPassword: false,
        isErrorUpdatePassword: action.payload.isError
      };
    }

    default:
      return state;
  }
}