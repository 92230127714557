import React from 'react';
import { 
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux'; 
import { fetchProviderProduct } from '../../../redux/actions/digital_product';
import { getCartDigital, addCartDigital } from '../../../redux/actions/digital_cart';
import { inquiryDigitalOrder } from '../../../redux/actions/digital_order';
import PLNPrepaidComponent from './PLNPrepaid.Component';

class PLNPrepaidContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      action: null,
      breadcrumb: null,
      providerProduct: [],
      cekProduct: true,

      processInquiry: false,
      inquiryProduct: null,
    }

    this.handleChangePlnPrepaidNo = this.handleChangePlnPrepaidNo.bind(this);
    this.handleChangeProductDigital = this.handleChangeProductDigital.bind(this);
    this.handleChangePlnPrepaidProduct = this.handleChangePlnPrepaidProduct.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.handleNext = this.handleNext.bind(this);

  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    this.loadData();
  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    const { fetchingProviderProduct, providerProduct } = this.props.digitalproduct;
    const { cartDigital } = this.props.digitalcart;
    const { isInquiryDigitalOrder, digital_order_inquiry } = this.props.digitalorder;

    const { isLoading, processInquiry } = this.state;
    let { breadcrumb, inquiryProduct,cekProduct } = this.state; 

    if(processInquiry && !isLoading && !isInquiryDigitalOrder) { 
      if(digital_order_inquiry != null ) {
        
        this.props.addCartDigital({...cartDigital, OrderID: digital_order_inquiry['ID'], TransactionID: digital_order_inquiry['TransactionID']});
        
        // TESTING PURPOSE
        localStorage.setItem("DIGITAL_ORDER_RESPON",JSON.stringify(digital_order_inquiry)); 
        this.props.history.push('/digital/confirmation')
      } 
      
      this.setState({processInquiry: false})
    }

    if (isLoading && !fetchingProviderProduct) {
      breadcrumb = [
        { link: '/beranda', text: 'Beranda' },
        { link: '#', text: 'Token Listrik' },
      ] 
            
      providerProduct.forEach(prod => { 
        if(prod['Code'] === "PLNINQ") {
          inquiryProduct = prod;
        }
      }) 

      this.setState({
        isLoading: false,
        providerProduct: providerProduct,
        CartDigital: cartDigital,
        cekProduct,
        breadcrumb,
        inquiryProduct,
      }) 

      if (cartDigital != null && cartDigital['Reference'] !== '' && cartDigital['ProductID'] !== '') {
        this.setState({ isLoading: false, cekProduct: false });
      }
    }


  }

  loadData(param) {
    const { breadcrumb, cekProduct } = this.props.match; 
    // this.props.fetchProviderProduct({page:1, size:100, search:{BrandName:"PLN", CategoryName:"PLN Prabayar"}});
    this.props.fetchProviderProduct({ page: 1, size: 100, sorting: "Price-asc", search: { CategoryID: 30 } });
    this.props.getCartDigital();

    this.setState({
      isLoading: true,
      breadcrumb: breadcrumb,
      cekProduct: cekProduct, 
    });
  }

  handleChangePlnPrepaidNo(values) { 

    if (values.length >= 10) {
      let ReferenceNo = { Reference: values }
      this.handleChangeProductDigital(ReferenceNo);
      this.setState({ isLoading: true});
      this.props.getCartDigital(); 
    }

  }

  handleChangePlnPrepaidProduct(values) { 

    this.handleChangeProductDigital(values); 
    this.setState({ isLoading: true, cekProduct: false });
    this.props.getCartDigital(); 
  }


  handleChangeProductDigital(values) {  

    this.props.addCartDigital({ 
      ...values,
      CategoryName: "Token Listrik", 
      CategoryURL: "/digital/plnprepaid" 
    });

    this.props.getCartDigital();  
  }

  handleClearForm() {
    let newdata = { Reference: '', ProductID: '' }

    this.handleChangeProductDigital(newdata);

    this.setState({ cekProduct: true, isLoading: true });
    this.props.getCartDigital();
  }

  handleNext() {
    const { inquiryProduct, CartDigital } = this.state; 

    if (inquiryProduct != null) {
      let data = { ...CartDigital, ProductID: inquiryProduct['ID'] }
      
      this.props.addCartDigital({OrderID: '', TransactionID: ''});
      this.props.inquiryDigitalOrder(data);
      this.setState({ processInquiry: true })
    }
  }

  render() {
    const { isLoading, breadcrumb, providerProduct, cekProduct, CartDigital } = this.state;
    let renderComponent = null; 

    renderComponent =
      <PLNPrepaidComponent
        isLoading={isLoading}
        breadcrumb={breadcrumb}
        providerProduct={providerProduct}
        cekProduct={cekProduct}
        CartDigital={CartDigital}

        onHandlePlnPrepaidNo={this.handleChangePlnPrepaidNo}
        onHandleChangePlnPrepaidProduct={this.handleChangePlnPrepaidProduct}
        onClearForm={this.handleClearForm}
        onClickSubmit={this.handleNext}

      />



    return (<div>{renderComponent}</div>);
  }


}


const mapStateToProps = (state) => {
  const { digitalproduct, digitalcart, digitalorder } = state;
  return { digitalproduct, digitalcart, digitalorder }
};
export default withRouter(connect(mapStateToProps, {
  fetchProviderProduct,
  addCartDigital,
  getCartDigital,
  inquiryDigitalOrder
})(PLNPrepaidContainer));

// export default PLNPrepaidContainer;

//