import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { useHistory } from "react-router";
import { isCompletedParamOrder } from "./../../../utils/cart";
import { parseCurrency } from "./../../../utils/common";
import { labelMetodePembayaran } from "./../../../utils/order";
import Breadcrumb from "./../../Layout/Breadcrumb";
import Loading from "./../../Layout/Loading";
import "./../Product/Product.css";
import "./Delivery.css";

function Delivery({
  isLoading,
  cartDeliveryAddress,
  listDeliveryOption,
  deliveryOption,
  cartItems,
  cartSummary,
  paymentNote,
  breadcrumb,
  ...props
}) {
  let isValidOrderData =
    cartSummary != null ? isCompletedParamOrder(cartSummary) : false;
  // let isDelivery = deliveryOption != null && deliveryOption === "Delivery";
  let isDelivery = true;
  const history = useHistory();

  useEffect(() => {
    ReactGA.set({ title: "checkout" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container fluid>
      {isLoading && <Loading />}
      {!isLoading && <div className="top-Judul">Atur Pesanan</div>}
      <div className="delivery-top-space">
        {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
      </div>

      {/* {!isLoading && <div style={{fontWeight:'bold',fontSize:'18px', marginTop:'24px', marginBottom:'6px'}}>Pengiriman</div>}  */}

      {typeof cartItems !== "undefined" &&
        cartItems != null &&
        cartItems.length > 0 && (
          <div className="listPesananContainer">
            <Row style={{ marginTop: "12px" }}>
              <Col md={7}>
                {!isLoading &&
                  listDeliveryOption != null &&
                  listDeliveryOption.length > 1 && (
                    <div className="delivery-card d-none">
                      <div className="delivery-section-title">
                        Pilihan Pengantaran
                      </div>
                      <Form>
                        <div>
                          {listDeliveryOption.map((option, index) => (
                            <Form.Check
                              onChange={(e) =>
                                props.onChangeDeliveryOption(option["Text"])
                              }
                              name="DeliveryOption"
                              value={option.Value}
                              label={<b>{option.Text}</b>}
                              checked={deliveryOption === option.Text}
                              type="radio"
                              className="delivery-option-text"
                              id={`inline-check-${index}`}
                              key={index}
                            />
                          ))}
                        </div>
                      </Form>
                    </div>
                  )}

                <div className="delivery-card">
                  <div className="delivery-section-title">
                    Alamat Pengiriman
                  </div>
                  {cartDeliveryAddress != null ? (
                    <div>
                      <div
                        className="address-list-card"
                        style={{ marginBottom: "16px" }}
                      >
                        <div className="address-name">
                          {cartDeliveryAddress.Recipient}
                        </div>
                        <div className="address-phone">
                          {cartDeliveryAddress.Phone}
                        </div>
                        <div className="address-address">
                          {cartDeliveryAddress.Address}

                          <div>
                            {cartDeliveryAddress.SubDistrict &&
                              cartDeliveryAddress.SubDistrict}
                            {cartDeliveryAddress.City &&
                              ", " + cartDeliveryAddress.City}
                            {cartDeliveryAddress.Province &&
                              "," + cartDeliveryAddress.Province}
                            {cartDeliveryAddress.PostalCode &&
                              "," + cartDeliveryAddress.PostalCode}
                          </div>
                        </div>
                      </div>

                      <div
                        className="text-right"
                        style={{ fontSize: "14px !important" }}
                      >
                        <Button variant="link" size="sm" href="/shop/address">
                          Ubah Alamat Pengiriman
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p>Alamat pengiriman belum ditentukan</p>

                      <div className="text-right">
                        <Button variant="link" size="sm" href="/shop/address">
                          Atur Alamat Pengiriman
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="delivery-card">
                  <div className="delivery-section-title">Opsi Pengiriman</div>
                  {cartSummary["CourierName"] != null ? (
                    <div>
                      <div className="address-list-card">
                        <div className="address-name">
                          {cartSummary["CourierCode"] === "StoreCourier" ? (
                            "Pengiriman Toko "
                          ) : (
                            <span>
                              {cartSummary["CourierName"]}{" "}
                              {cartSummary["CourierServiceName"]}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="text-right">
                        <Button
                          variant="link"
                          size="sm"
                          href="/shop/courier"
                          disabled={
                            typeof cartSummary["DeliverySubDistrictID"] ===
                            "undefined"
                          }
                        >
                          Ubah Opsi Pengiriman
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {typeof cartSummary["DeliverySubDistrictID"] !==
                      "undefined" ? (
                        <p>Opsi pengiriman belum ditentukan</p>
                      ) : (
                        <p>
                          Atur alamat pengiriman sebelum menentukan opsi
                          pengiriman
                        </p>
                      )}

                      <div className="text-right">
                        {cartSummary && cartSummary["StoreAreaID"] ? (
                          <Button
                            variant="link"
                            size="sm"
                            href="/shop/courier"
                            disabled={
                              typeof cartSummary["DeliverySubDistrictID"] ===
                              "undefined"
                            }
                          >
                            Atur Opsi Pengiriman
                          </Button>
                        ) : (
                          <Button
                            variant="link"
                            size="sm"
                            onClick={props.onShowInfoCourier}
                          >
                            Atur Opsi Pengiriman
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="delivery-card">
                  <div className="delivery-section-title">
                    Metode Pembayaran
                  </div>

                  <div className="metode-pembayaran-list">
                    {cartSummary != null &&
                    typeof cartSummary["PaymentType"] !== "undefined" &&
                    cartSummary["PaymentType"] != null ? (
                      <div>
                        {cartSummary["PaymentType"] === "Bank" ? (
                          <Row>
                            <Col md={2} xs={4}>
                              <img
                                src={cartSummary["BankImage"]}
                                alt={"Logo " + cartSummary["BankName"]}
                                width="100%"
                              />
                            </Col>
                            <Col>
                              <div style={{ paddingTop: "6px" }}>
                                {" "}
                                {cartSummary["Payment"]} (
                                {cartSummary["BankName"]}){" "}
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            {/* <Col md={1} xs={2}><FontAwesomeIcon icon={faWallet} size="2x"/></Col>
                             */}
                            <Col>
                              <div style={{ paddingTop: "6px" }}>
                                {labelMetodePembayaran({
                                  Payment: cartSummary["Payment"],
                                  PaymentType: cartSummary["PaymentType"],
                                })}{" "}
                              </div>
                            </Col>
                          </Row>
                        )}
                        <div className="payment-small-note">
                          {" "}
                          {paymentNote}{" "}
                        </div>
                        <div className="text-right">
                          <Button variant="link" size="sm" href="/shop/payment">
                            Ubah Metode Pembayaran
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="pt-2">
                          Metode pembayaran belum ditentukan
                        </div>

                        <div className="text-right">
                          <Button variant="link" size="sm" href="/shop/payment">
                            Pilih Metode Pembayaran
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>

              {/* Desktop view */}
              <Col className="d-none d-md-block" md={4}>
                <div className="delivery-card-2">
                  <div className="delivery-section-title delivery-summary-title">
                    Ringkasan Belanja
                  </div>

                  <div>
                    {cartSummary != null && (
                      <div className="float-right total-price">
                        Rp {parseCurrency(cartSummary.TotalPrice)}
                      </div>
                    )}
                    <div>Total Belanja</div>
                  </div>
                  {isDelivery &&
                    typeof cartSummary["CourierServiceFee"] !== "undefined" && (
                      <div>
                        {cartSummary != null && (
                          <div className="float-right total-price">
                            Rp {parseCurrency(cartSummary.CourierServiceFee)}
                          </div>
                        )}
                        <div>Biaya Pengiriman</div>
                      </div>
                    )}

                  <div className="delivery-summary-line">&nbsp;</div>

                  <div style={{ marginTop: "14px", marginBottom: "33px" }}>
                    {cartSummary != null && (
                      <div className="float-right total-price">
                        Rp {parseCurrency(cartSummary.Total)}
                      </div>
                    )}
                    <div>Total</div>
                  </div>
                </div>

                <Button
                  variant="primary"
                  onClick={(e) => {
                    ReactGA.event({
                      category: "shopping",
                      action: "continue_checkout",
                    });
                    return history.push("/shop/order-confirmation");
                  }}
                  disabled={!isValidOrderData}
                  className="delivery-btn-next"
                >
                  Lanjutkan
                </Button>

                {/* <div className="clearfix"></div>

                        <div style={styles.termInfoBox}>
                            <div style={{minWidth:"52px", textAlign:'center'}}>
                                <img src={iclight} width={14} style={{marginTop:'20px'}} />
                            </div>
                            <div style={{flex:'auto', wordBreak:'normal', color:"#5A5A5A"}}>
                                <div>Pesanan yang tidak dikonfirmasi oleh toko setelah 24 jam akan otomatis dibatalkan. </div>
                                <div><a href="/terms" style={{color:"#3476DA", fontWeight:'bold'}}>Lihat Standar Ketentuan Layanan</a></div>
                            </div>
                        </div> */}
              </Col>

              {/* <div className="clearfix"></div> 
                    <div style={styles.termInfoBox} className="d-md-none">
                        <div style={{minWidth:"52px", textAlign:'center'}}>
                            <img src={iclight} width={14} style={{marginTop:'20px'}} />
                        </div>
                        <div style={{flex:'auto', wordBreak:'normal', color:"#5A5A5A"}}>
                            <div>Pesanan yang tidak dikonfirmasi oleh toko setelah 24 jam akan otomatis dibatalkan. </div>
                            <div><a href="/terms" style={{color:"#3476DA", fontWeight:'bold'}}>Lihat Standar Ketentuan Layanan</a></div>
                        </div>
                    </div> */}

              <div style={{ height: "77px" }}>&nbsp;</div>

              {/* Mobile view fixed in bottom */}
              <div className="product-summary-bottom d-sm-block d-md-none">
                <Row>
                  <Col xs={6}>
                    <div className="label-total">Total Harga</div>
                    <div className="label-price">
                      {cartSummary != null && (
                        <div className="total-price">
                          Rp {parseCurrency(cartSummary.Total)}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        ReactGA.event({
                          category: "shopping",
                          action: "continue_checkout",
                        });
                        return history.push("/shop/order-confirmation");
                      }}
                      disabled={!isValidOrderData}
                      block
                    >
                      Lanjutkan
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        )}
    </Container>
  );
}

export default Delivery;
