import moment from "moment";
import qs from "query-string";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  addCart,
  getCart,
  removeCart,
} from "./../../../redux/actions/mart_cart";
import { fetchMartProduct } from "./../../../redux/actions/mart_product";
import {
  fetchMartStore,
  fetchMartStoreByID,
  fetchNearbyStores,
} from "./../../../redux/actions/mart_store";
import { calculateProduct, mergeProductCart } from "./../../../utils/cart";
import { isChangeView, setupView } from "./../../../utils/common";
import Product from "./Product.component";
import Store from "./Store.component";

class StoreContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      filterStore: {},
      filterStoreNearby: null,
      filterProduct: {},
      stores: [],
      store: null,
      products: [],
      breadcrumb: null,
      currentView: null,
      allowPath: { extra: ["product"] },
      searchKeyword: null,
      pageTitle: null,
      pageType: null,
      cartItems: [],
      cartSummary: null,

      action: "list",
      storeID: null,

      // paging store
      activePage: 1,
      itemPerPage: 15,
      totalStore: null,

      // paging product
      activeProductPage: 1,
      itemProductPerPage: 16,
      totalProduct: null,

      // Modal
      showModalConfirm: false,
      modalConfirmTitle: null,
      modalConfirmBody: null,
      modalConfirmAction: null,
    };

    this.loadData = this.loadData.bind(this);
    this.toggleModalConfirm = this.toggleModalConfirm.bind(this);
    this.handleChangeQty = this.handleChangeQty.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangePageProduct = this.handleChangePageProduct.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const {
      isLoading,
      action,
      currentView,
      allowPath,
      searchKeyword,
      updatingCart,
      pageType,
    } = this.state;
    const {
      isFetchingMartStoreList,
      isFetchingMartStore,
      isFetchingMartStoreNearby,
      mart_stores,
      mart_nearby_stores,
      mart_store,
      mart_store_total_store,
    } = this.props.martstore;
    const { isFetchingMartProductList, mart_products, mart_total_products } =
      this.props.martproduct;
    let { breadcrumb } = this.state;
    const { carts } = this.props.martcart;
    let { keyword } = qs.parse(this.props.location.search);

    // small update change
    if (updatingCart) {
      this.setState({
        updatingCart: false,
        cartSummary: typeof carts.summary ? carts.summary : null,
        cartItems: typeof carts.items ? carts.items : null,
        products: mergeProductCart(mart_products),
      });
    }

    if (
      !isFetchingMartStoreList &&
      !isFetchingMartStore &&
      !isFetchingMartProductList &&
      !isFetchingMartStoreNearby &&
      isLoading
    ) {
      if (action === "product") {
        let storeName = mart_store != null ? mart_store.Name : "";
        console.log(pageType);

        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          {
            link:
              "/store" +
              (pageType && pageType === "nearby" ? "?type=nearby" : ""),
            text: pageType && pageType === "nearby" ? "Toko Terdekat" : "Toko",
          },
          {
            link: "/store/" + mart_store.ID + "/" + storeName,
            text: storeName,
          },
        ];
      } else {
        breadcrumb = [
          { link: "/beranda", text: "Beranda" },
          {
            link:
              "/store" +
              (pageType && pageType === "nearby" ? "?type=nearby" : ""),
            text: pageType && pageType === "nearby" ? "Toko Terdekat" : "Toko",
          },
        ];
      }

      this.setState({
        isLoading: false,
        stores:
          pageType && pageType === "nearby" ? mart_nearby_stores : mart_stores,
        store: mart_store,
        products: mergeProductCart(mart_products),
        totalStore: mart_store_total_store,
        totalProduct: mart_total_products,
        cartSummary: typeof carts.summary ? carts.summary : null,
        cartItems: typeof carts.items ? carts.items : null,
        breadcrumb,
      });
    }

    // reload component
    let isReloadComponent = isChangeView(
      this.props.match.params,
      currentView,
      allowPath
    );
    if (searchKeyword !== keyword) {
      this.loadData(1);
    } else if (isReloadComponent) {
      this.loadData(1);
    }
  }

  loadData(newPage) {
    let {
      stores,
      products,
      allowPath,
      activePage,
      activeProductPage,
      filterStore,
      filterStoreNearby,
      pageTitle,
      itemPerPage,
      itemProductPerPage,
      filterProduct,
      action,
      storeID,
      pageType,
    } = this.state;
    let currentView = setupView(this.props.match.params, allowPath);

    const { id } = this.props.match.params;
    let { type, keyword } = qs.parse(this.props.location.search);

    if (type) {
      pageType = type;
    }

    let myLocation = localStorage.getItem(
      process.env.REACT_APP_PREFIX + "MY_LOCATION"
    );
    myLocation = myLocation ? JSON.parse(myLocation) : null;

    activePage = typeof newPage !== "undefined" ? newPage : activePage;
    activeProductPage =
      typeof newPage !== "undefined" ? newPage : activeProductPage;

    if (currentView != null && typeof currentView["extra"] !== "undefined") {
      // if(currentView['extra'] === "NOT_FOUND") {
      //     action = "NOT_FOUND";
      // } else {

      filterProduct["StoreID"] = id;

      this.props.fetchMartStoreByID(currentView["id"]);
      this.props.fetchMartProduct(
        {
          page: activeProductPage,
          size: itemProductPerPage,
          search: filterProduct,
        },
        true
      );

      action = "product";
      storeID = id;
      // }
    } else {
      action = "list";

      if (keyword) {
        pageTitle = "Pencarian Toko '" + keyword + "'";
        filterStore["Name"] = keyword;
      } else {
        delete filterStore["Name"];
      }

      this.props.getCart();
      if (
        type === "nearby" &&
        myLocation &&
        myLocation["latitude"] &&
        myLocation["longitude"]
      ) {
        filterStoreNearby = {
          ...filterStore,
          Lat: myLocation["latitude"],
          Long: myLocation["longitude"],
        };

        ReactGA.pageview(
          window.location.pathname + window.location.search,
          [],
          "nearest_stores"
        );

        this.props.fetchNearbyStores({
          page: activePage,
          size: itemPerPage,
          search: filterStoreNearby,
        });
      } else {
        ReactGA.pageview(
          window.location.pathname + window.location.search,
          [],
          "store_list"
        );

        this.props.fetchMartStore(
          { page: activePage, size: itemPerPage, search: filterStore },
          true
        );
      }
    }

    this.setState({
      isLoading: true,
      searchKeyword: keyword,
      pageTitle,
      stores,
      products,
      currentView,
      activePage,
      activeProductPage,
      filterStore,
      filterStoreNearby,
      filterProduct,
      action,
      storeID,
      pageType,
    });
  }

  handleChangePage(pageNumber) {
    this.loadData(pageNumber);
  }

  handleChangePageProduct(pageNumber) {
    this.loadData(pageNumber);
  }

  toggleModalConfirm(isOpen, action, confirm) {
    let { modalConfirmAction } = this.state;

    if (
      typeof action != "undefined" &&
      action != null &&
      typeof action.type !== "undefined"
    ) {
      modalConfirmAction = action;
    }

    if (
      modalConfirmAction != null &&
      modalConfirmAction.type === "CONFIRM_DIFF_STORE" &&
      typeof confirm !== "undefined" &&
      confirm
    ) {
      this.handleChangeQty(modalConfirmAction.data, true);
    }

    this.setState({
      showModalConfirm: isOpen,
      modalConfirmAction,
    });
  }

  handleChangeQty(param, isConfirm) {
    let { cartSummary, cartItems } = this.state;
    let { product, type } = param;
    let confirmStore = isConfirm != null && isConfirm;
    let isExistCart =
      cartItems != null &&
      cartItems[0] != null &&
      cartItems[0]["ProductID"] != null;

    let ExistStoreID =
      cartSummary != null && !isNaN(cartSummary["StoreID"])
        ? parseInt(cartSummary["StoreID"])
        : null;
    let ProductStoreID =
      product != null && !isNaN(product["StoreID"])
        ? parseInt(product["StoreID"])
        : null;

    let ExistPurchaseTypeID =
      cartSummary != null && !isNaN(cartSummary["PurchaseType"])
        ? parseInt(cartSummary["PurchaseType"])
        : null;
    let ProductPurchaseTypeID =
      product != null && !isNaN(product["PurchaseType"])
        ? parseInt(product["PurchaseType"])
        : null;

    let ExistProduct =
      cartItems != null && cartItems[0] != null ? cartItems[0] : null;
    let ExistProductID =
      cartItems != null &&
      cartItems[0] != null &&
      !isNaN(cartItems[0]["ProductID"])
        ? parseInt(cartItems[0]["ProductID"])
        : null;
    let ProductID =
      product != null && !isNaN(product["ID"]) ? parseInt(product["ID"]) : null;

    // kondisi untuk validasi Berbeda Store
    const diffStore = ExistStoreID !== ProductStoreID && isExistCart;
    const diffPurchaseType =
      ExistPurchaseTypeID !== ProductPurchaseTypeID && isExistCart;
    const limitPreorder =
      ExistPurchaseTypeID === 2 &&
      ExistProduct &&
      moment(ExistProduct.DeliveryDate).diff(product.DeliveryDate, "days") !==
        0 &&
      ProductID !== ExistProductID &&
      isExistCart;

    // cek validasi jika berbeda store dan purchaseType
    if (diffStore && !confirmStore) {
      this.toggleModalConfirm(true, {
        type: "CONFIRM_DIFF_STORE",
        data: param,
      });
      this.setState({
        modalBody:
          "Item yang anda tambahkan berbeda toko dengan sebelumnya, jika dilanjutkan maka item di keranjang sebelumnya akan terhapus",
      });
    }
    // cek validasi berbeda purchasetype
    else if (diffPurchaseType && !confirmStore) {
      this.toggleModalConfirm(true, {
        type: "CONFIRM_DIFF_STORE",
        data: param,
      });
      this.setState({
        modalBody:
          "Keranjang hanya dapat memuat produk sejenis (ready stock / pre order). Jika dilanjutkan, maka item sebelumnya akan terhapus",
      });
    }
    // cek validasi untuk purchaseType hanya 1 jenis produk
    else if (limitPreorder && !confirmStore) {
      this.toggleModalConfirm(true, {
        type: "CONFIRM_DIFF_STORE",
        data: param,
      });
      this.setState({
        modalBody:
          "Keranjang tidak dapat memuat lebih dari satu barang Pre Order dengan tanggal pengiriman yang berbeda. Jika dilanjutkan, barang sebelumnya akan terhapus",
      });
    } else {
      product = calculateProduct(param);

      if (confirmStore) {
        this.props.removeCart();
      }

      if (
        typeof product["error"] !== "undefined" &&
        product["error"] &&
        typeof product["message"]
      ) {
        this.props.displayMessage(product["message"], product["error"]);
      } else {
        ReactGA.event({
          category: "shopping",
          action: type === "DECREMENT" ? "remove_from_cart" : "add_to_cart",
        });
        this.props.addCart(product, type);
      }
    }

    this.setState({ updatingCart: true });
  }

  render() {
    const {
      isLoading,
      breadcrumb,
      stores,
      store,
      action,
      pageTitle,
      pageType,
      products,
      itemPerPage,
      modalBody,
      totalStore,
      totalProduct,
      activePage,
      activeProductPage,
      itemProductPerPage,
      showModalConfirm,
    } = this.state;

    let renderComponent = null;

    if (action === "product") {
      renderComponent = (
        <Product
          isLoading={isLoading}
          breadcrumb={breadcrumb}
          products={products}
          store={store}
          activePage={activeProductPage}
          itemPerPage={itemProductPerPage}
          totalProduct={totalProduct}
          onChangePageProduct={this.handleChangePageProduct}
          onChangeQty={this.handleChangeQty}
        />
      );
    } else {
      renderComponent = (
        <Store
          isLoading={isLoading}
          breadcrumb={breadcrumb}
          pageTitle={pageTitle}
          stores={stores}
          activePage={activePage}
          itemPerPage={itemPerPage}
          totalStore={totalStore}
          pageType={pageType}
          onChangePage={this.handleChangePage}
        />
      );
    }

    return (
      <div>
        {renderComponent}

        <Modal
          show={showModalConfirm}
          onHide={(e) => this.toggleModalConfirm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Konfirmasi Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => this.toggleModalConfirm(false)}
            >
              Batal
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.toggleModalConfirm(false, "", true)}
            >
              Lanjutkan
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { martstore, martproduct, martcart } = state;
  return { martstore, martproduct, martcart };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchMartStore,
    fetchNearbyStores,
    fetchMartStoreByID,
    fetchMartProduct,
    addCart,
    getCart,
    removeCart,
  })(StoreContainer)
);
