import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
// import ReactGA from "react-ga";
import { Provider } from "react-redux";
// import "./bootstrap.custom.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Route from "./Routes";
import "./index.css";
import { handleError } from "./redux/actions/app";
import configureStore from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import GlobalSytle from "./themes/globalStyle";

// ReactGA.initialize(process.env.REACT_APP_GANALYTIC_ID, {
//   titleCase: false,
//   debug: true,
// });
// ReactGA.pageview(window.location.pathname + window.location.search);

const store = configureStore();

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.timeout = 30000;
axios.defaults.headers.common["Authorization"] =
  "bearer " + localStorage.getItem(process.env.REACT_APP_PREFIX + "TOKEN");

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    store.dispatch(handleError(error));
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <GlobalSytle />
    <Route />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
