import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

// parameter props
// url = [{link:'/mar/product',text:'Product'},{link:'/mar/product/15/detail',text:'Detail'}]
export default function Breadcrumb({url}) {

    if(typeof(url) === "undefined" || url == null || !Array.isArray(url)) {
        return "";
    } 

    const TotalIndex = parseInt(url.length - 1);

    return (<ul className='page-breadcrumb'>
        {url.map(({link, text},index) => <li key={index} >
            {index > 0 && <FontAwesomeIcon icon={faChevronRight} className="caret" />} <a href={link} className={parseInt(index) < parseInt(TotalIndex) ? "" : "active"}>{text}</a>
        </li>)}
    </ul>);
}