import React, { Component } from 'react';
import { Container, Row, Col, Button, Accordion, Card } from 'react-bootstrap';
import { parseCurrency } from './../../../utils/common';
import { colorPaymentStatus, labelMetodePembayaran, labelOrderPayment } from './../../../utils/order';
import Breadcrumb from './../../Layout/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import Loading from './../../Layout/Loading'; 
import './Order.css';

export default class OrderDetail extends Component {

    render() {
        const { isLoading, order, breadcrumb } = this.props; 

        return (
            <Container>

                {isLoading && <Loading />}

                <div style={{ marginTop: '40px' }}>
                    {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                </div>
                {!isLoading && <div style={{ marginTop: '24px', fontWeight: 'bold', fontSize: '18px' }}>Detail Pesanan</div>}
 
                <Row> 
                    <Col md={8}> 

                        {!isLoading && order != null && <div>

                            <div className="detail-order-product-card">
                                {/* <div className="section-title">Produk dibeli</div> */}
                                {typeof (order.OrderItems) !== "undefined" && order.OrderItems != null && order.OrderItems.length >= 0 && order.OrderItems.map((item, index) => {
                                    return (
                                        <Col className="product-list-card" key={index}>
                                            <Row noGutters>
                                                <Col xs={4} md={2}><img src={item.ProductImage} width="100%" alt={item.Name + " Image"} /></Col>
                                                <Col style={{paddingLeft:'12px'}}>
                                                    <div className="list-product-name">{item['ProductName']}</div>
                                                    <Row noGutters>
                                                        <Col xs={9}>
                                                            <div>
                                                                <span className={parseInt(item['ProductDiscount']) > 0 ? "list-product-discount-price pr-1" : "list-product-price pr-1"}> Rp {parseCurrency(item['ProductPromoPrice'])} </span>
                                                                {parseInt(item['ProductDiscount']) > 0 && <span className="list-product-strip-price">Rp {parseCurrency(item['Price'])}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <div className="list-product-qty">x{item['Quantity']}</div>
                                                        </Col>
                                                    </Row>
                                                    <div className="text-right pt-2 list-product-total-price">Rp {parseCurrency(item['TotalPrice'])}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                })}
                            </div>
                            
                            {/* Mobile Status Pembayaran */} 
                            <div className="d-block d-md-none detail-order-total-card">
                                <Row>
                                    <Col xs={6}>
                                        <div className="label-total-bayar">Total Pembayaran</div>
                                        {order != null && <div className="m-total-price"> Rp {parseCurrency(order['TotalPrice'])}</div>}
                                    </Col> 
                                    <Col xs={6}>
                                        {order != null && <div className="badge-status" style={{ background: colorPaymentStatus(order.PaymentStatus) }}>{labelOrderPayment(order.PaymentStatus)}</div>}
                                        {/* Upload Bukti */}
                                        
                                        {!isLoading && order != null && parseInt(order.PaymentStatus) === 0 && isNaN(parseInt(order.PaymentConfirmationID)) && order.PaymentType === "Bank" && <Button variant="outline-primary" onClick={(e) => this.props.onOpenModal(true, { type: "UPLOAD_BUKTI_BAYAR", id: order.ID })} block style={{ marginTop: '8px' }} size="sm">Upload bukti bayar</Button>}
                                        {/* Sudah Di upload Bukti Bayar */}
                                        {/* {order != null && parseInt(order.PaymentStatus) === 0 && !isNaN(parseInt(order.PaymentConfirmationID)) && <Button variant="outline-primary" onClick={(e) => this.props.onOpenModal(true, { type: "VIEW_BUKTI_BAYAR", id: order.ID })} block style={{ marginTop: '8px' }} size="sm">Lihat Bukti Bayar</Button>} */}
                                    </Col>   
                                </Row>
                                

                                {/* {order != null && parseInt(order.PaymentStatus) === 0 && <Button variant="danger" onClick={(e) => this.props.onOpenModal(true, { type: "CANCEL_ORDER", id: order.ID })} block style={{ marginTop: '8px' }} >Batalkan Pesanan</Button>} */}
                            </div> 

                            {order['DeliveryOption'] === "Self Pickup" &&
                                <div className="detail-order-delivery-card">
                                    <div className="delivery-title">Pilihan Pengantaran</div>

                                    <div className="address-list-card" style={{ marginBottom: '16px' }}>
                                        <div>Ambil pesanan sendiri (Self Pickup)</div>
                                    </div>
                                </div>}

                            {order['DeliveryOption'] === "Delivery" &&
                                <div className="detail-order-delivery-card">
                                    <div className="delivery-title">Pilihan Pengantaran</div>

                                    {/* Selected Address */}
                                    <div className="address-list-card" style={{ marginBottom: '16px' }}>
                                        <div>Pengiriman (Delivery)</div>
                                        <div className="address-name">{order['DeliveryAddress']}</div>
                                    </div>
                                </div>}

                            {typeof (order['PaymentType']) !== "undefined" && order['PaymentType'] != null && <React.Fragment>
                                <div className="detail-order-payment-card">
                                    <div className="font-weight-bold">Metode Pembayaran</div>

                                    <ul className="metode-pembayaran-list">
                                        <li>
                                            {order['PaymentType'] === "Bank" ?   
                                                <Row>    
                                                    <Col md={2} xs={4}><img src={order['BankImage']} alt={"Logo "+order['BankName']} width="100%" /></Col>
                                                    <Col><div style={{paddingTop:'6px'}}> {order['Payment']} ({order['BankName']}) </div></Col> 
                                                </Row>  :   
                                                <Row>
                                                    <Col md={1} xs={2}><FontAwesomeIcon icon={faWallet} size="2x"/></Col>
                                                    <Col><div style={{paddingTop:'6px'}}>{labelMetodePembayaran({Payment:order['Payment'], PaymentType: order['PaymentType']})} </div></Col> 
                                                </Row>}
                                        </li>
                                    </ul>
                                </div>

                                {order['PaymentType'] === "Bank" && parseInt(order['PaymentStatus']) === 0 && 
                                <Accordion defaultActiveKey="0" style={{marginTop: '16px'}} className="detail-order-info-pembayaran">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" style={{background:'#fff', borderBottom:'none'}}>
                                            <b>Petunjuk Pembayaran</b>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body> 
                                                Test
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                     
                                </Accordion>} 

                            </React.Fragment>}

                            {!isLoading && order != null && parseInt(order.Status) === 1 && parseInt(order.PaymentStatus) === 0 && <Button className="d-md-none" variant="danger" style={{ marginTop: '8px' }} onClick={(e) => this.props.onOpenModal(true, { type: "CANCEL_ORDER", id: order.ID })} block>Batalkan Pesanan</Button>}
                        </div>}
                    </Col>

                    {/* Desktop Total Bayar */}
                    {!isLoading && <Col className="d-none d-md-block">
                        <div className="detail-order-total-card">
                            <p className="font-weight-bold">Total Pembayaran</p>
                            {order != null && <div className="total-price"> Rp {parseCurrency(order['TotalPrice'])}</div>}
                            {order != null && <div className="badge-status" style={{width:'100%', background: colorPaymentStatus(order.PaymentStatus) }}>{labelOrderPayment(order.PaymentStatus)}</div>}
                        </div>
                        
                        {/* Belum upload bukti bayar */}
                        {order != null && parseInt(order.PaymentStatus) === 0 && isNaN(parseInt(order.PaymentConfirmationID)) && order.PaymentType === "Bank" && <Button variant="primary" onClick={(e) => this.props.onOpenModal(true, { type: "UPLOAD_BUKTI_BAYAR", id: order.ID })} block style={{ marginTop: '8px' }}>Upload bukti bayar</Button>}
                        {/* Sudah upload bukti bayar */}
                        {/* {order != null && parseInt(order.PaymentStatus) === 0 && !isNaN(parseInt(order.PaymentConfirmationID)) && <Button variant="primary" onClick={(e) => this.props.onOpenModal(true, { type: "VIEW_BUKTI_BAYAR", id: order.ID })} block style={{ marginTop: '8px' }}>Lihat bukti bayar</Button>} */}
                        
                        {order != null && parseInt(order.Status) === 1 && parseInt(order.PaymentStatus) === 0 && <Button variant="danger" onClick={(e) => this.props.onOpenModal(true, { type: "CANCEL_ORDER", id: order.ID })} block style={{ marginTop: '8px' }} >Batalkan Pesanan</Button>}
                    </Col>}

                    <div className="d-sm-block d-md-none" style={{ height: '80px' }}>
                        &nbsp;
                    </div>

                </Row>
            </Container>
        )
    }
}