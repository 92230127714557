import React, {useState} from 'react'; 
import {Container, Row, Col, Form, Accordion, Card} from 'react-bootstrap'; 
import './Product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import Loading from "./../../Layout/Loading";
import EmptyState from "./../../Layout/EmptyState"; 
import Breadcrumb from './../../Layout/Breadcrumb';
import ProductItem from './ProductItem.component';

export default function Product(props) {

    const {isLoading, products, categories, category, itemPerPage, activePage, totalProduct, breadcrumb, pageTitle} = props;
    

    const [desktopCategory, setDesktopCategory] = useState(true);
    const [mobileCategory, setMobileCategory] = useState(false);
 
    return (
        <Container> 
             
            {isLoading && <Loading />}

            <div className="product-list-title">{!isLoading && pageTitle != null && pageTitle}</div>

            <Row> 
                {/* Left Nav Category */}
                <Col md={3}>
                    
                    {/* Breadcrumb */}
                    <div>
                        {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                    </div>

                    {!isLoading && categories.length >= 0 && <div className="product-category-card d-none d-md-block">   

                        {/* Desktop */}
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={(e) => setDesktopCategory(!desktopCategory)}>
                                    Kategori
                                    {desktopCategory ? 
                                        <FontAwesomeIcon style={{float:'right'}} icon={faCaretDown} /> : 
                                        <FontAwesomeIcon style={{float:'right'}} icon={faCaretRight} />}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <ul className="sidenav-list">
                                        {categories.length > 0 && categories.map(cat => {
                                            return ( 
                                            <li key={cat.ID}><a href={"/shop/product?type=cat&cat_id="+cat.ID} className={category != null && parseInt(cat.ID) === parseInt(category.ID) ? 'active' : ''}>{cat.Name}</a></li>); 
                                        })}
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card> 
                        </Accordion>
                            
                        
                    </div>}
                </Col>

                {/* List Product */}
                <Col>   

                    {/* Spacing in mobile view */}
                    {/* <div className="mt-1 d-md-none">&nbsp;</div> */}

                    {!isLoading && typeof(TEMPORARY_HIDE) !== "undefined" ? <Row className="text-md-right">  
                        <Col md={{offset:'4', span: '4'}} sm={12}>
                            <Form.Label htmlFor="Urutkan">Urutkan berdasarkan </Form.Label>
                        </Col>
                        <Col md={4} sm={12}>
                            <Form.Control id="Urutkan" as="select">
                                <option>Paling Terbaru</option>
                            </Form.Control> 
                        </Col>
                    </Row> : <div className="d-none d-md-block" style={{marginBottom:'1rem'}}>&nbsp;</div>} 

                    {/* Empty State Product */}
                    {!isLoading && products.length <= 0 && <EmptyState teks="Belum ada data" className="text-center" />}

                    <Row noGutters style={{marginTop: '19px'}} className="product-mart-list">
                    {!isLoading && products != null && products.length > 0 && products.map((item, index) => { 

                        return (
                        <Col lg={3} md={4} xs={6} key={index}>
                            <ProductItem product={item} onChangeQty={props.onChangeQty} buyButton />
                        </Col>    
                      )
                    })} 
                    </Row>

                    {!isLoading && products.length > 0 && parseInt(totalProduct) > parseInt(itemPerPage) && <Row className="justify-content-center" style={{margin:'10px 0px'}}><Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemPerPage}
                        totalItemsCount={totalProduct}
                        pageRangeDisplayed={3}
                        onChange={props.onChangePage}
                        itemClass="page-item"
                        linkClass="page-link"
                        
                        /></Row>} 
                </Col>

                {!isLoading && categories.length >= 0 && <Col className="d-md-none mb-2" xs={12}>
                    {/* Mobile */}
                    <Accordion className="product-category-card" >
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={(e) => setMobileCategory(!mobileCategory)}>
                                Kategori
                                {mobileCategory ? 
                                    <FontAwesomeIcon style={{float:'right'}} icon={faCaretDown} /> : 
                                    <FontAwesomeIcon style={{float:'right'}} icon={faCaretRight} />}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <ul className="sidenav-list">
                                    {categories.length > 0 && categories.map(cat => {
                                        return ( 
                                        <li key={cat.ID}><a href={"/shop/product?type=cat&cat_id="+cat.ID} className={category != null && parseInt(cat.ID) === parseInt(category.ID) ? 'active' : ''}>{cat.Name}</a></li>); 
                                    })}
                                </ul>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card> 
                    </Accordion>  
                </Col>}
            </Row>
        </Container>
    )
}