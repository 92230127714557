import React from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from "react-router-dom";
import "./App.css";
import Beranda from "./Beranda";
import Register from "./components/Auth/Register";
import BeritaContainer from "./components/Berita/Berita.container";
import BPJSContainer from "./components/Digital/BPJS/BPJS.Container";
import DigitalConfirmationContainer from "./components/Digital/DigitalOrderConfirmation/DigitalConfirmation.Container";
import DigitalOrderContainer from "./components/Digital/Order/Order.container";
import PLNPascabayarContainer from "./components/Digital/PLNPascabayar/PLNPascabayar.Container";
import TokenContainer from "./components/Digital/PLNPrepaid/PLNPrepaid.Container";
import PulsaContainer from "./components/Digital/Pulsa/Pulsa.container";
import ErrorPage from "./components/Layout/ErrorPage";
import TopNav from "./components/Layout/TopNav";
import AddressContainer from "./components/Mart/Address/Address.container";
import MartCartContainer from "./components/Mart/Cart/Cart.container";
import MartCourierContainer from "./components/Mart/Courier/Courier.container";
import MartCourierTermComponent from "./components/Mart/Courier/CourierTerm.component";
import MartDeliveryAddressContainer from "./components/Mart/Delivery/Delivery.container";
import OrderContainer from "./components/Mart/Order/Order.container";
import OrderConfirmationContainer from "./components/Mart/OrderConfirmation/Confirmation.container";
import MartPaymentContainer from "./components/Mart/Payment/Payment.container";
import MartProductContainer from "./components/Mart/Product/Product.container";
import StoreContainer from "./components/Mart/Store/Store.container";
import ModalToast from "./components/ModalToast";
import ProfileComponent from "./components/Profile/Profile.component";
import ProfileContainer from "./components/Profile/Profile.container";
import ProfileAddressContainer from "./components/Profile/ProfileAddress.container";
import ProfilePasswordContainer from "./components/Profile/ProfilePassword.container";
import PromoContainer from "./components/Promo/Promo.container";
import WalletContainer from "./components/Wallet/Wallet.container";
import { fetchCsPhone, handleError, resetAppState } from "./redux/actions/app";
import { isLogin, logout } from "./utils/JWTAuth";

var timeOutMessage;

// ReactGA.initialize(process.env.REACT_APP_GANALYTIC_ID, {
//   titleCase: false,
//   debug: true,
// });
// ReactGA.pageview(window.location.pathname + window.location.search);

// import WelcomeMessage from "./components/Auth/WelcomeMessage";

// Add a request interceptor

// Untuk route yang membutuhkan login gunakan <PrivateRoute
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogin() === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const Logout = () => {
  let history = useHistory();

  // logout
  logout();

  history.push("/beranda");
  return null;
};

// var messaging;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      message: null,
      messageExtra: null,
      errorType: null,
      redirectTo: null,
      isError: null,
    };

    this.toggleShowToast = this.toggleShowToast.bind(this);
  }

  componentDidMount() {
    this.props.fetchCsPhone();
    // this.handleFirebaseMessage()
  }

  componentDidUpdate() {
    this.handleAppMessage(this.props.app);
  }

  handleAppMessage({ message, messageExtra, errorType, isError }) {
    let { pathname } = this.props.location;

    if (isError && errorType && pathname !== "/error") {
      this.props.history.push("/error", { from: pathname });
    } else if (!isError && message && this.state.message !== message) {
      clearTimeout(timeOutMessage);

      this.setState({
        message,
        messageExtra,
        errorType,
        isError,
        showToast: message && !isError ? true : false,
      });

      var timeOutMessage = setTimeout(() => {
        this.props.resetAppState();
        this.setState({ showToast: false, message: null });
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearTimeout(timeOutMessage);
  }

  toggleShowToast(showToast) {
    // this.props.resetAppState();
    this.setState({ showToast: showToast });
  }

  render() {
    const { showToast, message } = this.state;

    return (
      <div className="App">
        {/* Navigasi */}
        <div>
          <TopNav />
        </div>

        {/* Content */}
        <div>
          <Switch>
            {/* URL untuk yang sudah login saja */}

            <Route path="/beranda">
              <Beranda />
              {/* bawah beranda */}
            </Route>

            {/* Contoh penggunaan PrivateRoute hanya mengganti kata Route menjadi PrivateRoute (route yang hanya digunakan untuk user login) */}
            <PrivateRoute path="/logout">
              <Logout />
            </PrivateRoute>

            {/* Register */}
            <Route path="/register" exact={true} component={Register} />

            {/* <Route path="/welcomeMessage" exact={true} component={WelcomeMessage} />  */}

            {/* Promo */}
            <Route path="/promo" exact={true} component={PromoContainer} />
            <Route
              path="/promo/:id/:extra"
              exact={true}
              component={PromoContainer}
            />

            {/* Berita */}
            <Route path="/berita" exact={true} component={BeritaContainer} />
            <Route
              path="/berita/:id/:extra"
              exact={true}
              component={BeritaContainer}
            />

            <PrivateRoute
              path="/shop/address/:id?/:extra?"
              exact={true}
              component={AddressContainer}
            />

            {/* Product Mart */}
            <Route
              path="/shop/product"
              exact={true}
              component={MartProductContainer}
            />
            <Route
              path="/shop/product/:id/:extra"
              exact={true}
              component={MartProductContainer}
            />

            {/* Store */}
            <Route path="/store" exact={true} component={StoreContainer} />
            <Route
              path="/store/:id/:extra"
              exact={true}
              component={StoreContainer}
            />

            {/* Cart Mart */}
            <Route
              path="/shop/cart"
              exact={true}
              component={MartCartContainer}
            />
            <PrivateRoute
              path="/shop/delivery"
              exact={true}
              component={MartDeliveryAddressContainer}
            />
            <PrivateRoute
              path="/shop/payment"
              exact={true}
              component={MartPaymentContainer}
            />
            <PrivateRoute
              path="/shop/courier"
              exact={true}
              component={MartCourierContainer}
            />
            <Route
              path="/terms"
              exact={true}
              component={MartCourierTermComponent}
            />
            <PrivateRoute
              path="/shop/order-confirmation"
              exact={true}
              component={OrderConfirmationContainer}
            />

            {/* Order Route */}
            <PrivateRoute
              path="/shop/order"
              exact={true}
              component={OrderContainer}
            />
            <PrivateRoute
              path="/shop/order/:id/:extra"
              exact={true}
              component={OrderContainer}
            />

            <PrivateRoute
              path="/wallet"
              exact={true}
              component={WalletContainer}
            />
            <PrivateRoute
              path="/wallet/:extra"
              exact={true}
              component={WalletContainer}
            />

            {/* redirect default profile kita gunakan profile/datadiri */}
            <Redirect path="/profile" to="/profile/datadiri" exact={true} />
            <PrivateRoute
              path="/profile/datadiri"
              exact={true}
              component={ProfileContainer}
            />
            {/* view profile */}
            <PrivateRoute
              path="/profile/datadiri/:extra"
              exact={true}
              component={ProfileContainer}
            />
            <PrivateRoute
              path="/profile/index"
              exact={true}
              component={ProfileComponent}
            />

            {/* Alamat */}
            <PrivateRoute
              path="/profile/alamat/:id?/:extra?"
              exact={true}
              component={ProfileAddressContainer}
            />
            <PrivateRoute
              path="/profile/change-password"
              exact={true}
              component={ProfilePasswordContainer}
            />

            {/* Pulsa */}
            <PrivateRoute
              path="/digital/pulsa"
              exact={true}
              component={PulsaContainer}
            />
            <PrivateRoute
              path="/digital/pulsa/:extra"
              exact={true}
              component={PulsaContainer}
            />

            {/* PlN prepaid*/}
            <PrivateRoute
              path="/digital/plnprepaid"
              exact={true}
              component={TokenContainer}
            />

            {/* PlN pascabayar*/}
            <PrivateRoute
              path="/digital/plnpascabayar"
              exact={true}
              component={PLNPascabayarContainer}
            />

            {/* BPJS */}
            <PrivateRoute
              path="/digital/bpjs"
              exact={true}
              component={BPJSContainer}
            />

            {/* Digital Order Confirmation */}
            <PrivateRoute
              path="/digital/confirmation"
              exact={true}
              component={DigitalConfirmationContainer}
            />
            <PrivateRoute
              path="/digital/confirmation/:extra"
              exact={true}
              component={DigitalConfirmationContainer}
            />

            {/* Digital Order Route */}
            <PrivateRoute
              path="/digital/order"
              exact={true}
              component={DigitalOrderContainer}
            />
            <PrivateRoute
              path="/digital/order/:id/:extra"
              exact={true}
              component={DigitalOrderContainer}
            />

            {/* Riwayat Transaksi */}
            <PrivateRoute
              path="/riwayattransaksi"
              exact={true}
              component={WalletContainer}
            />

            {/* jika ke halaman / maka redirect ke beranda */}
            <Route exact path="/">
              <Redirect to="/beranda" />
            </Route>

            <Route path="/error">
              <ErrorPage />
            </Route>

            <Route path="*">
              <ErrorPage status="ERROR_404" />
            </Route>
          </Switch>
        </div>

        {/* Toast */}
        {/* <Container style={{position:'relative'}}>  
            <div
              className={"toast-container "+(showToast ? "d-block" : "d-none")}
            >
              <Toast autohide={true} show={showToast} className={isError ? "error" : ""} onClose={(e) => this.toggleShowToast(false)}>
                <Toast.Header>
                  <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                  <strong className="mr-auto">Notifikasi</strong> 
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
              </Toast> 

            </div> 
          </Container> */}

        <ModalToast
          success
          show={showToast}
          // autoHide delay={3000} onHide={e => this.toggleShowToast(false)}
        >
          <div className="text-center">{message}</div>
        </ModalToast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

export default withRouter(
  connect(mapStateToProps, {
    resetAppState,
    fetchCsPhone,
    handleError,
    //list of dispatch action
  })(App)
);
