import _ from "lodash";
import qs from "query-string";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateDeliveryAddress } from "./../../../redux/actions/mart_cart";
import {
  deleteMartDeliveryAddress,
  fetchMartDeliveryAddress,
  fetchMartDeliveryAddressById,
  saveMartDeliveryAddress,
} from "./../../../redux/actions/mart_delivery";
import {
  fetchRegArea,
  fetchRegByLatLng,
  fetchRegCity,
  fetchRegProvince,
  fetchRegSubDistrict,
} from "./../../../redux/actions/regional";
import Address from "./Address.component";
import AddressForm from "./AddressForm.component";
import AddressList from "./AddressList.component";
import AddressMap from "./AddressMap.component";

const getStorageAddress = (address, addressID) => {
  let formAddress = localStorage.getItem(
    process.env.REACT_APP_PREFIX + "ADDRESS_FORM"
  );

  formAddress = formAddress ? JSON.parse(formAddress) : null;

  let selectedFormAddress =
    formAddress &&
    ((address && parseInt(formAddress["ID"]) === parseInt(address["ID"])) ||
      formAddress["ID"] == addressID);

  return selectedFormAddress ? formAddress : null;
};

class AddressContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingProvince: false,
      loadingCity: false,
      loadingSubDistrict: false,
      loadingAddress: false,
      loadingArea: false,
      loadingLatLng: false,

      provinces: [],
      cities: [],
      subdistricts: [],
      area: [],

      formAddress: null,
      formAddressID: null,

      showModal: false,
      modalTitle: null,
      modalBody: null,
      modalAction: null,

      action: null,
      breadcrumb: null,
      addressList: [],
      addressDetail: [],
      addressID: null,
      SubDistrictID: null,
      submittingProfile: false,
      successUpdate: false,
      submittingAddress: false,
      successAdd: false,
      pageTitle: "",
      pageReference: null,

      currentPage: 1,
      itemPerPage: 10,
      totalAddress: 0,
    };

    this.loadAddress = this.loadAddress.bind(this);
    this.handleChangeProvince = this.handleChangeProvince.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeSubDistrict = this.handleChangeSubDistrict.bind(this);
    this.handleSubmitAddress = this.handleSubmitAddress.bind(this);
    this.handleDeleteAddress = this.handleDeleteAddress.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
  }

  componentDidMount() {
    this.props.fetchRegProvince({ page: 1, size: 100 });
    this.loadData();
  }

  componentDidUpdate() {
    const {
      isFetchingMartDeliveryAddressList,
      mart_delivery_addresslist,
      mart_delivery_address,
      isSavingMartDeliveryAddress,
      isFetchingMartDeliveryAddress,
      isDeletingMartDeliveryAddress,
    } = this.props.martdelivery;

    const {
      isLoading,
      action,
      loadingAddress,
      submittingAddress,
      SubDistrictID,
      addressID,
      pageReference,
      currentPage,
      itemPerPage,
      formAddress,
      addressDetail,
    } = this.state;

    const { id, extra } = this.props.match.params;

    const {
      isFetchingRegProvince,
      isFetchingRegCity,
      isFetchingRegSubDistrict,
      isFetchingRegArea,
      isFetchingRegByLatLng,
      reg_provinces,
      reg_cities,
      reg_subdistricts,
      reg_area,
      reg_by_latlng,
    } = this.props.regional;

    let {
      successAdd,
      successUpdate,
      breadcrumb,
      loadingProvince,
      loadingCity,
      loadingSubDistrict,
      loadingArea,
      pageTitle,
      loadingLatLng,
    } = this.state;

    if (
      !isFetchingMartDeliveryAddressList &&
      !isSavingMartDeliveryAddress &&
      !isFetchingMartDeliveryAddress &&
      !isFetchingRegProvince &&
      !isFetchingRegByLatLng &&
      isLoading
    ) {
      pageTitle = "Daftar Alamat";

      breadcrumb = [
        // { link: "/beranda", text: "Beranda" },
        // { link: "/shop/cart", text: "Keranjang" },
        { link: "/shop/delivery", text: "Atur Pesanan" },
        { link: "/shop/address", text: "Alamat Pengiriman" },
      ];

      let selectedFormAddress = getStorageAddress(
        mart_delivery_address,
        addressID
      );

      let addressDetail = { ...mart_delivery_address };

      // merge alamat yang ada dengan alamat yang diisi diform
      if (selectedFormAddress) {
        addressDetail = { ...addressDetail, ...selectedFormAddress };
      }

      // merge region dengan yang di dapet dari latlng
      if (loadingLatLng && reg_by_latlng) {
        let addressByLatLng = {
          AddressLatLng: reg_by_latlng.Address,
          ProvinceID: reg_by_latlng.ProvinceID,
          Province: reg_by_latlng.ProvinceName,
          SubDistrictID: reg_by_latlng.SubDistrictID,
          SubDistrict: reg_by_latlng.SubDistrictName,
          AreaID: reg_by_latlng.AreaID,
          Area: reg_by_latlng.AreaName,
          CityID: reg_by_latlng.CityID,
          City: reg_by_latlng.CityName,
          PostalCode: reg_by_latlng.PostalCode,
        };

        addressDetail = { ...addressDetail, ...addressByLatLng };
        this.setState({ loadingLatLng: false });
      }
      // hapus province, subdistrict, area, kodepos, jika area didn't match / data null dari api
      // ketika sebelumnya pilih pinpoint yang benar / valid
      else if (
        loadingLatLng &&
        !reg_by_latlng &&
        addressDetail &&
        addressDetail["TmpAddress"]
      ) {
        let addressByLatLng = {
          AddressLatLng: null,
          ProvinceID: null,
          Province: null,
          SubDistrictID: null,
          SubDistrict: null,
          AreaID: null,
          Area: null,
          CityID: null,
          City: null,
          PostalCode: null,
        };

        addressDetail = { ...addressDetail, ...addressByLatLng };
        this.setState({ loadingLatLng: false });
      }

      // successAdd = submittingAddress && successAddAdress;

      // kembali ke view data diri setelah berhasil update data diri
      if (submittingAddress) {
        if (pageReference && pageReference === "courier") {
          this.props.history.push("/shop/courier");
        } else {
          this.props.history.push("/shop/address");
        }
        // this.loadData()s;
      }

      if (!isDeletingMartDeliveryAddress && loadingAddress) {
        this.loadData();
      }

      if (loadingProvince && !isFetchingRegProvince) {
        this.setState({ loadingProvince: false, provinces: reg_provinces });
      }

      if (
        loadingArea &&
        !isFetchingRegArea &&
        reg_subdistricts !== "undefined"
      ) {
        this.setState({ loadingArea: false, area: reg_area });
      }

      // update city setelah selesai load
      if (action === "edit" || addressDetail) {
        if (addressDetail && addressDetail.ProvinceID) {
          this.props.fetchRegCity({
            page: 1,
            size: 1000,
            search: { ProvinceID: addressDetail.ProvinceID },
          });
          loadingCity = true;
        }

        if (addressDetail && addressDetail.CityID) {
          this.props.fetchRegSubDistrict({
            page: 1,
            size: 1000,
            search: { CityID: addressDetail.CityID },
          });
          loadingSubDistrict = true;
        }
      }

      if (id) {
        pageTitle = !isNaN(parseInt(id)) ? "Ubah Alamat" : "Tambah Alamat";
        breadcrumb.push({
          link: "/shop/address/" + id,
          text:
            id && !isNaN(parseInt(id))
              ? "Ubah Alamat Pengiriman"
              : "Tambah Alamat Pengiriman",
        });
      }

      if (action === "map") {
        pageTitle = "Pinpoint Alamat";
        breadcrumb.push({
          link: "/shop/address/" + id + "/map",
          text: "Pinpoint Alamat",
        });
      }

      let total = 0;
      let countList =
        mart_delivery_addresslist && mart_delivery_addresslist.length
          ? mart_delivery_addresslist.length
          : 0;
      // manual paging
      if (countList >= itemPerPage) {
        total = parseInt(currentPage) * parseInt(itemPerPage) + 1;
      } else if (currentPage > 1) {
        total = (parseInt(currentPage) - 1) * parseInt(itemPerPage) + 1;
      } else {
        total = countList;
      }

      this.setState({
        isLoading: false,
        loadingAddress: false,
        addressList: mart_delivery_addresslist,
        addressDetail,
        totalAddress: total,
        submittingProfile: false,
        successUpdate: successUpdate,
        breadcrumb,
        submittingAddress: false,
        successAdd: successAdd,
        loadingProvince: false,
        loadingCity: loadingCity,
        loadingSubDistrict: loadingSubDistrict,
        loadingArea: loadingArea,
        provinces: reg_provinces,
        cities: reg_cities,
        subdistricts: reg_subdistricts,
        area: reg_area,
        SubDistrictID: SubDistrictID,
        addressLatLng: reg_by_latlng,
        pageTitle,
      });
    }

    if (!isLoading && loadingCity && !isFetchingRegCity) {
      this.setState({ loadingCity: false, cities: reg_cities });
    }

    //sudah ada hasil atau result dari api
    if (!isLoading && loadingSubDistrict && !isFetchingRegSubDistrict) {
      this.setState({
        loadingSubDistrict: false,
        subdistricts: reg_subdistricts,
      });

      // if (action === "edit" && reg_subdistricts != null && addressDetail) {
      if (reg_subdistricts != null && addressDetail) {
        let selectedSubdistrict = _.find(
          reg_subdistricts,
          (o) => parseInt(o.ID) === parseInt(addressDetail.SubDistrictID)
        );
        if (selectedSubdistrict != null) {
          this.props.fetchRegArea({
            page: 1,
            size: 1000,
            search: {
              SubDistrictIDShipper: selectedSubdistrict.IDShipper,
            },
          });
          this.setState({ loadingArea: true, area: reg_area });
        }
      }
    }

    if (!isLoading && loadingArea && !isFetchingRegArea) {
      this.setState({ loadingArea: false, area: reg_area });
    }

    if (extra && action !== "map") {
      this.loadData();
    } else if (!extra && typeof id !== "undefined") {
      if (!isNaN(parseInt(id)) && action !== "edit") {
        this.loadData();
      }

      if (isNaN(parseInt(id)) && action !== "add") {
        this.loadData();
      }
    } else if (typeof id === "undefined" && action !== "list") {
      this.loadData();
    }
  }

  loadData(param) {
    let { action, pageReference, itemPerPage } = this.state;
    const { id, extra } = this.props.match.params;
    const { reference } = qs.parse(this.props.location.search);
    const { breadcrumb } = this.props.match;

    let formAddress = getStorageAddress(null, id);

    if (formAddress && formAddress["Latitude"] && formAddress["Longitude"]) {
      const { Latitude, Longitude } = formAddress;
      this.props.fetchRegByLatLng({ Latitude, Longitude });

      this.setState({ loadingLatLng: true });
    }

    if (typeof id !== "undefined" && !isNaN(parseInt(id))) {
      this.props.fetchMartDeliveryAddressById(id);
      // this.props.fetchRegProvince({page:1,size:100});
      // this.props.fetchRegCity({page:1,size:100});
      // this.props.fetchRegSubDistrict({page:1,size:100});

      action = "edit";
    } else if (typeof id !== "undefined" && isNaN(parseInt(id))) {
      action = "add";
    } else {
      this.props.fetchMartDeliveryAddress({ page: 1, size: itemPerPage });
      action = "list";
    }

    if (extra && extra === "map") {
      action = "map";
    }

    if (reference) {
      pageReference = reference;

      if (reference === "courier") {
        localStorage.removeItem(process.env.REACT_APP_PREFIX + "ADDRESS_FORM");
      }
    }

    this.setState({
      isLoading: true,
      loadingAddress: true,
      action: action,
      addressID: id,
      breadcrumb: breadcrumb,
      pageReference,
      formAddress,
    });
  }

  handleSubmitAddress(values) {
    let { id } = this.props.match.params;

    // hapus localstorage sebelumnya
    localStorage.removeItem(process.env.REACT_APP_PREFIX + "ADDRESS_FORM");

    if (!isNaN(parseInt(id))) {
      this.props.saveMartDeliveryAddress(values, id);
    } else {
      this.props.saveMartDeliveryAddress(values);
    }

    this.setState({ isLoading: true, submittingAddress: true });
  }

  handleChangePage(newPage, total) {
    const { itemPerPage } = this.state;

    this.props.fetchMartDeliveryAddress({ page: newPage, size: itemPerPage });
    this.setState({
      currentPage: newPage,
      loadingAddress: true,
      isLoading: true,
    });
  }

  handleSelectAddress(selectedAddress) {
    this.props.updateDeliveryAddress(selectedAddress);

    localStorage.removeItem(process.env.REACT_APP_PREFIX + "ADDRESS_FORM");

    // this.props.history.goBack()
    this.props.history.push("/shop/delivery");
  }

  handleChangeProvince(e) {
    let ProvinceID = e;
    this.props.fetchRegCity({
      page: 1,
      size: 1000,
      search: { ProvinceID: ProvinceID },
    });
    this.setState({ loadingCity: true, subdistricts: [] });

    // this.handleChangeFormAddress(e);
  }

  handleChangeCity(e) {
    let CityID = e;
    this.props.fetchRegSubDistrict({
      page: 1,
      size: 1000,
      search: { CityID: CityID },
    });
    this.setState({ loadingSubDistrict: true });

    // this.handleChangeFormAddress(e);
  }

  handleChangeSubDistrict(e) {
    const { subdistricts } = this.state;
    let SubDistrictID = e;

    let selectedSubdistrict = _.find(
      subdistricts,
      (o) => parseInt(o.ID) === parseInt(SubDistrictID)
    );
    this.props.fetchRegArea({
      page: 1,
      size: 1000,
      search: { SubDistrictIDShipper: selectedSubdistrict.IDShipper },
    });
    this.setState({ loadingArea: true, area: [] });
  }

  handleDeleteAddress(id) {
    this.props.deleteMartDeliveryAddress(id);
    this.setState({ loadingAddress: true, submittingAddress: true });
    this.loadData();
  }

  toggleModal(isOpen, data, confirm, id) {
    let { modalTitle, modalBody, modalAction } = this.state;

    if (typeof data !== "undefined" && typeof data["type"] !== "undefined") {
      modalAction = data;
    }

    if (modalAction != null && typeof modalAction["type"] !== "undefined") {
      switch (modalAction["type"]) {
        case "DELETE_ADDRESS":
          modalTitle = "Konfirmasi Hapus";
          modalBody = "Apakah anda ingin menghapus alamat ini?";

          if (typeof confirm !== "undefined" && confirm) {
            this.props.deleteMartDeliveryAddress(modalAction["id"]);
            this.setState({ loadingAddress: true });
            this.loadData();
          }

          break;

        default:
          console.log("");
          break;
      }
    }

    this.setState({
      showModal: isOpen,
      modalTitle,
      modalBody,
      modalAction,
    });
  }

  loadAddress() {
    const { itemPerPage } = this.state;

    this.props.fetchMartDeliveryAddress({ page: 1, size: itemPerPage });
    this.setState({ loadingAddress: true });
  }
  render() {
    const {
      isLoading,
      action,
      breadcrumb,
      addressList,
      loadingAddress,
      successAdd,
      formAddress,
      addressDetail,
      addressLatLng,
      loadingCity,
      loadingProvince,
      loadingArea,
      provinces,
      cities,
      subdistricts,
      area,
      loadingSubDistrict,
      showModal,
      modalTitle,
      modalBody,
      pageReference,
      pageTitle,

      currentPage,
      itemPerPage,
      totalAddress,
    } = this.state;

    let renderComponent = null;
    const { id } = this.props.match.params;

    if (action === "map") {
      renderComponent = (
        <AddressMap
          addressDetail={addressDetail}
          addressID={id}
          isLoading={isLoading}
          loadingAddress={loadingAddress}
          successAdd={successAdd}
          addressUrl={"/shop/address/"}
          pageReference={pageReference}
          onSubmitAddress={this.handleSubmitAddress}
        />
      );
    } else if (action === "add" || action === "edit") {
      renderComponent = (
        <AddressForm
          addressDetail={addressDetail}
          addressLatLng={addressLatLng}
          formAddress={formAddress}
          isLoading={isLoading}
          breadcrumb={breadcrumb}
          loadingAddress={loadingAddress}
          successAdd={successAdd}
          loadingProvince={loadingProvince}
          loadingCity={loadingCity}
          loadingSubDistrict={loadingSubDistrict}
          loadingArea={loadingArea}
          provinces={provinces}
          cities={cities}
          subdistricts={subdistricts}
          area={area}
          addressID={id}
          addressUrl={"/shop/address/"}
          onSubmitAddress={this.handleSubmitAddress}
          onChangeProvince={this.handleChangeProvince}
          onChangeCity={this.handleChangeCity}
          onChangeSubdistrict={this.handleChangeSubDistrict}
        />
      );
    } else {
      renderComponent = (
        <React.Fragment>
          <AddressList
            addressList={addressList}
            isLoading={isLoading}
            loadingAddress={loadingAddress}
            handleDeleteAddress={this.handleDeleteAddress}
            onOpenModal={this.toggleModal}
            addressUrl={"/shop/address/"}
            currentPage={currentPage}
            itemPerPage={itemPerPage}
            totalAddress={totalAddress}
            onChangePage={this.handleChangePage}
            onSelectAddress={this.handleSelectAddress}
          />
          {/* Confirmation Modal */}
          <Modal show={showModal} onHide={(e) => this.toggleModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={(e) => this.toggleModal(false)}
              >
                Batalkan
              </Button>
              <Button
                variant="danger"
                onClick={(e) => this.toggleModal(false, "", true, "")}
              >
                Lanjutkan
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }

    return (
      <Address
        breadcrumb={breadcrumb}
        pageTitle={pageTitle}
        loadingAddress={loadingAddress}
      >
        {renderComponent}
      </Address>
    );
  }
}

const mapStateToProps = (state) => {
  const { martdelivery, mart_cart, regional, addressDetail } = state;
  return { martdelivery, mart_cart, regional, addressDetail };
};
export default withRouter(
  connect(mapStateToProps, {
    fetchMartDeliveryAddress,
    fetchRegProvince,
    fetchRegCity,
    fetchRegSubDistrict,
    saveMartDeliveryAddress,
    fetchMartDeliveryAddressById,
    deleteMartDeliveryAddress,
    fetchRegArea,
    updateDeliveryAddress,
    fetchRegByLatLng,

    //list of dispatch action
  })(AddressContainer)
);
