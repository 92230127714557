import React from 'react';  
import PropTypes from 'prop-types';  
import './Store.css'; 
import { Container, Row, Col } from 'react-bootstrap';
import Loading from './../../Layout/Loading';
import EmptyState from './../../Layout/EmptyState'; 
import Breadcrumb from './../../Layout/Breadcrumb'; 
import ProductItem from './../Product/ProductItem.component'; 
import Pagination from "react-js-pagination";
import defaultLogo from './../../../Images/store.png';

function Product(props) { 
 
    const {products, isLoading, breadcrumb, store, activePage, itemPerPage, totalProduct} = props; 

    const storeLogo = store && store.Logo ? store.Logo : defaultLogo; 

    return (
        <Container>

            {isLoading && <Loading />}  

            {!isLoading && breadcrumb != null && <div style={{marginTop:'33px',marginBottom:'24px'}}> <Breadcrumb url={breadcrumb} /> </div>}

            {!isLoading && store == null && <div className="text-center"><EmptyState style={{margin:'auto'}} /></div>} 

            {!isLoading && store != null && <Row noGutters>
                <Col xs={12} lg={3}>
                    <Row className="store-info-card">
                        <Col lg={12} xs={4} style={{background:"#fff", padding:'20px'}}>
                            {store != null ? <img src={storeLogo} width="100%" alt="sample store" /> : <small>Logo</small>}
                        </Col>
                        <Col lg={12} xs={8} style={{background:"#fff"}} className="store-info-content">
                            <div className="store-info-title">{store != null ? store.Name : "-"}</div>
                            <div className="store-info-owner">Pemilik Toko : {store != null ? store.Owner : "-"}</div>
                            {/* <div className={"store-info-desc "+(showDesc ? "" : "limit-2-line")} onClick={e => setShowDesc(!showDesc)} dangerouslySetInnerHTML={createMarkup(store.Description)} />
                             */}
                            {/* lg 58 */}
                            {/* {store.Description.length > 58 && <div className="text-center d-none d-lg-block"><Button variant="link" size="sm" style={{ textAlign: 'center', fontSize:'.75rem'}} onClick={e => setShowDesc(!showDesc)}>{showDesc ? "Tutup" : "Lihat Detail"}</Button></div>}
                            */}
                            {/* md 170 */}
                            {/* {store.Description.length > 170 && <div className="text-center d-none d-md-block d-lg-none"><Button variant="link" size="sm" style={{ textAlign: 'center', fontSize:'.75rem'}} onClick={e => setShowDesc(!showDesc)}>{showDesc ? "Tutup" : "Lihat Detail"}</Button></div>}
                            */}
                            {/* sm 74 */}
                            {/* {store.Description.length > 74 && <div className="text-center d-md-none"><Button variant="link" size="sm" style={{ textAlign: 'center', fontSize:'.75rem'}} onClick={e => setShowDesc(!showDesc)}>{showDesc ? "Tutup" : "Lihat Detail"}</Button></div>}
                             */}

                            <div className="store-info-desc">{store.Description}</div>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} lg={9} style={{paddingLeft:'3.5px'}}>
                    {!isLoading && products != null && products.length <= 0 && <div className="text-center"><EmptyState style={{margin:'auto'}} /></div>} 

                    <Row noGutters className="store-product">
                    {products != null && products.length > 0 && products.map((item, index) => { 
                        return (<Col lg={3} md={4} xs={6} key={index}>
                            <ProductItem product={item} onChangeQty={props.onChangeQty} buyButton />
                        </Col>)})
                    }
                    </Row> 

                    {!isLoading && products != null && products.length > 0 && parseInt(totalProduct) > parseInt(itemPerPage) && <Row className="justify-content-center" style={{margin:'20px auto'}}><Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemPerPage}
                        totalItemsCount={totalProduct}
                        pageRangeDisplayed={3}
                        onChange={props.onChangePageProduct}
                        itemClass="page-item"
                        linkClass="page-link"
                        
                        /></Row>}
                </Col>
            </Row>}

            

        </Container>
    )
}


Product.propTypes = {
    isLoading: PropTypes.bool, 
    products: PropTypes.array,
}


Product.defaultProps = { 
    isLoading: false,
    products: []
}


export default Product;