import React, { useState } from 'react';
import { parseCurrency } from './../../utils/common';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Loading from './../Layout/Loading';
import './Wallet.css';
import Breadcrumb from '../Layout/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { getPaymentNote, isCompletedParamOrderTopup } from '../../utils/cart';
import { labelMetodePembayaran } from '../../utils/order';
import InputMask from './../InputMask';


// import { useHistory } from 'react-router-dom';

export function Topup(props) {

    const { isLoading, walletTopupForm, breadcrumb, submittingTopup } = props;  
    const [nominal, setNominal] = useState('');
    const iconClose = <FontAwesomeIcon className="btn-qty" icon={faTimesCircle} /> 

    let isAllowTopup = isCompletedParamOrderTopup(walletTopupForm); 

    let inputNominal = !isNaN(parseInt(nominal)) && parseInt(nominal) !== 0 ? parseInt(nominal) : 
                        walletTopupForm != null && !isNaN(parseInt(walletTopupForm['Nominal'])) && parseInt(walletTopupForm['Nominal']) !== 0 ? 
                            parseInt(walletTopupForm['Nominal']) : ''; 

    const optionNominal = [30000, 50000, 75000, 100000, 125000, 150000];
    const labelPayment = walletTopupForm != null && labelMetodePembayaran(walletTopupForm); 
    const paymentNote = walletTopupForm != null && getPaymentNote("Topup");  

    return (
        <Container fluid>

            {isLoading && <Loading />}

            <div className="breadcrumbStyleWallet">
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
            </div>

            {!isLoading && <div className="wallet-topup-container">

                <div className="topup-nominal-card">
                    <div className="section-title">Nominal Isi Saldo</div>

                    <Row className="input-container">
                        <Col md={4}>
                            <Form>
                                <Form.Label className="label-input">Jumlah (Rp)</Form.Label>
                                <InputMask name="Nominal" value={parseInt(inputNominal)} onBlur={() => {}}
                                    onChange={(name,val) => { 
                                        val = !isNaN(parseInt(val)) && parseInt(val) !== 0 ? val : '';
                                        setNominal(val);
                                        props.onChangeNominal(val);
                                }} />
                                {walletTopupForm != null && walletTopupForm.Nominal !== '' && 
                                    <Button variant="link" className="btn-Close-topup" onClick={e => {
                                        setNominal('')
                                        props.onDeleteNominal(e.target.value)
                                    }} >{iconClose}</Button>}
                            </Form>
                        </Col>
                        <Col md={12} style={{ marginTop: '17px' }}><div className="label-input">Nominal</div></Col>
                    </Row>


                    <Row className="option-nominal">
                        {optionNominal.map((option, index) => {
                            return (<Col xs={4} key={index}>
                                <div className="option-nominal-item" onClick={e => {
                                    setNominal(null);
                                    props.onChangeNominal(option);
                                }}>{parseCurrency(option)}</div>
                            </Col>)
                        })}
                    </Row>
                </div>

                {/* <div className="topup-payment-card">
                    <div className="section-title">Pilihan Pembayaran</div>
                    {walletTopupForm != null && typeof(walletTopupForm.BankAccount) !== "undefined" ? 
                    <div>{walletTopupForm.BankAccount.BankName}</div> :
                    
                    <React.Fragment><div>Pilih metode pembayaran</div>

                    <div className="text-right" style={{marginTop:'18px'}}>
                        <Button variant="outline-primary" size="sm" href="/wallet/topup-payment">Atur Metode pembayaran</Button>
                    </div></React.Fragment>}
                </div>

                <div className="fixed-bottom-nav">
                    <Button variant="primary" onClick={e => props.onTopupBalance(walletTopupForm)} disabled={isLoading || !isValid} block>Isi saldo</Button>
                </div> */}

                <div className="topup-payment-card">
                    <Row>
                        <Col><div className="section-title">Metode Pembayaran</div></Col>
                    </Row>

                    {walletTopupForm != null && typeof (walletTopupForm.PaymentType) !== "undefined" ?
                        <React.Fragment>
                            <Row className="paymentRowStyle">
                                {walletTopupForm != null && walletTopupForm['PaymentType'] === "Bank" ? 
                                <React.Fragment>
                                    <Col md={2} xs={4} ><img src={walletTopupForm.BankImage} height="46px" alt="wallet"></img></Col>
                                    <Col md={6} xs={8} style={{ paddingTop: '20px' }}>
                                        {labelPayment} 
                                    </Col>
                                </React.Fragment> :
                                <Col style={{ paddingTop: '20px' }}>
                                    {labelPayment}
                                </Col>}
                                <Col md={4} xs={12} className="text-right"><Button variant="link" size="sm" href="/shop/payment?type=topup">Atur Metode pembayaran</Button></Col>
                            </Row>
                            
                            <Row>
                                <Col style={{paddingTop: '8px',fontSize:'12px', color:'#828282'}}>{paymentNote}</Col>
                            </Row>
                        </React.Fragment> : 
                        <Row style={{paddingTop:'20px'}}>
                            <Col md={6} xs={12}>Metode pembayaran belum ditentukan</Col>
                            <Col md={6} xs={12} className="text-right"><a href="/shop/payment?type=topup">Pilih metode pembayaran</a></Col>
                        </Row> 
                    } 
                </div>  

                {/* TAMPILAN MOBILE */}
                <div className="product-summary-bottom d-sm-block d-md-none"> 
                    <Button variant="primary" disabled={submittingTopup || !isAllowTopup} onClick={e => props.onSubmitTopup(e)}>{submittingTopup ? "Memproses..." : "Lanjut"}</Button> 
                </div>

                {/* TAMPILAN WEB */} 
                <div className="d-none d-sm-block" style={{marginRight:'15px', marginBottom:'70px', clear:'both'}}> 
                    <Button variant="primary" disabled={submittingTopup || !isAllowTopup} onClick={e => props.onSubmitTopup(e)} className="delivery-btn-next"  >{submittingTopup ? "Memproses..." : "Lanjut"}</Button> 
                </div> 

                <div style={{height:'50px'}}>&nbsp;</div>
            </div>}

        </Container>
    )
}

export default Topup;