
export const colorOrderStatus = (idStatus) => {
	let color = null;
	switch(parseInt(idStatus)) {
		case 2: 
			color = "#52a876";
		break;

		case 3: 
			color = "#f2a100";
		break;

		case 4: 
			color = "#bb6bd9";
		break;

		case 5: 
			color = "#1a7cfc";
		break;

		case 6: 
			color = "#7bc242";
		break;

		case 7: 
			color = "#6FCF97";
		break;

		case 8: 
			color = "#fa3232";
		break;

		case 9: 
			color = "#fa3232";
		break;

		// case 9: 
		// 	color = "#7bc242";
		// break;

		default:
			color = "#6FCF97";
		break;
	}

	return color;
}

export const colorPaymentStatus = (idStatus) => {
	let color = null; 
	switch(parseInt(idStatus)) {   
		case 1: 
			color = "#F2994A";
			break; 

		case 2: 
			color = "#BB6BD9";
			break;

		case 3: 
			color = "#F56B95";
			break; 

		case 4:
			color = "#EB5757";
			break;

		default:
			color = "#52a876";
			break;
	} 

	return color;
}

export const labelMetodePembayaran = ({Payment, PaymentType, BankName}) => {
	let label = "";
	
	if(PaymentType == null || PaymentType === "") {
		return "";
	}

    switch(PaymentType.toLowerCase()) {
        case "wallet":
            label = PaymentType+" ("+Payment+")";
            break;
        
        case "bank":
            label = Payment+" ("+BankName+")";
            break;

        case "cash":
            label = Payment;
            break;

        case "paylater":
            label = Payment+" ("+PaymentType+")";
			break;
			
        case "qris":
            label = PaymentType;
            break;

        default:
            label = PaymentType+" ("+Payment+")";
            break;
    }

    return label;
}

export const labelOrderPayment = (PaymentStatus) => {
    let label = "";
    switch(PaymentStatus) {
        case 1:
            label = "Pembayaran Diverifikasi";
            break;
        
        case 2:
            label = "Pembayaran Berhasil";
            break;
        
        case 3:
            label = "Pembayaran Kadaluarsa";
            break;
        
        case 4:
            label = "Pembayaran Tidak Berhasil";
            break;
 
        default:
            label ="Menunggu Pembayaran";
            break;
	} 

    return label;
}