import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getCart,
  updateDeliveryCourier,
} from "./../../../redux/actions/mart_cart";
import {
  fetchMartCourierFeeShipper,
  fetchMartCourierList,
} from "./../../../redux/actions/mart_courier";
import Courier from "./Courier.component";

class CourierContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingFee: false,
      filterData: {},
      couriers: [],
      courierFees: null,
      courierCodes: [],
      storeCourierFee: null,
      breadcrumb: null,
      showModal: false,
      PaymentType: null,
      paymentSchema: [],
      DeliveryID: null,
    };

    // this.loadData = this.loadData.bind(this);
    this.handleFetchFee = this.handleFetchFee.bind(this);
    this.handleChangeCourier = this.handleChangeCourier.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const {
      isFetchingMartCourierList,
      isFetchingMartCourierFee,
      mart_couriers,
      mart_courier_fees,
    } = this.props.martcourier;
    const { isLoading, isLoadingFee } = this.state;
    let { breadcrumb, courierCodes } = this.state;
    const { summary } = this.props.martcart.carts;

    if (
      !isLoadingFee &&
      isLoading &&
      summary != null &&
      typeof summary !== "undefined"
    ) {
      this.handleFetchFee();
    }

    // if(!isFetchingMartCourierFee && isLoadingFee) {
    //     this.setState({isLoadingFee: false, courierFees: mart_courier_fees});
    // }

    // if(!isFetchingMartCourierFee && isLoading && isLoadingFee) {
    //     breadcrumb = [
    //         {link:'/beranda', text:'Beranda'},
    //         {link:'/shop/cart', text: 'Keranjang'},
    //         {link:'/shop/delivery', text: 'Atur Pesanan'},
    //         {link:'/shop/payment', text: 'Opsi Pengiriman'},
    //     ]

    //     console.log('load finish')

    //     // this.setState({isLoadingFee: false, courierFees: mart_courier_fees});
    //     this.setState({
    //         isLoading: false,
    //         isLoadingFee: false,
    //         // couriers: mart_couriers,
    //         courierFees: mart_courier_fees,
    //         paymentType: summary != null && typeof(summary['PaymentType']) !== "undefined" ? summary['PaymentType'] : null,
    //         couriersID: summary != null && typeof(summary['BankAccountID']) !== "undefined" ? summary['BankAccountID'] : null,
    //         courierCodes,
    //         breadcrumb,
    //     })
    // }

    // if(!isLoadingFee && isLoading && summary != null && typeof(summary) !== "undefined") {
    //     let origin = summary != null && typeof(summary['StoreAreaID']) !== "undefined" ? summary['StoreAreaID'] : null;
    //     let destination = summary != null && typeof(summary['DeliveryAreaID']) !== "undefined" ? summary['DeliveryAreaID'] : null;
    //     let productIDs = [];
    //     let quantities = [];

    //     console.log('load fee')

    //     if(items != null && items !== "undefined") {
    //         items.forEach(item => {
    //             productIDs.push(item.ID)
    //             quantities.push(item.Qty)
    //         })
    //     }

    //     this.props.fetchMartCourierFeeShipper({OriginAreaID: origin, DestinationAreaID: destination, ProductIDs:productIDs, Quantities:quantities});
    //     this.setState({isLoadingFee: true})
    // }

    if (
      !isFetchingMartCourierList &&
      !isFetchingMartCourierFee &&
      isLoading &&
      isLoadingFee
    ) {
      breadcrumb = [
        // {link:'/beranda', text:'Beranda'},
        // {link:'/shop/cart', text: 'Keranjang'},
        { link: "/shop/delivery", text: "Atur Pesanan" },
        { link: "/shop/payment", text: "Opsi Pengiriman" },
      ];

      // if(mart_couriers != null && mart_couriers.length > 0) {
      //     courierCodes = mart_couriers.map(courier => courier.Code.toLowerCase());
      //     this.handleFetchFee(courierCodes);
      // }

      this.setState({
        isLoading: false,
        isLoadingFee: false,
        couriers:
          typeof mart_courier_fees.couriers !== "undefined"
            ? mart_courier_fees.couriers
            : null,
        courierFees:
          typeof mart_courier_fees.services !== "undefined"
            ? mart_courier_fees.services
            : null,
        paymentType:
          summary != null && typeof summary["PaymentType"] !== "undefined"
            ? summary["PaymentType"]
            : null,
        couriersID:
          summary != null && typeof summary["BankAccountID"] !== "undefined"
            ? summary["BankAccountID"]
            : null,
        DeliveryID:
          summary != null && typeof summary["DeliveryID"] !== "undefined"
            ? summary["DeliveryID"]
            : null,
        summary: summary,
        storeCourierFee:
          mart_couriers && typeof mart_couriers["Price"] !== "undefined"
            ? mart_couriers["Price"]
            : null,
        courierCodes,
        breadcrumb,
      });
    }
  }

  loadData() {
    this.props.getCart();

    this.setState({
      isLoading: true,
    });
  }

  handleChangeCourier(courier) {
    this.props.updateDeliveryCourier(courier);
    // this.props.history.goBack();
    this.props.history.push("/shop/delivery");
  }

  // handleFetchFee(courierCodes) {
  //     const {summary} = this.props.martcart.carts;

  //     let origin = summary != null && typeof(summary['StoreSubDistrictID']) !== "undefined" ? summary['StoreSubDistrictID'] : null;
  //     let destination = summary != null && typeof(summary['DeliverySubDistrictID']) !== "undefined" ? summary['DeliverySubDistrictID'] : null;
  //     let weight = summary != null && typeof(summary['TotalWeight']) !== "undefined" && parseInt(summary['TotalWeight']) > 0 ? summary['TotalWeight'] : 1;

  //     if(courierCodes != null && courierCodes.length > 0) {
  //         this.props.fetchMartCourierFeeShipper({Courier: courierCodes.join(","), Origin: origin, Destination: destination, Weight:weight});
  //         this.setState({isLoadingFee: true});
  //     }
  // }

  handleFetchFee() {
    const { summary, items } = this.props.martcart.carts;

    let StoreID =
      summary != null && typeof summary["StoreID"] !== "undefined"
        ? summary["StoreID"]
        : null;
    let DeliveryID =
      summary != null && typeof summary["DeliveryID"] !== "undefined"
        ? summary["DeliveryID"]
        : null;
    // let weight = summary != null && typeof(summary['TotalWeight']) !== "undefined" && parseInt(summary['TotalWeight']) > 0 ? summary['TotalWeight'] : 1;

    let ProductIDs = [];
    let Quantities = [];

    if (items != null && items !== "undefined") {
      items.forEach((item) => {
        ProductIDs.push(item.ID);
        Quantities.push(item.Qty);
      });
    }

    // if(courierCodes != null && courierCodes.length > 0) {
    // mendapatkan fee store courier
    this.props.fetchMartCourierList({
      StoreID,
      DeliveryID,
      ProductIDs,
      Quantities,
      DeliverOption: 4,
    });

    // mendapatkan courier dari shipper
    this.props.fetchMartCourierFeeShipper({
      StoreID,
      DeliveryID,
      ProductIDs,
      Quantities,
    });
    this.setState({ isLoadingFee: true });
    // }
  }

  render() {
    const {
      isLoading,
      isLoadingFee,
      breadcrumb,
      couriers,
      courierFees,
      storeCourierFee,
      paymentType,
      DeliveryID,
      summary,
    } = this.state;

    return (
      <Courier
        isLoading={isLoading}
        isLoadingFee={isLoadingFee}
        breadcrumb={breadcrumb}
        couriers={couriers}
        courierFees={courierFees}
        paymentType={paymentType}
        DeliveryID={DeliveryID}
        summary={summary}
        storeCourierFee={storeCourierFee}
        onChangeCourier={this.handleChangeCourier}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { martcourier, martcart } = state;
  return { martcourier, martcart };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchMartCourierList,
    fetchMartCourierFeeShipper,
    updateDeliveryCourier,
    getCart,
  })(CourierContainer)
);
