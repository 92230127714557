import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React from "react";
import { Button, Container, Form } from "react-bootstrap";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import imgSuccessRegister from "./../../Images/success-register.png";
import { register } from "./../../utils/JWTAuth";
import "./Auth.css";

// logo org
var LogoGreen = require(`./../../${process.env.REACT_APP_THEME_IMAGE}logo-color.png`);

// Validasi form disini
const RegisterSchema = Yup.object().shape({
  Email: Yup.string()
    .email("Format email salah")
    .min(4, "Email terlalu pendek, minimal 4 karakter")
    .required("Email harus diisi"),
  Password: Yup.string()
    .min(4, "Password terlalu pendek, minimal 4 karakter")
    .required("Password harus diisi"),
  ConfirmationPassword: Yup.string()
    .required("Konfirmasi password harus diisi")
    .oneOf(
      [Yup.ref("Password"), null],
      "Passwords dan konfirmasi password harus sama"
    ),
});

const RegisterFinalSchema = Yup.object().shape({
  FullName: Yup.string()
    .min(3, "Nama Lengkap terlalu pendek, minimal 3 karakter")
    .required("Nama Lengkap harus diisi"),
  Phone: Yup.number()
    .typeError("No.Handphone harus berupa angka")
    .min(10000000, "No.Handphone terlalu pendek, minimal 9 karakter")
    .max(999999999999, "No.Handphone terlalu panjang, maksimak 13 karakter"),
  //   .required('No.Handphone harus diisi'),
  // BirthDate: Yup.string()
  //   .required('Tanggal Lahir harus diisi'),
  // BirthPlace: Yup.string()
  //   .required('Tempat Lahir harus diisi'),
});

class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      input: { Email: "", Password: "", Phone: 0 },

      // ini untuk menandakan login sedang proses atau sudah selesai
      processRegister: false,
      responRegister: null,
      data: null,
      finalStep: false,
      completedRegister: false,
      showPassword: false,
      showConfirmPassword: false,
    };

    this.handleNext = this.handleNext.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleShowConfirmPassword = this.handleShowConfirmPassword.bind(this);
  }

  componentDidMount() {
    ReactGA.set({ title: "register" });
    ReactGA.pageview("/register");
  }

  componentDidUpdate() {
    let { data, completedRegister } = this.state;

    if (
      data != null &&
      typeof data.UserID !== "undefined" &&
      !completedRegister
    ) {
      // this.props.history.push("/beranda");
      this.setState({ completedRegister: true });
    }
  }

  async handleRegister(values) {
    let { input } = this.state;

    this.setState({ processRegister: true });

    input = { ...values };

    // mengambil result dari fungsi login (yang sudah direturn)
    let respon = await register(input);

    //kita akan menyimpan hasil login jika sudah dapat ke state
    this.setState({
      data: respon.data,
      responRegister: respon,
      processRegister: false,
      input,
    });
  }

  handleNext(values) {
    this.setState({ finalStep: true, input: values });
  }

  handleShowPassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  handleShowConfirmPassword() {
    const { showConfirmPassword } = this.state;
    this.setState({ showConfirmPassword: !showConfirmPassword });
  }

  render() {
    const { processRegister, responRegister, finalStep, completedRegister } =
      this.state;

    return (
      <Container>
        <div className="register-wrapper">
          <div className="register-form">
            {!completedRegister && (
              <div className="register-green-logo">
                <img src={LogoGreen} alt="logo agrego hijau" />
              </div>
            )}
            <div className="login-text-welcome">Formulir Pendaftaran</div>

            {responRegister != null && !responRegister.success && (
              <div className="alert alert-danger login-message-error">
                {responRegister.message}
              </div>
            )}

            {/* Completed register */}
            {completedRegister && (
              <div className="text-center">
                <img
                  src={imgSuccessRegister}
                  width="100%"
                  alt="success register"
                />
                <div style={{ paddingTop: "32px" }}>
                  Terima kasih telah mendaftar di {process.env.REACT_APP_NAME}.
                  Tunggu email dari kami untuk proses selanjutnya
                </div>

                <Button className="mt-5" variant="primary" href="/">
                  Kembali ke Beranda
                </Button>
              </div>
            )}

            {/* Register form */}
            {!completedRegister && (
              <React.Fragment>
                <Formik
                  enableReinitialize={true}
                  initialValues={{ ...this.state.input }}
                  validationSchema={
                    !finalStep ? RegisterSchema : RegisterFinalSchema
                  }
                  onSubmit={(values, { setSubmitting }) => {
                    if (!finalStep) {
                      this.handleNext(values);
                    } else {
                      ReactGA.event({
                        category: "membership",
                        action: "register",
                      });

                      this.handleRegister(values);
                    }
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue,
                    setFieldTouched,
                  }) => {
                    // const checkboxClick = (e) => {
                    //   let isChecked = e.target.checked;
                    //   if (isChecked) {
                    //     setFieldValue('OrgName', process.env.REACT_APP_ORGNAME, true);
                    //     setFieldTouched('OrgName', true, true);
                    //   }
                    // }

                    const handleChangePhone = (e) => {
                      let phoneNumber = e.target.value;

                      if (phoneNumber.slice(0, 2) === "62") {
                        phoneNumber = "0" + phoneNumber.slice(2);
                      } else if (phoneNumber.slice(0, 3) === "+62") {
                        phoneNumber = "0" + phoneNumber.slice(3);
                      }
                      // cek jika nomer telpon tidak diawali 0 yang panjang nomer telepon lebih dari 3,
                      // agar bisa di cek dulu di kondisi awal apakah diawali 62 dan +62
                      else if (
                        !isNaN(parseInt(phoneNumber)) &&
                        phoneNumber.slice(0, 1) !== "0" &&
                        phoneNumber.length > 2
                      ) {
                        phoneNumber = "0" + phoneNumber;
                      }

                      setFieldValue("Phone", phoneNumber);
                      setFieldTouched("Phone", true, true);
                    };

                    return (
                      <form onSubmit={handleSubmit} id="form-login">
                        {!finalStep && (
                          <React.Fragment>
                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="Email"
                                value={values["Email"]}
                                placeholder="Masukkan Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.Email && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.Email}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicPassword"
                              style={{ position: "relative" }}
                            >
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                value={values["Password"]}
                                name="Password"
                                placeholder="Masukkan Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <div
                                className="eye-icon"
                                onClick={this.handleShowPassword}
                              >
                                {this.state.showPassword ? (
                                  <FontAwesomeIcon icon={faEye} />
                                ) : (
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                )}
                              </div>

                              {errors.Password && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.Password}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicConfirmationPassword"
                              style={{ position: "relative" }}
                            >
                              <Form.Label>Konfirmasi Password</Form.Label>
                              <Form.Control
                                type={
                                  this.state.showConfirmPassword
                                    ? "text"
                                    : "password"
                                }
                                value={values["ConfirmationPassword"]}
                                name="ConfirmationPassword"
                                placeholder="Masukkan Konfirmasi Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <div
                                className="eye-icon"
                                onClick={this.handleShowConfirmPassword}
                              >
                                {this.state.showConfirmPassword ? (
                                  <FontAwesomeIcon icon={faEye} />
                                ) : (
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                )}
                              </div>

                              {errors.ConfirmationPassword && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.ConfirmationPassword}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </React.Fragment>
                        )}

                        {finalStep && (
                          <React.Fragment>
                            {/* <Form.Group className="login-form-group" controlId="formBasicOrgName">
                    <Form.Label>Organisasi *</Form.Label>
                    <Form.Control type="text" name="OrgName" value={values['OrgName']} placeholder="Masukkan Organisasi" onChange={handleChange} onBlur={handleBlur} disabled={values['is_agrego']} />
                    {errors.OrgName &&
                      <Form.Text className="text-danger"> {errors.OrgName} </Form.Text>}

                    <Form.Check name="is_agrego" value={1} checked={values['is_agrego']} id="check-is-agrego" onChange={handleChange} onBlur={handleBlur} onClick={e => checkboxClick(e)} type="checkbox" label="Bergabung dengan komunitas Agrego" />
                  </Form.Group> */}

                            {/* <Form.Group className="login-form-group" controlId="formBasicMemberNumber">
                    <Form.Label>Nomor Anggota</Form.Label>
                    <Form.Control type="text" name="MemberNumber" placeholder="Masukkan Nomor Anggota" onChange={handleChange} onBlur={handleBlur} />
                    {errors.MemberNumber && touched.MemberNumber &&
                      <Form.Text className="text-danger"> {errors.MemberNumber} </Form.Text>}
                  </Form.Group> */}

                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicFullName"
                            >
                              <Form.Label>Nama Lengkap</Form.Label>
                              <Form.Control
                                type="text"
                                name="FullName"
                                value={values["FullName"]}
                                placeholder="Masukkan Nama Lengkap"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.FullName && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.FullName}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicGender"
                            >
                              <Form.Label>Jenis Kelamin</Form.Label>
                              <Form.Control
                                as="select"
                                name="Gender"
                                value={values["Gender"]}
                                placeholder="Masukkan Jenis Kelamin"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value={1}>Laki - laki</option>
                                <option value={0}>Perempuan</option>
                              </Form.Control>

                              {errors.Gender && touched.Gender && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.Gender}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicPhone"
                            >
                              <Form.Label>No.Handphone</Form.Label>
                              <Form.Control
                                type="text"
                                name="Phone"
                                value={values["Phone"]}
                                placeholder="Masukkan No.Handphone"
                                onChange={handleChangePhone}
                                onBlur={handleBlur}
                              />
                              {errors.Phone && touched.Phone && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.Phone}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicBirthPlace"
                            >
                              <Form.Label>Tempat Lahir (Opsional)</Form.Label>
                              <Form.Control
                                type="text"
                                name="BirthPlace"
                                value={values["BirthPlace"]}
                                placeholder="Masukkan Tempat Lahir"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.BirthPlace && touched.BirthPlace && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.BirthPlace}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="login-form-group"
                              controlId="formBasicBirthDate"
                            >
                              <Form.Label>Tanggal Lahir (Opsional)</Form.Label>
                              <Form.Control
                                type="date"
                                name="BirthDate"
                                value={values["BirthDate"]}
                                placeholder="Masukkan Tanggal Lahir"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.BirthDate && touched.BirthDate && (
                                <Form.Text className="text-danger">
                                  {" "}
                                  {errors.BirthDate}{" "}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </React.Fragment>
                        )}
                      </form>
                    );
                  }}
                </Formik>

                <Button
                  variant="primary"
                  block
                  type="submit"
                  className="login-btn"
                  disabled={processRegister}
                  form="form-login"
                >
                  {processRegister
                    ? "Memproses..."
                    : !finalStep
                    ? "Lanjutkan"
                    : "Kirim"}
                </Button>

                {finalStep && (
                  <Button
                    variant="outline-primary"
                    block
                    disabled={processRegister}
                    onClick={(e) => this.setState({ finalStep: false })}
                  >
                    Kembali
                  </Button>
                )}

                <div className="login-text-notregister">
                  Sudah punya akun? <a href="/login">Masuk</a>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(Register);
