import React from 'react';
import { 
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux'; 
import { fetchProviderProduct } from '../../../redux/actions/digital_product';
import { getCartDigital, addCartDigital } from '../../../redux/actions/digital_cart';
import { inquiryDigitalOrder } from '../../../redux/actions/digital_order';
import PLNPascabayarComponent from './PLNPascabayar.Component';

class PLNPascabayarContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      breadcrumb: null,
      inquiryProduct: null,
      product: null
    }

    this.handleClearForm = this.handleClearForm.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChangeProductDigital = this.handleChangeProductDigital.bind(this);
    this.handleChangePLNPascabayarNo = this.handleChangePLNPascabayarNo.bind(this);


  }

  //method yang dijalankan ketika react selesai menjalankan app
  componentDidMount() {
    this.loadData();
  }

  //dijalankan oleh react ketika ada perubahan nilai di props
  componentDidUpdate() {
    const { cartDigital } = this.props.digitalcart;
    const { fetchingProviderProduct, providerProduct } = this.props.digitalproduct;
    const { isInquiryDigitalOrder, digital_order_inquiry } = this.props.digitalorder;
    const { isLoading, processInquiry } = this.state;
    let { breadcrumb, inquiryProduct, product } = this.state; 

    if (processInquiry && !isLoading && !isInquiryDigitalOrder) {
      if (digital_order_inquiry != null) {

        this.props.addCartDigital({ 
          ...cartDigital, 
          OrderID: digital_order_inquiry['ID'], 
          TransactionID: digital_order_inquiry['TransactionID'] 
        }); 

        // TESTING PURPOSE
        localStorage.setItem("DIGITAL_ORDER_RESPON", JSON.stringify(digital_order_inquiry));
        this.props.history.push('/digital/confirmation')
      }

      this.setState({ processInquiry: false })
    }

    if (isLoading && !fetchingProviderProduct) {

      breadcrumb = [
        { link: '/beranda', text: 'Beranda' },
        { link: '#', text: 'Tagihan Listrik' },
      ]

      providerProduct.forEach(prod => {
        if (prod['Code'] === "PLNPASCAINQ") {
          inquiryProduct = prod;
        } else if(prod['Code'] === "PLNPASCABAYAR") {
          product = prod;
        }
      })

      this.setState({
        isLoading: false,
        CartDigital: cartDigital,
        breadcrumb,
        inquiryProduct,
        product
      })

    }


  }

  loadData(param) {
    this.props.fetchProviderProduct({ page: 1, size: 100, sorting: "Price-asc", search: { CategoryID: 56 } });

    this.setState({
      isLoading: true
    });
  }

  handleChangePLNPascabayarNo(values) {

    const { product } = this.state;

    if (values.length >= 10) {
      var newReference = { 
        Reference: values, 
        Name: product != null && product['Name'] != null ? product['Name'] : "",
        ProductID: product != null && !isNaN(product['ID']) ? product['ID'] : "" 
      }

      this.handleChangeProductDigital(newReference);

    }

  }

  handleChangeProductDigital(values) {  

    this.props.addCartDigital({ 
      ...values, 
      CategoryName: "Tagihan Listrik", 
      CategoryURL: "/digital/plnpascabayar"
    });

    this.props.getCartDigital();

    this.setState({isLoading: true})
  }

  handleClearForm() {
    let newdata = { Reference: '' } 
    this.handleChangeProductDigital(newdata);  
  }

  
  handleNext() {
    const { inquiryProduct, CartDigital } = this.state; 

    if (inquiryProduct != null) {
      let data = { ...CartDigital, ProductID: inquiryProduct['ID'] } 
      this.props.inquiryDigitalOrder(data);
      this.setState({ processInquiry: true })
    }
  }



  render() {
    const { isLoading, breadcrumb, processInquiry } = this.state;
    let renderComponent = null;

    renderComponent =
      <PLNPascabayarComponent
        isLoading={isLoading || processInquiry} 
        breadcrumb={breadcrumb}

        onClearForm={this.handleClearForm}
        onClickSubmit={this.handleNext}
        onChangePLNPascabayarNo={this.handleChangePLNPascabayarNo}
        onChangeProductDigital={this.handleChangeProductDigital}
      /> 
    return (<div>{renderComponent}</div>);
  }


}


const mapStateToProps = (state) => {
  const { digitalproduct, digitalcart, digitalorder } = state;
  return { digitalproduct, digitalcart, digitalorder }
};
export default withRouter(connect(mapStateToProps, {
  addCartDigital,
  inquiryDigitalOrder,
  fetchProviderProduct,
  getCartDigital 
})(PLNPascabayarContainer)); 