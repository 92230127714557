import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import '../PLNPascabayar/PLNPascabayar.css';
import '../ppob.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Breadcrumb from '../../Layout/Breadcrumb'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'; 


const BPJSSchema = Yup.object().shape({
    BPJSno: Yup.number()
    .typeError('Nomor VA Keluarga harus berupa angka')
    .required('Nomor VA Keluarga harus diisi')
    .min(1000000000,'Nomor VA Keluarga minimal 10 karakter')
   
  });


export default function BPJS(props) {
  const{ breadcrumb }= props;
  const iconClose = <FontAwesomeIcon className="btn-qty" icon={faTimesCircle} />   
  var disabledbtn; 
    
  return ( 
    <Formik
    enableReinitialize={true}
    validationSchema={BPJSSchema}
    initialValues={{BPJSno : ''}}
        onSubmit={(values) => {
                    // ketika di submit data form di variable values dikirim ke parent component lewat props
       
                    // kirim form ke parent lewat onSubmitProfile
props.onChangeBPJSNo(values);
}}


>
{({ errors, touched, handleChange, handleBlur, handleSubmit, values, isSubmitting, setFieldValue, setFieldTouched }) => {

const onHandleProductDigital = (e) => {

    props.onChangeBPJSNo(e.target.value)
    setFieldValue('BPJSno',e.target.value)
    
    
    }

    console.log(values['BPJSno']);
    
const onClearForm= (e) =>{

props.onClearForm(e.target.value)

setFieldValue('BPJSno','')
setFieldTouched('BPJSno', true, true)
console.log('clear');

}
if(values['BPJSno'] === ''){
    disabledbtn = true;
}

else if(errors['BPJSno']!== undefined){
    disabledbtn = true;
}

else{
    disabledbtn = false;
}


console.log(values['BPJSno']);
return (
   <Container fluid>

       {/* TAB MENU PPOB */}

       <div className="d-none d-sm-block">

       <Row style={{marginLeft:'54px',background:'#FFFF'}} >
           <Col md={2} className="ppob-custom-tab">
               <div className="ppob-custom-tab-Nav-Item">
                   <a href="/digital/pulsa">Pulsa dan Data</a>
                   </div>
            </Col>

            <Col md={2} className="ppob-custom-tab">
               <div className="ppob-custom-tab-Nav-Item">
                   <a href="/digital/plnprepaid">Token Listrik</a>
                   </div>
            </Col>

            <Col md={2} className="ppob-custom-tab">
               <div className="ppob-custom-tab-Nav-Item">
                   <a href="/digital/plnpascabayar">Tagihan Listrik</a>
                   </div>
            </Col>


            <Col md={2} className="ppob-custom-tab">
               <div className="ppob-custom-tab-Nav-Item-active">
                   <a href="/digital/bpjs">BPJS</a>
                   </div>
            </Col>

       </Row>
       </div>

        {/* TAMPILAN MOBILE */}
        <div className="d-block d-sm-none">

            <div className="scrollmenu">
                <a href="/digital/pulsa">Pulsa dan Data</a>
                <a href="/digital/plnprepaid">Token Listrik</a>
                <a href="/digital/plnpascabayar">Tagihan Listrik</a>

                <a className="scrollmenu-active" href="/digital/bpjs">BPJS</a> 
                </div>

                </div>

    <Row>
        <Col>
        <div style={{ marginTop: '20px', paddingBottom: '0px' , marginLeft:'8%'}}>
                {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
                </div>
</Col>
    </Row>
       <Row>
           <div className="PLNPascabayarContainer">
           
                <Col md={12}><div className="PLNPascabayarJudul">Bayar BPJS</div></Col>
                <Col md={12}>
                    <div className="col-md-4" style={{paddingLeft:'0px'}}>
                    <Form >
                        <Form.Group>
                            <Form.Label className="labelPLNPascabayar">Nomor VA Keluarga</Form.Label>
                            <Form.Control 
                            name="BPJSno"
                            onBlur={handleBlur}
                            value={values.BPJSno}
                            onChange={onHandleProductDigital}
                            
                            />
                            {values['BPJSno'] !== '' &&  <Button variant="link" className="btnplnClose" onClick={onClearForm} >{iconClose}</Button>}
                        
                        </Form.Group>
                        
                        {errors.BPJSno && 
                        <div className="errorTextPLN">
                        <Form.Text className="text-danger">
                            {errors.BPJSno}
                        </Form.Text>
                        </div>}

                    </Form>
                    </div>
                </Col>

                
                <Col md={4} className="btnPLNPascabayar">
                    <Button variant="primary" block disabled={disabledbtn}>Lanjut</Button>
                </Col>
           
           
           </div>
       </Row>
       




       </Container>)}}

</Formik>)}