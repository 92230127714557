import Axios from "axios";
import {
  parseInquiryPlnPostpaid,
  parseInquiryPlnPrepaid,
} from "../../utils/digital_parser";
import { setDataSubmitOrderDigital } from "./../../utils/cart";
import { setFieldSearchQuery, setQueryString } from "./../../utils/common";
import { displayMessage, handleError } from "./app";
import { removeCartDigital } from "./digital_cart";

export const REQUEST_DIGITALORDER_LIST = "REQUEST_DIGITALORDER_LIST";
export const REQUEST_DIGITALORDER = "REQUEST_DIGITALORDER";
export const REQUEST_DIGITALORDER_INQUIRY = "REQUEST_DIGITALORDER_INQUIRY";
export const REQUEST_DIGITALORDER_SUBMIT = "REQUEST_DIGITALORDER_SUBMIT";
export const REQUEST_DIGITALORDER_STATUS = "REQUEST_DIGITALORDER_STATUS";
export const CANCELING_DIGITALORDER = "CANCELING_DIGITALORDER";
export const CONFIRMING_DIGITALORDER = "CONFIRMING_DIGITALORDER";
export const UPLOADING_DIGITALORDER_BUKTIBAYAR =
  "UPLOADING_DIGITALORDER_BUKTIBAYAR";

export const RESULT_DIGITALORDER_LIST = "RESULT_DIGITALORDER_LIST";
export const RESULT_DIGITALORDER_TOTAL_ORDER =
  "RESULT_DIGITALORDER_TOTAL_ORDER";
export const RESULT_DIGITALORDER = "RESULT_DIGITALORDER";
export const RESULT_DIGITALORDER_INQUIRY = "RESULT_DIGITALORDER_INQUIRY";
export const RESULT_DIGITALORDER_SUBMIT = "RESULT_DIGITALORDER_SUBMIT";
export const RESULT_DIGITALORDER_WALLET = "RESULT_DIGITALORDER_WALLET";
export const RESULT_DIGITALORDER_STATUS = "RESULT_DIGITALORDER_STATUS";
export const CANCELED_DIGITALORDER = "CANCELED_DIGITALORDER";
export const CONFIRMED_DIGITALORDER = "CONFIRMED_DIGITALORDER";
export const UPLOADED_DIGITALORDER_BUKTIBAYAR =
  "UPLOADED_DIGITALORDER_BUKTIBAYAR";

const searchFieldType = { OrderStatus: "int" };

const requestDigitalOrderList = () => ({
  type: REQUEST_DIGITALORDER_LIST,
});

const requestDigitalOrder = () => ({
  type: REQUEST_DIGITALORDER,
});

const requestDigitalOrderInquiry = () => ({
  type: REQUEST_DIGITALORDER_INQUIRY,
});

const requestDigitalOrderSubmit = () => ({
  type: REQUEST_DIGITALORDER_SUBMIT,
});

const requestDigitalOrderStatus = () => ({
  type: REQUEST_DIGITALORDER_STATUS,
});

const cancelingDigitalOrder = () => ({
  type: CANCELING_DIGITALORDER,
});

const confirmingDigitalOrder = () => ({
  type: CONFIRMING_DIGITALORDER,
});

const uploadingDigitalOrderBuktiBayar = () => ({
  type: UPLOADING_DIGITALORDER_BUKTIBAYAR,
});

const resultDigitalOrderList = (data) => ({
  type: RESULT_DIGITALORDER_LIST,
  payload: {
    data,
  },
});

const resultMartTotalOrder = (data) => ({
  type: RESULT_DIGITALORDER_TOTAL_ORDER,
  payload: {
    data: data.data,
    count: data.count,
  },
});

const resultDigitalOrder = (data) => ({
  type: RESULT_DIGITALORDER,
  payload: {
    data,
  },
});

const resultDigitalOrderInquiry = (data) => ({
  type: RESULT_DIGITALORDER_INQUIRY,
  payload: {
    data,
  },
});

const resultDigitalOrderSubmit = (data) => ({
  type: RESULT_DIGITALORDER_SUBMIT,
  payload: {
    data,
  },
});

const resultDigitalOrderStatus = (data) => ({
  type: RESULT_DIGITALORDER_STATUS,
  payload: {
    data: data,
  },
});

const canceledDigitalOrder = (data) => ({
  type: CANCELED_DIGITALORDER,
  payload: {
    data: data,
  },
});

const confirmedDigitalOrder = (data) => ({
  type: CONFIRMED_DIGITALORDER,
  payload: {
    data: data,
  },
});

const uploadedDigitalOrderBuktiBayar = (data) => ({
  type: UPLOADED_DIGITALORDER_BUKTIBAYAR,
  payload: {
    data: data,
  },
});

// Request mart order
export const fetchDigitalOrder = (props, withTotalCount) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  withTotalCount = typeof withTotalCount !== "undefined" && withTotalCount;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestDigitalOrderList());

    // // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_ORDER");
    // if(localData.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartTotalOrder({data: localData, count: 16}));
    // }

    return Axios.get("/me/digitalorder?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        // kalau pake total count, maka jalankan query count data
        if (withTotalCount) {
          return dispatch(
            fetchMartTotalOrder({
              ...props,
              items: data.data,
              message: data.message,
            })
          );
        }

        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedDigitalOrder
        return dispatch(resultDigitalOrderList(data.data));
      })
      .catch((error) => {
        return dispatch(handleError(error));
      });
  };
};

// Request mart order
export const fetchDigitalOrderById = (transactionID) => {
  return (dispatch) => {
    dispatch(requestDigitalOrder());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_ORDER", transactionID);
    // if(localData != null && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultDigitalOrder(localData));
    // }

    return Axios.get("/me/digitalorder/" + transactionID)
      .then((response) => response.data)
      .then((data) => {
        let responData = data.data;

        if (
          responData != null &&
          responData["Note"] != null &&
          responData["ProductCode"] === "PLNPREPAID" &&
          responData["ProductCategory"] === "PLN Prabayar"
        ) {
          responData["Parse"] = parseInquiryPlnPrepaid(responData["Note"]);
          responData["ParseData"] =
            responData["Parse"] != null && responData["Parse"]["data"] != null
              ? responData["Parse"]["data"]
              : responData["Parse"]["data"];
        }

        if (
          responData != null &&
          responData["Note"] != null &&
          responData["ProductCode"] === "PLN" &&
          responData["ProductCategory"] === "PLN Pascabayar"
        ) {
          responData["Parse"] = parseInquiryPlnPostpaid(responData["Note"]);
          responData["ParseData"] =
            responData["Parse"] != null && responData["Parse"]["data"] != null
              ? responData["Parse"]["data"]
              : responData["Parse"]["data"];
        }
        // respon atau data dari server ada di variable data
        // console.log(data);
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedDigitalOrder
        return dispatch(resultDigitalOrder(responData));
      })
      .catch((error) => {
        return dispatch(handleError(error));
      });
  };
};

// Request mart order
export const fetchMartTotalOrder = ({
  search,
  operand,
  items,
  message,
  queryString,
}) => {
  return (dispatch) => {
    search = typeof search !== "undefined" ? search : "";
    queryString = setFieldSearchQuery(search, "", searchFieldType);

    return Axios.get("/me/digitalordercount?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        // ketika sudah berhasil mendapat data kita memberi tahu aplikasi data sudah selesai di load / sudah mendapat respon data dari API        // memanggil action creator fetchedDigitalOrder
        return dispatch(
          resultMartTotalOrder({ data: items, count: data.data.count })
        );
      })
      .catch((error) => console.log("error"));
  };
};

// get order status scheme
export const fetchDigitalOrderStatus = () => {
  return (dispatch) => {
    dispatch(requestDigitalOrderStatus());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_ORDER_STATUS");
    // if(localData.length > 0) {
    //     return dispatch(resultDigitalOrderStatus(localData));
    // }

    return Axios.get("/customdata/1/orderstatusscheme")
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultDigitalOrderStatus(data.data.OrderStatus));
      })
      .catch((error) => console.log("error"));
  };
};

// cancel order
export const confirmDigitalOrder = (id) => {
  return (dispatch) => {
    if (typeof id === "undefined" || isNaN(parseInt(id))) {
      return dispatch(displayMessage("ERROR"));
    }

    dispatch(confirmingDigitalOrder());

    return Axios.put("/me/confirmdigitalorder/" + id, {})
      .then((response) => response.data)
      .then((data) => {
        return dispatch(confirmedDigitalOrder(data.data.OrderStatus));
      })
      .catch((error) => console.log("error"));
  };
};

export const cancelDigitalOrder = (id) => {
  return (dispatch) => {
    if (typeof id === "undefined" || isNaN(parseInt(id))) {
      return dispatch(displayMessage("ERROR"));
    }

    dispatch(cancelingDigitalOrder());

    return Axios.put("/me/canceldigitalorder/" + id, {})
      .then((response) => response.data)
      .then((data) => {
        return dispatch(canceledDigitalOrder(data.data.OrderStatus));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
export const uploadBuktiBayar = (File, FileName, Order) => {
  return (dispatch) => {
    dispatch(uploadingDigitalOrderBuktiBayar());

    return Axios.get("/tools/s3/upload", {
      headers: {
        Path:
          "agrego/asset/" + localStorage.getItem("AGREGO_USER_OID") + "/image",
        ContentType: "image",
        FileName:
          typeof FileName !== "undefined" && FileName !== "" && FileName != null
            ? FileName
            : "",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;

        let form = new FormData();
        for (var key in responseData) {
          form.set(key, responseData[key]);
        }

        let imageUrl = responseData.endpoint + responseData.key;

        form.append("file", File, responseData["x-amz-meta-uuid"]);

        // // respon atau data dari server ada di variable data
        // let message = responseData.message;

        // // Order gagal saat wallet tidak cukup
        // if(parseInt(responseData.Status) === 7) {
        //     message = responseData.Note;
        //     return dispatch(resultDigitalOrderSubmit(responseData, true, message));
        //     // return dispatch(displayMessage(message, true));
        // }

        // // remove item from cart
        // dispatch(removeCart());
        // return dispatch(resultDigitalOrderSubmit(responseData));
        return dispatch(uploadToBucket(form, imageUrl, Order));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
const uploadToBucket = (form, imageUrl, Order) => {
  return (dispatch) => {
    // dispatch(requestUploadBuktiBayar());

    return Axios.post("https://agrego.s3.eu-central-1.wasabisys.com", form, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Request-Headers": "origin",
        "Access-Control-Request-Method": "POST",
      },
    })
      .then((resp) => {
        if (resp.status === 200 || resp.status === 204) {
          // return dispatch(uploadedDigitalOrderBuktiBayar(imageUrl));
          return dispatch(
            submitPaymentConfirmation({
              Image: imageUrl,
              OrderID: Order.ID,
              Type: Order.Type,
            })
          );
        }
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart category from API
export const submitDigitalOrder = (data) => {
  return (dispatch) => {
    dispatch(requestDigitalOrderSubmit());

    let data = setDataSubmitOrderDigital();

    return Axios.post("/me/digitalorder", data)
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;

        // respon atau data dari server ada di variable data
        // let message = responseData.message;
        let message = data.message;

        // Order gagal saat wallet tidak cukup
        if (data.status === false) {
          message =
            message === "Insufficient stock"
              ? "Order Gagal. Stok tidak mencukupi, periksa kembali barang belanja anda"
              : message;
          dispatch(displayMessage(message, true));
          return dispatch(resultDigitalOrderSubmit({}));
        } else if (parseInt(responseData.OrderStatus) === 9) {
          message = responseData.Note;
          return dispatch(resultDigitalOrderSubmit(responseData));
        }

        // remove item from cart digital
        dispatch(removeCartDigital());
        return dispatch(resultDigitalOrderSubmit(responseData));
      })
      .catch((error) => {
        // message = responseData.Note;
        dispatch(resultDigitalOrderSubmit());
        return dispatch(displayMessage("Terjadi kesalahan", true, false));
      });
  };
};

// Request mart category from API
export const inquiryDigitalOrder = (input) => {
  return (dispatch) => {
    dispatch(requestDigitalOrderInquiry());

    input = setDataSubmitOrderDigital(input);

    return Axios.post("/me/digitalorder", input)
      .then((response) => response.data)
      .then((data) => {
        let responData = data != null ? data.data : null;

        if (!data.status) {
          dispatch(resultDigitalOrderSubmit());
          return dispatch(
            displayMessage("Maaf Terjadi kesalahan", true, false)
          );
        }

        // console.log(data);
        return dispatch(resultDigitalOrderInquiry(responData));
      })
      .catch((error) => {
        dispatch(resultDigitalOrderSubmit());
        return dispatch(displayMessage("Maaf Terjadi kesalahan", true, false));
      });
  };
};

// Request mart category from API
export const submitPaymentConfirmation = ({ Image, OrderID, Type }) => {
  return (dispatch) => {
    return Axios.post("/me/paymentconfirmation", {
      OID: localStorage.getItem("AGREGO_USER_OID"),
      TypeID: OrderID,
      Img: Image,
      Type: typeof Type !== "undefined" && !isNaN(parseInt(Type)) ? Type : 1,
    })
      .then((response) => response.data)
      .then((data) => {
        let responseData = data.data;
        return dispatch(uploadedDigitalOrderBuktiBayar(responseData));
      })
      .catch((error) => console.log("error"));
  };
};
