import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'; 
import { displayMessage } from './../../redux/actions/app';
import { useDispatch } from 'react-redux';

const handleCopy = ({value}) => {  
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el); 
} 

function CopyButton(props) {
    const {children} = props;
    const [btnText,setBtnText] = useState(children); 
    let dispatch = useDispatch()
    
    return (<Button onClick={(e) => { 
        setBtnText("Tersalin");
        dispatch(displayMessage("teks berhasil di salin"));
        setTimeout(() => setBtnText("Salin"),1000);
        return handleCopy({value: props.copyText});
    }} {...props}> {btnText} </Button>);
}

CopyButton.propTypes = {
    copyText: PropTypes.string
}
 
CopyButton.defaultProps = {
    copyText: null
}

export default CopyButton;