import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Container, Card, Accordion, Table, useAccordionToggle } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';

const styles = {
    root: {
        
        background: '#fff'
    },
    pageIitle: {
        color: '#4F4F4F',
        fontSize: '20px',
        marginBottom: '50px',
        fontWeight: 'bold'
    },
    card: {
        // color:'#5A5A5A',
        // fontWeight: 'bold',
        border: 'none',
    },
    cardHeader: {
        color: '#5A5A5A',
        fontWeight: 'bold',
        fontSize: '20px',
        background: 'none',
        border: 'none',
        paddingLeft: '0',
        paddingRight: '0',
    },
    cardBody: {
        paddingLeft: '0',
        paddingRight: '0'
    },
    subTitle: {
        color: '#5A5A5A',
        fontWeight: 'bold',
        fontSize: '20px'
    }
}

const CustomToggle = ({ children, eventKey, callback  }) => {  
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        // setActive(!active)
        callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div
            onClick={decoratedOnClick}
        >
            {isCurrentEventKey && <div className="float-right"><FontAwesomeIcon icon={faAngleDown} /></div>}
            {!isCurrentEventKey && <div className="float-right"><FontAwesomeIcon icon={faAngleRight} /></div>} 
             
            { children }
        </div >
    );
}

function CourierTerm() {

    return <Container>
        <div style={styles.root} className="term-wrapper">
            <div style={styles.pageIitle}>Ketentuan Layanan Pengguna</div>

            <Accordion>
                <Card style={styles.card}>
                    <Card.Header style={styles.cardHeader}>
                        <CustomToggle eventKey="0">Pemesanan</CustomToggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={styles.cardBody}>
                            Konfirmasi pesanan oleh Toko: Maks. 24 jam <br />
                    Pesanan yang belum dikonfirmasi dalam 24 jam akan otomatis dibatalkan.
                </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card style={styles.card}>
                    <Card.Header style={styles.cardHeader}>
                        <CustomToggle eventKey="1">
                            Delivery
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body style={{ paddingRight: '0' }}>

                            <Accordion>
                                <Card style={styles.card}>
                                    <Card.Header style={styles.cardHeader}>
                                        <CustomToggle eventKey="5">
                                            Durasi Pengiriman Barang
                                </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body>
                                            <Table responsive>
                                                <thead style={{ backgroundColor: '#F2F2F2' }}>
                                                    <tr>
                                                        <th>Jenis Layanan</th>
                                                        <th>Batas Waktu Pengajuan Barang</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Instant</td>
                                                        <td>Mengikuti ketentuan GoSend atau GrabExpress*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Same Day</td>
                                                        <td>Mengikuti ketentuan GoSend atau GrabExpress*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reguler</td>
                                                        <td>Maks. 2-3 hari setelah Pengambilan Barang, sesuai dengan Layanan yang dipilih</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card style={styles.card}>
                                    <Card.Header style={styles.cardHeader}>
                                        <CustomToggle eventKey="6">
                                            Ketentuan Layanan GoSend
                                </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="6">
                                        <Card.Body>
                                            <Table responsive>
                                                <thead style={{ backgroundColor: '#F2F2F2' }}>
                                                    <tr>
                                                        <th></th>
                                                        <th>Instant</th>
                                                        <th>Same Day</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Jam Layanan</td>
                                                        <td>24 Jam</td>
                                                        <td>08.00 - 15.00 WIB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Area Pengiriman</td>
                                                        <td>Seluruh Indonesia</td>
                                                        <td>Jabodetabek</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Waktu Pengiriman</td>
                                                        <td>Langsung</td>
                                                        <td>Maks. 4 (empat) jam</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lama Pengiriman</td>
                                                        <td>Maks. 4 (empat) jam</td>
                                                        <td>Maks. 4 (empat) jam setelah pengambilan</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Batasan Jarak</td>
                                                        <td>40 km</td>
                                                        <td>40 km</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Batasan Produk</td>
                                                        <td>Berat: maks. 20 kg <br />
                                                Volume: maks. 70 x 50 x 50 cm <br />
                                                (panjang x lebar x tinggi)
                                            </td>
                                                        <td>Berat: maks. 5 kg <br />
                                                Volume: maks. 40 x 40 x 17 cm <br />
                                                (panjang x lebar x tinggi)
                                            </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card style={styles.card}>
                                    <Card.Header style={styles.cardHeader}>
                                        <CustomToggle eventKey="7">
                                            Ketentuan Layanan GrabExpress
                                        </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="7">
                                        <Card.Body>
                                            <Table responsive>
                                                <thead style={{ backgroundColor: '#F2F2F2' }}>
                                                    <tr>
                                                        <th></th>
                                                        <th>Instant</th>
                                                        <th>Same Day</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Jam Layanan</td>
                                                        <td>09:00 – 16:00 WIB</td>
                                                        <td>09:00 – 16:00 WIB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Area Pengiriman</td>
                                                        <td>Jabodetabek</td>
                                                        <td>Jabodetabek</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Waktu Pengiriman</td>
                                                        <td>Langsung</td>
                                                        <td>Maks. 4 (empat) jam</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lama Pengiriman</td>
                                                        <td>Maks. 4 (empat) jam</td>
                                                        <td>Maks. 4 (empat) jam setelah pengambilan</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Batasan Jarak</td>
                                                        <td>30 km</td>
                                                        <td>40 km</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Batasan Produk</td>
                                                        <td>Berat: maks. 7 kg
                                                Volume: maks. 40 x 40 x 20 cm <br />
                                                (panjang x lebar x tinggi)
                                            </td>
                                                        <td>Berat: maks. 7 kg<br />
                                                Volume: maks. 40 x 40 x 20 cm <br />
                                                (panjang x lebar x tinggi)
                                            </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card style={styles.card}>
                    <Card.Header style={styles.cardHeader}>
                        <CustomToggle eventKey="3"> 
                            Pembayaran
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body style={styles.cardBody}>
                            Pembeli mengunggah (upload) Bukti Pembayaran. <br />
                    Verifikasi pembayaran di : <br />
                            <ul>
                                <li><span className="font-weight-bold">Jam Operasional</span> (Senin – Sabtu, Jam 9:00 – 18:00 ): Maks. 3 (tiga) jam setelah pembayaran dilakukan</li>
                                <li><span className="font-weight-bold">Luar Jam Operasional</span> : Hari operasional berikutnya</li>
                            </ul>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card style={styles.card}>
                    <Card.Header style={styles.cardHeader}>
                        <CustomToggle eventKey="4"> 
                            Customer Care
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body style={styles.cardBody}>
                            <p>
                                <b>Customer Care: </b><br />
                        WA: <a href="https://wa.me/6281389081904" target="_blank" rel="noopener noreferrer">+62 813-8908-1904</a> <br />
                        Email: <a href="mailto:contact@agrego.id">contact@agrego.id</a> <br />
                            </p>
                            <p>
                                <b>Jam Operasional:</b><br />
                        Senin – Sabtu : Jam 9:00 – 18:00
                    </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>
        </div>
    </Container>
}

export default CourierTerm;