import Axios from "axios";
import { getLocalData, setQueryString } from "./../../utils/common";
import { handleError } from "./app";

export const REQUEST_MARTDELIVERY_ADDRESSLIST =
  "REQUEST_MARTDELIVERY_ADDRESSLIST";
export const REQUEST_MARTDELIVERY_ADDRESS = "REQUEST_MARTDELIVERY_ADDRESS";
export const REQUEST_MARTDELIVERY_OPTION = "REQUEST_MARTDELIVERY_OPTION";
export const SAVING_MARTDELIVERY_ADDRESS = "SAVING_MARTDELIVERY_ADDRESS";
export const DELETING_MARTDELIVERY_ADDRESS = "DELETING_MARTDELIVERY_ADDRESS";
export const RESULT_MARTDELIVERY_ADDRESSLIST =
  "RESULT_MARTDELIVERY_ADDRESSLIST";
export const RESULT_MARTDELIVERY_ADDRESS = "RESULT_MARTDELIVERY_ADDRESS";
export const RESULT_MARTDELIVERY_OPTION = "RESULT_MARTDELIVERY_OPTION";
export const SAVED_MARTDELIVERY_ADDRESS = "SAVED_MARTDELIVERY_ADDRESS";
export const DELETED_MARTDELIVERY_ADDRESS = "DELETED_MARTDELIVERY_ADDRESS";

const searchFieldType = { ID: "int" };

export const requestMartDeliveryAddressList = () => ({
  type: REQUEST_MARTDELIVERY_ADDRESSLIST,
});

export const requestMartDeliveryAddress = () => ({
  type: REQUEST_MARTDELIVERY_ADDRESS,
});

export const requestMartDeliveryOption = () => ({
  type: REQUEST_MARTDELIVERY_OPTION,
});

export const savingMartDeliveryAddress = () => ({
  type: SAVING_MARTDELIVERY_ADDRESS,
});

export const deletingMartDeliveryAddress = () => ({
  type: DELETING_MARTDELIVERY_ADDRESS,
});

export const resultMartDeliveryAddressList = ({ data, total }) => ({
  type: RESULT_MARTDELIVERY_ADDRESSLIST,
  payload: {
    data,
    total: total ? total : null,
  },
});

export const resultMartDeliveryAddress = (data) => ({
  type: RESULT_MARTDELIVERY_ADDRESS,
  payload: {
    data,
  },
});

export const resultMartDeliveryOption = (data) => ({
  type: RESULT_MARTDELIVERY_OPTION,
  payload: {
    data,
  },
});

export const savedMartDeliveryAddress = (data) => ({
  type: SAVED_MARTDELIVERY_ADDRESS,
  payload: {
    data,
  },
});

export const deletedMartDeliveryAddress = (data) => ({
  type: DELETED_MARTDELIVERY_ADDRESS,
  payload: {
    data,
  },
});

// Request mart address from API
export const fetchMartDeliveryAddress = (props, withTotal) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartDeliveryAddressList());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_ADDRESS");
    // if(localData.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartDeliveryAddressList(localData));
    // }

    return Axios.get("/me/delivery?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        if (withTotal && withTotal === true) {
          return dispatch(
            fetchMartDeliveryAddressCount({
              result: data.data,
              query: queryString,
              ...props,
            })
          );
        }

        return dispatch(resultMartDeliveryAddressList({ data: data.data }));
      })
      .catch((error) => {
        dispatch(resultMartDeliveryAddressList({ data: null }));
        return dispatch(handleError(error));
      });
  };
};

// Request mart address count
export const fetchMartDeliveryAddressCount = ({ result, query, ...props }) => {
  return (dispatch) => {
    dispatch(requestMartDeliveryAddressList());

    return Axios.get("/me/deliverycount")
      .then((response) => response.data)
      .then((data) => {
        return dispatch(
          resultMartDeliveryAddressList({ data: result, total: data.data })
        );
      })
      .catch((error) => {
        dispatch(resultMartDeliveryAddressList({ data: result, total: null }));
        return dispatch(handleError(error));
      });
  };
};

// Request mart address from API
export const fetchMartDeliveryAddressById = (id) => {
  return (dispatch) => {
    dispatch(requestMartDeliveryAddress());

    // LOCAL DATA
    // let localData = getLocalData("AGREGO_USER_ADDRESS",id);
    // if(localData != null && process.env.REACT_APP_LOCAL_DATA === "true") {
    //     return dispatch(resultMartDeliveryAddress(localData));
    // }

    return Axios.get("/me/delivery/" + id)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartDeliveryAddress(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart address from API
export const saveMartDeliveryAddress = (form, id) => {
  return (dispatch) => {
    dispatch(savingMartDeliveryAddress());

    let method = "POST";
    form.UserID = localStorage.getItem("AGREGO_USER_ID");

    if (typeof id !== "undefined" && !isNaN(parseInt(id))) {
      method = "PUT";
      form.ID = id;
    }

    return Axios({
      method: method,
      url: "/me/delivery",
      data: form,
    })
      .then((response) => response.data)
      .then((data) => {
        return dispatch(savedMartDeliveryAddress(data.data));
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart address from API
export const deleteMartDeliveryAddress = (id) => {
  return (dispatch) => {
    // cek id
    if (typeof id === "undefined" || isNaN(parseInt(id))) {
      console.log("Error");
      return dispatch(deletedMartDeliveryAddress(false));
    }

    dispatch(deletingMartDeliveryAddress());

    return Axios.delete("/me/delivery/" + id)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(deletedMartDeliveryAddress());
      })
      .catch((error) => console.log("error"));
  };
};

// Request mart address from API
export const fetchMartDeliveryOption = (props) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  let queryString = setQueryString(props);

  return (dispatch) => {
    dispatch(requestMartDeliveryOption());

    // LOCAL DATA
    let localData = getLocalData("AGREGO_DELIVERY_OPTION");
    if (localData.length > 0 && process.env.REACT_APP_LOCAL_DATA === "true") {
      return dispatch(resultMartDeliveryOption(localData));
    }

    return Axios.get("/me/deliveryoption?" + queryString)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultMartDeliveryOption(data.data));
      })
      .catch((error) => console.log("error"));
  };
};
