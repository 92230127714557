import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import iclight from "./../../../Images/ic-light.png";
import { parseCurrency, parseDateTime } from "./../../../utils/common";
import Breadcrumb from "./../../Layout/Breadcrumb";
import CopyButton from "./../../Layout/CopyButton";
import Loading from "./../../Layout/Loading";
import "./Order.css";

const styles = {
  termInfoBox: {
    clear: "both",
    maxWidth: "362px",
    background: "rgba(255, 248, 189, 0.83)",
    borderRadius: "8px",
    // opacity:'0.83',
    margin: "24px auto",
    padding: "8px",
    display: "flex",
    flexDirection: "row",
  },
};

function OrderPayment(props) {
  const { isLoading, order, breadcrumb, includeComponent } = props;
  const uniqueNum =
    order != null && !isNaN(parseInt(order.UniqueNum))
      ? parseInt(order.UniqueNum)
      : 0;
  const totalPrice =
    order != null ? parseInt(order.TotalTransaction) + parseInt(uniqueNum) : 0;
  const totalBelanja = order != null ? parseInt(order.TotalPrice) : 0;
  const lastTotal = order != null ? (totalPrice + "").substr(-3, 3) : "";
  const firstTotal =
    order != null
      ? (totalPrice + "").substr(0, (totalPrice + "").length - 3)
      : 0;

  useEffect(() => {
    ReactGA.set({ title: "payment_detail" });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const layout = {
    span: typeof includeComponent !== "undefined" ? 12 : 10,
    offset: typeof includeComponent !== "undefined" ? 0 : 1,
  };

  return (
    <Container>
      {isLoading && <Loading />}

      <div
        style={{
          marginTop: typeof includeComponent !== "undefined" ? "0px" : "40px",
        }}
      >
        {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
      </div>

      <Row>
        <Col md={layout}>
          {order != null && (
            <div
              className="order-payment-card"
              style={{
                maxWidth: "657px",
                margin: "auto",
                padding: "32px 48px",
              }}
            >
              <div className="section-title-detail">Detail Pembayaran</div>
              <div className="info-detail-pembayaran">
                <Row>
                  <Col>Total Belanja</Col>
                  <Col className="item-price">
                    Rp {parseCurrency(totalBelanja)}
                  </Col>
                </Row>
                <Row>
                  <Col>Biaya Kirim</Col>
                  <Col className="item-price">
                    Rp {parseCurrency(order.PostalFee)}
                  </Col>
                </Row>
                <Row>
                  <Col>Kode Unik</Col>
                  <Col className="item-price">{uniqueNum}</Col>
                </Row>

                <div
                  style={{
                    margin: "16px 0",
                    borderBottom: "1px solid #ccc",
                    height: "1px",
                  }}
                >
                  &nbsp;
                </div>

                <Row>
                  <Col className="font-weight-bold">Total Bayar</Col>
                  <Col className="item-price">
                    Rp {parseCurrency(totalPrice)}
                  </Col>
                </Row>
              </div>

              {/* <Row>
                                <Col>Nomor Pesanan</Col>
                                <Col className="order-number">#{order.ID}</Col>
                            </Row> */}

              <div className="section-title">Transfer Sejumlah</div>
              <div
                className="text-center"
                style={{
                  position: "relative",
                  margin: "10px auto",
                  maxWidth: "345px",
                }}
              >
                <span className="order-price">
                  Rp {parseCurrency(firstTotal)}.
                  <span className="text-danger">{lastTotal}</span>
                </span>
                {/* <span className="order-price">Rp {parseCurrency(totalPrice)}</span> */}
                <span>
                  <CopyButton
                    style={{ position: "absolute", right: 0, top: 0 }}
                    variant="outline-success"
                    size="sm"
                    copyText={parseInt(totalPrice)}
                  >
                    Salin
                  </CopyButton>
                </span>
              </div>
              <div className="info-transfer">
                <span className="text-danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Penting!
                </span>{" "}
                Mohon transfer tepat sampai 3 angka terakhir
              </div>

              {/* <div className="line">&nbsp;</div>

                            <div className="section-title">Sebelum tanggal</div>
                            <div className="transfer-deadline">{parseDateTime(order.TransferDeadline)} WIB</div> */}

              <div className="line">&nbsp;</div>

              {order.PaymentType === "QRIS" ? (
                <div>
                  <div className="qrisImg">
                    <img
                      src={order.QRISPaymentImg}
                      width="90%"
                      alt="img-qris"
                    ></img>
                  </div>
                  <div className="qrisNoteBold">
                    Scan QR Code diatas dengan Dompet Digital Anda
                  </div>
                  <div className="qrisNote">
                    GoPay, OVO, LinkAja, Dana, dan Dompet Digital lainnya
                  </div>
                </div>
              ) : (
                <>
                {process.env.REACT_APP_SHOW_BANK_ACCOUNT_NUMBER ?
                    <CustomBankAccount order={order} /> :
                        <BankAccount order={order} /> }
                </>
              )}
              <div
                style={{
                  paddingTop: "15px",
                  maxWidth: "355px",
                  margin: "auto",
                }}
                className="text-center"
              >
                {" "}
                Transfer sebelum{" "}
                <span style={{ fontWeight: "bold" }}>
                  {parseDateTime(order.TransferDeadline)} WIB
                </span>{" "}
                atau transaksi Anda dibatalkan oleh sistem
              </div>

              {order != null &&
                parseInt(order.PaymentStatus) === 0 &&
                ["bank", "qris"].includes(order.PaymentType.toLowerCase()) && (
                  <div style={{ marginTop: "55px" }} className="text-center">
                    {/* {order != null && ['bank','qris'].includes(order.PaymentType.toLowerCase()) && <div style={{marginTop:'55px'}} className="text-center">  */}
                    <Button
                      variant="link"
                      onClick={(e) =>
                        props.onOpenModal(true, {
                          type: "UPLOAD_BUKTI_BAYAR",
                          id: order.ID,
                        })
                      }
                    >
                      {" "}
                      Upload Bukti Pembayaran
                    </Button>
                  </div>
                )}

              {order != null && (
                <div style={styles.termInfoBox}>
                  <div style={{ minWidth: "52px", textAlign: "center" }}>
                    <img
                      src={iclight}
                      width={14}
                      style={{ marginTop: "20px" }}
                      alt="icon-light"
                    />
                  </div>
                  <div style={{ flex: "auto", color: "#5A5A5A" }}>
                    <div>
                      Pembayaran akan diverifikasi maksimal 3 jam apabila
                      dilakukan pada jam operasional (Senin – Sabtu, Jam 9:00 –
                      18:00)
                    </div>
                    <div>
                      <a
                        href="/terms"
                        target="_blank"
                        style={{ color: "#3476DA", fontWeight: "bold" }}
                      >
                        Lihat Ketentuan Layanan Pengguna
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {typeof includeComponent === "undefined" && (
                <div className="text-center">
                  <Button
                    style={{ maxWidth: "311px" }}
                    variant="primary"
                    className="btn-mart"
                    href="/shop/order"
                  >
                    Selesai
                  </Button>
                </div>
              )}
              {order != null &&
                parseInt(order.Status) <= 1 &&
                parseInt(order.PaymentStatus) === 0 &&
                ["bank", "qris"].includes(order.PaymentType.toLowerCase()) && (
                  <div style={{ marginTop: "10px" }} className="text-center">
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={(e) =>
                        props.onOpenModal(true, {
                          type: "CANCEL_ORDER",
                          id: order.ID,
                        })
                      }
                    >
                      {" "}
                      Batalkan Pesanan
                    </Button>
                  </div>
                )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

const BankAccount = ({order}) => {
  return (<div>
    <div
        className="section-title"
        style={{ paddingBottom: "16px" }}
    >
      Transfer ke
    </div>
    <div className="text-center">
      <img
          src={order.BankImage}
          height={64}
          alt={"image " + order.BankName}
      />
      <div
          className="account-number"
          style={{
            position: "relative",
            margin: "8px auto",
            maxWidth: "345px",
          }}
      >
        {order.BankAccountNumber}
        <span>
                        <CopyButton
                            style={{
                              position: "absolute",
                              right: 0,
                              top: "-12px",
                            }}
                            variant="outline-success"
                            size="sm"
                            copyText={order.BankAccountNumber}
                        >
                          Salin
                        </CopyButton>
                      </span>
      </div>
      <div
          className="account-name"
          style={{ maxWidth: "300px", margin: "auto" }}
      >
        (a/n {order.BankAccountName} - {order.BankAccountBranch})
      </div>
    </div>
  </div>)
}

const CustomBankAccount = ({order}) => {

    order = {
        ...order, ...{
            BankAccountNumber: process.env.REACT_APP_SHOW_BANK_ACCOUNT_NUMBER,
            BankAccountName: process.env.REACT_APP_SHOW_BANK_ACCOUNT_NAME,
            BankAccountBranch: process.env.REACT_APP_SHOW_BANK_ACCOUNT_BRANCH,
            BankName: process.env.REACT_APP_SHOW_BANK_NAME,
            BankImage: process.env.REACT_APP_SHOW_BANK_IMAGE,
        }
    }

  return (<div>
    <div
        className="section-title"
        style={{ paddingBottom: "16px" }}
    >
      Transfer ke
    </div>
    <div className="text-center">
      <img
          src={order.BankImage}
          height={64}
          alt={"image " + order.BankName}
      />
      <div
          className="account-number"
          style={{
            position: "relative",
            margin: "8px auto",
            maxWidth: "345px",
          }}
      >
        {order.BankAccountNumber}
        <span>
                        <CopyButton
                            style={{
                              position: "absolute",
                              right: 0,
                              top: "-12px",
                            }}
                            variant="outline-success"
                            size="sm"
                            copyText={order.BankAccountNumber}
                        >
                          Salin
                        </CopyButton>
                      </span>
      </div>
      <div
          className="account-name"
          style={{ maxWidth: "300px", margin: "auto" }}
      >
        (a/n {order.BankAccountName} - {order.BankAccountBranch})
      </div>
    </div>
  </div>)
}

export default OrderPayment;
