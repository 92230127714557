import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import Pagination from "react-js-pagination";
import Breadcrumb from "../Layout/Breadcrumb";
import { parseDate } from "./../../utils/common";
import BottomNav from "./../Layout/BottomNav";
import EmptyState from "./../Layout/EmptyState";
import Loading from "./../Layout/Loading";
import "./Promo.css";

export default function Promo(props) {
  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [],
      "promo_list"
    );
  }, []);

  // const limit_judul = function (str, length, ending) {
  //   if (length == null) {
  //     length = 30;
  //   }
  //   if (ending == null) {
  //     ending = '...';
  //   }
  //   if (str.length > length) {
  //     return str.substring(0, length - ending.length) + ending;
  //   } else {
  //     return str;
  //   }
  // };

  // const limit_desk = function (str, length, ending) {
  //   if (length == null) {
  //     length = 65;
  //   }
  //   if (ending == null) {
  //     ending = '...';
  //   }
  //   if (str.length > length) {
  //     return str.substring(0, length - ending.length) + ending;
  //   } else {
  //     return str;
  //   }
  // };

  const { isLoading, promo, itemPerPage, activePage, totalPromo, breadcrumb } =
    props;

  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Container>
      {/* Loading State */}
      {isLoading && <Loading />}

      {!isLoading && (
        <div style={{ marginTop: "20px", paddingBottom: "0px" }}>
          {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
        </div>
      )}

      {/* Sekarang tab di hilangkan */}
      {/* {!isLoading &&
        <ul className="custom-tab promo-tab">
          <li><a href="/berita">Berita</a></li>
          <li className="active"><a href="/promo">Promosi</a></li>

        </ul>
      } */}

      {/* Tampilan mobile */}
      <div className="d-block d-sm-none">
        {/* Empty State */}
        {!isLoading && promo.length <= 0 && (
          <EmptyState teks="Belum ada data" className="emptyState" />
        )}
        {promo.length > 0 &&
          !isLoading &&
          promo.map((item) => (
            <div className="listPromoTest">
              <Row>
                <Col xs={4}>
                  <a href={"/promo/" + item.ID + "/detail"}>
                    <div
                      className={`${isLoaded ? "d-none" : ""}`}
                      style={{
                        width: "127px",
                        height: "94px",
                        background: "#f3f3f3",
                      }}
                      // alt="image default"
                      // src={thumbImg}
                    />

                    <img
                      onLoad={() => {
                        setIsLoaded(true);
                      }}
                      className={`${isLoaded ? "" : "d-none"} imgContent`}
                      src={item.Img}
                      alt="img-promo"
                    ></img>
                  </a>
                </Col>
                <Col xs={8}>
                  <a href={"/promo/" + item.ID + "/detail"}>
                    <div className="cardpromojudul" alt="img-promotion">
                      {item.Title}
                    </div>{" "}
                  </a>
                  <div className="cardpromodetail">{item.ShortDesc}</div>
                </Col>
              </Row>
            </div>
          ))}
        {!isLoading && parseInt(totalPromo) > parseInt(itemPerPage) && (
          <Row
            className="justify-content-center"
            style={{ margin: "10px 0px" }}
          >
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalPromo}
              pageRangeDisplayed={3}
              onChange={props.onChangePage}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Row>
        )}
      </div>

      {/* Tampilan web */}
      <div className="d-none d-sm-block">
        {!isLoading && (
          <Row className="contentContainer">
            {/* Empty State */}
            {!isLoading && promo.length <= 0 && (
              <EmptyState teks="Belum ada data" className="emptyState" />
            )}

            {promo.length > 0 &&
              !isLoading &&
              promo.map((item) => (
                <Col md={4} xs={12}>
                  <div className="listPromoTest">
                    <div className="imgContent">
                      <div
                        className={`${isLoaded ? "d-none" : ""}`}
                        style={{
                          width: "100%",
                          height: "210px",
                          background: "#f3f3f3",
                        }}
                        // alt="image default"
                        // src={thumbImg}
                      />

                      <img
                        onLoad={() => {
                          setIsLoaded(true);
                        }}
                        className={`${isLoaded ? "" : "d-none"}`}
                        src={item.Img}
                        width="100%"
                        alt="img-promotion"
                      />
                    </div>
                    <div className="cardpromojudul">{item.Title}</div>
                    <div className="cardpromodetail">{item.ShortDesc}</div>
                    <div className="periodepromo">
                      {parseDate(item.StartDate)} - {parseDate(item.DueDate)}{" "}
                    </div>
                    <Button
                      variant="primary"
                      className="cardpromobutton"
                      href={"/promo/" + item.ID + "/detail"}
                      block
                    >
                      Lihat Promo
                    </Button>
                  </div>
                </Col>
              ))}
          </Row>
        )}
        {!isLoading && parseInt(totalPromo) > parseInt(itemPerPage) && (
          <Row
            className="justify-content-center"
            style={{ margin: "10px 0px" }}
          >
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalPromo}
              pageRangeDisplayed={3}
              onChange={props.onChangePage}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Row>
        )}
      </div>

      {/* Bottom Nav */}
      <BottomNav activeKey={4} />
    </Container>
  );
}
