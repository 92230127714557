import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './OrderDigital.css'; 
import Breadcrumb from '../../Layout/Breadcrumb';
import Loading from './../../Layout/Loading';
import EmptyState from './../../Layout/EmptyState';
import { parseCurrency } from './../../../utils/common'; 
import {isCompletedParamOrderDigital} from './../../../utils/cart'; 

export default function DigitalConfirmationPPOB(props) {
  const { breadcrumb, isLoading, digitalOrder, CartDigital, paymentNote } = props;
  let { loadInquiry, successInquiry } = props; 
  let isAllowOrder = false;

  // cek apakah sudah bisa melakukan order
  if(CartDigital != null) {
    isAllowOrder = isCompletedParamOrderDigital(CartDigital);
  }

  // remove this line
  // successInquiry = true; 

  return (
    <Container>

      {!isLoading && loadInquiry && <Row>
        <Col>
          <div style={{ marginTop: '30px', paddingBottom: '0px' }}>
            {breadcrumb != null && <Breadcrumb url={breadcrumb} />}
          </div>

        </Col>
      </Row>}

      {/* Loading data */}
      {(isLoading || !loadInquiry) && <Loading />}

      {/* Loading selesai tapi tidak mendapatkan data / transaksi gagal diproses */}
      {!isLoading && loadInquiry && !successInquiry && <div className="text-center"><EmptyState style={{ margin: 'auto' }} /></div>}
      
      {/* Loading selesai tapi tidak mendapatkan data / transaksi gagal diproses */}
      {!isLoading && loadInquiry && successInquiry && digitalOrder != null && digitalOrder['Parse'] != null && !digitalOrder['Parse']['success'] && <div className="text-center">
        <div>{digitalOrder['Parse']['message']}</div>
        <Button variant="primary" href="/digital/plnprepaid">Kembali</Button>
      </div>}

      {/* Loading selsai dan berhasil mendapatkan data */}
      {!isLoading && digitalOrder != null && CartDigital != null && loadInquiry && successInquiry && digitalOrder['Parse'] != null && digitalOrder['Parse']['success'] && <React.Fragment>

        <Row>
          <div className="ppob-confr-Container">

            <Col md={12}>
              <div className="ppob-rincian-font">Rincian Informasi</div>
            </Col>

            <Col md={12}>

              <Row>

                <Col md={2} xs={6}><div className="ppob-label-font">ID Pelanggan</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font">{digitalOrder['ParseData'] != null && digitalOrder['ParseData']['IDPelanggan'] != null && digitalOrder['ParseData']['IDPelanggan']}</div></Col>
              </Row>
              {/* <Row>

                <Col md={2} xs={6}><div className="ppob-label-font">Jenis Layanan</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font">{digitalOrder['ParseData'] != null && digitalOrder['ParseData']['IDPelanggan'] != null && digitalOrder['ParseData']['IDPelanggan']}</div></Col>
              </Row> */}

              <Row>

                <Col md={2} xs={6}><div className="ppob-label-font">Nama</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font">{digitalOrder['ParseData'] != null && digitalOrder['ParseData']['NamaPelanggan'] != null && digitalOrder['ParseData']['NamaPelanggan']}</div></Col>
              </Row>

              <Row>

                <Col md={2} xs={6}><div className="ppob-label-font">Tarif/Daya</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font">{digitalOrder['ParseData'] != null && digitalOrder['ParseData']['Daya'] != null && digitalOrder['ParseData']['Daya']}</div></Col>
              </Row>

              <Row>

                <Col md={2} xs={6}><div className="ppob-label-font">Harga</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font">{parseCurrency(CartDigital.Total)}</div></Col>
              </Row>

              <div className="ppob-hr"></div>



            </Col>


            <Col md={12}>
              <div className="ppob-rincian-font" style={{ marginTop: '17px' }}>Detail Tagihan</div>
            </Col>

            <Col>
              <Row>

                <Col md={2} xs={6}><div className="ppob-label-font">Token Listrik</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font">{parseCurrency(CartDigital.Price)}</div></Col>
              </Row>

              <Row>

                <Col md={2} xs={6}><div className="ppob-label-font">Biaya Admin</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font">{parseCurrency(CartDigital.Fee)}</div></Col>
              </Row>


              <Row>

                <Col md={2} xs={6}><div className="ppob-label-font font-weight-bold">Total Tagihan</div></Col>
                <Col md={10} xs={6}><div className="ppob-ket-font font-weight-bold">{parseCurrency(CartDigital.Total)}</div></Col>
              </Row>

            </Col>

          </div>


        </Row>

        <div className="PaymentContainer">

          {/* {CartDigital.length > 0 && !isLoading && CartDigital.map(item => (
                         */}


          <Row>

            <Col md={12} xs={12}>

              <div className="RincianPesananJudul">Metode Pembayaran</div>

              <div style={{ marginTop: '0px', marginLeft: '45px' }}>

                {CartDigital != null && typeof (CartDigital.PaymentType) !== "undefined" && CartDigital.PaymentType != null ?
                  <div style={{ marginTop: '20px' }}>
                    {CartDigital.PaymentType === "Bank" ?

                      <Row>
                        <Col md={2} xs={4}><img src={CartDigital.BankImage} width="100%" alt="img-bank" /></Col>
                        <Col md={6} xs={8}><div style={{ marginBottom: '40px' }}>{CartDigital.BankName}</div></Col>
                        <Col md={4} xs={10}><a className="paymenthref" href={"/shop/payment?type=digital"}>Ubah Metode Pembayaran</a></Col>
                      </Row> :

                      <Row>
                        {/* <Col md={1} xs={4} style={{paddingLeft:'4%'}} ><FontAwesomeIcon icon={faWallet} size="2x" /></Col> */}
                        <Col md={8} xs={12}><div style={{ marginBottom: '40px' }}>{CartDigital.PaymentType}&nbsp;({CartDigital.Payment})
                        <div className="payment-note"> {paymentNote} </div>
                        </div>
                        </Col>
                        <Col md={4} xs={12}><div style={{ marginBottom: '40px' }}><a className="paymenthref" href={"/shop/payment?type=digital"}>Ubah Metode Pembayaran</a></div></Col>

                      </Row>}</div> :

                  <div style={{ marginTop: '20px', marginLeft: '0px' }}>

                    <Row>

                      <Col md={8} xs={12}>
                        <div style={{ marginBottom: '40px' }}>Metode pembayaran belum ditentukan</div></Col>
                      <Col md={4} xs={12}><a href={"/shop/payment?type=digital"}>Pilih Metode Pembayaran</a></Col>

                    </Row>
                  </div>
                }

              </div>

            </Col>
          </Row>
        </div>



        {/* TAMPILAN MOBILE */}
        <div className="product-summary-bottom d-sm-block d-md-none">
          <Row>
            <Col md={4}>
              <Button variant="primary" className="Pulsa-btn" disabled={!isAllowOrder} onClick={e => props.onSubmitOrder(e)}> Lanjut</Button>
            </Col>
          </Row>
        </div>

        {/* TAMPILAN WEB */}
        <div className="d-none d-sm-block float-right mb-3">
          <Row>
            <Col md={4}>
              <div className="btnStyle">
                <Button variant="primary" className="Pulsa-btn" disabled={!isAllowOrder} onClick={e => props.onSubmitOrder(e)} >Lanjut</Button>
              </div>
            </Col>
          </Row>
        </div>

        <div className="d-sm-block d-md-none" style={{ height: '80px' }}>
          &nbsp;
        </div>
      </React.Fragment>}
    </Container>
  );
}