import { addToDigital } from './../../utils/cart';

export const ADD_TO_CART_DIGITAL = "ADD_TO_CART_DIGITAL"; 
export const GET_ALL_CART_DIGITAL = "GET_ALL_CART_DIGITAL"; 
export const RESULT_ADD_CART_DIGITAL= "RESULT_ADD_CART_DIGITAL"; 
export const REMOVE_CART_DIGITAL= "REMOVE_CART_DIGITAL"; 

// Request mart product from API
export const getCartDigital = () => ({
    type: GET_ALL_CART_DIGITAL
})


// Request mart product from API
const addingCartDigital = (product) => ({
    type: ADD_TO_CART_DIGITAL,
    payload: {
        product
    }
})

// Request mart product from API
const resultAddCartDigital = () => ({
    type: RESULT_ADD_CART_DIGITAL
})


// Hapus cart
export const removeCartDigital = () => ({
    type: REMOVE_CART_DIGITAL
})


// Request mart product from API
export const addCartDigital = (product) => {
    return dispatch => {  

        product = {
            ...product,
            // reset value if not available
            Name: product != null && product['Name'] != null && product['Name'] !== "" ? product['Name'] : "",
            ProductID: product != null && !isNaN(product['ProductID']) ? product['ProductID'] : "",
            Price: product != null && !isNaN(product['Price']) ? product['Price'] : "",
            Fee: product != null && !isNaN(product['Fee']) ? product['Fee'] : "",
            Total: product != null && !isNaN(product['Total']) ? product['Fee'] : "",
            OrderID: product != null && !isNaN(product['OrderID']) ? product['OrderID'] : "",
            TransactionID: product != null && !isNaN(product['TransactionID']) ? product['TransactionID'] : "",
        } 

        dispatch(addingCartDigital(product));  
        addToDigital(product);  

        return dispatch(resultAddCartDigital()); 
    }
}


