import _ from 'lodash';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addCart, getCart, removeCart, updateDeliveryAddress, updateDeliveryOption } from './../../../redux/actions/mart_cart';
import { deleteMartDeliveryAddress, fetchMartDeliveryAddress, fetchMartDeliveryOption, saveMartDeliveryAddress } from './../../../redux/actions/mart_delivery';
import { fetchMartProduct } from './../../../redux/actions/mart_product';
import { fetchRegArea, fetchRegCity, fetchRegProvince, fetchRegSubDistrict } from './../../../redux/actions/regional';
import { getPaymentNote } from './../../../utils/cart';
import { validateAddress } from './../../../utils/validate';
import Delivery from './Delivery.component';
import ListAddressComponent from './ListAddress.component';
import NewAddressComponent from './NewAddress.component';

class DeliveryContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false, 
            filterData: {}, 
            loadingAddress: false,
            loadingProvince: false,
            loadingCity: false,
            loadingSubDistrict: false,
            loadingArea: false,

            provinces: [],
            cities: [],
            subdistricts: [],
            area: [],

            products: [],
            product: null, 
            cartItems: [],
            cartSummary: null,
            addressList: [],
            selectedAddress: null,
            selectedAddressID: null,

            formAddress:null,
            formAddressID:null,

            address: null,
            breadcrumb: null,
            showModal: false,
            showModalInfo: false,
            modalSection: null, 
            modalConfirmTitle: null,
            modalConfirmBody: null,
            modalConfirmAction: null,
            cartDeliveryAddress: null,
            deliveryOption: null,
            listDeliveryOption: [], 
            paymentNote: null, 
            isProcessAddress: false,
            errorInputAddress: null, 

            setDefaultDeliveryOption: false
        } 

        this.loadAddress = this.loadAddress.bind(this);
        this.handleEditAddress = this.handleEditAddress.bind(this); 
        this.toggleModalAddress = this.toggleModalAddress.bind(this);
        this.toggleModalConfirm = this.toggleModalConfirm.bind(this);
        this.toggleModalInfo = this.toggleModalInfo.bind(this);
        this.handleChangeProvince = this.handleChangeProvince.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this); 
        this.handleChangeSubDistrict = this.handleChangeSubDistrict.bind(this); 
        this.handleChangeArea = this.handleChangeArea.bind(this); 
        this.handleChangeFormAddress = this.handleChangeFormAddress.bind(this);
        this.handleChangeLocationAddress = this.handleChangeLocationAddress.bind(this);
        this.handleChangeDeliveryOption = this.handleChangeDeliveryOption.bind(this);
        this.handleChangeSelectedAddress = this.handleChangeSelectedAddress.bind(this);
    } 

    componentDidMount()  { 

        let {product, items} = this.state; 

        this.props.fetchMartProduct({page:1,size:5}); 
        this.props.fetchMartDeliveryAddress({page:1,size:10}); 
        this.props.fetchRegProvince({page:1,size:100}); 
        this.props.fetchMartDeliveryOption({page:1,size:10}); 
        this.props.getCart(); 

        this.setState({
            isLoading: true, 
            product: product,
            items: items,
        });
    }
 
    componentDidUpdate() { 
        const {isFetchingMartProductList, mart_products} = this.props.martproduct;

        const {isFetchingMartDeliveryAddressList, isFetchingMartDeliveryOption, isSavingMartDeliveryAddress,
            isDeletingMartDeliveryAddress, mart_delivery_addresslist, mart_delivery_option} = this.props.martdelivery; 
 
        const {isFetchingRegProvince, isFetchingRegCity, isFetchingRegSubDistrict, isFetchingRegArea, reg_provinces, reg_cities, reg_subdistricts, reg_area} = this.props.regional; 
        const {isLoading, isProcessAddress, loadingAddress} = this.state;   
        const {carts} = this.props.martcart; 
        const {items, summary} = carts;  
        let {breadcrumb, selectedAddress, selectedAddressID, listDeliveryOption, deliveryOption, formAddress, 
            paymentNote, loadingProvince, loadingCity, loadingSubDistrict, loadingArea, setDefaultDeliveryOption} = this.state; 

        // Selesai Delete data
        if(isProcessAddress && !isDeletingMartDeliveryAddress && !isSavingMartDeliveryAddress) {
            // this.props.fetchMartDeliveryAddress({page:1,size:10});
            // this.toggleModalConfirm(false); 

            this.loadAddress(); 
            this.setState({isProcessAddress: false, modalSection: null, showModal: true, showModalConfirm: false});
        }

        if(loadingAddress && !isFetchingMartDeliveryAddressList) {
            this.setState({loadingAddress: false, addressList: mart_delivery_addresslist})
        } 
        
        // update province setelah selesai load
        if(loadingProvince && !isFetchingRegProvince) {
            this.setState({loadingProvince: false, provinces:reg_provinces })
        }

        // update city setelah selesai load
        if(loadingCity && !isFetchingRegCity) {
            this.setState({loadingCity: false, cities:reg_cities })
        }

        // update province setelah selesai load
        if(loadingSubDistrict && !isFetchingRegSubDistrict) {

            let {loadingArea} = this.state;
            
            if(formAddress != null && typeof(formAddress.ID) !== "undefined" && !isNaN(parseInt(formAddress.ID))) { 
                let selectedSubDistrict = _.find(reg_subdistricts, o => parseInt(o.ID) === parseInt(formAddress.SubDistrictID))

                if(typeof(selectedSubDistrict) !== "undefined") {
                    this.props.fetchRegArea({page:1, size: 100, search: {SubDistrictIDShipper: selectedSubDistrict.IDShipper}})
                    loadingArea = true;
                }
            }

            this.setState({loadingSubDistrict: false, loadingArea, subdistricts:reg_subdistricts }) 
        }

        // update province setelah selesai load
        if(loadingArea && !isFetchingRegArea) {
            this.setState({loadingArea: false, area:reg_area })
        }
        
        if(!isLoading && listDeliveryOption != null && listDeliveryOption.length === 1 && 
            listDeliveryOption[0] != null && listDeliveryOption[0]['Text'] != null && !setDefaultDeliveryOption) {
            this.props.updateDeliveryOption(listDeliveryOption[0]['Text']);   
            this.setState({isLoading: true, setDefaultDeliveryOption: true}); 
        }  

        //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
        if(!isFetchingMartProductList && !isFetchingMartDeliveryAddressList && 
            !isFetchingMartDeliveryOption && !isSavingMartDeliveryAddress && !isFetchingRegProvince && 
            !isFetchingRegCity && !isFetchingRegSubDistrict && isLoading) { 

            let setIsLoading = false;
             
            breadcrumb = [
                // {link:'/beranda', text:'Beranda'}, 
                // {link:'/shop/cart', text: 'Keranjang'},
                {link:'/shop/delivery', text: 'Atur Pesanan'},
            ] 

            let cartDeliveryAddress = summary != null && typeof(summary['DeliveryID']) !== "undefined" ? summary['DeliveryID'] : null; 
            deliveryOption = summary != null && typeof(summary['DeliveryOption']) !== "undefined" ? summary['DeliveryOption'] : null; 

            if(cartDeliveryAddress != null && mart_delivery_addresslist.length > 0) {
                cartDeliveryAddress = _.find(mart_delivery_addresslist, (o) => parseInt(o.ID) === parseInt(cartDeliveryAddress));
            }  

            if(mart_delivery_option != null && typeof(mart_delivery_option[0]) !== "undefined" && 
                typeof(mart_delivery_option[0]['DeliveryOption']) !== "undefined" && listDeliveryOption.length <= 0) { 

                mart_delivery_option[0]['DeliveryOption'].forEach((option,index) => {
                    listDeliveryOption.push({
                        Text: option,
                        Value: mart_delivery_option[0]['DeliveryOpts'][index]
                    });
                }); 
            } 

            paymentNote = getPaymentNote()

            this.setState({
                isLoading: setIsLoading, 
                products: mart_products, 
                listDeliveryOption: listDeliveryOption,
                cartItems: items, 
                cartSummary: summary, 
                addressList: mart_delivery_addresslist, 
                loadingProvince: false,
                loadingCity: false,
                loadingSubDistrict: false,
                provinces: reg_provinces,
                cities: reg_cities,
                subdistricts: reg_subdistricts,
                deliveryOption,
                breadcrumb,
                selectedAddress,
                selectedAddressID,
                cartDeliveryAddress, 
                paymentNote,
                setDefaultDeliveryOption
            })
        } 
    }     
    
    toggleModalAddress(openModal, param) {
        let {modalSection, isLoading, isProcessAddress, selectedAddress, formAddress, errorInputAddress} = this.state;
        let action = typeof(param) !== "undefined" && typeof(param.action) !== "undefined" ? param.action : null;

        let paramSection = typeof(param) !== "undefined" && typeof(param.section) !== "undefined" ? param.section : null;

        if(paramSection !== modalSection && paramSection === "add-address") {
            formAddress = {
                Labels: null,
                Recipient: null,
                Phone: 0,
                Address: null,
                ProvinceID: null,
                CityID: null,
                SubDistrictID: null,
                AreaID: null,
                PostalCode: 0,
                Latitude: "",
                Longitude: "" 
            };
        }

        modalSection = paramSection != null ? paramSection : modalSection; 

        switch(action) {  
            case "SAVE_ADDRESS":
                // save address 
                let isValid = validateAddress(param.form);  
                if(isValid.pass) {
                    this.props.saveMartDeliveryAddress(param.form, param.form.ID);  
                    isProcessAddress=true; 

                    formAddress = {
                        Labels: null,
                        Recipient: null,
                        Phone: 0,
                        Address: null,
                        Province: null,
                        District: null,
                        SubDistrict: null,
                        PostalCode: 0,
                        Latitude: "",
                        Longitude: "" 
                    }
 
                } else {
                    openModal = true;
                    errorInputAddress = isValid.error; 
                }
                break;

            case "SELECT_ADDRESS":
                // select address     
                    this.props.updateDeliveryAddress(selectedAddress) 
                    isLoading = true;
                break;

            default:
                // close modal
                break;
        } 

        this.setState({
            showModal: openModal,
            modalSection,
            selectedAddress,
            formAddress,
            errorInputAddress,
            isProcessAddress,
            isLoading
        })
    }

    toggleModalConfirm(openModal, action, confirm) {

        let  {modalConfirmAction, modalConfirmTitle, modalConfirmBody, isProcessAddress, showModal, modalSection} = this.state;

        if(typeof(action) !== "undefined" && typeof(action.type) !== "undefined") {
            modalConfirmAction = action; 
        }

        if(openModal && showModal) {
            modalConfirmAction = {...modalConfirmAction, showModal, modalSection};
            this.toggleModalAddress(false);
        } else if(!openModal && modalConfirmAction != null && typeof(modalConfirmAction.showModal) !== "undefined") {
            
            modalConfirmTitle = null;
            modalConfirmBody = null;

            this.toggleModalAddress(modalConfirmAction.showModal);
        } 

        if(modalConfirmAction != null && typeof(modalConfirmAction.type) !== "undefined") {
            switch(modalConfirmAction.type) {
                case "DELETE_ADDRESS":
                    modalConfirmTitle = "Konfirmasi hapus";
                    modalConfirmBody = <div>Yakin ingin menghapus alamat ini?</div>; 

                    if(typeof(confirm) !== "undefined" && confirm &&
                        typeof(modalConfirmAction.id) !== "undefined") { 

                        this.props.deleteMartDeliveryAddress(modalConfirmAction.id);
                        isProcessAddress = true;

                        if(typeof(modalConfirmAction.showModal) !== "undefined") {
                            modalConfirmAction = {showModal};
                        } else {
                            modalConfirmAction = null;
                        }
                    }
                break;

                default:
                    console.log("default confirm");
                break;
            }
        }


        this.setState({
            showModalConfirm: openModal,
            modalConfirmTitle,
            modalConfirmBody,
            modalConfirmAction,
            isProcessAddress
        })
    }

    toggleModalInfo() {
        const {showModalInfo} = this.state; 
        this.setState({showModalInfo: !showModalInfo});
    }

    handleEditAddress(addressID) {
        let {formAddress, formAddressID,addressList} = this.state;

        formAddress = _.find(addressList, (o) => parseInt(o.ID) === parseInt(addressID));
        formAddressID = parseInt(addressID); 

        // this.props.fetchRegProvince({page:1, size:100});
        if(formAddress != null && typeof(formAddress['ProvinceID']) !== "undefined" && formAddress['ProvinceID'] != null) { 
            this.props.fetchRegCity({page:1, size:1000, search: {ProvinceID: formAddress['ProvinceID']}});   
        }
        if(formAddress != null && typeof(formAddress['CityID']) !== "undefined" && formAddress['CityID'] != null) {
            this.props.fetchRegSubDistrict({page:1, size:1000, search: {CityID: formAddress['CityID']}}); 
        }
        
        this.setState({ 
            modalSection: 'add-address', 
            formAddress,
            loadingCity: true,
            loadingSubDistrict: true,
            loadingArea: true,
            formAddressID
        })
    } 

    handleChangeFormAddress(e) {
        let {formAddress, errorInputAddress} = this.state;
        
        let {name, value} = e.target;
        formAddress = formAddress != null ? formAddress : {};
        formAddress[name] = value;

        // if(name === "ProvinceID") {
        //     if(typeof(e.target.selectedOptions[0]['text']) !== "undefined") {
        //         formAddress['Province'] = e.target.selectedOptions[0]['text'];
        //     }
        // }

        // if(name === "CityID") {
        //     if(typeof(e.target.selectedOptions[0]['text']) !== "undefined") {
        //         formAddress['City'] = e.target.selectedOptions[0]['text']
        //     }
        // }

        // if(name === "SubDistrictID") {
        //     if(typeof(e.target.selectedOptions[0]['text']) !== "undefined") {
        //         formAddress['SubDistrict'] = e.target.selectedOptions[0]['text']
        //     }
        // } 

        let isValid = validateAddress(formAddress);
        if(!isValid.pass) {
            errorInputAddress = isValid.error;
        } else {
            errorInputAddress = null;
        }

        this.setState({formAddress, errorInputAddress});
    }
    
    handleChangeLocationAddress(name, value) {
        let {formAddress, errorInputAddress} = this.state;
         
        formAddress = formAddress != null ? formAddress : {};
        formAddress[name] = value;

        // if(name === "ProvinceID") {
        //     if(typeof(e.target.selectedOptions[0]['text']) !== "undefined") {
        //         formAddress['Province'] = e.target.selectedOptions[0]['text'];
        //     }
        // }

        // if(name === "CityID") {
        //     if(typeof(e.target.selectedOptions[0]['text']) !== "undefined") {
        //         formAddress['City'] = e.target.selectedOptions[0]['text']
        //     }
        // }

        // if(name === "SubDistrictID") {
        //     if(typeof(e.target.selectedOptions[0]['text']) !== "undefined") {
        //         formAddress['SubDistrict'] = e.target.selectedOptions[0]['text']
        //     }
        // } 

        let isValid = validateAddress(formAddress);
        if(!isValid.pass) {
            errorInputAddress = isValid.error;
        } else {
            errorInputAddress = null;
        }

        this.setState({formAddress, errorInputAddress});
    }

    handleChangeSelectedAddress(address) {  
        let {selectedAddressID, selectedAddress} = this.state;
        selectedAddress = address;
        selectedAddressID = parseInt(address.ID); 

        this.setState({isLoading: true, selectedAddressID, selectedAddress});
    }

    handleChangeDeliveryOption(deliveryOption) {  
        this.props.updateDeliveryOption(deliveryOption);  
        this.setState({isLoading: true});
    }
    handleChangeProvince(e) {   
        const {formAddress} = this.state;
        let ProvinceID = e.target.value;
        formAddress['CityID'] = "";
        formAddress['SubDistrictID'] = "";
        formAddress['AreaID'] = "";
        this.props.fetchRegCity({page:1, size:1000, search: {ProvinceID: ProvinceID}});  
        this.setState({loadingCity: true, subdistricts: [], area: [], formAddress});

        this.handleChangeFormAddress(e);
    }
    handleChangeCity(e) {  
        const {formAddress} = this.state;
        let CityID = e.target.value; 
        formAddress['SubDistrictID'] = "";
        formAddress['AreaID'] = "";
        this.props.fetchRegSubDistrict({page:1, size:1000, search: {CityID: CityID}});  
        this.setState({loadingSubDistrict: true, area: [], formAddress});

        this.handleChangeFormAddress(e);
    }
    handleChangeSubDistrict(e) {  
        const {subdistricts, formAddress} = this.state; 
        formAddress['AreaID'] = "";
        let SubDistrictID = e.target.value; 
        let selectedSubdistrict = _.find(subdistricts, o => parseInt(o.ID) === parseInt(SubDistrictID)); 
        this.props.fetchRegArea({page:1, size:1000, search: {SubDistrictIDShipper: selectedSubdistrict.IDShipper}});  
        this.setState({loadingArea: true, formAddress});

        this.handleChangeFormAddress(e);
    }

    handleChangeArea(e) {  
        const {area, formAddress} = this.state;  
        formAddress['AreaID'] = e.target.value;  
        let selectedArea = _.find(area, o => parseInt(o.ID) === parseInt(formAddress['AreaID'])); 
        formAddress['PostalCode'] = selectedArea['PostalCode'];   
        this.setState({formAddress});

        this.handleChangeFormAddress(e);
    }

    loadAddress() { 
        this.props.fetchMartDeliveryAddress({page:1,size:10}); 
        this.setState({loadingAddress: true})
    }

    render() {
        const {cartItems, 
            cartSummary, 
            cartDeliveryAddress, 
            listDeliveryOption, 
            deliveryOption, 
            addressList, 
            selectedAddress, 
            selectedAddressID, 
            formAddress, 
            isLoading, 
            loadingAddress,
            isProcessAddress,
            breadcrumb, 
            showModal, 
            showModalConfirm,
            showModalInfo, 
            modalSection,
            modalConfirmTitle,
            modalConfirmBody,
            errorInputAddress,
            loadingProvince,
            loadingCity,
            loadingSubDistrict,
            loadingArea,
            provinces,
            cities,
            subdistricts,
            area,
            paymentNote } = this.state;  

        return (<React.Fragment>
                
                <Delivery 
                    cartItems={cartItems}
                    cartSummary={cartSummary} 
                    isLoading={isLoading}
                    breadcrumb={breadcrumb} 
                    cartDeliveryAddress={cartDeliveryAddress}
                    deliveryOption={deliveryOption}
                    listDeliveryOption={listDeliveryOption}
                    paymentNote={paymentNote}
                    onShowInfoCourier={this.toggleModalInfo}
                    
                    onChangeDeliveryOption={this.handleChangeDeliveryOption}
                    onChangeAddress={this.toggleModalAddress}
                />
            
            {/* Modal Confirmation */}
            <Modal  show={showModalConfirm} onHide={(e) => this.toggleModalConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalConfirmTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {modalConfirmBody}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => this.toggleModalConfirm(false)}>Batal</Button>
                    <Button variant="primary" disabled={isProcessAddress} onClick={(e) => this.toggleModalConfirm(true,'',true)}>{!isProcessAddress ? "Lanjutkan" : "Loading..."}</Button>
                </Modal.Footer>
            </Modal> 

            <Modal size="lg" show={showModal} onHide={(e) => this.toggleModalAddress(false)}>
                <Modal.Header closeButton>
                <Modal.Title>{modalSection != null && modalSection === "add-address" ? 
                    (selectedAddress != null && !isNaN(parseInt(selectedAddress.ID)) ? "Ubah Alamat" : "Tambah Alamat") : "Daftar Alamat"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalSection != null && modalSection === "add-address" ? 
                        <NewAddressComponent
                            values={formAddress}
                            errorInputAddress={errorInputAddress}
                            loadingProvince={loadingProvince}
                            loadingCity={loadingCity}
                            loadingSubDistrict={loadingSubDistrict}
                            loadingArea={loadingArea}
                            provinces={provinces}
                            cities={cities}
                            subdistricts={subdistricts}
                            area={area}

                            onChangeProvince={this.handleChangeProvince}
                            onChangeCity={this.handleChangeCity}
                            onChangeSubDistrict={this.handleChangeSubDistrict}
                            onChangeArea={this.handleChangeArea}
                            onChangeFormAddress={this.handleChangeFormAddress} 
                            onChangeLocationAddress={this.handleChangeLocationAddress} /> : 
                        <ListAddressComponent 
                            addressList={addressList}
                            selectedAddressID={selectedAddressID}
                            isLoading={isLoading}
                            loadingAddress={loadingAddress}

                            onEditAddress={this.handleEditAddress} 
                            onChangeSelectedAddress={this.handleChangeSelectedAddress}
                            onModalConfirm={this.toggleModalConfirm}
                        />}
                </Modal.Body>
                <Modal.Footer> 
                    {modalSection != null && modalSection === "add-address" ? 
                        <React.Fragment>
                            <Button variant="outline-primary" disabled={isLoading} onClick={(e) => this.toggleModalAddress(true,{action: "MODAL_SECTION", section: "list-address"})}>
                                Kembali
                            </Button>
                            <Button variant="primary" disabled={isLoading || isProcessAddress} onClick={(e) => this.toggleModalAddress(true, {action: "SAVE_ADDRESS", form: formAddress})}>
                                {!isProcessAddress ? "Simpan" : "Loading..."}
                            </Button> 
                        </React.Fragment> :
                        <React.Fragment>
                            <Button variant="outline-primary" disabled={errorInputAddress != null} onClick={(e) => this.toggleModalAddress(true,{action: "MODAL_SECTION", section: "add-address"})}>
                                Buat Alamat
                            </Button>
                            <Button variant="primary" onClick={(e) => this.toggleModalAddress(false,{action: "SELECT_ADDRESS"})}>
                                Pilih
                            </Button> 
                    </React.Fragment>
                }
                </Modal.Footer>
            </Modal>

            <Modal show={showModalInfo} onHide={this.toggleModalInfo}>
                <Modal.Header closeButton>
                    <Modal.Title>Informasi Pengiriman</Modal.Title>
                </Modal.Header>
                <Modal.Body>Pembelian produk dari toko ini tidak dapat menggunakan pilihan pengantaran 'Pengiriman', Ubah pilihan pengantaran untuk melanjutkan pesanan</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.toggleModalInfo}>
                        Close
                    </Button> 
                </Modal.Footer>
            </Modal>
                
            </React.Fragment>);
    }
}

const mapStateToProps = (state) => { 
    const { martproduct, martcart, martdelivery, wallet, regional } = state; 
    return { martproduct, martcart, martdelivery, wallet, regional }
};

export default withRouter(connect(mapStateToProps, {
    fetchMartProduct, 
    fetchMartDeliveryOption,
    fetchMartDeliveryAddress,  
    getCart,
    addCart,
    removeCart,
    saveMartDeliveryAddress,
    updateDeliveryAddress,
    updateDeliveryOption,
    deleteMartDeliveryAddress,
    fetchRegProvince,
    fetchRegCity,
    fetchRegSubDistrict,
    fetchRegArea
    //list of dispatch action
})(DeliveryContainer));

