// import action type newsnya
import { REQUEST_NEWS, RESULT_NEWS, REQUEST_NEWS_DETAIL, RESULT_NEWS_DETAIL, RESULT_TOTAL_NEWS } from "./../actions/news";

// state untuk menyimpan data 
const initialState = {
    // state ini sebagai tanda apakah sedang loading mengambil data atau tidak
    fetchingNews: false,
    fetchingNewsDetailById: false,
    
    // nantinya data news dari API disimpan di state news
    news: [],
    newsDetail:[],
};

export default function(state = initialState, action) {
  switch (action.type) {
    
    // Ketika action yang berjalan request news, artinya sedang loading data news
    case REQUEST_NEWS: { 
      return {
        ...state,
        fetchingNews: true
      };
    }

    case REQUEST_NEWS_DETAIL: { 
      return {
        ...state,
        fetchingNewsDetailById: true
      };
    }
    

    // data selesai di load, maka fetchingNews = false (selesai loading), dan datanya di simpan di state news
    
    case RESULT_NEWS: {
      return {
        ...state,
        fetchingNews: false,
        news: action.payload.data
      };
    }

    case RESULT_NEWS_DETAIL: { 
      return {
        ...state,
        fetchingNewsDetailById: false,
        newsDetail : action.payload.data
      };
    }

    case RESULT_TOTAL_NEWS: {

      return {
          ...state,
          fetchingNews: false,
          news: action.payload.data,
          news_total: action.payload.count
      };
  }

    default:
      return state;
  }
}