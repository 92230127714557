import React from 'react'; 
import PropTypes from 'prop-types';
import EmptyImage from './../../Images/empty-state.png';

// Empty state
function EmptyState(props) {
    const {teks, style, className} = props;

    const componentStyle = {
        box: {
            margin:'16px',
            border:'1px solid red'
        },
        teks: {
            padding:'16px'
        },
        ...style
    }
    return (<div className={'empty-state-box '+className} style={componentStyle}><img src={EmptyImage} alt={teks} /> 
        <div className="empty-state-teks" style={componentStyle.teks}>{teks}</div>
    </div>);
}


EmptyState.propTypes = {
    teks: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
}


EmptyState.defaultProps = {
    teks: "Tidak ada data",
    style: {},
    className: "",
}

export default EmptyState;