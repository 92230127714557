import { Formik } from "formik";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { forgot } from "./../../utils/JWTAuth";
import "./Auth.css";

var Logo = require(`./../../${process.env.REACT_APP_THEME_IMAGE}logo-white.png`);
var LogoColor = require(`./../../${process.env.REACT_APP_THEME_IMAGE}logo-color.png`);

// Validasi form disini
const ForgotSchema = Yup.object().shape({
  Email: Yup.string()
    .email("Format email salah")
    .min(4, "Email terlalu pendek, minimal 4 karakter")
    .required("Email harus diisi"),
});

class Forgot extends React.Component {
  constructor() {
    super();
    this.state = {
      input: { email: null },

      // ini untuk menandakan login sedang proses atau sudah selesai
      processForgot: false,
      responForgot: null,
      data: null,
      csdata: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [],
      "forgot_password"
    );
  }

  componentDidUpdate() {
    let { data } = this.state;

    if (data != null) {
      this.props.history.push("#forgot-password");
    }

    document.getElementById("formForgot").reset();
  }

  handleChange(e) {
    let input = {};
    let name = e.target.name;
    let value = e.target.value;

    input[name] = value;

    this.setState({ input });
    console.log(input);
  }

  async handleForgot({ Email }) {
    this.setState({ processForgot: true });

    let info = {
      Email: Email,
    };

    // mengambil result dari fungsi login (yang sudah direturn)
    let respon = await forgot(info);

    //kita akan menyimpan hasil login jika sudah dapat ke state
    this.setState({
      data: respon.data,
      responForgot: respon,
      processForgot: false,
    });
  }

  render() {
    const { processForgot, responForgot } = this.state;

    return (
      <div>
        <Row noGutters>
          <Col md={7} className="d-none d-lg-block">
            <div className="login-left-box">
              <div className="login-bg-pattern"></div>
              <div className="login-logo">
                <img src={Logo} alt="logo" width="100%"></img>
                {/* <p>Solusi untuk komunitas anda</p> */}
              </div>
            </div>
          </Col>

          <Col>
            <div className="login-form-wrapper">
              <div className="login-green-logo d-md-none">
                <img src={LogoColor} alt="logo agrego hijau" />
              </div>

              <div className="login-text-welcome">Lupa Password?</div>
              <div className="forgotText">
                Masukkan e-mail yang terdaftar. Kami akan mengirimkan email
                untuk mengatur ulang kata sandi.
              </div>

              {responForgot && !responForgot.success && (
                <div className="alert alert-danger login-message-error">
                  {responForgot.message}
                </div>
              )}

              {responForgot && responForgot.success && (
                <div className="alert alert-success login-message-error">
                  {responForgot.message}
                </div>
              )}

              <Formik
                enableReinitialize={true}
                initialValues={{ email: null }}
                validationSchema={ForgotSchema}
                onSubmit={(values, { setSubmitting }) => {
                  this.handleForgot(values);
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} id="formForgot">
                      <Form.Group
                        className="login-form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Alamat Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="Email"
                          value={values["email"]}
                          placeholder="Masukkan Alamat Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Email && touched.Email && (
                          <Form.Text className="text-danger">
                            {" "}
                            {errors.Email}{" "}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </form>
                  );
                }}
              </Formik>

              <Button
                variant="primary"
                block
                className="forgot-btn"
                disabled={processForgot}
                type="submit"
                form="formForgot"
              >
                {processForgot ? "Loading..." : "Kirim"}
              </Button>

              <div className="login-text-notforgot">
                Kembali ke halaman <a href="/login">login</a> atau
                <a href="/register"> daftar</a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Forgot);
