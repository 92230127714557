// import action type newsnya
import { REQUEST_PROVIDER, RESULT_PROVIDER, REQUEST_DIGITAL_BRAND, RESULT_DIGITAL_BRAND} from "./../actions/digital_brand";

// state untuk menyimpan data 
const initialState = {
    // state ini sebagai tanda apakah sedang loading mengambil data atau tidak
    fetchingProvider: false,
    fetchingDigitalBrand: false,
    
    // nantinya data news dari API disimpan di state news
    provider: [],
    digitalBrand:[]
    
};

export default function(state = initialState, action) {
  switch (action.type) {
    
    // Ketika action yang berjalan request news, artinya sedang loading data news
    case REQUEST_PROVIDER: { 
      return {
        ...state,
        fetchingProvider: true
      };
    }

    case REQUEST_DIGITAL_BRAND: { 
      return {
        ...state,
        fetchingDigitalBrand: true
      };
    }
    
    // data selesai di load, maka fetchingNews = false (selesai loading), dan datanya di simpan di state news
    
    case RESULT_PROVIDER: {
      return {
        ...state,
        fetchingProvider: false,
        provider: action.payload.data
      };
    }

    case RESULT_DIGITAL_BRAND: {
      return {
        ...state,
        fetchingDigitalBrand: false,
        digitalBrand: action.payload.data
      };
    }
   

    default:
      return state;
  }
}