import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../Layout/Breadcrumb";
import Loading from "./../../Layout/Loading";
import "./Address.css";

const styles = {
  title: {
    margin: "29px auto",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  children: {
    marginTop: "33px",
  },
};

function Address({
  isLoading,
  breadcrumb,
  pageTitle,
  loadingAddress,
  ...props
}) {
  return (
    <Container>
      {/* Loading State */}
      {(isLoading || loadingAddress) && <Loading />}

      {!isLoading && !loadingAddress && (
        <>
          <div>
            <div style={styles.title}>
              {pageTitle ? pageTitle : "Daftar Alamat"}
            </div>
          </div>

          <div>{breadcrumb != null && <Breadcrumb url={breadcrumb} />}</div>

          <div style={styles.children}>{props.children}</div>
        </>
      )}
    </Container>
  );
}

export default Address;
