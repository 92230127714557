import { ADD_TO_CART, 
    GET_ALL_CART, 
    GET_ALL_CART_DIGITAL, 
    GET_CART_BY_PRODUCT, 
    UPDATE_DELIVERY_ADDRESS, 
    UPDATE_DELIVERY_OPTION, 
    UPDATE_PAYMENT_METHOD, 
    UPDATE_DELIVERY_COURIER,
    UPDATE_STORE_DELIVERY_ADDRESS,
    REMOVE_CART_ITEM, 
    REQUEST_SUBMIT_ORDER, 
    RESULT_ADD_CART, 
    RESULT_SUBMIT_ORDER 
} from "./../actions/mart_cart";

import {
    getCarts, 
    getDigital,
    updateDeliveryAddress, 
    updateDeliveryOption, 
    updatePaymentMethod, 
    updateDeliveryCourier,
    updateStoreDeliveryAddress,
    removeFromCart } from "./../../utils/cart";

const initialState = {  
    stateCart: null,
    carts: getCarts(),
    cartDigital: getDigital(),
    cart: null,
    isSubmitOrder: false, 
    successOrder: false,
    orderData: null
};

export default function(state = initialState, action) {
    switch (action.type) {  
        case ADD_TO_CART: {
            // addToCart(action.payload.product);

            return {
                ...state, 
                stateCart: 'addcart',
                // carts: getCarts()
            };
        }

        case GET_ALL_CART: {  
            return {
                ...state,
                stateCart: 'getAllCart',
                carts: getCarts()
            }
        }

        case GET_ALL_CART_DIGITAL: {  
            return {
                ...state,
                stateCart: 'getAllCartDigital',
                cartDigital: getDigital()
            }
        }

        case GET_CART_BY_PRODUCT: {  
            return {
                ...state,
                cart: getCarts(action.payload.productID)
            }
        }

        case UPDATE_DELIVERY_ADDRESS: {   
            updateDeliveryAddress(action.payload.data);

            return {
                ...state,
                stateCart: 'addcart',
                carts: getCarts()
            };
        }

        case UPDATE_DELIVERY_OPTION: {
            updateDeliveryOption(action.payload.DeliveryOption);

            return {
                ...state,
                stateCart: 'addcart',
                carts: getCarts()
            };
        } 

        case UPDATE_PAYMENT_METHOD: {
            updatePaymentMethod(action.payload.data);

            return {
                ...state,
                stateCart: 'addcart',
                carts: getCarts()
            };
        }

        case UPDATE_DELIVERY_COURIER: { 
            updateDeliveryCourier(action.payload.data);

            return {
                ...state,
                stateCart: 'addcart',
                carts: getCarts()
            };
        }

        case UPDATE_STORE_DELIVERY_ADDRESS: { 
            updateStoreDeliveryAddress(action.payload.data);

            return {
                ...state,
                stateCart: 'update delivery address',
                carts: getCarts()
            };
        }

        case REMOVE_CART_ITEM: { 
            removeFromCart(action.payload.productID); 

            return {
                ...state,
                carts: getCarts()
            }
        }

        case REQUEST_SUBMIT_ORDER: { 

            return {
                ...state,
                isSubmitOrder: true
            }
        }

        case RESULT_SUBMIT_ORDER: {  
            // removeFromCart();

            return {
                ...state,
                isSubmitOrder: false,
                orderData: action.payload.data,
                successOrder: !action.payload.isError,
                orderMessage: action.payload.message
            }
        }

        case RESULT_ADD_CART: { 
            return {
                ...state, 
                stateCart: 'result-addcart',
                carts: getCarts()
            };
        }

        default:
            return state;
  }
}