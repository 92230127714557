import React, { useState } from 'react';
import {  Row, Col, Carousel } from 'react-bootstrap';

export default function ImageCarousel({images, ...props}) { 

    const [index, setIndex] = useState(0); 

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    return (<div {...props}>
        <Carousel indicators={false} activeIndex={index} onSelect={handleSelect}>
            {images && images.length > 0 && images.map(img => (<Carousel.Item interval={1000}>
                <img
                className="d-block w-100"
                src={img}
                alt="product-img"
                />
                {/* <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
            </Carousel.Item>))} 
        </Carousel>
        
        <Row noGutters style={{marginTop:'19px', marginLeft:'-5px', marginRight:'-5px'}}>
            {images && images.length > 0 && images.map((thumb, i) => (
                <Col xs={4} md={2} className="mx-md-1" style={{border: (index === i ? '2px solid #ccc' : ''), background:"#ccc" }}>
                    <img src={thumb} onClick={e => handleSelect(i)} alt="product-img" style={{width:'100%'}} />
                </Col>))} 
        </Row>
    </div>)
}