import Axios from "axios";
import { displayMessage, handleError } from "./app";

export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const RESULT_PROFILE = "RESULT_PROFILE";
export const UPDATING_PROFILE = "UPDATING_PROFILE";
export const UPDATED_PROFILE = "UPDATED_PROFILE";
export const PROFILE_PASSWORD_UPDATING = "PROFILE_PASSWORD_UPDATING";
export const PROFILE_PASSWORD_UPDATED = "PROFILE_PASSWORD_UPDATED";

const requestProfile = () => ({
  type: REQUEST_PROFILE,
});

const resultProfile = (data) => ({
  type: RESULT_PROFILE,
  payload: {
    data,
  },
});

const updatingProfile = () => ({
  type: UPDATING_PROFILE,
});

const updatedProfile = (data, isSuccess) => ({
  type: UPDATED_PROFILE,
  payload: {
    data,
    isSuccess,
  },
});

const updatingPassword = () => ({
  type: PROFILE_PASSWORD_UPDATING,
});

const updatedPassword = (data, isError) => ({
  type: PROFILE_PASSWORD_UPDATED,
  payload: {
    data,
    isError,
  },
});

// ACTION UNTUK FETCH DATA PROFILE
export const fetchProfile = () => {
  return (dispatch) => {
    dispatch(requestProfile());

    return Axios.get("/me/profile")
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultProfile(data.data));
      })
      .catch((error) => dispatch(handleError(error)));
  };
};

// ACTION UNTUK UPDATE PROFILE
export const updateProfile = (data) => {
  return (dispatch) => {
    dispatch(updatingProfile());

    // UPDATE MENGGUNAKAN METHOD PUT axios.put(URL, DATA, CONFIG), dan ada data yang dibawa di parameter ke dua
    return Axios.put("/me/profile", data)
      .then((response) => response.data)
      .then((data) => {
        return dispatch(updatedProfile(data.data, true));
      })
      .catch((error) => console.log("error"));
  };
};

export const updatePassword = (data) => {
  return (dispatch) => {
    dispatch(updatingPassword());
    return Axios.put("/me/password", data)
      .then((response) => response.data)
      .then((data) => {
        if (!data.status) {
          dispatch(
            displayMessage(
              data.usermsg ? data.usermsg : "Gagal mengubah password",
              true
            )
          );
          return dispatch(updatedPassword(null, true));
        }

        dispatch(displayMessage("Berhasil mengubah password"));
        return dispatch(updatedPassword(data.data));
      })
      .catch((error) => {
        console.log(error);

        dispatch(displayMessage("Gagal mengubah password", true));
        return dispatch(updatedPassword(null, true));
      });
  };
};
