import Axios from "axios";
import { setQueryString } from "./../../utils/common";
import { handleError } from "./app";

const searchFieldType = { CategoryID: "int" };

// baris di bawah ini di hapus aja gpp sama function laen yang ga diperluin lagi ndri

// 1. buat action type untuk mengambil data berita dari API
// 2. buat action creator / function untuk menghandle yang menjalankan request untuk mengambil data dari API

// Action type untuk news, ini bisa inline di file ini atau di import dari actionTypes.js
export const REQUEST_PROVIDER_PRODUCT = "REQUEST_PROVIDER_PRODUCT";
export const RESULT_PROVIDER_PRODUCT = "RESULT_PROVIDER_PRODUCT";
export const RESULT_ADD_PRODUCT = "RESULT_ADD_PRODUCT";
export const GET_ALL_CART_DIGITAL = "GET_ALL_CART_DIGITAL";

// Action Creator
// state ketika sedang loading request data
export const requestProviderProduct = () => ({
  type: REQUEST_PROVIDER_PRODUCT,
});

export const getCartDigital = () => ({
  type: GET_ALL_CART_DIGITAL,
});

// const resultAddProduct = () => ({
//   type: RESULT_ADD_PRODUCT
// })

// state ketika data sudah berhasil di dapat
export const resultProviderProduct = (data) => ({
  type: RESULT_PROVIDER_PRODUCT,
  payload: {
    data,
  },
});

export const fetchProviderProduct = (props) => {
  props = typeof props !== "undefined" ? props : {};
  props.searchFieldType = searchFieldType;

  let queryString = setQueryString(props) + "&Status=1&Status=int";

  return (dispatch) => {
    dispatch(requestProviderProduct());

    return Axios.get(
      "/digitalproduct/" + process.env.REACT_APP_OID + "?" + queryString
    )
      .then((response) => response.data)
      .then((data) => {
        return dispatch(resultProviderProduct(data.data));
      })
      .catch((error) => dispatch(handleError(error)));
  };
};
