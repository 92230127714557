import React from 'react';
import '../../Beranda.css';
import './Profile.css'; 
import { 
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchProfile, updateProfile } from './../../redux/actions/profile'; 
import Datadiri from './DataDiri.component';
import DatadiriEdit from './DataDiriEdit.component'; 
import Profile from './Profile.component';

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      action: null,
      actionTask: null,
      profile: [],
      breadcrumb: null,
      submittingProfile: false,
      successUpdate: false, 
    }

    this.handleSubmitProfile = this.handleSubmitProfile.bind(this);    
        
  }

  componentDidMount() {
    this.loadData();
        
  }

  componentDidUpdate() {
    const { fetchingProfile, isUpdatingProfile, successUpdateProfile, profile} = this.props.profile;
    const { isLoading, action, actionTask, submittingProfile} = this.state;
    const { extra } = this.props.match.params;
        
    let { successUpdate } = this.state;
    let { breadcrumb} = this.state;

    //ketika telah mendapat hasil dari api maka isi state local data dengan news dari redux
    if (!fetchingProfile && !isUpdatingProfile && isLoading) {
      successUpdate = submittingProfile && successUpdateProfile;

      // kembali ke view data diri setelah berhasil update data diri
      if (submittingProfile && successUpdate) {

          setTimeout(() => this.props.history.push('/profile'));

      }
      
      if (action === "datadiri" && actionTask === "edit") {
        breadcrumb = [
          { link: '/beranda', text: 'Beranda' },
          { link: '/profile/datadiri', text: 'Profil' },
          { link: '/profile/datadiri/edit', text: 'Data Pribadi' }
        ]
      }

      else {
        breadcrumb = [
          { link: '/beranda', text: 'Beranda' },
          { link: '/profile', text: 'Profile' },
        ]
      }

      this.setState({
        isLoading: false,
        profile: profile,
        submittingProfile: false,
        successUpdate: successUpdate,
        breadcrumb,
        })
    }

    if(typeof(extra) !== "undefined" && action === null){
      this.loadData()
    }
    
    // load data jika dari list menjadi edit
    // if (typeof (task) !== "undefined" && action !== actionTask) {
    //   this.loadData();
    //   // load data jika dari edit menjadi list

    //  } else if (typeof (extra) !== "undefined" && extra !== action) {
    //   this.loadData();
    // }

      
  }

  loadData(param) { 

    let { action, actionTask } = this.state;
    const { extra } = this.props.match.params;
    const { breadcrumb } = this.props.match;
   
    this.props.fetchProfile();


    if(typeof extra !== "undefined" && extra === "index") {
         action = "index";
    } 
    
    if(typeof extra !== "undefined" && extra === "edit"){
      action = "edit";
    }
    // if (typeof extra !== "undefined" && extra === "ahliwaris") {
    //   action = "ahliwaris";
    // } 
    
    // else if (typeof extra !== "undefined" && extra === "index") {
    //   action = "index";

    //   // defaultnya menampilkan data diri
    // } else {
    //   action = "datadiri";
    // }

    
    // if (typeof task !== "undefined" && task === "edit" ) {
    //   actionTask = "edit";
    // }
  

    this.setState({
      isLoading: true,
      action: action,
      actionTask,
      breadcrumb: breadcrumb

    }); 
  }

  // menghandle form submit profile
  handleSubmitProfile(values) { 
    this.props.updateProfile(values);
    
    this.setState({ isLoading: true, submittingProfile: true, successUpdate: false });
  }
  
  

  render() {
    const { isLoading, 
            action, 
            profile,  
            successUpdate, 
            breadcrumb, 
            
          } = this.state; 
    
    let renderComponent = null;

    if (action === "index") {
      renderComponent = <Profile />
    
    } else {
      if(action === "edit") {
        renderComponent = <DatadiriEdit
          profile={profile}
          isLoading={isLoading}
          breadcrumb={breadcrumb}

          // mendapatkan data form dari datadiriedit melalui props onSubmitProfile
          // dan handle dengan method handleSubmitProfile
          onSubmitProfile={this.handleSubmitProfile}
          successUpdate={successUpdate}
        />;
      } else {
        renderComponent = <Datadiri
          profile={profile}
          isLoading={isLoading}
        />
      }
    }

    return (<div>{renderComponent}</div>);
  }

}


const mapStateToProps = (state) => {
  const { profile} = state;
  return { profile}
};
export default withRouter(connect(mapStateToProps, {
  fetchProfile,
  updateProfile
    
  //list of dispatch action
})(ProfileContainer));
